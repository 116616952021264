import Mapper from 'data-import-mapper';

export default function mapLineItems(company) {
  const config = {
    lineItems: {
      from: 'lineItems',
      map: (item) => {
        if (item.lineItem && item.lineItem.base) {
          return;
        }

        const override =
          company &&
          company.company.lineItems &&
          company.company.lineItems.find((x) => x.lineItemId === item.lineItem.lineItemId);

        if (override) {
          item.price = override.price;
          item.lookerFee = override.lookerFee;
          item.conditions = override.conditions;
        }

        return {
          name: item.lineItem && item.lineItem.name ? item.lineItem.name : '',
          conditions: item.conditions,
          lineItemId: item.lineItemId,
          category: item.lineItem && item.lineItem.category ? item.lineItem.category : '',
          price: {
            US: {
              amount: item.price.amount,
              currency: item.price.currency
            }
          },
          lookerFee: {
            US: {
              amount: item.lookerFee.amount,
              currency: item.lookerFee.currency
            }
          },
          itemCode: item.lineItem && item.lineItem.itemCode ? item.lineItem.itemCode : '',
          glAccountNumber:
            item.lineItem && item.lineItem.glAccountNumber ? item.lineItem.glAccountNumber : '',
          taxCode: item.lineItem && item.lineItem.taxCode ? item.lineItem.taxCode : 'NONE',
          _id: item._id
        };
      }
    }
  };

  return new Mapper(config);
}
