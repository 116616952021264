import Joi from 'joi-browser';
import _ from 'lodash';
import moment from 'moment';
//Patterns
export const PHONE_PATTERN = /^\(*\+*[1-9]{0,3}\)*-*[1-9]{0,3}[-. /]*\(*[2-9]\d{2}\)*[-. /]*\d{3}[-. /]*\d{4} *e*x*t*\.* *\d{0,4}$/;
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ADDRESS_PATTERN = /^.*$/;

export const validators = [
  {
    id: 'is-address',
    label: 'Is an Address',
    icon: 'mail',
    type: 'text',
    validate: () => {
      return Joi.string().regex(ADDRESS_PATTERN, 'Address');
    }
  },
  {
    id: 'is-email',
    label: 'Is an Email',
    icon: 'mail',
    type: 'text',
    validate: () => {
      return Joi.string().regex(EMAIL_PATTERN, 'Email');
    }
  },
  {
    id: 'is-phone',
    label: 'Must Be a Phone Number',
    icon: 'phone',
    validate: () => {
      return Joi.string().regex(PHONE_PATTERN, 'Phone Number');
    }
  },
  {
    id: 'length-maximum',
    icon: 'angle left',
    label: 'Cannot exceed maximum length',
    validate: ({ value }) => {
      let validator = Joi.string();
      if (_.isFinite(value)) {
        validator = validator.max(value);
      }

      return validator;
    }
  },
  {
    id: 'length-minumum',
    icon: 'angle right',
    label: 'Must exceed minmum length',
    validate: ({ value }) => {
      let validator = Joi.string();
      if (_.isFinite(value)) {
        validator = validator.min(value);
      }
      return validator;
    }
  },
  {
    id: 'required',
    label: 'Required',
    icon: 'asterisk',
    validate: ({ value }) => {
      if (value) {
        let validator = Joi.string();
        if (_.isFinite(value)) {
          validator = validator.min(value);
        }
        return validator;
      }
      return Joi.string().required();
    }
  },
  {
    id: 'allowed',
    label: 'Allowed',
    icon: 'check',
    validate: ({ value }) => {
      let validator = Joi.string();
      if (_.isFinite(value)) {
        validator = validator.max(value);
      }

      return validator;
    }
  },
  {
    id: 'is-sanitized',
    icon: 'food',
    label: 'Sanitized: Cannot contain special characters.',
    validate: () => {
      return Joi.string().alphanum();
    }
  },
  {
    id: 'matches-pattern',
    label: 'Must match the pattern',
    icon: 'code',
    validate: ({ value }) => {
      let validator = Joi.string();
      if (_.isString(value) && value.length) {
        validator = validator.regex(new RegExp(value));
      }

      return validator;
    }
  },
  {
    id: 'amount-greater',
    label: 'Is Greater Than',
    icon: 'angle left',
    validate: ({ value }) => {
      let validator = Joi.number();
      if (_.isFinite(value)) {
        validator = validator.less(value);
      }

      return validator;
    }
  },
  {
    id: 'amount-less',
    label: 'Is Less Than',
    icon: 'angle right',
    validate: ({ value }) => {
      let validator = Joi.number();
      if (_.isFinite(value)) {
        validator = validator.greater(value);
      }

      return validator;
    }
  },
  {
    id: 'videolength-amount-greater',
    label: 'Is Length (in seconds) Greater Than',
    icon: 'file video outline',
    validate: ({ value }) => {
      let validator = Joi.number();
      if (_.isFinite(value)) {
        validator = validator.greater(value);
      }

      return validator;
    }
  },
  {
    id: 'videolength-amount-less',
    label: 'Is Length (in seconds) Less Than',
    icon: 'file video outline',
    validate: ({ value }) => {
      let validator = Joi.number();
      if (_.isFinite(value)) {
        validator = validator.less(value);
      }

      return validator;
    }
  },
  {
    id: 'is-currency',
    icon: 'dollar',
    label: 'Is American Currency',
    validate: () => {
      return Joi.number().precision(2);
    }
  },
  {
    id: 'is-reference-number',
    icon: 'number',
    label: 'Is a reference number',
    validate: () => {
      return Joi.string();
    }
  },
  {
    id: 'min-date',
    icon: 'angle left',
    label: 'minDate',
    validate: ({ value }) => {
      // eslint-disable-next-line no-unused-vars
      let date = moment()
        .add(value, 'h')
        .toString();
      date = moment(date).format('MM-DD-YYYY');
      return Joi.string();
    }
  },
  {
    id: 'max-date',
    icon: 'angle right',
    label: 'maxDate',
    validate: ({ value }) => {
      // eslint-disable-next-line no-unused-vars
      let date = moment()
        .add(value, 'h')
        .toString();
      date = moment(date).format('MM-DD-YYYY');
      return Joi.string();
    }
  }
];
