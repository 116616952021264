import createReducer from './create-reducer';

const initial = {
  page: 1,
  perPageCard: 10,
  perPageTable: 20,
  layout: 'table-layout', //todo make this come form layout strategy ?
  search: '',
  facets: [],
  pagerInfo: {},
  sort: null
};

const reducers = {
  UPDATE_TOOLTIPS_LAYOUT: (state, value) => {
    return {
      ...state,
      ...{
        pagerInfo: value.pagerInfo,
        layout: value.layout
      }
    };
  },
  UPDATE_TOOLTIPS_PAGE: (state, value) => {
    return { ...state, ...{ page: value.page, pagerInfo: value.pagerInfo } };
  },
  UPDATE_TOOLTIPS_PERPAGE: (state, value) => {
    return { ...state, ...{ perPage: value } };
  },
  UPDATE_TOOLTIPS_SEARCH_TERM: (state, value) => {
    return { ...state, ...{ search: value, page: 1 } };
  },
  UPDATE_TOOLTIPS_SEARCH_FACETS: (state, value) => {
    return { ...state, ...{ facets: value, page: 1 } };
  },
  CLEAR_TOOLTIP_SEARCH: (state, value) => {
    return { ...JSON.parse(JSON.stringify(initial)), page: 1 };
  },
  UPDATE_TOOLTIPS_SORT: (state, value) => {
    return { ...state, ...{ sort: value, page: 1 } };
  }
};

export default createReducer(JSON.parse(JSON.stringify(initial)), reducers);
