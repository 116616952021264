import './index.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Dimmer, Dropdown, Loader, Pagination } from 'semantic-ui-react';

import PublishSchema from '../../../graphql/mutations/publishSchemaMutation.graphql';
import GetSchemaFacets from '../../../graphql/queries/getSchemaFacets.graphql';
import GetAllPublishedSchemasWithVersions from '../../../graphql/queries/getAllPublishedSchemasWithVersions.graphql';
import {
  setCopyMachineTargetId,
  setDeleteMachineTarget,
  updateSchemasClientFacets,
  updateSchemasLayout,
  updateSchemasPage,
  updateSchemasSchemaGroupFacets,
  updateSchemasStatusFacets,
  updateSchemasTagsFacets,
  updateSchemasTerm,
  updateSchemasSort,
  addSchemaPermissions,
  // updateSchemaPermissions,
  removeSchemaPermission
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import SchemaCopyMachine from '../../schema-copy-machine';
import SchemaDeleteMachine from '../../schema-delete-machine';
import schemaLayoutStrategy from './schema-layout-strategy';

const FACETS_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.addSchemaPermissionsSearch,
    schemaDeleteMachine: state.schemaDeleteMachine,
    schemaCopyMachine: state.schemaCopyMachine,
    schemaPermissions: state.schemaPermissions
  };
}
@connect(stateToComponent)
@graphql(GetSchemaFacets, {
  name: 'schemaFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@graphql(PublishSchema, {
  name: 'publishSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
@graphql(GetAllPublishedSchemasWithVersions, {
  name: 'schemasData',
  options: (props) => {
    const {
      page,
      perPageCard,
      perPageTable,
      search,
      schemaGroupFacets,
      clientFacets,
      tagsFacets,
      statusFacets,
      layout,
      pagerInfo,
      sort
    } = props.search;

    const { ninIds = [] } = props;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        schemaGroupFacets,
        statusFacets,
        tagsFacets,
        page: _page,
        perPage: _perPage,
        companyIds: clientFacets,
        ninIds
      }
    };
  }
})
class SchemaAddPermissionsSearch extends Component {
  renderFacetSelector = () => {
    const { loading, schemaFacets, companyMany } = this.props.schemaFacets;

    const toFacet = (x) => ({ key: x, text: x, value: x });

    let items = loading
      ? { categories: [], tags: [], status: [], clients: [] }
      : {
          categories: schemaFacets.info_schemaGroup.map(toFacet),
          tags: schemaFacets.info_tags.map(toFacet),
          status: schemaFacets.status.map(toFacet),
          clients: companyMany.map((c) => ({
            key: c._id,
            text: c.name,
            value: c._id
          }))
        };

    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
    });

    return (
      <>
        <Dropdown
          placeholder="Status"
          icon="certificate"
          floating
          labeled
          button
          selection
          clearable
          header="Status"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateSchemasStatusFacets}
          value={this.props.search.statusFacets[0]}
          renderLabel={renderLabel}
          options={items.status}
        />
        <Dropdown
          placeholder="Categories"
          icon="folder open"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Categories"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateSchemasSchemaGroupFacets}
          renderLabel={renderLabel}
          value={this.props.search.schemaGroupFacets}
          options={items.categories}
          scrolling={true}
        />
        <Dropdown
          placeholder="Tags"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Tags"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateSchemasTagsFacets}
          renderLabel={renderLabel}
          value={this.props.search.tagsFacets}
          options={items.tags}
          scrolling={true}
        />
        <Dropdown
          placeholder="Companies"
          icon="building"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Clients"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateSchemasClientFacets}
          renderLabel={renderLabel}
          options={items.clients}
          scrolling={true}
        />
      </>
    );
  };

  changeView = (event, data) => {
    const { dispatch, schemasData } = this.props;
    const pagerInfo = _.get(schemasData, 'schemasData.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout =
      _.find(schemaLayoutStrategy, { id: data.value }) || schemaLayoutStrategy[0];

    dispatch(
      updateSchemasLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateSchemasStatusFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateSchemasStatusFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );
  updateSchemasSchemaGroupFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateSchemasSchemaGroupFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );
  updateSchemasClientFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateSchemasClientFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );
  updateSchemasTagsFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateSchemasTagsFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );
  updateSchemasClientFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateSchemasClientFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  renderLayoutContent = (items) => {
    const { who, search, dispatch, history, loadHandler, publishSchema, schemasData } = this.props;

    const activeLayout =
      _.find(schemaLayoutStrategy, { id: search.layout }) || schemaLayoutStrategy[0];

    return (
      <activeLayout.component
        items={items}
        publishedVersions={schemasData.schemaPaginationWithPublishedVersions.publishedVersions}
        who={who}
        schemaPermissions={this.props.schemaPermissions}
        model={activeLayout.model}
        actions={{
          load:
            loadHandler ||
            ((item) => {
              if (item._id) {
                history.push(`/schema-editor/${item._id}/edit-info`);
              }
            }),
          delete: (item) => {
            dispatch(setDeleteMachineTarget(item));
          },
          copy: (item) => {
            dispatch(setCopyMachineTargetId(item._id));
          },
          history: (item) => {
            history.push(`/schema/history/${item._id}`);
          },
          publish: {
            show: (item) => item.status !== 'published',
            action: (item) => {
              publishSchema({ variables: { _id: item._id } });
            }
          },
          addSchemaPermission: (payload) => {
            payload.companyId = this.props.companyId;
            dispatch(addSchemaPermissions(payload));
          },
          removeSchemaPermission: (payload) => {
            dispatch(removeSchemaPermission(payload));
          }
        }}
      />
    );
  };

  render() {
    const { schemasData, dispatch } = this.props;
    const { refetch } = schemasData;

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Schemas</Loader>
          </Dimmer>
        </div>
      </div>
    );

    if (!schemasData.schemaPaginationWithPublishedVersions) {
      schemasData.refetch();
      return loader;
    }

    const items = _.get(schemasData, 'schemaPaginationWithPublishedVersions.items', []);
    const pageInfo = _.get(schemasData, 'schemaPaginationWithPublishedVersions.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);

    const { currentPage, pageCount } = pageInfo;

    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateSchemasPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace schemas-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            label="Schemas"
            search={this.props.search}
            strategy={schemaLayoutStrategy}
            searchAction={updateSchemasTerm}
            filters={this.renderFacetSelector()}
            sort={updateSchemasSort}
            viewToggle={this.changeView}
          />
          {results}
        </div>
        {pager}
        <SchemaCopyMachine refetch={refetch} />
        <SchemaDeleteMachine refetch={refetch} />
      </div>
    );
  }
}
export default SchemaAddPermissionsSearch;
