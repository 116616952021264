import React, { Component } from 'react';
import { graphql } from 'react-apollo';

import { loadLineItem } from '../../../state/actions';
import updateLineItem from '../../../graphql/mutations/updateLineItem.graphql';
import SaveActions from '../../common/save-actions.js';

@graphql(updateLineItem, {
  name: 'updateLineItem',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
class lineItemViewActions extends Component {
  render() {
    const { lineItem, updateLineItem } = this.props;
    const actions = [
      {
        label: 'Save',
        icon: 'save',
        action: 'update'
      }
    ];

    return (
      <SaveActions
        actions={actions}
        entity={lineItem}
        entityType={'lineItem'}
        updateQuery={updateLineItem}
        loadAction={loadLineItem}
      />
    );
  }
}

export default lineItemViewActions;
