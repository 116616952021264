import createReducer from './create-reducer';
import { removeFromList } from './list-manipulation.js';

const initial = null;

const reducers = {
  CLEAR_COMPANY: (state, value) => {
    return initial;
  },
  LOAD_COMPANY: (state, value) => {
    console.log('loaded', value);
    return JSON.parse(JSON.stringify(value));
  },
  UPDATE_COMPANY: (state, value) => {
    return { ...state, ...value };
  },
  ADD_COMPANY_BILLING_CENTER: (state, value) => {
    const newBillingCenter = {
      name: 'New Billing Center ' + state.billingCenters.length,
      accountingId: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        type: ''
      }
    };
    const newBillingCenters = [...state.billingCenters, newBillingCenter];
    return {
      ...state,
      billingCenters: newBillingCenters
    };
  },
  REMOVE_COMPANY_BILLING_CENTER: (state, value) => {
    return {
      ...state,
      billingCenters: removeFromList(state.billingCenters, value)
    };
  }
};

export default createReducer(initial, reducers);
