import { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import GetDefaultsQuery from '../../graphql/queries/getDefaults.graphql';
import {
  setSchemaDefaultLineItems,
  setSchemaDefaultFields,
  loadDefaults
} from '../../state/actions';
import { initial } from '../../state/reducers/edit-schema-info';
import * as schemaSchema from '../../state/reducers/edit-schema-schema';
import { omitDeep } from '../../apollo/apollo-typename-cleaner';

function stateToComponent(state) {
  return {};
}

@graphql(GetDefaultsQuery, {
  name: 'defaults',
  options: (props) => {
    return {
      skip: () => {
        return initial.lineItems.length;
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    };
  }
})
@connect(stateToComponent)
@withRouter
class DefaultsProvider extends Component {
  shouldUpdateSchemaDefaults = () => {
    const { defaults } = this.props;
    return !defaults.loading && !initial.lineItems.length && !schemaSchema.initial.fields.length;
  };

  mapLineItems = (lineItem) => {
    const item = { ...lineItem };
    item.lineItemId = lineItem._id;
    item.required = true;

    return omitDeep(item, '__typename');
  };
  mapFields = (field) => {
    const mapped = { ...JSON.parse(JSON.stringify(field)) };
    mapped.uniqueFieldId = mapped._id;
    delete mapped._id;

    mapped.validations.forEach((x) => {
      if (x.value === null) {
        delete x.value;
      }
    });

    if (!mapped.options) {
      delete mapped.options;
    }

    return omitDeep(mapped, '__typename');
  };

  componentDidUpdate() {
    const { defaults, dispatch } = this.props;
    const { defaultsOne } = defaults;

    if (this.shouldUpdateSchemaDefaults()) {
      const lineItems = (defaultsOne && defaultsOne.lineItems.map(this.mapLineItems)) || [];
      const fields = (defaultsOne && defaultsOne.fields.map(this.mapFields)) || [];

      // Setup reference number
      dispatch(setSchemaDefaultLineItems(lineItems));
      dispatch(setSchemaDefaultFields(fields));
      dispatch(loadDefaults(defaultsOne));
    }
  }

  render() {
    return this.props.children;
  }
}

export default DefaultsProvider;
