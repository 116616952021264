import React, { Component } from 'react';

import SchemaCard from './schema-card';

class SchemaCards extends Component {
  itemToCardHtml = (item, index) => {
    const { actions, model } = this.props;

    return (
      <SchemaCard
        key={`card-${item._id}`}
        actions={actions}
        result={item}
        index={index}
        model={model}
      />
    );
  };

  render() {
    const { items } = this.props;

    const content = items.map(this.itemToCardHtml);

    return <div className="search-results">{content}</div>;
  }
}

export default SchemaCards;
