import './company-ordering.css';

import { FormRenderer } from '@wegolook/schema-form-renderer';
import React, { Component } from 'react';

class CompanyOrderingContext extends Component {
  render() {
    return (
      <FormRenderer
        {...this.props}
        schema={this.props.editSchemaSchema}
        formValue={this.props.editSchemaPreview}
        onChange={this.props.onChange}
        renderContext="client-ordering"
      />
    );
  }
}

export default CompanyOrderingContext;
