import _ from 'lodash';

export function removeById(list, item) {
  return _.filter(list, (li) => {
    return li.id !== item.id;
  });
}

export function removeFromList(list, predicate) {
  if (_.isNumber(predicate)) {
    return [...list.slice(0, predicate), ...list.slice(predicate + 1)];
  }
  return _.reject(list, predicate);
}

//index can also be an object that
//will find the index of that comparator
export function replaceInList(list, newThing, index, addIfNotFound = false) {
  let _index = index;

  //remove objects with empty arrays
  const condition = newThing['all'] || newThing['any'];
  if (condition && condition.length === 0) {
    let newIndex = _index;
    if (!_.isNumber(_index)) {
      newIndex = _.findIndex(list, index);
    }
    const newList =
      list.length > 1 && newIndex ? [...list.filter((val, ind) => ind !== newIndex)] : [];
    return newList;
  }

  //its either a function or an object.
  if (!_.isNumber(_index)) {
    _index = _.findIndex(list, index);
  }

  if (_index === -1) {
    if (addIfNotFound) {
      return [...list, newThing];
    }
    return [...list];
  }

  return [...list.slice(0, _index), newThing, ...list.slice(_index + 1, list.length)];
}

export function moveInList(list, predicate, distance) {
  const index = _.findIndex(list, predicate);
  const item = list[index];

  const newList = [...list.slice(0, index), ...list.slice(index + 1, list.length)];

  newList.splice(index - distance, 0, item);

  return newList;
}

export function setPropInList(list, item, prop, newValue) {
  const index = _.findIndex(list, {
    id: item.id
  });

  return [
    ...list.slice(0, index),
    { ...item, ...{ [prop]: newValue } },
    ...list.slice(index + 1, list.length)
  ];
}

export function keysDeep(obj, parent = '') {
  let keys = _.keys(obj).map((k) => parent + k);

  keys.forEach((k) => {
    const prop = _.get(obj, k);

    if (_.isObject(prop) && !_.isArray(prop)) {
      keys = keys.concat(keysDeep(_.get(obj, k), k + '.'));
    }

    if (_.isArray(prop)) {
      prop.forEach((item, index) => {
        keys = keys.concat(keysDeep(_.get(item, k), k + `[${index}].`));
      });
    }
  });

  return keys.filter((k) => {
    const prop = _.get(obj, k);
    return !_.isObject(prop) && !_.isArray(prop);
  });
}

export function pickDeep(to, from) {
  return _.pick(_.cloneDeep(to), keysDeep(from));
}

export function omitDeep(to, from) {
  return _.omit(_.cloneDeep(to), keysDeep(from));
}

export function defaultDeep(to, from, defaultKeys, keepKeys, omitKeys) {
  return Object.assign(
    {},
    pickDeep(to, from),
    omitDeep(_.omit(from, omitKeys), to),
    _.pick(from, defaultKeys),
    _.pick(to, keepKeys)
  );
}
