import _ from 'lodash';
import { v4 as uuid } from 'uuid'

import createReducer from './create-reducer';
import { removeById, replaceInList } from './list-manipulation';

const initial = {
  fields: [],
  groups: [
    {
      name: 'Basic Information',
      metadata: [],
      fields: [],
      groupType: 'generic'
    }
  ]
};

function groupSequencedFields(fields, groups) {
  let fieldSequence = _.map(
    _.flatMap(groups, (g) => {
      return _.filter(fields, (f) => {
        return f.group === g.name;
      });
    }),
    (f, i) => {
      let nf = { ...f, ...{ index: i } };
      return nf;
    }
  );

  return fieldSequence;
}

const reducers = {
  CLEAR_SCHEMA: (state, value) => {
    return JSON.parse(JSON.stringify(initial));
  },
  SET_DEFAULT_FIELDS: (state, value) => {
    //TODO: remove HACK to unfreeze
    initial.fields = JSON.parse(JSON.stringify(value));
    initial.groups[0].fields = initial.fields.map((x) => x.id);
    return state;
  },

  LOAD_SCHEMA: (state, value) => {
    return value;
  },
  EDIT_SCHEMA_INFO: (state, value) => {
    const { property, newValue } = value;

    let _newInfo = { ...state.info };

    _newInfo[property] = newValue;

    return {
      ...state,
      ...{ info: _newInfo }
    };
  },
  EDIT_SCHEMA_INFO_TAGS: (state, value) => {},
  ADD_SCHEMA_FIELD: (state, value) => {
    const { field, targetGroup } = value;

    const _keys = _.keys(field);

    const activeGroupIndex = _.findIndex(state.groups, { name: targetGroup }) || 0;

    let _addField = {
      ...field
    };

    if (_.includes(_keys, 'options')) {
      const _newOptions = _.map(field.options, (option) => {
        return { ...option, ...{ id: uuid() } };
      });
      _addField = {
        ..._addField,
        ...{ options: _newOptions }
      };
    }

    _addField = {
      ..._addField,
      ...{ group: state.groups[activeGroupIndex].name }
    };

    const newSchemaGroups = _.map(state.groups, (schemaGroup) => {
      let fg = { ...schemaGroup };

      if (fg.name === targetGroup) {
        fg.fields = _.uniq([...fg.fields, _addField.id]);
      } else {
        fg.fields = _.filter(fg.fields, (id) => {
          return id != _addField.id;
        });
      }

      return fg;
    });

    let newFields = [...state.fields, _addField];

    newFields = groupSequencedFields(newFields, newSchemaGroups);

    return {
      ...state,
      ...{
        fields: newFields,
        groups: newSchemaGroups
      }
    };
  },
  UPDATE_SCHEMA_FIELD: (state, value) => {
    const { field } = value;
    const newSchemaGroups = _.map(state.groups, (schemaGroup) => {
      let sg = { ...schemaGroup };
      sg.fields = replaceInList(sg.fields, field, { id: field.id });
      return sg;
    });

    return {
      ...state,
      ...{
        fields: groupSequencedFields(
          replaceInList(state.fields, field, { id: field.id }),
          newSchemaGroups
        ),
        groups: newSchemaGroups
      }
    };
  },
  REMOVE_SCHEMA_FIELD: (state, value) => {
    const newSchemaGroups = _.map(state.groups, (schemaGroup) => {
      let sg = { ...schemaGroup };
      sg.fields = _.filter(sg.fields, (fid) => fid != value.id);
      return sg;
    });

    return {
      ...state,
      ...{
        fields: groupSequencedFields(removeById(state.fields, value), newSchemaGroups),
        groups: newSchemaGroups
      }
    };
  },
  MOVE_SCHEMA_FIELD: (state, value) => {
    const { field, distance } = value;
    const fieldIndex = _.findIndex(state.fields, { id: field.id });

    let newFields = [
      ...state.fields.slice(0, fieldIndex),
      ...state.fields.slice(fieldIndex + 1, state.fields.length)
    ];

    newFields.splice(fieldIndex - distance, 0, field);
    newFields.forEach((x, i) => (x.index = i));

    return {
      ...state,
      ...{
        fields: newFields
      }
    };
  },
  MOVE_SCHEMA_GROUP: (state, value) => {
    const { group, distance } = value;
    const groupIndex = _.findIndex(state.groups, { name: group.name });

    let newGroups = [
      ...state.groups.slice(0, groupIndex),
      ...state.groups.slice(groupIndex + 1, state.groups.length)
    ];

    newGroups.splice(groupIndex - distance, 0, group);

    return {
      ...state,
      ...{
        groups: newGroups
      }
    };
  },

  // CLONE_SCHEMA_FIELD: (state, value) => {
  //   const { field, distance } = value;
  //   const fieldIndex = _.findIndex(state.fields, { id: field.id });

  //   const overrideObject = {
  //     id: uuid()
  //   };

  //   if (field.name) {
  //     overrideObject.name =
  //       field.component.toLowerCase() + '-' + overrideObject.id;
  //   }

  //   let _overiddenIdObject = {
  //     ...field,
  //     ...overrideObject
  //   };

  //   if (field.options && field.options.length) {
  //     _overiddenIdObject.options = _.map(
  //       field.options,
  //       (option, optionIndex) => {
  //         return { ...option, ...{ id: uuid() } };
  //       }
  //     );
  //   }

  //   const newFields = [...state.fields];

  //   newFields.splice(fieldIndex - (distance || -1), 0, _overiddenIdObject);

  //   return {
  //     ...state,
  //     ...{
  //       fields: newFields
  //     }
  //   };
  // },
  EDIT_SCHEMA_FIELD_UNIQUEFIELD: (state, value) => {
    const { field, uniqueField } = value;

    const newFields = replaceInList(
      state.fields,
      {
        ...field,
        ...uniqueField,
        id: field.id,
        uniqueFieldId: uniqueField._id
      },
      { id: field.id }
    );

    const newState = {
      ...state,
      ...{
        fields: newFields
      }
    };
    return newState;
  },
  REMOVE_SCHEMA_FIELD_UNIQUEFIELD: (state, value) => {
    const { field } = value;

    const newFields = replaceInList(
      state.fields,
      { ...field, uniqueFieldId: null },
      { id: field.id }
    );

    const newState = {
      ...state,
      ...{
        fields: newFields
      }
    };
    return newState;
  },
  EDIT_SCHEMA_FIELD_GROUPS_OPTIONS: (state, value) => {
    const { groupsOptions } = value;
    return {
      ...state,
      ...{
        groups: groupsOptions
      }
    };
  },

  EDIT_SCHEMA_FIELD_GROUP: (state, value) => {
    const { field, group } = value;

    const groups = state.groups.map((g) => {
      const fields =
        g.name === group ? _.uniq([...g.fields, field.id]) : g.fields.filter((x) => x !== field.id);

      return { ...g, fields };
    });

    const fields = replaceInList(state.fields, { ...field, group }, { id: field.id });

    return {
      ...state,
      ...{
        groups,
        fields
      }
    };
  },
  // EDIT_SCHEMA_GROUP: (state, value) => {
  //   let newFields = [...state.fields];
  //   let newSchemaGroups = [...state.groups];
  //   //update this group

  //   //replace the name in the groups on the fields.

  //   const newState = {
  //     ...state,
  //     ...{
  //       fields: newFields,
  //       groups: newSchemaGroups
  //     }
  //   };
  //
  // },

  REMOVE_INVALID_FIELD_GROUP: (state, value) => {
    const { fieldId, group } = value;
    const newGroups = _.cloneDeep(state.groups);
    const currentGroup = newGroups.find((i) => i.name === group.name);
    const index = currentGroup.fields.indexOf(fieldId);
    if (index > -1) {
      currentGroup.fields.splice(index, 1);
    }

    const newState = {
      ...state,
      ...{
        groups: newGroups
      }
    };
    return newState;
  },

  CHANGE_ACTIVE_GROUP_NAME: (state, value) => {
    const newState = {
      ...state,
      ...{
        activeGroupName: value
      }
    };
    return newState;
  },
  EDIT_SCHEMA_FIELD_GROUP_DETAILS: (state, _val) => {
    const { group, value, prop } = _val;

    let newGroup = {};
    prop.forEach((item) => {
      newGroup[item] = value[item];
    });
    let newFields = [...state.fields];

    newFields = state.fields
      .filter((f) => f.group === group.name)
      .map((f) => ({ ...f, group: value.name }))
      .reduce(
        (fields, f) => {
          return replaceInList(fields, { ...f }, { id: f.id });
        },
        [...state.fields]
      );

    const newSchemaGroups = replaceInList(
      state.groups,
      { ...group, ...newGroup },
      { name: group.name }
    );

    const newState = {
      ...state,
      groups: newSchemaGroups,
      fields: newFields
    };

    return newState;
  },
  ADD_SCHEMA_FIELD_GROUP: (state, value) => {
    const { group, index } = value;

    const existingGroups = _.filter(state.groups, (g) => {
      return g.name !== group.name;
    });

    const newGroups = [
      ...existingGroups.slice(0, index),
      group,
      ...existingGroups.slice(index, existingGroups.length)
    ];

    const newState = {
      ...state,
      groups: newGroups
    };

    return newState;
  },
  REMOVE_SCHEMA_FIELD_GROUP: (state, value) => {
    const { group } = value;

    const newSchemaGroups = _.filter(state.groups, (g) => {
      return g.name !== group.name;
    });

    const defaultGroup = newSchemaGroups.find((x) => x.name === 'Basic Information');

    const otherFields = state.fields.filter((x) => x.group !== group.name);

    const newGroups = replaceInList(newSchemaGroups, { ...defaultGroup }, { id: defaultGroup.id });
    const newState = {
      ...state,
      groups: newGroups,
      fields: [...otherFields]
    };

    return newState;
  }
};

export default createReducer(JSON.parse(JSON.stringify(initial)), reducers);
export { initial };
