import React, { Component } from 'react';
import { Header, Icon } from 'semantic-ui-react';

class Placeholder extends Component {
  render() {
    return (
      <div className="placeholder-cta">
        <Header as="h3" disabled>
          <Icon name={this.props.icon} circular />
          <Header.Content>
            {this.props.header}
            <Header.Subheader>{this.props.subheader}</Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    );
  }
}

export default Placeholder;
