import createReducer from './create-reducer';

const initial = {};

const reducers = {
  LOAD_USER: (state, value) => {
    return value;
  }
};

export default createReducer(initial, reducers);
