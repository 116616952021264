import { SearchActionButtonSection } from '../../search/actionSection';
import SearchInfoSection from '../../search/infoSection';
import SearchTable from '../../search/search-table';
import SchemaCards from './schema-cards';

function actionSection(actions) {
  return [
    {
      icon: 'edit',
      label: 'Edit',
      action: actions.load,
      routes: ['schema/search']
    },
    {
      icon: 'edit',
      label: 'Select',
      action: actions.load,
      routes: ['company/view']
    },
    {
      icon: 'copy',
      label: 'Copy',
      action: actions.copy,
      routes: ['schema/search']
    },
    {
      icon: 'delete',
      label: 'Delete',
      action: actions.delete,
      routes: ['schema/search']
    },
    {
      icon: 'history',
      label: 'History',
      action: actions.history,
      routes: ['schema/search']
    },
    {
      icon: 'certificate',
      label: 'Publish',
      action: actions.publish,
      routes: ['schema/search']
    }
  ];
}

function infoSection(actions) {
  // actions may be necessary
  // if you template something
  // with some kind of view inside of it.
  return [
    {
      icon: 'user',
      content: (item) => `author: ${item.author.firstName} ${item.author.lastName}`,
      routes: ['search', 'company-view']
    }
  ];
}

// TODO: extend this model to have a card
// content
// contentInfo
// in the model.
// this will result in a 'generalized' model for cards
// now we can consider refactoring them.
export default [
  {
    id: 'card-layout',
    icon: 'block layout',
    label: 'Card',
    description: 'Convert layout to a cards. More detail; less per page',
    component: SchemaCards,
    model: {
      infoSection: infoSection,
      actionSection: actionSection
    }
  },
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    component: SearchTable,
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Title',
        property: 'info.schemaName',
        type: 'string'
      },
      {
        label: 'Sub Schema',
        property: 'info.subSchema',
        type: 'string'
      },
      {
        label: 'Schema Group',
        property: 'info.schemaGroup',
        type: 'string'
      },
      {
        label: 'Info',
        type: 'component',
        component: SearchInfoSection,
        model: infoSection
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ]
  }
];
