import '../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader, Pagination, Dropdown } from 'semantic-ui-react';

import GetAllCompaniesQuery from '../../../graphql/queries/getAllCompanies.graphql';
import GetCompanyFacets from '../../../graphql/queries/getCompanyFacets.graphql';
import {
  updateCompaniesFacets,
  updateCompaniesLayout,
  updateCompaniesPage,
  updateCompaniesTerm,
  updateCompaniesSort,
  clearCompany
} from '../../../state/actions';
import companyLayoutStrategy from './company-layout-strategy';
import FilterBar from '../../filter-bar';

const FACETS_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.companySearch
  };
}

@withRouter
@connect(stateToComponent)
@graphql(GetAllCompaniesQuery, {
  name: 'companyData',
  options: (props) => {
    const { exclude } = props;
    const {
      sort,
      page,
      perPageCard,
      perPageTable,
      search,
      facets,
      layout,
      pagerInfo
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        facets,
        page: _page,
        perPage: _perPage,
        notInIds: exclude
      }
    };
  }
})
@graphql(GetCompanyFacets, {
  name: 'companyFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
class CompanySearch extends Component {
  renderFacetSelector = () => {
    const { loading } = this.props.companyFacets;

    let items = loading ? [] : [];
    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
      // icon: 'check'
    });

    return (
      <Dropdown
        text="Tags"
        icon="tags"
        floating
        labeled
        button
        multiple
        selection
        search
        header="Tags"
        className="icon utility-bar-facet-dropdown"
        onChange={this.updateCompaniesFacets}
        renderLabel={renderLabel}
        value={this.props.search.facets}
        options={items}
        scrolling={true}
      />
    );
  };

  changeView = (event, data) => {
    const { dispatch, companyData } = this.props;
    const pagerInfo = _.get(companyData, 'companyData.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout =
      _.find(companyLayoutStrategy, { id: data.value }) || companyLayoutStrategy[0];

    dispatch(
      updateCompaniesLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateCompaniesFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateCompaniesFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  loadCompany = (company) => {
    const { history, dispatch } = this.props;
    dispatch(clearCompany());
    history.push(`/company/view/${company._id}`);
  };

  renderLayoutContent = (items) => {
    const { who, search, loadHandler } = this.props;
    const activeLayout =
      _.find(companyLayoutStrategy, { id: search.layout }) || companyLayoutStrategy[0];

    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{
          select: loadHandler || this.loadCompany
        }}
      />
    );
  };

  render() {
    const { companyData, dispatch } = this.props;
    const { loading } = companyData;

    const items = _.get(companyData, 'companyPagination.items', []);
    const pageInfo = _.get(companyData, 'companyPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Companies</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateCompaniesPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace company-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            label="Companies"
            sort={updateCompaniesSort}
            search={this.props.search}
            strategy={companyLayoutStrategy}
            searchAction={updateCompaniesTerm}
            filters={this.renderFacetSelector()}
            viewToggle={this.changeView}
          />
          {content}
        </div>
        {pager}
      </div>
    );
  }
}

export default CompanySearch;
