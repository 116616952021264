import 'react-minimap/dist/react-minimap.css';
import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Icon } from 'semantic-ui-react';
import { Button, Menu, Tab } from 'semantic-ui-react';

import {
  changeEditingField,
  changeVisibleGroupName,
  clearTooltipSearch,
  moveSchemaGroup,
  updateSchemaField
} from '../../../state/actions';
import EditFieldDetailsModal from '../edit-field-details-modal';
import ToolBar from '../toolbar';
import EditGroupsModal from './edit-groups-modal';
import FieldList from './field-list';
import editSchemaSchema from '../../../state/reducers/edit-schema-schema';

function stateToComponent(state) {
  const activeIndex = state.editSchemaSchema.groups.findIndex(
    (g) => g.name === state.editSchemaToolbar.activeGroupsTabName
  );

  return {
    editSchemaSchema: state.editSchemaSchema,
    editSchemaField: state.editSchemaField,
    editSchemaToolbar: state.editSchemaToolbar,
    activeIndex: activeIndex !== -1 ? activeIndex : 0
  };
}
@connect(stateToComponent)
class EditFieldsView extends Component {
  state = {
    activeIndex: this.props.activeIndex,
    editing: null,
    editingGroup: null
  };

  ascendInList = (group) => (e) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    if (group.name === this.props.editSchemaToolbar.activeGroupsTabName) {
      this.setState({ activeIndex: this.state.activeIndex - 1 });
    }
    dispatch(
      moveSchemaGroup({
        group,
        distance: 1
      })
    );
    dispatch(changeVisibleGroupName(this.props.editSchemaToolbar.activeGroupsTabName));
  };

  descendInList = (group) => (e) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    if (group.name === this.props.editSchemaToolbar.activeGroupsTabName) {
      this.setState({ activeIndex: this.state.activeIndex + 1 });
    }
    dispatch(
      moveSchemaGroup({
        group,
        distance: -1
      })
    );

    dispatch(changeVisibleGroupName(this.props.editSchemaToolbar.activeGroupsTabName));
  };

  placeholder = () => {
    return (
      <div className={`empty-message`}>
        <Header as="h1" icon textAlign="center">
          <Icon name="file text outline" circular />
          <Header.Content>
            This Schema Group is Empty
            <Header.Subheader>
              This Schema is still empty because no fields have been added yet.
              <br /> Select an item on the left to add that form field to the schema.
            </Header.Subheader>
          </Header.Content>
        </Header>
      </div>
    );
  };

  makeMenuItem = (group, i) => {
    const { groups } = this.props.editSchemaSchema;

    let menuItem = <Menu.Item key={`groups-${group.name}`}>{group.name}</Menu.Item>;

    const first = i === 1;
    const last = i === groups.length - 1;

    if (groups.length === 1) {
      menuItem = (
        <div className="active item">
          {group.name}
          <Icon
            className="plus-icon-button"
            size="large"
            title="Add Group Here"
            onClick={() => {
              this.setState({ editingGroup: group });
            }}
            name="plus"
          />
        </div>
      );
    }

    if (i !== 0) {
      menuItem = (
        <Menu.Item key={`groups-${group.name}`}>
          <div class="group-interface">
            <span className="group-interface-label">{group.name}</span>
            <Button.Group className="group-interface-controlpanel" size={`mini`}>
              <Button
                icon
                circular
                basic
                size={'mini'}
                onClick={this.ascendInList(group)}
                disabled={first}
                title="Raise To Higher Position in List"
              >
                <Icon name="arrow up" />
              </Button>
              <Button
                icon
                circular
                basic
                size={'mini'}
                onClick={this.descendInList(group)}
                disabled={last}
                title="Lower Position in List"
              >
                <Icon name="arrow down" />
              </Button>
              <Button
                icon
                circular
                basic
                size={'mini'}
                onClick={() => {
                  this.setState({
                    editingGroup: group
                  });
                }}
                title="Add Group Here"
              >
                <Icon name="plus" />
              </Button>
              <Button
                icon
                circular
                basic
                size={'mini'}
                onClick={() => {
                  this.setState({
                    editingCurruntGroup: group
                  });
                }}
                title="Edit Group"
              >
                <Icon name="edit" />
              </Button>
            </Button.Group>
          </div>
        </Menu.Item>
      );
    }

    return menuItem;
  };

  makePane = (group) => () => {
    const { fields, groups } = this.props.editSchemaSchema;
    const { activeIndex } = this.state;

    let groupFields = _.filter(fields, (x) => groups[activeIndex].fields.includes(x.id));
    let groupType = groups[activeIndex].groupType;

    const listContents = groupFields.length ? <FieldList data={groupFields} /> : this.placeholder();
    return (
      <Tab.Pane className="list-content">
        <Header as="h3" content={`GroupType: ${groupType || ''}`} />
        {listContents}
      </Tab.Pane>
    );
  };

  groupToPane = (group, i) => {
    return {
      menuItem: this.makeMenuItem(group, i),
      render: this.makePane(group)
    };
  };

  groupsToPanes = () => {
    const { groups } = this.props.editSchemaSchema;
    return _.map(groups, this.groupToPane);
  };

  handleGroupTabChange = (e, value) => {
    const { dispatch } = this.props;
    const { groups } = this.props.editSchemaSchema;

    dispatch(changeVisibleGroupName(groups[value.activeIndex].name));
    this.setState({
      activeIndex: value.activeIndex
    });
  };

  closeModal = () => {
    const { dispatch, editSchemaField } = this.props;
    const field = editSchemaField.field;

    this.setState({
      openEditor: false
    });
    dispatch(
      updateSchemaField({
        field
      })
    );
    dispatch(clearTooltipSearch());
    dispatch(
      changeEditingField({
        field: null
      })
    );
  };

  closeEditingGroupsModal = () => {
    this.setState({
      editingGroup: null,
      editingCurruntGroup: null
    });
  };

  render() {
    const { field } = this.props.editSchemaField;

    return (
      <div className="edit-view">
        <div className="edit-view-editor">
          <div className="editor-explorer-frame">
            <ToolBar />
          </div>
          <div className="editor-list-frame">
            <Tab
              className="editor-list-tabs"
              panes={this.groupsToPanes()}
              activeIndex={this.state.activeIndex}
              onTabChange={this.handleGroupTabChange}
              grid={{ paneWidth: 12, tabWidth: 4 }}
              menuPosition="left"
              menu={{ fluid: true, vertical: true, tabular: true }}
            />
          </div>
          <EditFieldDetailsModal field={field} onClose={this.closeModal} />
          <EditGroupsModal
            open={this.state.editingGroup || this.state.editingCurruntGroup}
            group={this.state.editingGroup || this.state.editingCurruntGroup}
            editCurrentGroup={this.state.editingCurruntGroup}
            onClose={this.closeEditingGroupsModal}
          />
        </div>
      </div>
    );
  }
}

export default EditFieldsView;
