import Joi from 'joi-browser';

export default {
  properties: Joi.array().items(
    Joi.object({
      value: Joi.any().required(),
      label: Joi.string().required(),
      name: Joi.string().required()
    })
  )
};
