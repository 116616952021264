import './index.css';

import React from 'react';
import { Component } from 'react';
import { Button, Container } from 'semantic-ui-react';
import LoginForm from '../../login-form';
import auth from '../../../auth';
import client from '../../../apollo';

class UnauthorizedPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backToLogin: false
    };
  }

  logout = () => {
    auth.logout();
    client.resetStore();
    window.location = window.location;
  };

  render = () => {
    return this.state.backToLogin ? (
      <LoginForm></LoginForm>
    ) : (
      <Container fluid textAlign="center" className="fill-screen">
        <h1>Unauthorized Access</h1>
        <p>
          You do not have access to this portion of the app. If you believe this is an error, please
          contact your system administrator.
        </p>
        <Button
          onClick={() => {
            this.logout();
          }}
        >
          Back to login
        </Button>
      </Container>
    );
  };
}

export default UnauthorizedPage;
