import _ from 'lodash';
import { v4 as uuid } from 'uuid'

import createReducer from './create-reducer';
import { moveInList, removeFromList, replaceInList } from './list-manipulation';

let initial = {
  field: null
};

const reducers = {
  CHANGE_EDITING_FIELD: (state, { field }) => {
    return {
      ...state,
      ...{ field: field }
    };
  },
  EDIT_SCHEMA_FIELD: (state, value) => {
    const { property, newValue } = value;
    const field = _.set({ ...state.field }, property.name, newValue);
    return {
      ...state,
      field
    };
  },
  EDIT_SCHEMA_FIELD_PROPERTY: (state, value) => {
    const { property, newValue } = value;

    const newProperties = replaceInList(
      state.field.properties,
      { ...property, ...{ value: newValue } },
      {
        name: property.name
      }
    );

    return {
      ...state,
      field: { ...state.field, properties: newProperties }
    };
  },
  EDIT_SCHEMA_FIELD_OPTION: (state, value) => {
    const { prop, newValue, option } = value;
    let newOption = {};
    let _updatedFieldObject = {};

    newOption[prop] = newValue;

    const newOptions = replaceInList(
      state.field.options,
      { ...option, ...newOption },
      {
        id: option.id
      }
    );

    _updatedFieldObject.options = newOptions;

    return {
      ...state,
      field: { ...state.field, ..._updatedFieldObject }
    };
  },
  ADD_SCHEMA_FIELD_OPTION: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.field.options, {
      id: option.id
    });

    let next = state.field.options.length;
    while (
      state.field.options.find(
        (x) => x.text === `Text-${next + 1}` || x.value === `value-${next + 1}`
      )
    ) {
      next++;
    }

    let newOption = {
      id: uuid(),
      text: `Text-${next + 1}`,
      value: `value-${next + 1}`
    };

    const newOptionsData = [
      ...state.field.options.slice(0, optionIndex + 1),
      newOption,
      ...state.field.options.slice(optionIndex + 1)
    ];

    return {
      ...state,
      field: { ...state.field, options: newOptionsData }
    };
  },
  REMOVE_SCHEMA_FIELD_OPTION: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.field.options, {
      id: option.id
    });

    let _updatedFieldObject = {};

    _updatedFieldObject.options = removeFromList(state.field.options, optionIndex);

    return {
      ...state,
      field: { ...state.field, ..._updatedFieldObject }
    };
  },
  MOVE_SCHEMA_FIELD_OPTION: (state, value) => {
    const { option, distance } = value;

    const optionIndex = _.findIndex(state.field.options, {
      id: option.id
    });

    let _updatedFieldObject = {};

    _updatedFieldObject.options = removeFromList(state.field.options, optionIndex);

    _updatedFieldObject.options.splice(optionIndex - distance, 0, option);

    return {
      ...state,
      field: { ...state.field, ..._updatedFieldObject }
    };
  },
  EDIT_SCHEMA_FIELD_VALIDATION: (state, value) => {
    const { prop, newValue, validation } = value;

    let _newVal = newValue;
    if (_.isNumber(newValue)) {
      _newVal = _.toNumber(newValue);
    }

    const newValidations = replaceInList(
      state.field.validations,
      { ...validation, ...{ [prop]: _newVal } },
      { id: validation.id }
    );

    return {
      ...state,
      field: {
        ...state.field,
        validations: newValidations
      }
    };
  },
  EDIT_SCHEMA_FIELD_TOOLTIP: (state, value) => {
    const { tooltip } = value;

    const newState = {
      ...state,
      field: { ...state.field, tooltipId: tooltip._id }
    };
    return newState;
  },
  REMOVE_SCHEMA_FIELD_TOOLTIP: (state, value) => {
    const newState = {
      ...state,
      field: { ...state.field, tooltipId: null }
    };
    return newState;
  },
  EDIT_SCHEMA_FIELD_CONTEXTS: (state, val, other) => {
    const { value, context } = val;

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        render: value
      },
      { environment: context.environment }
    );

    const newField = {
      ...state.field,
      contexts: [...newContexts]
    };

    const newState = {
      ...state,
      field: newField
    };

    return newState;
  },
  ADD_SCHEMA_FIELD_CONDITION_ANY: (state, val) => {
    const { context, as } = val;
    const all = context.conditions.find((c) => c.as === as);
    const newCondition = {
      as: (all && all.as) || as,
      any: (all && all.any) || []
    };
    newCondition.any.push({ all: [{ field: '', operator: '', value: '' }] });

    const newConditions = replaceInList(
      context.conditions,
      newCondition,
      {
        as
      },
      true
    );

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        conditions: newConditions
      },
      {
        environment: context.environment
      }
    );

    const newState = {
      ...state,
      field: { ...state.field, contexts: newContexts }
    };

    return newState;
  },
  REMOVE_SCHEMA_FIELD_CONDITION_ANY: (state, val) => {
    const { context, as, index } = val;
    const condition = context.conditions.find((c) => c.as === as);
    const newCondition = {
      ...condition,
      any: removeFromList(condition.any, index)
    };

    const newConditions = replaceInList(context.conditions, newCondition, {
      as
    });

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        conditions: newConditions
      },
      {
        environment: context.environment
      }
    );

    const newState = {
      ...state,
      field: { ...state.field, contexts: newContexts }
    };

    return newState;
  },
  ADD_SCHEMA_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index } = val;
    const condition = context.conditions.find((c) => c.as === as);
    const newCondition = {
      as: (condition && condition.as) || as,
      any: (condition && condition.any) || [{ any: [{ all: [] }] }]
    };
    newCondition.any[index].all.push({ field: '', operator: '', value: '' });

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        conditions: replaceInList(context.conditions, newCondition, { as })
      },
      {
        environment: context.environment
      }
    );

    const newState = {
      ...state,
      field: { ...state.field, contexts: newContexts }
    };

    return newState;
  },
  REMOVE_SCHEMA_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index, item } = val;
    const condition = context.conditions.find((c) => c.as === as);

    const newCondition = {
      ...condition,
      any: replaceInList(
        condition.any,
        { all: removeFromList(condition.any[index].all, item) },
        index
      )
    };

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        conditions: replaceInList(context.conditions, newCondition, { as })
      },
      {
        environment: context.environment
      }
    );

    const newState = {
      ...state,
      field: { ...state.field, contexts: newContexts }
    };

    return newState;
  },
  UPDATE_SCHEMA_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index, item, value, prop } = val;
    const condition = context.conditions.find((c) => c.as === as);

    const newItem = {
      ...item,
      ...{ [prop]: value }
    };

    const newCondition = {
      ...condition,
      any: replaceInList(
        condition.any,
        { all: replaceInList(condition.any[index].all, newItem, item) },
        index
      )
    };

    const newContexts = replaceInList(
      state.field.contexts,
      {
        ...context,
        conditions: replaceInList(context.conditions, newCondition, { as })
      },
      {
        environment: context.environment
      }
    );

    const newState = {
      ...state,
      field: { ...state.field, contexts: newContexts }
    };

    return newState;
  },

  EDIT_SCHEMA_FIELD_REPORTS: (state, val) => {
    const { value, report } = val;
    const newReports = replaceInList(
      state.field.reports,
      { ...report, enabled: value },
      { environment: report.environment }
    );

    const newField = { ...state.field, reports: newReports };

    const newState = {
      ...state,
      field: newField
    };

    return newState;
  },
  EDIT_SCHEMA_FIELD_METADATA: (state, value) => {
    const { prop, newValue, option } = value;

    const newOptions = replaceInList(
      state.field.metadata,
      { ...option, ...{ [prop]: newValue } },
      {
        key: option.key
      }
    );

    return {
      ...state,
      field: { ...state.field, metadata: newOptions }
    };
  },
  ADD_SCHEMA_FIELD_METADATA: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.field.metadata, {
      key: option.key
    });

    let next = state.field.metadata.length;
    while (
      state.field.metadata.find(
        (x) => x.key === `Text-${next + 1}` || x.value === `value-${next + 1}`
      )
    ) {
      next++;
    }

    let newOption = {
      key: `Text-${next + 1}`,
      value: `value-${next + 1}`
    };

    const newMetadata = [
      ...state.field.metadata.slice(0, optionIndex + 1),
      newOption,
      ...state.field.metadata.slice(optionIndex + 1, state.field.metadata.length)
    ];

    return {
      ...state,
      field: { ...state.field, metadata: newMetadata }
    };
  },
  REMOVE_SCHEMA_FIELD_METADATA: (state, value) => {
    const { option } = value;

    const newMetadata = removeFromList(state.field.metadata, {
      key: option.key
    });

    return {
      ...state,
      field: { ...state.field, metadata: newMetadata }
    };
  },
  MOVE_SCHEMA_FIELD_METADATA: (state, value) => {
    const { option, distance } = value;

    const newMetadata = moveInList(state.field.metadata, { key: option.key }, distance);

    return {
      ...state,
      field: { ...state.field, metadata: newMetadata }
    };
  }
};

export default createReducer(initial, reducers);

export { initial };
