import './index.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter, Switch } from 'react-router-dom';

import auth from '../../auth';

@withRouter
@connect(() => ({}))
class LoginForm extends Component {
  render() {
    const { history } = this.props;
    return (
      <Switch>
        <Route
          path={`/callback`}
          exact={true}
          render={(props) => {
            auth.handleAuthentication(props).then((authed) => {
              if (authed) {
                history.replace('/dashboard');
              }
            });
            return null;
          }}
        />
        <Route
          path={`/login`}
          exact={true}
          render={() => {
            auth.login();
            return null;
          }}
        />
        <Route
          path={`/`}
          exact={false}
          render={() => {
            history.push('/login');
            return <Redirect from="/" to="/login" />;
          }}
        />
      </Switch>
    );
  }
}
export default LoginForm;
