export function rulesToCriteria(rule) {
  if (Array.isArray(rule.value)) {
    return rule.value.map((value) => ({
      type: 'string',
      value: value.trim()
    }));
  }

  return rule.value.split('||').map((x) => {
    return {
      type: 'string',
      value: x.trim()
    };
  });
}

export function getFieldsWithCriteria(parent, schema) {
  const groups = schema.fields;

  return groups.reduce((acc, group) => {
    if (!group || !group.fields) {
      return acc;
    }
    const fields = group.fields
      .filter((field) => {
        if (field.groupType === 'photo-with-description') {
          return (
            field.fields[0].forms &&
            field.fields[0].forms.looker &&
            field.fields[0].forms.looker.matchCriteria
          );
        }
        return field.forms && field.forms.looker && field.forms.looker.matchCriteria;
      })
      .map((field) => ({ field, destinationGroup: group.name }));

    return acc.concat(fields);
  }, []);
}

export function getNameForField(fieldId, fields) {
  let fieldName;
  fields.forEach((group) => {
    let field = group.fields && group.fields.find((f) => f.id === fieldId);
    if (field) {
      fieldName = field.name;
    }
  });

  return fieldName;
}

export function getGroupForField(fieldId, fields, errorHandler) {
  return fields.find((group) => {
    if (!group.fields) {
      errorHandler(group);
      return;
    }
    return group.fields.find((f) => f.id === fieldId);
  });
}

function groupHasSameCriteria(group, criteria, allFields, errorHandler) {
  const groupCriteria = group.requirements[0].meetsAllCriteria;
  return groupCriteria.every((gc) => {
    return criteria.every((fc) => {
      const criteriaFieldGroup = getGroupForField(fc.field, allFields, errorHandler);
      if (criteriaFieldGroup) {
        const fieldName = getNameForField(fc.field, allFields);

        const name = criteriaFieldGroup.name + '.' + fieldName;
        return (
          gc.formField === name &&
          gc.includesOneOf.every((i) => {
            if (Array.isArray(fc.value)) {
              return gc.includesOneOf.length === fc.value.length
                ? fc.value.includes(i.value)
                : fc.value.every((x) => x === i.value);
            }
            return i.value.toString() === fc.value.toString();
          })
        );
      }
    });
  });
}

function buildMultiFieldsGroup(criteria, allFields, errorHandler) {
  const critName = criteria
    .reduce((a, b) => {
      let fieldName = getNameForField(b.field, allFields);
      return a + ' ' + fieldName + '.' + b.value;
    }, '')
    .trim();

  return {
    name: critName,
    requirements: [
      {
        meetsAllCriteria: criteria.map((c) => {
          if (c.field.length > 0) {
            const criteriaFieldGroup = getGroupForField(c.field, allFields, errorHandler);
            const fieldName = getNameForField(c.field, allFields);
            if (!criteriaFieldGroup) return;
            return {
              formField: `${criteriaFieldGroup.name}.${fieldName}`,
              includesOneOf: rulesToCriteria(c)
            };
          }
        })
      }
    ],
    addFields: [],
    addLineItems: []
  };
}

export function buildMultiFieldsIfMatched(fields, lineItems, allFields, errorHandler) {
  const addedFields = [];
  const addLineItems = [];
  const multiFieldGroups = [];

  fields.forEach((field) => {
    const criteria =
      field.field.groupType === 'photo-with-description'
        ? field.field.fields[0].forms.looker.matchCriteria
        : field.field.forms.looker.matchCriteria;
    const fieldAdded = addedFields.find((f) => f.id && f.id === field.field.id);
    let multiFieldGroup = multiFieldGroups.find((g) => {
      return groupHasSameCriteria(g, criteria, allFields, errorHandler);
    });

    if (!fieldAdded) {
      if (!multiFieldGroup) {
        multiFieldGroup = buildMultiFieldsGroup(criteria, allFields, errorHandler);
        multiFieldGroups.push(multiFieldGroup);
      }

      multiFieldGroup.addFields.push(field);
      addedFields.push(field);
    }
  });

  lineItems.forEach((lineItem) => {
    let criteria = lineItem.conditions.any[0].all;
    const alreadyAdded = addLineItems.find((f) => f._id === lineItem._id);

    let multiFieldGroup = multiFieldGroups.find((g) => {
      return groupHasSameCriteria(g, criteria, allFields);
    });

    if (!alreadyAdded) {
      if (!multiFieldGroup) {
        const group = buildMultiFieldsGroup(criteria, allFields);
        multiFieldGroups.push(group);
      }
      if (multiFieldGroup) {
        multiFieldGroup.addLineItems.push(lineItem);
      }
      addLineItems.push(lineItem);
    }
  });
  multiFieldGroups.forEach((group) => {
    group.addFields.forEach((field) => {
      if (field.field.groupType === 'photo-with-description') {
        field.field.fields.forEach((i) => {
          if (i.forms.client) {
            delete i.forms.client.matchCriteria;
          }
          if (i.forms.looker) {
            delete i.forms.looker.matchCriteria;
          }
        });
      } else {
        if (field.field.forms.client) {
          delete field.field.forms.client.matchCriteria;
        }
        if (field.field.forms.looker) {
          delete field.field.forms.looker.matchCriteria;
        }
      }
    });

    group.addLineItems.forEach((li) => {
      li.multiAddedId = group.name;
      delete li.conditions;
      delete li.lineItemId;
      delete li._id;
    });
  });

  return multiFieldGroups;
}
