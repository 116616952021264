import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { updateUniqueFieldsTerm } from '../../../state/actions';
import ToolbarList from './item-list.js';

const SEARCH_TERM_DEBOUNCE = 300;

function stateToComponent(state) {
  return {
    search: state.uniqueFieldSearch
  };
}

@connect(stateToComponent)
class ToolBar extends Component {
  state = {
    search: ''
  };

  updateUniqueFieldsTerm = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateUniqueFieldsTerm(data.value));
    },
    SEARCH_TERM_DEBOUNCE,
    this.debounceOptions
  );

  UNSAFE_componentWillMount() {
    this.setState({ search: this.props.search.search });
  }

  updateFieldSearch = (e, data) => {
    this.setState({ search: data.value });
    this.updateUniqueFieldsTerm(e, data);
  };

  render() {
    const { search } = this.state;
    return (
      <div className="toolbar">
        <Input
          icon="search"
          placeholder="Search Fields.."
          size="big"
          fluid
          value={search}
          onChange={this.updateFieldSearch}
        />
        <ToolbarList />
      </div>
    );
  }
}

export default ToolBar;
