import '../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Dimmer, Loader, Pagination, Dropdown } from 'semantic-ui-react';

import departments from '../../../api/data/departments';
import roles from '../../../api/data/roles';
import UpdateUserRoleMutation from '../../../graphql/mutations/updateUserRoleById.graphql';
import GetAllUsersQuery from '../../../graphql/queries/getAllUsers.graphql';
import {
  loadUser,
  updateUsersLayout,
  updateUsersPage,
  updateUsersSearchFacetsDepartments,
  updateUsersSearchFacetsRoles,
  updateUsersSearchTerm,
  updateUsersSort
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import userLayoutStrategy from './user-layout-strategy';

const FACETS_ROLES_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.userSearch
  };
}

@connect(stateToComponent)
@graphql(GetAllUsersQuery, {
  name: 'usersData',
  options: (props) => {
    const {
      page,
      perPageCard,
      perPageTable,
      roleFacets,
      departmentFacets,
      search,
      layout,
      pagerInfo,
      sort
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        roleFacets,
        departmentFacets,
        page: _page,
        perPage: _perPage
      }
    };
  }
})
@graphql(UpdateUserRoleMutation, {
  name: 'updateUserRole',
  options: (props) => {
    return {
      errorPolicy: 'all',
      varables: {}
    };
  }
})
class UserSearch extends Component {
  renderFacetSelector = () => {
    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
      // icon: 'check'
    });

    return (
      <>
        <Dropdown
          text="Roles"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Tags"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateFacetsRoles}
          renderLabel={renderLabel}
          value={this.props.search.roleFacets}
          options={roles.map((r) => ({
            key: r.value,
            value: r.value,
            text: r.label
          }))}
          scrolling={true}
        />
        <Dropdown
          text="Departments"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Departments"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateFacetsDepartments}
          renderLabel={renderLabel}
          value={this.props.search.departmentFacets}
          options={departments.map((d) => ({
            key: d.id,
            value: d.id,
            text: d.label
          }))}
          scrolling={true}
        />
      </>
    );
  };

  changeView = (event, data) => {
    const { dispatch, usersData } = this.props;
    const pagerInfo = _.get(usersData, 'userPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout = _.find(userLayoutStrategy, { id: data.value }) || userLayoutStrategy[0];

    dispatch(
      updateUsersLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateFacetsRoles = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateUsersSearchFacetsRoles(data.value));
    },
    FACETS_ROLES_DEBOUNCE,
    this.debounceOptions
  );

  updateFacetsDepartments = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateUsersSearchFacetsDepartments(data.value));
    },
    FACETS_ROLES_DEBOUNCE,
    this.debounceOptions
  );

  loadUser = (user) => {
    const { dispatch, history } = this.props;
    dispatch(loadUser(user));
    history.push(`/user/view/${user._id}`);
  };

  renderLayoutContent = (items) => {
    const { who, search } = this.props;
    const activeLayout = _.find(userLayoutStrategy, { id: search.layout }) || userLayoutStrategy[0];
    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{ load: this.loadUser }}
      />
    );
  };

  render() {
    const { usersData, dispatch } = this.props;
    const { loading } = usersData;

    const items = _.get(usersData, 'userPagination.items', []);
    const pageInfo = _.get(usersData, 'userPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Users</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateUsersPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace tooltips-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            label="Users"
            sort={updateUsersSort}
            search={this.props.search}
            strategy={userLayoutStrategy}
            searchAction={updateUsersSearchTerm}
            viewToggle={this.changeView}
          />
          {content}
        </div>
        {pager}
      </div>
    );
  }
}

export default UserSearch;
