import './index.css';

import React, { Component } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';

import EditFieldDetails from '../edit-field-details';

class EditFieldDetailsModal extends Component {
  contentStyle = {
    minHeight: '550px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  };

  modalStyle = {};

  render() {
    const { field } = this.props;
    if (!field) {
      return null;
    }
    const { label } = field;
    return (
      <Modal
        open={!!this.props.field}
        onClose={this.props.onClose}
        size="large"
        style={this.modalStyle}
      >
        <Header icon="options" content={`Edit the ${label}`} />
        <Modal.Content style={this.contentStyle}>
          <EditFieldDetails field={field} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="All Done"
            onClick={this.props.onClose}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default EditFieldDetailsModal;
