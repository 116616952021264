import Mapper from 'data-import-mapper';

export default function mapStandardFee(company) {
  const config = {
    obj: {
      from: 'lineItems',
      reduce: (lineItems) => {
        let item = lineItems.find((x) => x.base === true);

        if (!item) {
          return {
            price: 0,
            lookerFee: 0,
            itemCode: 'UNKNOWN',
            glAccountNumber: 'UNKNOWN',
            taxCode: 'All Looks'
          };
        }

        const override =
          company &&
          company.lineItems &&
          company.lineItems.find((x) => x.lineItemId === item.lineItemId);

        if (override) {
          item.price = override.price;
          item.lookerFee = override.lookerFee;
        }

        return {
          price: {
            US: {
              amount: item.price.amount,
              currency: item.price.currency
            }
          },
          lookerFee: {
            US: {
              amount: item.lookerFee.amount,
              currency: item.lookerFee.currency
            }
          },
          itemCode: item.lineItem && item.lineItem.itemCode ? item.lineItem.itemCode : '',
          glAccountNumber:
            item.lineItem && item.lineItem.glAccountNumber ? item.lineItem.glAccountNumber : '',
          taxCode: item.lineItem && item.lineItem.taxCode ? item.lineItem.taxCode : ''
        };
      }
    }
  };

  return new Mapper(config);
}
