import Mapper from 'data-import-mapper';
import mapFields from './fieldMapper';

export default async function mapGroups(schema) {
  const mapperConfig = {
    groups: {
      from: 'groups',
      map: async (group) => {
        return {
          name: group.name,
          groupType: group.groupType || 'generic',
          type: 'group',
          fields: await schema.fields
            .filter((f) => group.fields.includes(f.id))
            .map((f) => {
              return mapFields(f);
            })
            .reduce(async (acc, cur) => {
              let arr = await acc;
              let field = await cur;
              if (Array.isArray(field)) {
                arr = arr.concat(field);
              } else {
                arr.push(field);
              }
              return arr;
            }, Promise.resolve([]))
        };
      }
    }
  };

  const mapper = new Mapper(mapperConfig);

  return (await mapper.map(schema)).groups.filter((x) => {
    return x.fields && x.fields.length > 0;
  });
}
