import createReducer from './create-reducer';

const initial = {};

const reducers = {
  UPDATE_WHO: (state, value) => {
    return { ...state, ...value };
  }
};

export default createReducer(initial, reducers);
