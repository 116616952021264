import Mapper from 'data-import-mapper';

export default async function mapDeliveryOptions(deliveryOptions, deliveryInfo) {
  const obj = { deliveryOptions, deliveryInfo };

  const deliveryOptionsMapConfig = {
    cutoff: 'deliveryInfo.cutoff',
    weekendDelivery: 'deliveryInfo.weekendDelivery',
    holidayDelivery: 'deliveryInfo.holidayDelivery',
    options: {
      from: 'deliveryOptions',
      map: (option) => {
        const mapped = Object.assign({}, option);
        mapped.price = { US: option.price || { amount: 0, currency: 'USD' } };

        if (mapped.standard) {
          mapped.days = option.days || 1;
          mapped.price = 0;
          delete mapped.itemCode;
        }

        // delete mapped.lookerDays;
        delete mapped.standard;

        return mapped;
      }
    }
  };

  const mapper = new Mapper(deliveryOptionsMapConfig);
  const mapped = await mapper.map(obj);

  const standard = mapped.options.splice(
    mapped.options.findIndex((x) => x.name === 'Standard'),
    1
  );
  mapped.options.unshift(standard[0]);

  return mapped;
}
