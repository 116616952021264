import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Workspace from '../routes/workspace';
import UniqueFieldHistory from './history';
import UniqueFieldSearch from './search';
import UniqueFieldView from './view';
import UniqueFieldEdit from './edit';

function componentToState(state) {
  return {
    companyView: state.companyView
  };
}

@withRouter
@connect(componentToState)
class UniqueFieldWorkspace extends Component {
  rootUrl = '/uniqueField';

  workSpaces = [
    {
      id: 'search',
      param: ':id?',
      component: UniqueFieldSearch
    },
    {
      id: 'view',
      param: ':id?',
      component: UniqueFieldView
    },
    {
      id: 'edit',
      param: ':id?',
      component: UniqueFieldEdit
    },
    {
      id: 'history',
      param: ':id?',
      component: UniqueFieldHistory
    }
  ];
  render() {
    return <Workspace rootUrl={this.rootUrl} workSpaces={this.workSpaces} />;
  }
}
export default UniqueFieldWorkspace;
