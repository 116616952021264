import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Form } from 'semantic-ui-react';

import {
  editSchemaFieldGroup,
  editSchemaFieldGroupsOptions,
  editSchemaField
} from '../../../../state/actions';
import GroupEditor from './group-editor';
import groupTypeOptions from '../../../common/group-type-options';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@connect(componentToState)
class GroupsEditor extends Component {
  state = {
    groupName: '',
    groupType: '',
    groupOptions: []
  };

  renderGroupInput = () => {
    const { dispatch, field, editSchemaSchema } = this.props;
    const { groupOptions, groupName, groupType } = this.state;
    let defaultValue;
    const initialGroupOptions = _.map(editSchemaSchema.groups, (value) => {
      if (field.group === value.name) {
        defaultValue = value.groupType;
      }
      return { text: value.name, value: value.name };
    });
    const changedGroupOptions = groupOptions.map((value) => {
      return { text: value, value };
    });
    return (
      <Form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowX: 'visible'
        }}
        onSubmit={() => {
          const newGroup = {
            name: groupName || field.group,
            groupType: groupType || defaultValue,
            fields: [],
            metadata: []
          };
          const newGroups = [...editSchemaSchema.groups];
          const existingGroup = newGroups.findIndex((item) => item.name === newGroup.name);
          if (existingGroup != -1) {
            newGroups[existingGroup].groupType = newGroup.groupType;
          } else {
            newGroups.push(newGroup);
          }
          dispatch(
            editSchemaFieldGroupsOptions({
              field: field,
              groupsOptions: newGroups
            })
          );
          dispatch(
            editSchemaFieldGroup({
              field: field,
              group: groupName || field.group
            })
          );
          dispatch(
            editSchemaField({
              property: { name: 'group' },
              newValue: groupName || field.group
            })
          );
        }}
      >
        <Form.Group
          style={{
            marginLeft: 0,
            marginRight: '0.5rem'
          }}
          widths="equal"
        >
          <Form.Dropdown
            options={groupOptions.length > 0 ? changedGroupOptions : initialGroupOptions}
            label={`Group`}
            placeholder="Enter Group this form field is associated with"
            search
            selection
            fluid
            allowAdditions
            value={groupName ? groupName : field.group}
            onAddItem={(e, { value }) => {
              value = value.trim();
              const oldOptions = groupOptions.length > 0 ? groupOptions : initialGroupOptions;
              const newOptions = _.uniq([value, ...oldOptions.map((x) => x.value)]);
              this.setState({ groupName: value, groupOptions: newOptions });
            }}
            onChange={(e, { value }) => {
              this.setState({ groupName: value });
            }}
            name={`group`}
            width={16}
          />
          <Form.Select
            style={{ width: '-webkit-fill-available' }}
            placeholder="Select GroupType"
            label={`Group Type`}
            fluid
            search
            defaultValue={defaultValue}
            onChange={(e, { value }) => {
              this.setState({ groupType: value });
            }}
            options={groupTypeOptions}
          />
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Form.Button content="Submit" />
          </div>
        </Form.Group>
      </Form>
    );
  };

  fieldGroupToHtml = (groups) => (group, index) => {
    return (
      <GroupEditor
        key={`group-${group.name}`}
        group={group}
        groups={groups}
        index={index}
        {...this.props}
      />
    );
  };

  renderGroupsEditor = () => {
    const { editSchemaSchema } = this.props;

    const { groups } = editSchemaSchema;

    return (
      <Card.Group className="card-group-container">
        {_.map(groups, this.fieldGroupToHtml(groups))}
      </Card.Group>
    );
  };

  render() {
    const content = (
      <div className="field-groups-editor">
        <div className="groups-input">{this.renderGroupInput()}</div>
        <div className="groups-editor">{this.renderGroupsEditor()}</div>
      </div>
    );

    return content;
  }
}
export default GroupsEditor;
