import _ from 'lodash';
import React, { Component } from 'react';
import { Card, Image } from 'semantic-ui-react';

import { SearchActionMenuSection } from '../../search/actionSection';

export class CompanyCard extends Component {
  renderExtra = () => {
    const { result, model, actions } = this.props;

    if (model) {
      const actionSection = (
        <SearchActionMenuSection item={result} model={model.actionSection(actions)} />
      );

      if (!actionSection) {
        return null;
      }

      return actionSection;
    }

    return null;
  };

  render() {
    const { result } = this.props;
    const { _id, name, logoUrl } = result;

    return (
      <Card key={`card-${_id}`}>
        <Card.Content>
          <Card.Header>
            {`${name}`}
            {this.renderExtra()}
          </Card.Header>
          <Image as="div" className="card-img" src={logoUrl} style={{ height: 200 }} />
          <Card.Meta>{''}</Card.Meta>
          <Card.Description>{''}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export class CompanyCards extends Component {
  render() {
    const { items, model, actions } = this.props;

    const resultItemsHtml = _.map(items, (item, index) => {
      return (
        <CompanyCard
          key={`card-${item._id}`}
          result={item}
          index={index}
          model={model}
          actions={actions}
        />
      );
    });

    return <div className="search-results">{resultItemsHtml}</div>;
  }
}

export default CompanyCards;
