import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import {
  removeSchemaBaseDeliveryOption,
  updateSchemaBaseDeliveryOptions
} from '../../../../state/actions';
import CurrencyInput from '../../../currency-input';
import Placeholder from '../../../placeholder';
import baseDeliveryOptions from '../../base-delivery-options';
import metaInfoSchema from './../metainfo-schema';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
class DeliveryOptionSelector extends Component {
  state = { showModal: false };

  removeDeliveryOption = (item) => () => {
    const { dispatch } = this.props;

    dispatch(removeSchemaBaseDeliveryOption(item));
  };

  openDeliveryOptionsModal = () => {
    this.setState({
      showModal: true
    });
  };
  closeDeliveryOptionsModal = (deliveryOption) => () => {
    const { dispatch } = this.props;
    const { deliveryOptions } = this.props.editSchemaInfo.info;
    if (deliveryOption) {
      dispatch(updateSchemaBaseDeliveryOptions([...deliveryOptions, deliveryOption]));
    }
    this.setState({
      showModal: false
    });
  };
  emptyCta = () => {
    return (
      <Placeholder
        icon="add to cart outline"
        header="No Delivery Options for this Schema (Yet)"
        subheader="Add available delivery options for this schema below."
      />
    );
  };

  deliveryOptionToSelectableList = (deliveryOption) => {
    return (
      <Table.Row key={`delivery-option-${deliveryOption.id}`}>
        <Table.Cell>{deliveryOption.name}</Table.Cell>
        <Table.Cell>{deliveryOption.days}</Table.Cell>
        <Table.Cell>{deliveryOption.lookerDays}</Table.Cell>
        <Table.Cell>{deliveryOption.itemCode}</Table.Cell>
        <Table.Cell>{deliveryOption.price.value}</Table.Cell>
        <Table.Cell>
          <Button
            icon
            labelPosition="left"
            size="small"
            onClick={this.closeDeliveryOptionsModal(deliveryOption)}
          >
            <Icon name="plus" />
            Add
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderDeliveryOptionsModal = () => {
    const content = _.chain(baseDeliveryOptions)
      .filter((x) => x)
      .map(this.deliveryOptionToSelectableList)
      .value();

    return (
      <Modal size={'large'} open={this.state.showModal} onClose={this.closeDeliveryOptionsModal()}>
        <Modal.Header>Select a delivery option</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <div className="search-content">
            <div className="search-results-frame">
              <Table style={{ marginBottom: 30 }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={7}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Days</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Looker Days</Table.HeaderCell>
                    <Table.HeaderCell width={2}>ItemCode</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Price</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>{content}</Table.Body>
              </Table>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeDeliveryOptionsModal()}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  addDeliveryOptionCta = () => {
    return (
      <Segment attached="bottom">
        <Button
          icon
          labelPosition="left"
          primary
          size="small"
          onClick={this.openDeliveryOptionsModal}
        >
          <Icon name="add" /> Add Delivery Option
        </Button>
      </Segment>
    );
  };

  renderDeliveryOptionLabels = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={7}>Name</Table.HeaderCell>
          <Table.HeaderCell width={1}>Days</Table.HeaderCell>
          <Table.HeaderCell width={2}>Looker Days</Table.HeaderCell>
          <Table.HeaderCell width={2}>ItemCode</Table.HeaderCell>
          <Table.HeaderCell width={1}>Standard</Table.HeaderCell>
          <Table.HeaderCell width={3}>Price</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );
  };

  renderDeliveryOption = (deliveryOption, i) => {
    const { deliveryOptions } = this.props.editSchemaInfo.info;
    const onRemove = this.removeDeliveryOption;

    const removeBtn = (
      <Button.Group basic fluid>
        <Button
          icon="trash"
          negative
          onClick={onRemove(deliveryOption)}
          title={`Remove ${deliveryOption.name}`}
        />
      </Button.Group>
    );

    return (
      <Table.Row
        key={`schema-deliveryOption-${deliveryOption.id}-${deliveryOption.standard}`}
        className="deliveryOption-item"
      >
        <Table.Cell>{deliveryOption.name}</Table.Cell>
        <Table.Cell collapsing>{deliveryOption.days}</Table.Cell>
        <Table.Cell collapsing>
          <Form.Field>
            <Form.Input name={`deliveryOptions[${i}].lookerDays`} width={12} />
          </Form.Field>
        </Table.Cell>
        <Table.Cell collapsing>{deliveryOption.itemCode}</Table.Cell>
        <Table.Cell collapsing>
          <Form.Checkbox
            onChange={() => {
              deliveryOptions.forEach((x) => (x.standard = false));
            }}
            name={`deliveryOptions[${i}].standard`}
          />
        </Table.Cell>
        <Table.Cell>
          <CurrencyInput
            key={`delivery-option-currency-input-${i}`}
            disabled={deliveryOption.standard}
            width={12}
            name={`deliveryOptions[${i}].price`}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Form.Field>{removeBtn}</Form.Field>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderDeliveryOptionsList = () => {
    const { deliveryOptions } = this.props.editSchemaInfo.info;

    return (
      <Table basic="very" className={`delivery-items `}>
        {this.renderDeliveryOptionLabels()}

        <Table.Body>{deliveryOptions.map(this.renderDeliveryOption)}</Table.Body>
      </Table>
    );
  };

  renderDeliveryOptionsSegment = () => {
    const { deliveryOptions } = this.props.editSchemaInfo.info;

    return (
      <Segment.Group>
        <Segment>
          {deliveryOptions.length ? this.renderDeliveryOptionsList() : this.emptyCta()}
        </Segment>

        {this.addDeliveryOptionCta()}
      </Segment.Group>
    );
  };

  render() {
    const { dispatch } = this.props;
    const { deliveryOptions } = this.props.editSchemaInfo.info;

    return (
      <div>
        <Header size="medium">Delivery Options</Header>
        <ValidatedForm
          whitelist={[CurrencyInput]}
          ignoreChildrenList={[CurrencyInput]}
          intialValue={{ deliveryOptions }}
          value={{ deliveryOptions }}
          validateSchema={{ deliveryOptions: metaInfoSchema.deliveryOptions }}
          onChange={(event, { field, form, submitting }, err) => {
            dispatch(updateSchemaBaseDeliveryOptions(form.deliveryOptions));
          }}
        >
          {this.renderDeliveryOptionsSegment()}
        </ValidatedForm>
        {this.renderDeliveryOptionsModal()}
      </div>
    );
  }
}

export default DeliveryOptionSelector;
