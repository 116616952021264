import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ValidatedForm from 'semantic-ui-react-validated-form';

import GetMapFacets from '../../../../graphql/queries/getMapFacets.graphql';
import { editMap, updateMapTagsFacets } from '../../../../state/actions';
import EditablePropertyField from '../mappers/to-editable-property';
import InfoEditorSchema from './schema';

function componentToState(state) {
  return {
    mapSearch: state.mapSearch
  };
}
@graphql(GetMapFacets, {
  name: 'mapFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@connect(componentToState)
@withRouter
class InfoEditor extends Component {
  generateMapFieldsJsonFromField = (field) => {
    const { mapSearch, dispatch } = this.props;
    let formfields = [
      {
        name: 'id',
        label: 'Map Id',
        title: `
          This is the id of the map
        `,
        readOnly: false
      },
      {
        name: 'name',
        label: 'Map Name',
        title: `
          This is the name of the map
        `,
        readOnly: false
      },
      {
        name: 'source',
        label: 'Source Name',
        title: `
          This is the name of the source
        `,
        readOnly: false
      },
      {
        name: 'target',
        label: 'Target Name',
        title: `
          This is the name of the target
        `,
        readOnly: false
      },
      {
        name: 'tags',
        label: 'Tags',
        title: `
          These are the tags of the map
        `,
        readOnly: false,
        options: mapSearch.tagsFacets,
        value: field.tags,
        updateTags: (value) => dispatch(updateMapTagsFacets(value))
      }
    ];

    return formfields;
  };

  render() {
    const { dispatch, field, mapFacets, mapSearch } = this.props;
    if (mapFacets.loading) return <div>Loading...</div>;

    if (mapSearch.tagsFacets.length === 0)
      dispatch(
        updateMapTagsFacets(mapFacets.mapFacets.tags.map((x) => ({ key: x, text: x, value: x })))
      );

    let formfields = this.generateMapFieldsJsonFromField(field);

    const fields = _.map(formfields, (property, index) => {
      let args = {
        keyPrefix: `edit-detail-info`,
        dispatchAction: editMap,
        dispatch,
        field,
        property,
        index
      };

      return <EditablePropertyField key={`edit-detail-info-${index}`} {...args} />;
    });

    return (
      <div className="map-info-wrapper">
        <ValidatedForm
          intialValue={field}
          value={field}
          validateSchema={InfoEditorSchema}
          readOnly={false}
          onChange={() => {}}
        >
          {fields}
        </ValidatedForm>
      </div>
    );
  }
}
export default InfoEditor;
