import './index.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Loader } from 'semantic-ui-react';

import { SearchActionButtonSection } from '../../search/actionSection';
import SearchTable from '../../search/search-table.js';

@withRouter
class ActivityTable extends Component {
  state = {
    page: 1,
    itemsPerPage: 10
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  getTableModel = () => {
    function actionSection(actions) {
      return [
        {
          icon: 'edit',
          label: 'Edit',
          action: actions.load
        },
        {
          icon: 'history',
          label: 'History',
          action: actions.history
        }
      ];
    }
    return [
      {
        label: 'User',
        property: 'user.email',
        type: 'string'
      },
      {
        label: 'Schema',
        property: 'item.info.schemaName',
        type: 'string'
      },
      {
        label: 'Action',
        property: 'method',
        type: 'string'
      },
      {
        label: 'version',
        property: 'version',
        type: 'string'
      },

      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ];
  };
  getActions = () => {
    const { history } = this.props;
    return {
      load: (item) => {
        console.log('ITEM', item);
        if (item && item.item && item.item._id) {
          history.push(`/schema-editor/${item.item._id}/edit-info`);
        }
      },
      history: (item) => {
        if (item && item.item && item.item._id) {
          history.push(`/schema/history/${item.item._id}`);
        }
      }
    };
  };
  render() {
    const { items, skip, updateSkip, loading } = this.props;
    return (
      <div className={'dashboard-tile-frame'}>
        <div className="schema-tile schema-table-frame">
          <div className="schema-table">
            {loading ? <Loader active /> : ''}
            <SearchTable
              items={items}
              actions={this.getActions()}
              model={this.getTableModel()}
              basic={'very'}
              compact={true}
            />
            <div style={{ float: 'right' }}>
              {skip > 0 ? (
                <Button onClick={() => updateSkip(skip - 10)} disabled={loading} basic icon>
                  <Icon name="chevron left" />
                </Button>
              ) : (
                ''
              )}
              {items.length === 10 ? (
                <Button onClick={() => updateSkip(skip + 10)} disabled={loading} basic icon>
                  <Icon name="chevron right" />
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ActivityTable;
