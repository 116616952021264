import '../index.css';
import moment from 'moment';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Card, Icon, Image, Popup, Dropdown } from 'semantic-ui-react';

function SchemaPermissionCard(props) {
  const { schema } = props;

  const { info, updatedAt, _id, author } = schema;
  const { schemaName } = info;

  const [hovering, setHovering] = useState(false);

  /**
   * Checks the previous state of permission versions that were selected for the schema and finds what version was
   * either appended or removed from the permission versions list.
   *
   * @param {array} newVersions
   * @returns {string} The version that was either appended or removed between the new versions and previous versions
   */
  const findChangeInVersions = (newVersions) => {
    const previousVersions =
      props.schemaPermissions.schemas
        .filter((version) => version.collectionId === props.schema._id)
        .map((version) => version.publishedVersion) || [];

    let difference = previousVersions
      .filter((version) => !newVersions.includes(version))
      .concat(newVersions.filter((version) => !previousVersions.includes(version)));

    return difference[0];
  };

  const checkIdExists = (id) => {
    let found = false;
    props.schemaPermissions.schemas.forEach((schema) => {
      if (schema._id === id) {
        found = true;
      }
    });
    return found;
  };

  /**
   * Gets called when the semantic UI dropdown component fires onChange()
   *
   * @param {object} event - Event data
   * @param {object} data - Data passed by the semantic UI dropdown component in the onChange() method
   */
  const onPermissionsChange = (event, { value }) => {
    const changedVersion = findChangeInVersions(value);

    // This is the schema permission linked to the version that was selected
    const selectedSchemaPermission = props.publishedVersions.find(
      (publishedVersion) =>
        publishedVersion.publishedVersion === changedVersion &&
        publishedVersion.collectionId === schema._id
    );

    // Schema permissions that are related to only this schema card
    const relatedSchemaPermissions = props.schemaPermissions.schemas.filter(
      (version) => version.collectionId === props.schema._id
    );

    if (value.length > relatedSchemaPermissions.length) {
      if (!checkIdExists(selectedSchemaPermission._id)) {
        props.actions.addSchemaPermission({
          schemas: [selectedSchemaPermission]
        });
      }
    } else {
      props.actions.removeSchemaPermission(selectedSchemaPermission._id);
    }
  };

  const renderPublishedVersionDropdown = (publishedVersions, schemaId) => {
    const { schemaPermissions } = props;

    // Get only the published schema versions that are related to this specific schema, not other schemas in the list.
    // After filtering, map only the published version to the values
    // Finally sort the values into decending order
    const values = schemaPermissions.schemas
      .filter((schema) => schema.collectionId === props.schema._id)
      .map((schema) => {
        return schema.publishedVersion;
      })
      .sort((a, b) => (parseInt(a.split('.')[0]) > parseInt(b.split('.')[0]) ? -1 : 1));

    // Map to get an object with the ID, collection ID, and a few other fields
    // Finally sort the values into decending order based on the publishedVersion (aka value)
    const options = publishedVersions
      .map((publishedVersion, index) => {
        const { collectionId } = publishedVersion;
        if (collectionId === props.schema._id) {
          return {
            id: publishedVersion._id,
            collectionid: publishedVersion.collectionId,
            key: publishedVersion.publishedVersion,
            text: publishedVersion.publishedVersion,
            value: publishedVersion.publishedVersion
          };
        }
      })
      .sort((a, b) => (parseInt(a.value.split('.')[0]) < parseInt(b.value.split('.')[0]) ? 1 : -1))
      .filter(Boolean);

    return (
      <Dropdown
        placeholder="Published Versions"
        fluid
        multiple
        onChange={onPermissionsChange}
        selection
        value={values}
        options={options}
      />
    );
  };

  const hoverIn = () => {
    setHovering(true);
  };

  const hoverOut = () => {
    setHovering(false);
  };

  const renderExtraAuthor = () => {
    const onClick = () => {
      const { history, schema } = props;
      history.push(`/user/view/${schema.authorId}`);
    };

    const authorName = author ? `${author.firstName} ${author.lastName}` : ``;
    const avatarUrl = author ? author.avatarUrl : ''; //mock image here

    return (
      <>
        <div>
          <Image src={avatarUrl} avatar onClick={onClick} />
        </div>
        <div>
          <span>{`${authorName}`}</span>
          <br />
          <span className="card-last-edited">
            <Icon name="history" fitted />
            {` Last Edited: ${moment(updatedAt).fromNow()}`}
          </span>
        </div>
      </>
    );
  };

  const renderExtraCompanies = () => {
    const { companies } = props.schema;

    // const onClick = (company) => () => {
    //   const { history } = props;
    //   history.push(`/company/view/${company.companyId}`);
    // };

    const popups = companies.slice(0, 3).map((company, index) => {
      const companyName = company.company ? company.company.name : '';
      return (
        <Popup
          className="no-select"
          key={`company-popup-${index}`}
          size="tiny"
          position="top center"
          // trigger={<Image onClick={onClick(company)} avatar />}
          trigger={<Image className="no-select" avatar />}
          content={companyName}
        />
      );
    });

    return <div>{popups}</div>;
  };

  const renderDescription = () => {
    if (info.schemaDescription != '') {
      return (
        <div className="card-description">
          <div>{info.schemaDescription}</div>
        </div>
      );
    }
  };

  return (
    <Card
      key={`schema-preview-${_id}`}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}
      className={`${hovering ? 'hovering' : ''}`}
    >
      <Card.Content className="card-shrink">
        <Card.Header className="card-header center-all">{schemaName}</Card.Header>
      </Card.Content>
      <Card.Content className="card-main">
        {renderPublishedVersionDropdown(props.publishedVersions, _id)}
        {renderDescription()}
      </Card.Content>
      <Card.Content className="card-footer">
        <div className="card-footer-left">{renderExtraAuthor()}</div>
        <div className="card-footer-right">{renderExtraCompanies()}</div>
      </Card.Content>
    </Card>
  );
}

export default withRouter(SchemaPermissionCard);
