import CompanyWorkspace from '../company';
import Dashboard from '../dashboard';
import LineItemWorkspace from '../lineItems';
import MapWorkspace from '../maps';
import OutputMapper from '../output-mapper';
import SchemaSearch from '../schema';
import SchemaEditor from '../schema-editor';
import Settings from '../settings';
import TooltipWorkspace from '../tooltip';
import UniqueFieldWorkspace from '../uniqueField';
import UserWorkspace from '../user';

export default [
  {
    id: 'dashboard',
    component: Dashboard,
    label: 'Dashboard',
    icon: 'dashboard'
  },

  {
    id: 'schema-editor',
    component: SchemaEditor,
    label: 'Schema Editor',
    icon: 'file text outline'
  },
  {
    id: 'schema',
    component: SchemaSearch,
    label: 'Schema Search',
    icon: 'search'
  },
  {
    id: 'tooltip',
    component: TooltipWorkspace,
    label: 'Tooltip Search',
    icon: 'briefcase'
  },
  {
    id: 'user',
    component: UserWorkspace,
    label: 'Users',
    icon: 'user'
  },
  {
    id: 'company',
    component: CompanyWorkspace,
    label: 'Companies',
    icon: 'building'
  },
  {
    id: 'uniquefield',
    component: UniqueFieldWorkspace,
    label: 'Unique Fields',
    icon: 'bullseye'
  },
  {
    id: 'maps',
    component: MapWorkspace,
    label: 'Maps',
    icon: 'map'
  },
  {
    id: 'lineItem',
    component: LineItemWorkspace,
    label: 'Line Items',
    icon: 'list alternate outline'
  },
  {
    id: 'output-mapper',
    component: OutputMapper,
    label: 'Schema Mapper',
    icon: 'map outline'
  },
  {
    id: 'settings',
    component: Settings,
    label: 'Settings',
    icon: 'settings',
    activityBarGroup: 'footer'
  }
];
