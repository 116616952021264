import { getFieldsWithCriteria, buildMultiFieldsIfMatched } from './matchFieldBuilder.js';
import { groupOnEverySchema, fieldIsOnOtherSchemasInSameGroup } from './utils';

const deliveryOptionsField = {
  name: 'Delivery Options',
  forms: {
    client: {
      type: 'delivery-options',
      validations: {
        required: true
      }
    }
  },
  id: 'delivery_options'
};

export function getSchemasByGroup(schemas) {
  const schemasByGroup = schemas.reduce((groups, schema) => {
    const groupid = schema.schemaGroup + schema.companyId;
    if (!groups[groupid]) {
      groups[groupid] = [];
    }

    groups[groupid].push(schema);

    return groups;
  }, {});

  return schemasByGroup;
}

export const addChildFieldsToParent = (parent, schema, schemas) => {
  schema.fields.forEach((group) => {
    if (!group) {
      return;
    }

    if (!group.fields || group.type !== 'group') {
      // THIS IS NOT A GROUP, ITS A PLAIN FIELD ON THE SCHEMA (i.e. looker_instructions.)
      // REMOVE EXCESS FIELDS FROM FIELD WHEN NOT IN A GROUP
      delete group.type;
      delete group.forms.client;
      delete group.relationship;
      parent.fields.push(group);
      return;
    }

    const parentFields = [];
    const findGroup = (f) => (f.id && f.id === group.id) || f.name === group.name;

    const groupIsOnAllSchemas = groupOnEverySchema(group, schemas);

    if (groupIsOnAllSchemas) {
      group.fields.forEach((field) => {
        const fieldOnAllSchemasInGroup = fieldIsOnOtherSchemasInSameGroup(field, group, schemas);
        const fieldNotInParentGroup = !parentFields.find(findGroup);
        let hasNoMatchCriteria;
        if (field.groupType === 'photo-with-description') {
          field.fields.forEach((i) => {
            hasNoMatchCriteria = !i.forms.looker || !i.forms.looker.matchCriteria;
          });
        } else {
          hasNoMatchCriteria = !field.forms.looker || !field.forms.looker.matchCriteria;
        }
        if (fieldOnAllSchemasInGroup && fieldNotInParentGroup && hasNoMatchCriteria) {
          parentFields.push(field);
        }
      });

      const GroupNotOnParent = !parent.fields.find(findGroup);

      if (GroupNotOnParent) {
        parent.fields.push({
          name: group.name,
          type: 'group',
          groupType: group.groupType || 'generic',
          fields: parentFields
        });
      }
    } else {
      let copy = JSON.parse(JSON.stringify(field));
      Object.keys(field).forEach((key) => delete field[key]);
      field.field = copy;
      field.destinationGroup = 'Basic Information';
    }
  });

  schema.lineItems.forEach((item) => {
    const onAllSchemas = schemas.every((s) => {
      return s.lineItems.find((i) => i.itemCode === item.itemCode);
    });

    if (onAllSchemas) {
      if (!parent.lineItems.find((i) => i.itemCode === item.itemCode)) {
        parent.lineItems.push(item);
      }
    }
  });
};

export function createParentSchema(schemas, errorHandler) {
  const parent = Object.assign({}, schemas[0]);

  parent.fields = [];
  parent.lineItems = [];

  schemas.forEach((s) => addChildFieldsToParent(parent, s, schemas));

  let fields = schemas.reduce((acc, s) => {
    const fields = getFieldsWithCriteria(parent, s);
    return acc.concat(fields);
  }, []);

  let lineItems = schemas.reduce((acc, s) => {
    const lineItems = s.lineItems.filter((x) => {
      return x.conditions && x.conditions.any.length;
    });

    return acc.concat(lineItems);
  }, []);

  const allFields = parent.fields;
  let multiFieldsIfMatched = buildMultiFieldsIfMatched(fields, lineItems, allFields, errorHandler);

  if (multiFieldsIfMatched.length) {
    parent.multiFieldsIfMatched = multiFieldsIfMatched;
  }

  parent.fields.push(deliveryOptionsField);

  return parent;
}

export default function combineSchemas(schemas, errorHandler) {
  return schemas.map((x) => createParentSchema([x], errorHandler));
}
