import '../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Icon, Loader, Pagination, Dropdown } from 'semantic-ui-react';

import getAllMapsQuery from '../../../graphql/queries/getAllMaps.graphql';
import GetMapFacets from '../../../graphql/queries/getMapFacets.graphql';
import {
  clearMap,
  loadMap,
  updateMapPage,
  updateMapSort,
  updateMapSearchTerm,
  updateMapFilterTags
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import mapLayoutStrategy from './map-layout-strategy';

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.mapSearch
  };
}

const FACETS_DEBOUNCE = 200;

@connect(stateToComponent)
@graphql(getAllMapsQuery, {
  name: 'mapData',
  options: (props) => {
    const {
      sort,
      page,
      perPageCard,
      perPageTable,
      search,
      filterTags,
      layout,
      pagerInfo
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        tagsFacets: filterTags,
        page: _page,
        perPage: _perPage,
        notInIds: props.excludeIds || []
      }
    };
  }
})
@graphql(GetMapFacets, {
  name: 'mapFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@withRouter
class MapSearch extends Component {
  debounceOptions = {
    leading: false,
    trailing: true
  };

  loadMap = (map) => {
    const { dispatch, history } = this.props;
    dispatch(loadMap(map));
    history.push(`/maps/view/${map._id}`);
  };

  copyMap = (map) => {
    const { dispatch, history } = this.props;
    delete map._id;
    dispatch(
      loadMap({
        ...map,
        ...{
          id: `${map.id} - Copy`,
          name: `${map.name} - Copy`
        }
      })
    );
    history.push(`/maps/edit/new`);
  };

  editMap = (map) => {
    const { dispatch, history } = this.props;
    dispatch(loadMap(map));
    history.push(`/maps/edit/${map._id}`);
  };

  loadMapHistory = (map) => {
    const { history } = this.props;
    history.push(`/maps/history/${map._id}`);
  };

  updateMapFilterTags = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateMapFilterTags(data.value));
    },
    FACETS_DEBOUNCE,
    {
      leading: false,
      trailing: true
    }
  );

  renderLayoutContent = (items) => {
    const { who, search, loadHandler, editHandler } = this.props;

    const activeLayout = _.find(mapLayoutStrategy, { id: search.layout }) || mapLayoutStrategy[0];
    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{
          select: loadHandler || this.loadMap,
          edit: editHandler || this.editMap,
          history: this.loadMapHistory,
          copy: this.copyMap
        }}
      />
    );
  };

  renderFacetSelector = () => {
    const { mapFacets } = this.props;
    const toFacet = (x) => ({ key: x, text: x, value: x });

    let items = mapFacets.loading
      ? { tags: [] }
      : {
          tags: mapFacets.mapFacets.tags.map(toFacet)
        };

    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
    });

    return (
      <>
        <Dropdown
          placeholder="Tags"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Tags"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateMapFilterTags}
          renderLabel={renderLabel}
          value={this.props.search.filterTags}
          options={items.tags}
          scrolling={true}
        />
      </>
    );
  };

  createNew = () => {
    const { history, dispatch } = this.props;
    dispatch(clearMap());
    history.push('/maps/edit/new');
  };

  renderButtons = () => {
    const { history, dispatch } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 16
        }}
      >
        <Button.Group>
          <Button
            size="big"
            primary
            onClick={() => {
              dispatch(clearMap());
              history.push(`/maps/edit/new`);
            }}
          >
            {<Icon name={`edit`} />}
            {`New`}
          </Button>
        </Button.Group>
      </div>
    );
  };

  render() {
    const { mapData, dispatch } = this.props;

    const { loading } = mapData;

    const items = _.get(mapData, 'mapPagination.items', []);
    const pageInfo = _.get(mapData, 'mapPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    //TODO extract to function.
    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Maps</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateMapPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace tooltips-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            sort={updateMapSort}
            label="Maps"
            search={this.props.search}
            strategy={mapLayoutStrategy}
            searchAction={updateMapSearchTerm}
            filters={this.renderFacetSelector()}
          />
          {content}
        </div>
        {pager}
        <div>{this.renderButtons()}</div>
      </div>
    );
  }
}
export default MapSearch;
