import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import SchemaCreateMutation from '../../graphql/mutations/createSchema.graphql';

function stateToComponent(state) {
  return { editSchemaSchema: state.editSchemaSchema, who: state.who };
}

@connect(stateToComponent)
@graphql(SchemaCreateMutation, {
  name: 'createSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
class SettingsActions extends Component {
  state = {
    saving: false
  };

  clearError = () => {
    this.setState({
      error: null
    });
  };

  errorMessage = () => {
    let errors = this.state.error.errors || this.state.error;

    if (!_.isArray(errors)) {
      errors = [errors];
    }

    const listofErrors = errors.map((er) => {
      return (
        <div>
          <Header icon="x" content={`${er.code}`} />
          <Modal.Content>{`${er.message}`}</Modal.Content>
          <Modal.Content>
            <pre>{`${er.stack}`}</pre>
          </Modal.Content>
        </div>
      );
    });

    return (
      <Modal basic size="small" open={errors.length}>
        {listofErrors}
        <Modal.Actions>
          <Button color="green" inverted onClick={this.clearError}>
            <Icon name="checkmark" /> Understood
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  preventaction = () => {
    return false;
  };

  updateSaveProfileButton = () => {
    const { saving } = this.state;
    return (
      <Button
        size="big"
        primary
        onClick={() => {}}
        disabled={this.preventaction()}
        loading={saving}
      >
        <Icon name="save" />
        Update Profile
      </Button>
    );
  };

  renderActions = () => {
    const actions = [];

    return actions;
  };

  render() {
    const { error } = this.state;

    const content = error ? this.errorMessage() : this.renderActions();

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 6
        }}
      >
        {content}
      </div>
    );
  }
}

export default SettingsActions;
