import createReducer from './create-reducer';

const initial = {
  activeGroupsTabName: 'Basic Information'
};
const reducers = {
  CHANGE_VISIBLE_GROUP_NAME: (state, value) => {
    return { ...state, ...{ activeGroupsTabName: value } };
  }
};

export default createReducer(initial, reducers);
