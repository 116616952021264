import './index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Header, Segment, Tab } from 'semantic-ui-react';

import GetDashboardInfo from '../../graphql/queries/GetDashboardInfo.graphql';
import ContributionGraph from './contribution-graph';
import HeroNumber from './heronumber';
import DraftsTable from './tables/drafts';
import PublishedTable from './tables/published';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(GetDashboardInfo, {
  name: 'dashboardInfo',
  options: (props) => {
    const monthAgo = new Date();
    monthAgo.setHours(0);
    monthAgo.setMinutes(0);
    monthAgo.setSeconds(0);
    monthAgo.setMonth(monthAgo.getMonth() - 1);
    const weekAgo = new Date();
    weekAgo.setHours(0);
    weekAgo.setMinutes(0);
    weekAgo.setSeconds(0);
    weekAgo.setHours(weekAgo.getHours() - 24 * 7);

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        userId: props.who.id
        // monthAgo: monthAgo.toJSON(),
        // weekAgo: weekAgo.toJSON()
      }
    };
  }
})
@withRouter
class Dashboard extends Component {
  state = {
    skip: 0
  };
  render() {
    const { dashboardInfo } = this.props;
    if (dashboardInfo.loading) {
      return null;
    }

    const { schemaManyDraft, schemaManyPublished } = dashboardInfo;

    const schemaCount =
      dashboardInfo.schemaManyDraft.length + dashboardInfo.schemaManyPublished.length;
    const tablePanes = [
      {
        menuItem: 'Published',
        pane: {
          key: 'published',
          content: <PublishedTable items={dashboardInfo.schemaManyPublished} />
        }
      },
      {
        menuItem: 'Drafts',
        pane: {
          key: 'drafts',
          content: (
            <DraftsTable reload={dashboardInfo.refetch} items={dashboardInfo.schemaManyDraft} />
          )
        }
      }
    ];

    const allSchemas = schemaManyDraft.concat(schemaManyPublished);

    return (
      <Grid className="dashboard-frame">
        <Grid.Row className="profile-frame">
          <Grid.Column className="profile-metrics">
            <HeroNumber
              title={'Schemas'}
              value={{
                total: schemaCount,
                updated: allSchemas[0],
                updatedName: allSchemas.length ? allSchemas[0].info.schemaName : '',
                editAction: (schemaId) => () => {
                  if (schemaId === null) {
                    return;
                  }
                  const { history } = this.props;
                  history.push(`/schema-editor/${schemaId}/edit-info`);
                },
                addAction: () => {
                  const { history } = this.props;
                  history.push(`/schema-editor/new/edit-info`);
                },
                chartItems: [
                  {
                    label: 'Published',
                    value: schemaManyPublished.length,
                    color: '#c7e9c0'
                  },
                  {
                    label: 'Drafts',
                    value: schemaManyDraft.length,
                    color: '#00441b'
                  }
                ]
              }}
              icon={'file text outline'}
              color={'olive'}
            />
          </Grid.Column>
          <Grid.Column className="profile-metrics">
            <HeroNumber
              title={'Companies'}
              value={{
                total: dashboardInfo.companyCount,
                updated: dashboardInfo.lastCompanyUpdated,
                updatedName: dashboardInfo.lastCompanyUpdated
                  ? dashboardInfo.lastCompanyUpdated.name
                  : '',
                editAction: (id) => () => {
                  const { history } = this.props;
                  history.push(`/company/view/${id}`);
                }
              }}
              icon={'building outline'}
              color={'yellow'}
            />
          </Grid.Column>
          <Grid.Column className="profile-metrics">
            <HeroNumber
              title={'Tooltips'}
              value={{
                total: dashboardInfo.tooltipCount,
                updated: dashboardInfo.lastTooltipUpdated,
                updatedName: dashboardInfo.lastTooltipUpdated
                  ? dashboardInfo.lastTooltipUpdated.label
                  : '',
                editAction: (id) => () => {
                  const { history } = this.props;
                  history.push(`/tooltip/view/${id}`);
                }
              }}
              icon={'lightbulb'}
              color={'orange'}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="dashboard-activity-frame">
          <Grid.Column className="dashboard-activity dashboard-col">
            <ContributionGraph
              skip={this.state.skip}
              updateSkip={(newSkip) => this.setState({ skip: newSkip })}
            />
          </Grid.Column>

          <Grid.Column className="dashboard-activity dashboard-col">
            <Header
              as="h3"
              content="Schemas"
              subheader="Your schema activity broken down by status"
            />
            <Segment.Group raised>
              <Segment padded="very">{/*[> TODO: add some graph in here <]*/}</Segment>
              <Segment className="dashboard-activity-tables">
                <Tab panes={tablePanes} menu={{ secondary: true }} renderActiveOnly={false} />
              </Segment>
            </Segment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default Dashboard;
