import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/eclipse.css';

import './index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import CodeMirror from 'react-codemirror';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import validator from '@wegolook/schema-validator';
import {
  Button,
  Form,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Segment,
  Tab,
  Dimmer,
  Modal,
  Input
} from 'semantic-ui-react';

import { omitDeep } from '../../apollo/apollo-typename-cleaner';
import getData from '../../graphql/query';
import GetSchemaForOutputQuery from '../../graphql/queries/getSchemaForOutput.graphql';
import GetPublishSchemaByIds from '../../graphql/queries/getPublishSchemaByIds.graphql';
import PublishSchemaV1Mutation from '../../graphql/mutations/publishSchemaV1.graphql';
import Mapper from './mapper';

@withRouter
@graphql(GetSchemaForOutputQuery, {
  name: 'schemasData',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@graphql(PublishSchemaV1Mutation, {
  name: 'publishSchemaV1',
  options: () => ({
    errorPolicy: 'all'
  })
})
class OutputMapper extends Component {
  state = {
    selectedSchemas: [],
    output: [],
    activeMappedJsonIndex: 0,
    copied: false,
    loading: false,
    errors: false,
    openMessageModal: false,
    modalText: '',
    inputModalOpen: false,
    linkedIssueNumber: ''
  };

  errorHandler = (err) => {
    this.setState({ errors: err });
  };

  mapToOutput = async () => {
    const variables = { schemaId: [] };
    const { selectedSchemas } = this.state;
    selectedSchemas.map((item) => {
      variables.schemaId.push({ _id: item._id });
    });
    this.setState({ loading: true });
    const data = await getData(GetPublishSchemaByIds, variables);
    const cleaned = omitDeep(data, '__typename');
    this.setState({ loading: false });
    const getSelectedPublishSchemas = cleaned.getPublishSchemaByIds;
    const schemas = [];
    getSelectedPublishSchemas.forEach((schemaItem) => {
      selectedSchemas.forEach((i) => {
        if (i._id === schemaItem._id) {
          const newCompanies = [];
          i.companies.forEach((company) => {
            if (company.companyId === '') {
              newCompanies.push(company);
            } else {
              schemaItem.companies.forEach((c) => {
                if (c.companyId === company.companyId) {
                  newCompanies.push(c);
                }
              });
            }
          });
          const newSchema = { ...schemaItem, ...{ companies: newCompanies } };
          schemas.push(newSchema);
        }
      });
    });
    const mapped = await Mapper(schemas, this.errorHandler);
    mapped.forEach((schema) => {
      Object.keys(schema).map((k) =>
        typeof schema[k] === 'string' ? (schema[k] = schema[k].trim()) : null
      );
    });

    let output = mapped.map((schema, index) => {
      delete schema.id;
      const validated = validator.validate(schema);
      const mappedOutput = { name: schema.name };

      if (validated.error) {
        mappedOutput.output = validated.error.annotate(true);
        mappedOutput.errors = validated.error.details;
      } else {
        mappedOutput.output = JSON.stringify(schema, null, 2);
      }
      return mappedOutput;
    });

    this.setState({
      output,
      activeMappedJsonIndex: 0,
      copied: false
    });
  };

  publishV1Schema = async () => {
    const { publishSchemaV1 } = this.props;
    const { output, activeMappedJsonIndex } = this.state;
    const schemas = [...output];
    const schemaV1Json = schemas[activeMappedJsonIndex];
    let message = '';
    this.setState({ loading: true });

    try {
      if (schemaV1Json.errors && schemaV1Json.errors.length) {
        message = 'Please correct errors in selected schema to publish.';
      } else {
        const variables = {
          record: JSON.parse(schemaV1Json.output),
          issueNumber: this.state.linkedIssueNumber
        };
        const schemaV1Response = await publishSchemaV1({ variables });
        const { message: responseMessage, error } = schemaV1Response.data.schemaV1;

        if (responseMessage === 'Success') {
          message = `MR for schema: ${variables.record.type} created successfully.`;
        } else {
          message = `MR for schema: ${variables.record.type} couldn't be created successfully`;
          if (error && error.description) {
            message = `${message}. \n Error: ${error.description}`;
          }
        }
      }
    } catch (error) {
      message = error.message;
    }

    this.setState({ modalText: message });
    this.setState({ loading: false });
    this.setState({ openMessageModal: true });
  };

  saveErrorPopup = () => {
    return (
      <Modal open={this.state.openMessageModal}>
        <Modal.Header>Publish Schema V1</Modal.Header>
        <Modal.Content>
          <h3>{this.state.modalText}</h3>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => this.setState({ openMessageModal: false })}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  toggleCompanySelection = (schema, companyId) => (e, val) => {
    const { selectedSchemas } = this.state;
    const defaultCompany = [
      {
        companyId: '',
        name: '',
        company: {
          name: '',
          deliveryInfo: {
            cutoff: {
              hour: 14,
              minute: 0,
              second: 0,
              timezone: 'US/Central'
            },
            weekendDelivery: false,
            holidayDelivery: false
          }
        },

        lineItems: []
      }
    ];

    if (val.checked) {
      let _selectedSchemas = [...selectedSchemas];

      let _thisSelectedSchema = selectedSchemas.find((s) => s._id === schema._id);

      if (!_thisSelectedSchema) {
        let _newSchema = {
          ...schema,
          ...{
            companies: companyId
              ? schema.companies.filter((c) => c.companyId === companyId)
              : defaultCompany
          }
        };
        _selectedSchemas = [..._selectedSchemas, _newSchema];
      } else {
        let _newSchema = {
          ..._thisSelectedSchema,
          ...{
            companies: [
              ..._thisSelectedSchema.companies.filter((c) => c.companyId !== companyId),
              companyId
                ? schema.companies.find((c) => c.companyId === companyId)
                : defaultCompany[0]
            ]
          }
        };
        _selectedSchemas = [
          ..._selectedSchemas.filter((s) => s._id !== _newSchema._id),
          _newSchema
        ];
      }
      this.setState({
        selectedSchemas: _selectedSchemas
      });
    } else {
      let _selectedSchemas = [...selectedSchemas];
      let _thisSelectedSchema = _selectedSchemas.find((s) => s._id === schema._id);
      let _theseLeftoverCompanies = _thisSelectedSchema.companies.filter((c) =>
        companyId ? c.companyId !== companyId : c.companyId !== ''
      );
      let _theseLeftoverSchemas = _selectedSchemas.filter((s) => s._id !== schema._id);

      let _newSchema = {
        ..._thisSelectedSchema,
        ...{
          companies: [..._theseLeftoverCompanies]
        }
      };

      _selectedSchemas = _theseLeftoverCompanies.length
        ? [..._theseLeftoverSchemas, _newSchema]
        : _theseLeftoverSchemas;

      this.setState({
        selectedSchemas: _selectedSchemas
      });
    }
  };

  renderCTA = () => {
    return (
      <Segment className="cta-message">
        <div>
          <Icon.Group size="huge">
            <Icon name="map" />
          </Icon.Group>
          <Header>Output Mapper</Header>
          <Header.Subheader>{`Currently there is nothing to show.`}</Header.Subheader>
          <Header.Subheader>
            {`Select Companies from schemas and run the output mapper`}
          </Header.Subheader>
        </div>
      </Segment>
    );
  };

  truncateName = (name) => {
    const hardNameLimit = 20;
    return name.length > hardNameLimit ? `${name.slice(0, hardNameLimit)}...` : name;
  };

  /**
   * Sets the visibility of the input modal for getting the linked issue number to link the MR to an issue
   *
   * @param {boolean} value - If true, activated the modal, if false, hides the modal
   */
  showInputModal = (value = true) => {
    this.setState({ inputModalOpen: value });
  };

  renderOutput = () => {
    const { output, loading, errors } = this.state;
    const options = {
      lineNumbers: true,
      readOnly: true,
      mode: { name: 'javascript', json: true },
      lineWrapping: true,
      theme: 'eclipse'
    };
    const errObj = {};

    let content = this.renderCTA();

    if (output.length > 0) {
      const panes = output.map((x, index) => {
        let hasError = (!!x.errors && x.errors.length) || errors;
        let color = '';
        let icon = '';
        let content = this.truncateName(x.name);

        if (errors) {
          x.output += `\n This group does not have fields array - ${JSON.stringify(errors)}`;
        }

        if (hasError) {
          icon = (
            <Icon.Group>
              <Icon size="big" color="red" name="dont" />
              <Icon color="black" name="map" />
            </Icon.Group>
          );
          errObj[index] = true;
          color = 'red';
        }

        return {
          menuItem: {
            key: `menu-item-${x.name}`,
            content: content,
            icon: icon,
            title: x.name
          },
          render: () => (
            <Tab.Pane color={color}>
              <CodeMirror key={`output-json-${x.name}`} value={x.output} options={options} />
            </Tab.Pane>
          )
        };
      });
      content = (
        <>
          <Tab
            menu={{
              color: this.isShowingErrorTab() ? 'red' : '',
              attached: true,
              pointing: true,
              vertical: false
            }}
            panes={panes}
            onTabChange={(e, { activeIndex }) => {
              this.setState({
                activeMappedJsonIndex: activeIndex || 0,
                copied: false
              });
            }}
          />
          <div className="output-mapper-buttons">
            <Button
              color={errObj[this.state.activeMappedJsonIndex] ? 'red' : 'blue'}
              disabled={errObj[this.state.activeMappedJsonIndex]}
              className={`publish-schemaV1`}
              onClick={() => this.showInputModal(true)}
            >
              Publish V1 to Schemas
            </Button>
          </div>
        </>
      );
    }

    return (
      <div className={`output-container`}>
        {loading ? (
          <Dimmer active page>
            <Loader />
          </Dimmer>
        ) : (
          ''
        )}
        {content}
      </div>
    );
  };

  isShowingErrorTab = () => {
    const { activeMappedJsonIndex, output } = this.state;
    const schemas = [...output];
    const thisOutput = schemas[activeMappedJsonIndex];

    return !!thisOutput.errors && thisOutput.errors.length;
  };

  renderCopyToClipboard = () => {
    const { activeMappedJsonIndex, output, copied } = this.state;

    if (!output.length) {
      return null;
    }

    const schemas = [...output];
    const thisOutput = schemas[activeMappedJsonIndex];
    const hasError = this.isShowingErrorTab();

    if (hasError || !thisOutput.name) {
      return (
        <div>
          <Button color="red" disabled>{`Copy ${thisOutput.name ? thisOutput.name : 'this Schema'}
          `}</Button>
          <Message negative>
            <Message.Header>
              Cannot Copy {thisOutput.name ? thisOutput.name : 'this Schema'} to your clipboard!
            </Message.Header>
            There are errors in this Schema. See the JSON output.
          </Message>
        </div>
      );
    }

    let copiedMessage = null;

    if (copied) {
      copiedMessage = (
        <Message positive>
          <Message.Header>Copied!</Message.Header>
          <p>
            The schema <b>{thisOutput.name}</b> Is currently in your clipboard.
          </p>
        </Message>
      );
    }

    return (
      <CopyToClipboard
        text={thisOutput.output}
        onCopy={() => {
          this.setState({
            copied: true
          });
        }}
      >
        <div>
          <Button color={copied ? 'green' : 'blue'}>
            <Icon name="clipboard outline" />
            {`Copy ${thisOutput.name} to your clipboard`}
          </Button>
          {copiedMessage}
        </div>
      </CopyToClipboard>
    );
  };

  renderCheckbox = (schema) => {
    return schema.companies.map((c, companyIndex) => {
      const { company, companyId } = c;
      if (!company) return null;
      return (
        <Form.Checkbox
          key={`select-output-schema-company-${companyId}`}
          label={company.name}
          onChange={this.toggleCompanySelection(schema, companyId)}
        />
      );
    });
  };

  render() {
    const { schemasData } = this.props;
    const { selectedSchemas } = this.state;

    if (schemasData.loading) {
      return (
        <Dimmer active page>
          <Loader />
        </Dimmer>
      );
    }

    const cleaned = omitDeep(schemasData, '__typename');
    const items = cleaned.getLatestPublishedSchema.map((schema) => {
      return (
        <List.Item key={`select-output-schema-${schema.lastestObj._id}`}>
          <List.Header>{schema.lastestObj.info.schemaName}</List.Header>
          <List.Content>
            <div>
              <List>
                <List.Content>
                  {schema.lastestObj.info.isConsumer ? (
                    <div>
                      <Form.Checkbox
                        label="Consumer Schema"
                        className="consumer-schema-check"
                        onChange={this.toggleCompanySelection(schema.lastestObj)}
                      />
                      {this.renderCheckbox(schema.lastestObj)}
                    </div>
                  ) : (
                    this.renderCheckbox(schema.lastestObj)
                  )}
                </List.Content>
              </List>
            </div>
          </List.Content>
        </List.Item>
      );
    });

    /**
     * This modal is used for getting a number from the user that can be used to like a new merge request with an issue in GitLab
     *
     * @returns The modal that will be used to retrieve input for getting an issue number from the user.
     */
    const inputModal = () => {
      return (
        <Modal
          onClose={() => this.showInputModal(false)}
          onOpen={() => this.showInputModal(true)}
          open={this.state.inputModalOpen}
        >
          <Modal.Header>Link issue number and confirm</Modal.Header>
          <Modal.Content>
            <Input
              label="Issue number"
              onChange={(e) => this.setState({ linkedIssueNumber: e.target.value })}
              value={this.state.linkedIssueNumber}
            ></Input>
            <Modal.Description>
              <p style={{ marginTop: '16px' }}>
                If you have an issue you'd like to link the newly created MR (merge request) to,
                please enter its number above.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={() => this.showInputModal(false)}>
              Cancel
            </Button>
            <Button
              content="Publish"
              labelPosition="right"
              icon="checkmark"
              onClick={() => {
                this.showInputModal(false);
                this.publishV1Schema();
              }}
              positive
            />
          </Modal.Actions>
        </Modal>
      );
    };

    const buttonStyle = selectedSchemas.length > 0 ? 'primary' : '';
    const selectedCompanyCount = selectedSchemas.reduce(
      (acc, cur) => (acc += cur.companies.length),
      0
    );
    const buttonText =
      selectedSchemas.length > 0
        ? `Convert ${selectedCompanyCount} compan${selectedCompanyCount > 1 ? 'ies' : 'y'} in ${
            selectedSchemas.length
          } schema${selectedSchemas.length > 1 ? 's' : ''} to Legacy Output`
        : 'Select schema(s)';

    return (
      <div className="editor-json-view output-mapper">
        <div className="form-schema-json json-view-frame control-panel-frame">
          <Header
            as="h3"
            content="Schema Mapping"
            subheader="Select which schemas you want mapped to json."
          />
          <Segment>
            <Form>
              <List divided size="large" relaxed>
                {items}
              </List>

              <Button
                onClick={this.mapToOutput}
                className={`${buttonStyle} output-mapper-buttons`}
                disabled={selectedSchemas.length == 0}
                style={{ zIndex: '12', bottom: '15px' }}
              >
                {buttonText}
              </Button>
            </Form>
          </Segment>
          <div className="output-mapper-buttons">{this.renderCopyToClipboard()}</div>
        </div>
        <div className="form-output-json json-view-frame">{this.renderOutput()}</div>
        {this.saveErrorPopup()}
        {inputModal()}
      </div>
    );
  }
}

export default OutputMapper;
