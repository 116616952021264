import '../index.css';
import React from 'react';
import { Image, Table, Icon } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import moment from 'moment';

const SchemaTableRow = (props) => {
  const { publishedVersions, schemaPermissions, schema, history } = props;

  // Get only the published schema versions that are related to this specific schema, not other schemas in the list.
  // After filtering, map only the published version to the values
  const values = schemaPermissions.schemas
    .filter((schema) => schema.collectionId === props.schema._id)
    .map((schema) => {
      return schema.publishedVersion;
    });

  const options = publishedVersions
    .map((publishedVersion, index) => {
      const { collectionId } = publishedVersion;
      if (collectionId === props.schema._id) {
        return {
          id: publishedVersion._id,
          collectionid: publishedVersion.collectionId,
          key: publishedVersion.publishedVersion,
          text: publishedVersion.publishedVersion,
          value: publishedVersion.publishedVersion
        };
      }
    })
    .filter(Boolean);

  /**
   * Checks the previous state of permission versions that were selected for the schema and finds what version was
   * either appended or removed from the permission versions list.
   *
   * @param {array} newVersions
   * @returns {string} The version that was either appended or removed between the new versions and previous versions
   */
  const findChangeInVersions = (newVersions) => {
    const previousVersions =
      props.schemaPermissions.schemas
        .filter((version) => version.collectionId === props.schema._id)
        .map((version) => version.publishedVersion) || [];

    let difference = previousVersions
      .filter((version) => !newVersions.includes(version))
      .concat(newVersions.filter((version) => !previousVersions.includes(version)));

    return difference[0];
  };

  const checkIdExists = (id) => {
    let found = false;
    props.schemaPermissions.schemas.forEach((schema) => {
      if (schema._id === id) {
        found = true;
      }
    });
    return found;
  };

  /**
   * Gets called when the semantic UI dropdown component fires onChange()
   *
   * @param {object} event - Event data
   * @param {object} data - Data passed by the semantic UI dropdown component in the onChange() method
   */
  const onPermissionsChange = (event, { value }) => {
    const changedVersion = findChangeInVersions(value);

    // This is the schema permission linked to the version that was selected
    const selectedSchemaPermission = props.publishedVersions.find(
      (publishedVersion) =>
        publishedVersion.publishedVersion === changedVersion &&
        publishedVersion.collectionId === schema._id
    );

    // Schema permissions that are related to only this schema card
    const relatedSchemaPermissions = props.schemaPermissions.schemas.filter(
      (version) => version.collectionId === props.schema._id
    );

    if (value.length > relatedSchemaPermissions.length) {
      if (!checkIdExists(selectedSchemaPermission._id)) {
        props.actions.addSchemaPermission({
          schemas: [selectedSchemaPermission]
        });
      }
    } else {
      props.actions.removeSchemaPermission(selectedSchemaPermission._id);
    }
  };

  const renderExtraAuthor = () => {
    const onClick = () => {
      history.push(`/user/view/${schema.authorId}`);
    };

    const { author } = schema;

    const authorName = author ? `${author.firstName} ${author.lastName}` : ``;
    const avatarUrl = author ? author.avatarUrl : ''; //mock image here

    return (
      <div className="table-author">
        <div>
          <Image src={avatarUrl} avatar onClick={onClick} />
        </div>
        <div>
          <span>{`${authorName}`}</span>
          <br />
          <span className="table-author-last-edited">
            <Icon name="history" fitted />
            {` Last Edited: ${moment(schema.updatedAt).fromNow()}`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Table.Row>
      <Table.Cell width="7">{schema.info.schemaName}</Table.Cell>
      <Table.Cell width="4">{renderExtraAuthor()}</Table.Cell>
      <Table.Cell width="5">
        <Dropdown
          placeholder="Published Versions"
          fluid
          multiple
          onChange={onPermissionsChange}
          selection
          value={values}
          options={options}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default withRouter(SchemaTableRow);
