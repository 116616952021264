import EditFieldsView from './edit-fields-view';
import EditInfoView from './edit-info-view';
import JSONView from './json-view';
import PreviewView from './preview-view';
import VersionsView from './versions-view';
export default [
  {
    id: 'edit-info',
    component: EditInfoView,
    label: 'Info Editor',
    icon: 'info circle'
  },
  {
    id: 'edit-fields',
    component: EditFieldsView,
    label: 'Field Editor',
    icon: 'wpforms'
  },
  {
    id: 'versions',
    component: VersionsView,
    label: 'Versions',
    icon: 'clone outline'
  },
  {
    id: 'preview',
    component: PreviewView,
    label: 'Preview',
    icon: 'eye'
  },
  {
    id: 'json-viewer',
    component: JSONView,
    label: 'JSON Schema',
    icon: 'code'
  }
];
