import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import config from '../../../config';

const BASE_URL = config.REACT_APP_BASE_URL;

class TooltipPreview extends Component {
  renderTemplatedToolTip(tooltip) {
    const { label, text, imageUrl, imageSignedUrl } = tooltip;

    const imageTag = imageUrl
      ? `<img style="max-height:500px; max-width:500px;" src="${imageSignedUrl}" />`
      : '';
    const labelTag = label ? `<h1>${label}</h1>` : '';
    const textTag = text ? `<p>${text}</p>` : '';

    return `
    <meta charset="utf-8" />
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="${BASE_URL}/v1/tooltipStatic/css/style.css" />

    <div class="container">
      ${imageTag}
      <div class="border"></div>
      <div class="arrow_box"></div>
      <div class="content">
        ${labelTag}
        ${textTag}
      </div>
    </div>`;
  }

  render() {
    const { tooltip } = this.props;
    return (
      <Segment className="tooltip-preview">
        <div
          dangerouslySetInnerHTML={{
            __html: tooltip ? this.renderTemplatedToolTip(tooltip) : null
          }}
        />
      </Segment>
    );
  }
}

export default TooltipPreview;
