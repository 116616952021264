import Joi from 'joi-browser';

export default {
  tags: Joi.array().items(Joi.string()),
  description: Joi.string(),
  country: Joi.string(),
  currency: Joi.string(),
  schemaName: Joi.string()
    .required()
    .label('Schema Name'),
  schemaGroup: Joi.string().label('Schema Group'),
  subSchema: Joi.string().label('Sub Schema'),
  requiredEquipment: Joi.array().items(Joi.string()),
  estimatedTime: {
    min: Joi.number()
      .example(0)
      .integer()
      .min(1),
    max: Joi.number()
      .example(0)
      .integer()
      .min(Joi.ref('min'))
  },
  companies: Joi.array().items(
    Joi.object({
      lineItems: Joi.array().items(
        Joi.object({
          price: {
            amount: Joi.number(),
            currency: Joi.string()
          },
          lookerFee: {
            amount: Joi.number(),
            currency: Joi.string()
          },
          required: Joi.boolean(),
          base: Joi.boolean()
        })
      )
    })
  ),
  deliveryOptions: Joi.array()
    .min(1)
    .items(
      Joi.object({
        name: Joi.string(),
        days: Joi.number().min(0),
        standard: Joi.boolean(),
        lookerDays: Joi.number()
          .when('days', {
            is: Joi.number().less(1),
            then: Joi.number()
              .min(0)
              .max(0)
          })
          .when('days', {
            is: Joi.number().greater(0),
            then: Joi.number()
              .min(0)
              .max(Joi.ref('days'))
          }),
        price: Joi.object({
          amount: Joi.number(),
          currency: Joi.string()
        }),
        itemCode: Joi.string()
      })
    )
};
