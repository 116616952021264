import './index.css';

import React, { Component } from 'react';
import { Hint, RadialChart } from 'react-vis';

export default class SimpleRadialChart extends Component {
  state = {
    value: null
  };
  render() {
    const { value } = this.state;
    const { items, size } = this.props;

    const itemSum = items.reduce((sum, v) => sum + v.value, 0);
    items.forEach((x) => (x.percent = Math.round((x.value / itemSum) * 100)));

    const hint = value ? (
      <Hint value={value}>
        <h3>{value.label}</h3>
        <p>{value ? value.percent + '%' : ''}</p>
      </Hint>
    ) : null;

    return (
      <RadialChart
        innerRadius={size / 3}
        radius={size / 2.2}
        getAngle={(d) => d.value}
        data={items}
        onValueMouseOver={(v) => this.setState({ value: v })}
        onSeriesMouseOut={(v) => this.setState({ value: null })}
        width={size}
        height={size}
      >
        {hint}
      </RadialChart>
    );
  }
}
