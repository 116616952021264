import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Icon, List } from 'semantic-ui-react';
import config from '../../config';
// import { API_VERSION, BASE_URL } from './../../consts';

const API_VERSION = config.REACT_APP_API_VERSION;
const BASE_URL = config.REACT_APP_BASE_URL;
function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
class DeveloperSettings extends Component {
  state = {};
  openInNewTab = (url) => {
    var a = document.createElement('a');
    a.target = '_blank';
    a.href = url;
    a.click();
  };
  componentDidMount() {}

  renderHeader = () => {
    return (
      <Header as="h2">
        <Icon name="lab" />
        <Header.Content>
          Developer Settings
          <Header.Subheader>
            If you are a Developer some resources and settings are here
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  };
  render() {
    const content = (
      <List divided relaxed selection>
        <List.Item
          onClick={() => {
            this.openInNewTab(`${BASE_URL}/${API_VERSION}/docs`);
          }}
        >
          <List.Icon name="file text outline" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Server Documentation</List.Header>
            <List.Description as="a">View Docs</List.Description>
          </List.Content>
        </List.Item>
        <List.Item
          onClick={() => {
            this.openInNewTab(`${BASE_URL}/${API_VERSION}/status`);
          }}
        >
          <List.Icon name="thumbs up" size="large" verticalAlign="middle" />
          <List.Content>
            <List.Header>Server Status</List.Header>
            <List.Description as="a">View Status</List.Description>
          </List.Content>
        </List.Item>
      </List>
    );
    return (
      <div className="settings-profile">
        <div style={{ padding: 15 }}>{this.renderHeader()}</div>
        <div style={{ padding: 15 }}>{content}</div>
      </div>
    );
  }
}
export default DeveloperSettings;
