import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Workspace from '../../routes/workspace';
import HistoryTable from './history-table';
import versionView from './versionView';

@withRouter
class HistoryWorkspace extends Component {
  workSpaces = [
    {
      id: 'diffs',
      component: HistoryTable
    },
    {
      id: 'version',
      param: ':version',
      component: versionView
    }
  ];
  render() {
    const { match } = this.props;
    return <Workspace rootUrl={match.path} workSpaces={this.workSpaces} />;
  }
}
export default HistoryWorkspace;
