import { combineReducers } from 'redux';

import activityBar from './activity-bar';
import companySearch from './company-search';
import companyView from './company-view';
import dashboard from './dashboard';
import editSchemaField from './edit-schema-field';
import editSchemaInfo from './edit-schema-info';
import editSchemaPreview from './edit-schema-preview';
import editSchemaSchema from './edit-schema-schema';
import editSchemaToolbar from './edit-schema-toolbar';
import schemaEditorTabSelected from './schema-editor-tab-selected';
import lineItemView from './lineitem-view';
import lineItemSearch from './lineItems-search';
import loginForm from './login-form';
import schemaCopyMachine from './schema-copy-machine';
import schemaDeleteMachine from './schema-delete-machine';
import schemaSearch from './schema-search';
import addSchemaPermissionsSearch from './add-schema-permissions-search';
import searchableEntitySearch from './searchableentity-search';
import tooltipSearch from './tooltip-search';
import tooltipView from './tooltip-view';
import uniqueFieldEdit from './uniquefield-edit';
import uniqueFieldSearch from './uniquefield-search';
import userSearch from './user-search';
import userView from './user-view';
import who from './who';
import defaults from './edit-defaults';
import mapEdit from './map-edit';
import mapSearch from './map-search';
import schemaPermissions from './schema-permissions';
import editSchemaVersions from './edit-schema-versions';

export default combineReducers({
  activityBar,
  companySearch,
  companyView,
  dashboard,
  editSchemaField,
  editSchemaInfo,
  editSchemaPreview,
  editSchemaSchema,
  editSchemaToolbar,
  schemaEditorTabSelected,
  lineItemSearch,
  lineItemView,
  loginForm,
  schemaCopyMachine,
  schemaDeleteMachine,
  schemaSearch,
  addSchemaPermissionsSearch,
  searchableEntitySearch,
  tooltipSearch,
  tooltipView,
  uniqueFieldEdit,
  uniqueFieldSearch,
  userSearch,
  userView,
  who,
  defaults,
  mapEdit,
  mapSearch,
  schemaPermissions,
  editSchemaVersions
});
