import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { Dropdown, Button, Icon, Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const DEFAULT_ICON = 'setting';

@withRouter
class SearchActionButtonElement extends Component {
  render() {
    const { item, actionElement } = this.props;
    const { icon, label, action, routes } = actionElement;

    const routeRoot = this.props.match ? this.props.match.path.toLowerCase() : null;

    const hide = action === null || (action && action.show && action.show(item) === false);
    if (
      (routeRoot &&
        routes &&
        !routes.find((x) => routeRoot.startsWith(x) || routeRoot.startsWith(`/${x}`))) ||
      hide
    ) {
      return null;
    }

    const onClick = () => {
      if (_.isFunction(action)) {
        action(item);
      }
      if (_.isFunction(action.action)) {
        action.action(item);
      }
    };

    const trigger = (
      <Button icon basic onClick={onClick}>
        <Icon name={icon || DEFAULT_ICON} />
      </Button>
    );

    return (
      <Popup position="top center" size="mini" inverted trigger={trigger}>
        {' '}
        {label}{' '}
      </Popup>
    );
  }
}

@withRouter
class SearchActionMenuItemElement extends Component {
  render() {
    const { item, actionElement } = this.props;
    const { icon, label, action, routes } = actionElement;

    const routeRoot = this.props.match ? this.props.match.path.toLowerCase() : null;

    const hide = action === null || (action && action.show && action.show(item) === false);
    if (
      (routeRoot &&
        routes &&
        !routes.find((x) => routeRoot.startsWith(x) || routeRoot.startsWith(`/${x}`))) ||
      hide
    ) {
      return null;
    }

    const onClick = () => {
      if (_.isFunction(action)) {
        action(item);
      }
      if (_.isFunction(action.action)) {
        action.action(item);
      }
    };

    return <Dropdown.Item icon={icon || DEFAULT_ICON} text={label} onClick={onClick} />;
  }
}

export class SearchActionMenuSection extends Component {
  render() {
    const { item, model } = this.props;

    if (!model) {
      return null;
    }

    const elements = model.map((actionElement) => (
      <SearchActionMenuItemElement
        key={`action-element=${item.id}-${actionElement.property || actionElement.label}`}
        item={item}
        actionElement={actionElement}
      />
    ));

    if (elements.length === 0) {
      return null;
    }

    return (
      <Dropdown item direction="right" className="icon" icon="ellipsis vertical">
        <Dropdown.Menu>{elements}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

export class SearchActionButtonSection extends Component {
  render() {
    const { item, model } = this.props;

    if (!model) {
      return null;
    }

    const elements = model.map((actionElement) => (
      <SearchActionButtonElement
        key={`action-element=${item.id}-${actionElement.property || actionElement.label}`}
        item={item}
        actionElement={actionElement}
      />
    ));

    if (elements.length === 0) {
      return null;
    }

    return (
      <div className="search-action-section">
        <Button.Group basic>{elements}</Button.Group>
      </div>
    );
  }
}
