import './index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Grid, Header, Modal } from 'semantic-ui-react';

import RemoveSchemaMutation from '../../graphql/mutations/removeSchema.graphql';
import { setDeleteMachineTarget } from '../../state/actions';
import SchemaCard from '../schema/search/schema-card';

function stateToComponent(state) {
  return {
    schemaDeleteMachine: state.schemaDeleteMachine
  };
}

@connect(stateToComponent)
@graphql(RemoveSchemaMutation, {
  name: 'removeSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
class SchemaDeleteMachine extends Component {
  deleteSchema = () => {
    const { schemaDeleteMachine, dispatch } = this.props;

    const item = schemaDeleteMachine.target;

    const { removeSchema, refetch } = this.props;
    const variables = { id: item._id };
    removeSchema({ variables })
      .then((res) => {
        if (res.errors) {
          console.log(res.errors);
        }

        dispatch(setDeleteMachineTarget(null));
        refetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  nevermind = () => {
    const { dispatch } = this.props;
    dispatch(setDeleteMachineTarget(null));
  };

  render() {
    const { schemaDeleteMachine } = this.props;

    const form = schemaDeleteMachine.target;

    if (!form) {
      return null;
    }

    return (
      <Modal open={!!schemaDeleteMachine.target}>
        <Modal.Content>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <SchemaCard result={form} />
              </Grid.Column>
              <Grid.Column className={`form-delete-machine-description`}>
                <Modal.Description>
                  <Header as="h2" icon>
                    {`Delete ${form.info.schemaName} ?`}
                    <Header.Subheader>
                      Deleting this form is a permenent decision. Are you certain you want to delete
                      this form?
                    </Header.Subheader>
                  </Header>
                </Modal.Description>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.nevermind}>
            Nevermind
          </Button>
          <Button
            negative
            icon="delete"
            labelPosition="right"
            content="Delete It"
            onClick={this.deleteSchema}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default SchemaDeleteMachine;
