import './index.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Message, Tab } from 'semantic-ui-react';

import { updateJsonSchemaEditorPreviewOutputModel } from '../../../state/actions';
import CompanyOrderingContext from './CompanyOrderingContext';
import LookerAppContext from './LookerAppContext';
import SchemaBuilderContext from './SchemaBuilderContext';
import WGLCSRContext from './WGLCSRContext';

function stateToProps(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    editSchemaPreview: state.editSchemaPreview
  };
}

@connect(stateToProps)
class FormPreview extends Component {
  state = {
    schemaBuilderMessage: true
  };

  onChange = (event, values, err) => {
    const { dispatch } = this.props;
    const { form } = values;
    console.log('dispatching preview action');
    dispatch(updateJsonSchemaEditorPreviewOutputModel(form));
  };

  removeSchemaBuilderMessage = () => {
    this.setState({
      schemaBuilderMessage: false
    });
  };

  renderSchemaBuilderMessage = () => {
    return (
      this.state.schemaBuilderMessage && (
        <Message onDismiss={this.removeSchemaBuilderMessage}>
          <Message.Header>Schema Builder</Message.Header>
          <p>This view shows every input, ignoring context rules</p>
        </Message>
      )
    );
  };

  renderPanes = () => {
    return [
      {
        menuItem: 'Schema Builder',
        render: () => (
          <Tab.Pane attached={false}>
            {this.renderSchemaBuilderMessage()}
            <SchemaBuilderContext {...this.props} onChange={this.onChange} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Company Ordering',
        render: () => (
          <Tab.Pane attached={false}>
            <CompanyOrderingContext {...this.props} onChange={this.onChange} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Looker App',
        render: () => (
          <Tab.Pane attached={false}>
            <LookerAppContext {...this.props} onChange={this.onChange} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'WGL CSR',
        render: () => (
          <Tab.Pane attached={false}>
            <WGLCSRContext {...this.props} onChange={this.onChange} />
          </Tab.Pane>
        )
      }
    ];
  };

  render() {
    return <Tab menu={{ secondary: true, pointing: true }} panes={this.renderPanes()} />;
  }
}

export default FormPreview;
