//Maps Components to an icon.
//Component: 'Icon';

const iconMapping = {
  Header: 'header',
  Label: 'font',
  Paragraph: 'paragraph',
  PageBreak: 'long arrow alternate right',
  Dropdown: 'dropdown',
  DatePicker: 'calendar alternate outline',
  Checkboxes: 'checkmark box',
  RadioButtons: 'selected radio',
  TextInput: 'font',
  NumberInput: 'plus',
  TextArea: 'text height',
  Image: 'photo',
  Range: 'sliders',
  Camera: 'camera',
  Video: 'video camera',
  FileUpload: 'cloud upload',
  Location: 'map marker alternate',
  Telephone: 'phone',
  Contact: 'address book outline',
  Address: 'address card outline',
  ColorSelector: 'paint brush',
  BarcodeInput: 'barcode'
};

export default iconMapping;

export function fieldToIcon(field) {
  let icon = 'file alternate outline';
  if (field.component) {
    icon = iconMapping[field.component] || icon;
  }

  return icon;
}
