import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import {
  addMapProperties,
  editMapProperties,
  moveMapProperties,
  removeMapProperties,
  updateSearchableEntitiesTerm
} from '../../../../state/actions';
import GetAlluniqueFieldsQuery from '../../../../graphql/queries/getAllUniqueFields.graphql';

function componentToState(state) {
  return {
    search: state.searchableEntitySearch
  };
}

@connect(componentToState)
@graphql(GetAlluniqueFieldsQuery, {
  name: 'uniqueFieldsData',
  options: (props) => {
    const { search } = props.search;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        search,
        notInIds: props.excludeIds || []
      }
    };
  }
})
@withRouter
class PropertiesEditor extends Component {
  SEARCH_TERM_DEBOUNCE = 300;
  debounceOptions = {
    leading: false,
    trailing: true
  };

  noOptionsHtml = () => {
    return (
      <Form.Group>
        <Form.Field>
          <label>Add New Properties</label>
          <Button.Group basic>
            <Button icon="add" onClick={this.addAtIndex({})} />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  addAtIndex = (option) => (event) => {
    const { dispatch, field } = this.props;

    const action = addMapProperties;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  removeAtIndex = (option) => (event) => {
    const { dispatch, field } = this.props;

    const action = removeMapProperties;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  changeIndex = (option, dir) => (event, data) => {
    const { dispatch, field } = this.props;

    const action = moveMapProperties;

    dispatch(
      action({
        field: field,
        option: option,
        distance: dir
      })
    );
  };

  editOptionSource = (option) => (event, data) => {
    const { dispatch } = this.props;
    const action = editMapProperties;
    const newValue = data.value;

    dispatch(
      action({
        prop: 'source',
        newValue: newValue,
        option: option
      })
    );
  };

  editOptionTarget = (option) => (event, data) => {
    const { dispatch } = this.props;

    const action = editMapProperties;
    const newValue = data.value;
    dispatch(
      action({
        prop: 'target',
        newValue: newValue,
        option: option
      })
    );
  };

  editOptionText = (option) => (event, data) => {
    const { dispatch } = this.props;

    const action = editMapProperties;
    const newValue = data.value;
    dispatch(
      action({
        prop: 'text',
        newValue: newValue,
        option: option
      })
    );
  };

  updateSearchableEntitiesTerm = _.debounce(
    (e, data) => {
      const { dispatch, field } = this.props;
      const isWGLSource = field.source.match(/^WGL$|^WEGOLOOK$/i);

      if (isWGLSource) {
        dispatch(updateSearchableEntitiesTerm(data.value));
      }
    },
    this.SEARCH_TERM_DEBOUNCE,
    this.debounceOptions
  );

  bindUniqueFieldsData = () => {
    const { uniqueFieldsData, field } = this.props;
    const isWGLSource = field.source.match(/^WGL$|^WEGOLOOK$/i);

    if (!uniqueFieldsData.loading && isWGLSource) {
      const { items } = uniqueFieldsData.uniqueFieldPagination;

      return items.map((item, index) => {
        const listKey = `${field.id}-unique-field-${index}`;

        return (
          <option key={listKey} value={item.id}>
            {item.id}
          </option>
        );
      });
    }
  };

  metadataToEditHtml = (option, index) => {
    const { field } = this.props;
    const { source, target, text } = option;
    const listKey = `${field.id}-options-${index}`;
    const valueNotUnique =
      _.filter(field.properties, (opt) => {
        return opt.target === option.target;
      }).length > 1;

    const keyNotUnique =
      _.filter(field.properties, (opt) => {
        return opt.source === option.source;
      }).length > 1;

    return (
      <Form.Group key={listKey}>
        <Form.Field width={1}>
          <label>&nbsp;</label>
          <Label circular size="big">
            {index}
          </Label>
        </Form.Field>
        <Form.Input
          label="Source"
          width={5}
          list="uniqueFields"
          value={source}
          error={keyNotUnique}
          onChange={(event, data) => {
            this.updateSearchableEntitiesTerm(event, data);
            this.editOptionSource(option)(event, data);
          }}
        />
        <datalist id="uniqueFields">{this.bindUniqueFieldsData()}</datalist>

        <Form.Input
          label="Target"
          width={5}
          value={target}
          error={valueNotUnique}
          onChange={(event, data) => {
            this.editOptionTarget(option)(event, data);
          }}
        />
        <Form.Input
          label="Description"
          width={5}
          value={text}
          onChange={(event, data) => {
            this.editOptionText(option)(event, data);
          }}
        />
        <Form.Field width={2}>
          <label>Add/Remove</label>
          <Button.Group basic fluid>
            <Button
              icon="add"
              basic
              onClick={this.addAtIndex(option)}
              title="Add another option at this index"
            />

            <Button
              icon="trash"
              basic
              onClick={this.removeAtIndex(option)}
              title="Remove this option"
            />
          </Button.Group>
        </Form.Field>
        <Form.Field width={2}>
          <label>Move</label>
          <Button.Group basic fluid>
            <Button
              icon="arrow up"
              onClick={this.changeIndex(option, 1)}
              disabled={index === 0}
              title="Move this option to a higher position in the list"
            />
            <Button
              icon="arrow down"
              onClick={this.changeIndex(option, -1)}
              disabled={field.properties.length === index + 1}
              title="Move this option to a lower position in the list"
            />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  render() {
    const { field } = this.props;
    const options =
      field.properties.length > 0
        ? _.map(field.properties, this.metadataToEditHtml.bind(this))
        : this.noOptionsHtml();
    return (
      <div>
        <Form>{options}</Form>
      </div>
    );
  }
}
export default PropertiesEditor;
