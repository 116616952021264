import createReducer from './create-reducer';

const initial = {
  page: 1,
  perPageCard: 10,
  perPageTable: 20,
  layout: 'card-layout', //todo make this come form layout strategy ?
  search: '',
  facets: [],
  pagerInfo: {},
  sort: null
};

const reducers = {
  UPDATE_COMPANIES_LAYOUT: (state, value) => {
    return {
      ...state,
      ...{
        pagerInfo: value.pagerInfo,
        layout: value.layout
      }
    };
  },
  UPDATE_COMPANIES_PAGE: (state, value) => {
    return { ...state, ...{ page: value.page, pagerInfo: value.pagerInfo } };
  },
  UPDATE_COMPANIES_PERPAGE: (state, value) => {
    return { ...state, ...{ perPage: value } };
  },
  UPDATE_COMPANIES_SEARCH_TERM: (state, value) => {
    return { ...state, ...{ search: value, page: 1 } };
  },
  UPDATE_COMPANIES_SORT: (state, value) => {
    return { ...state, ...{ sort: value, page: 1 } };
  },
  UPDATE_COMPANIES_SORT: (state, value) => {
    return { ...state, ...{ sort: value } };
  },
  UPDATE_COMPANIES_SEARCH_FACETS: (state, value) => {
    return { ...state, ...{ facets: value, page: 1 } };
  }
};

export default createReducer(initial, reducers);
