import _ from 'lodash';
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import activityBarWorkspaces from './activitybar-workspaces';

class Routes extends Component {
  renderWorkspaces = () => {
    return _.map(activityBarWorkspaces, (workspace) => {
      return (
        <Route
          key={`route-${workspace.id}`}
          path={`/${workspace.id}`}
          exact={false}
          component={workspace.component}
        />
      );
    });
  };

  render() {
    return (
      <div className="workspace-frame">
        <Route
          path={`/`}
          exact={true}
          render={() => <Redirect to={`/${activityBarWorkspaces[0].id}`} />}
        />
        {this.renderWorkspaces()}
      </div>
    );
  }
}
export default Routes;
