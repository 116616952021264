import infoMapper from './infoMapper';
import mapFields from './fieldMapper';
import mapDeliveryOptions from './deliveryOptionsMapper';
import mapStandardFee from './standardFeeMapper';
import mapGroups from './groupsMapper';
import mapLineItems from './lineItemMapper';
import combineSchemas from './schemaCombiner';

async function schemaCompanyOverride(schema) {
  const { companies } = schema;
  let schemas = [];
  schemas = companies.map(async (company, index) => {
    return await getSchemaV1(company, schema);
  });
  return await Promise.all(schemas);
}

async function getSchemaV1(company, schema) {
  let mapped = await infoMapper(company, schema.info).map(schema);
  const { clientWarning, tooltip } = mapped;

  if (clientWarning.length == 0) {
    delete mapped.clientWarning;
  }
  if (tooltip.length == 0) {
    delete mapped.tooltip;
  }

  let deliveryOptions = await mapDeliveryOptions(
    schema.info.deliveryOptions,
    company.company.deliveryInfo
  );

  const instructions = schema.fields.find((f) => {
    return f.metadata.find((d) => d.key === 'legacy_type' && d.value === 'instructions');
  });

  if (instructions) {
    schema.fields = schema.fields.filter((f) => f.id !== instructions.id);
  }

  const callScripts = schema.fields
    .filter((f) => {
      return f.component === 'Telephone';
    })
    .reduce((acc, f) => {
      const callScript = f.properties.find((p) => p.name === 'callScript').value;

      if (callScript && callScript.length) {
        acc.onsiteContact = callScript;
      }

      return acc;
    }, {});

  let mappedStandardFee = await mapStandardFee(company).map(schema);
  let fields = await mapGroups(schema);

  if (instructions) {
    const mappedInstructions = await mapFields(instructions);
    delete mappedInstructions.reports;
    delete mappedInstructions.forms.looker.validations;

    const content = instructions.properties.find((x) => x.name === 'content');

    mappedInstructions.forms.looker.content = (content && content.value) || '';

    fields.push(mappedInstructions);
  }

  let lineItems = await mapLineItems(company).map(schema);

  return {
    ...mapped,
    ...mappedStandardFee.obj,
    ...{
      callScripts: {
        looker: callScripts
      }
    },
    fields,
    deliveryOptions,
    lineItems: [...lineItems.lineItems],
    companyId: company.companyId,
    version: schema.publishedVersion
  };
}
export default async function mapToOutput(schemas, errorHandler) {
  let companySchemas = await Promise.all(schemas.map(schemaCompanyOverride));

  return combineSchemas(
    companySchemas.reduce((arr, cur) => arr.concat(cur), []),
    errorHandler
  ).map((c) => {
    delete c.schemaGroup;
    delete c.companyId;
    delete c.lineItems;

    c.fields.forEach((group) => {
      if (!group.fields) {
        return;
      }
      group.fields.forEach((field) => {
        if (field && field.forms && field.forms.client) {
          delete field.forms.client.matchCriteria;
        }
        if (field && field.forms && field.forms.looker) {
          delete field.forms.looker.matchCriteria;
        }
      });
    });

    return c;
  });
}
