import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import GetToolTipByIdQuery from '../../../../graphql/queries/getToolTipById.graphql';
import {
  editSchemaFieldTooltip,
  editUniqueFieldTooltip,
  removeSchemaFieldTooltip,
  removeUniqueFieldTooltip
} from '../../../../state/actions';
import ToolTipSearch from '../../../tooltip/search';
import ToolTipPreview from '../../../tooltip/view/tooltip-preview';

@connect()
@withRouter
@graphql(GetToolTipByIdQuery, {
  name: 'tooltipData',
  skip: (props) => !props.field.tooltipId,
  options: (props) => {
    const { tooltipId } = props.field;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id: tooltipId
      }
    };
  }
})
class TooltipsEditor extends Component {
  loadHandler = (tooltip) => {
    const { dispatch, field } = this.props;
    const isSchemaEditor = this.props.match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? editSchemaFieldTooltip : editUniqueFieldTooltip;

    dispatch(action({ field, tooltip }));
  };

  clear = () => {
    const { dispatch, field } = this.props;
    const isSchemaEditor = this.props.match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? removeSchemaFieldTooltip : removeUniqueFieldTooltip;

    dispatch(action({ field }));
  };

  tooltipEditorStyles = {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  };

  render() {
    const { tooltipId } = this.props.field;
    const { tooltipData } = this.props;

    if (!tooltipId) {
      return <ToolTipSearch editor={true} loadHandler={this.loadHandler} />;
    }

    if (tooltipData.loading) {
      return null;
    }

    const tooltip = tooltipData.tooltipById;

    if (tooltipId && tooltip == null) {
      return <div>This tooltip has an id, but no entry for the tooltip. Contact Dev.</div>;
    }

    return (
      <Grid verticalAlign="middle" centered style={this.tooltipEditorStyles}>
        <Grid.Row>
          <Grid.Column width={8} style={{ display: 'flex' }}>
            <ToolTipPreview tooltip={tooltip} />
          </Grid.Column>
          <Grid.Column width={8} className="tooltip-editor-header" style={this.tooltipEditorStyles}>
            <Header as="h1" icon size="huge">
              <Icon name="pointing left" />
              {tooltip.label}
              <Header.Subheader>You've currently got this tooltip selected.</Header.Subheader>
              <Button basic onClick={this.clear}>
                Select a different tooltip!
              </Button>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
export default TooltipsEditor;
