import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';
import getSchemaVersion from '../../../graphql/queries/getSchemaVersion.graphql';
import { SearchActionButtonSection } from '../../search/actionSection';
import SearchTable from '../../search/search-table';
import '../index.css';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

function actionSection(actions) {
  return [
    {
      icon: 'eye',
      label: 'View Version',
      action: actions.version
    }
  ];
}

@connect(stateToComponent)
@graphql(getSchemaVersion, {
  name: 'schemaVersions',
  options: (props) => {
    const { id } = props.match.params;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class HistoryTable extends Component {
  historyTableModel = [
    {
      label: 'Version',
      property: 'version',
      type: 'string'
    },
    {
      label: 'Date',
      property: 'updatedAt',
      type: 'date'
    },
    {
      label: 'Action',
      property: 'method',
      type: 'string'
    },
    {
      label: 'Actions',
      type: 'component',
      component: SearchActionButtonSection,
      model: actionSection
    }
  ];

  loadSchemaVersion = (schema) => {
    const { history } = this.props;
    const { id } = this.props.match.params;
    history.push(`/schema/history/${id}/version/${schema.version}`);
  };

  render() {
    const { schemaVersions } = this.props;
    if (schemaVersions.loading) {
      return (
        <div style={{ height: '100vh' }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Versions</Loader>
          </Dimmer>
        </div>
      );
    }

    const diffs = schemaVersions.schemaVersionById.diffs;
    return (
      <SearchTable
        who={null}
        items={diffs}
        model={this.historyTableModel}
        actions={{
          version: this.loadSchemaVersion
        }}
        area="history"
      />
    );
  }
}
export default HistoryTable;
