import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Icon, Segment, Form, Header, List, Button } from 'semantic-ui-react';

import UniqueFieldSearch from '../../uniqueField/search';
import { addDefaultField, removeDefaultField } from '../../../state/actions';

function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
class FieldList extends Component {
  state = { showFieldModal: false };

  openFieldModal = () => {
    this.setState({ showFieldModal: true });
  };

  closeFieldModal = () => {
    this.setState({ showFieldModal: false });
  };

  addField = (field) => {
    const { dispatch } = this.props;
    dispatch(addDefaultField(field));
    this.closeFieldModal();
  };

  removeField = (field) => () => {
    const { dispatch } = this.props;
    dispatch(removeDefaultField(field));
  };

  addAnotherCta = () => {
    return (
      <Button basic onClick={this.openFieldModal}>
        <Icon name="plus" />
        Add another Field
      </Button>
    );
  };

  emptyCta = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Header as="h2" icon textAlign="center" className="empty-message" style={{ padding: 10 }}>
          <Icon name="add to cart" />
          No Default Fields (Yet)
        </Header>
        <br />
        <div>
          <Button basic onClick={this.openFieldModal}>
            <Icon name="plus" /> Add a Field
          </Button>
        </div>
      </div>
    );
  };

  renderField = (field, i) => {
    const onRemove = this.removeField;

    return (
      <List.Item key={`schema-field-${field._id}`} className="field-item">
        <Form.Group className={`line-item`}>
          <Form.Field width={4}>
            {<label>ID</label>}
            <Header>{field.id}</Header>
          </Form.Field>
          <Form.Field width={4}>
            {<label>Label</label>}
            <Header>{field.label}</Header>
          </Form.Field>

          <Form.Field width={1}>
            <Button.Group basic fluid style={{ marginTop: 18 }}>
              <Button icon="trash" basic onClick={onRemove(field)} title="Remove This Field" />
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </List.Item>
    );
  };

  renderFieldList = () => {
    const { fields } = this.props;
    return <List className={`line-items`}>{fields.map(this.renderField)}</List>;
  };

  renderSelectFieldModal = () => {
    const ids = this.props.fields.map((x) => x.id);
    return (
      <Modal size={'large'} open={this.state.showFieldModal} onClose={this.closeFieldModal}>
        <Modal.Header>Select a field</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <UniqueFieldSearch loadHandler={this.addField} area="defaults-editor" excludeIds={ids} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeFieldModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { fields } = this.props;

    return (
      <div style={{ padding: 8 }}>
        <Header size="medium">Fields</Header>

        <Form className="settings-data-seeder">
          <Segment>
            {fields.length ? this.renderFieldList() : this.emptyCta()}
            {fields.length ? this.addAnotherCta() : null}
          </Segment>
        </Form>
        {this.renderSelectFieldModal()}
      </div>
    );
  }
}

export default FieldList;
