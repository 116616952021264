import DataSeederSettings from './data-seeder';
import DeveloperSettings from './developer';
import ProfileSettings from './profile';
import SiteSettings from './site';

export default [
  { id: 'profile', component: ProfileSettings, label: 'Profile', icon: 'user' },
  // { id: 'password', component: MyPassword, label: 'Password', icon: 'lock' },
  { id: 'site', component: SiteSettings, label: 'Site', icon: 'options' },
  {
    id: 'developer',
    component: DeveloperSettings,
    label: 'Developer',
    icon: 'lab'
  },
  {
    id: 'seed',
    component: DataSeederSettings,
    label: 'Data Seeder',
    icon: 'leaf'
  }
];
