import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './apollo';
import store from './store';

export default ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>{children}</Router>
      </Provider>
    </ApolloProvider>
  );
};
