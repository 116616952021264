import { ApolloLink } from 'apollo-link';

import { getState } from '../store';

export const createStandardVariablesLink = () => {
  return new ApolloLink((operation, forward) => {
    const store = getState();
    const standardVariables = { userId: store.who._id };
    operation.variables = { ...operation.variables, ...standardVariables };

    return forward(operation).map((response) => {
      return response;
    });
  });
};
