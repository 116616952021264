import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Form, Header, Segment, Grid } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import { updateLineItem } from '../../../state/actions';
import CurrencyInput from '../../currency-input';
import GetLineItemById from '../../../graphql/queries/getLineItemById.graphql';
import LineItemViewActions from './lineitem-view-actions';
import lineItemViewSchema from './lineitem-view-schema';

function stateToComponent(state) {
  return {
    who: state.who,
    lineItem: state.lineItemView
  };
}

@connect(stateToComponent)
@graphql(GetLineItemById, {
  name: 'lineItemData',
  options: (props) => {
    const { id } = props.match.params;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class LineItemView extends Component {
  updateLineItem = (e, { form, field }) => {
    const { dispatch } = this.props;
    dispatch(updateLineItem(form));
  };

  renderlineItem(lineItem) {
    return (
      <Segment basic>
        <ValidatedForm
          whitelist={[CurrencyInput]}
          ignoreChildrenList={[CurrencyInput]}
          intialValue={lineItem}
          validateSchema={lineItemViewSchema}
          value={lineItem}
          readOnly={true}
          onChange={this.updateLineItem}
        >
          <Header>Line Item Info</Header>
          <Segment>
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <Form.Input fluid label="Name" name="name" />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input fluid label="Category" name="category" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <CurrencyInput fluid label="Price" name="price" />
                </Grid.Column>
                <Grid.Column>
                  <CurrencyInput fluid label="Looker Fee" name="lookerFee" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="equal">
                <Grid.Column>
                  <Form.Input fluid label="Tax Code" name="taxCode" />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input fluid label="Item Code" name="itemCode" />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input fluid label="GL Account Number" name="glAccountNumber" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </ValidatedForm>
      </Segment>
    );
  }

  renderActions = (lineitem) => {
    return <LineItemViewActions lineItem={lineitem} />;
  };

  render() {
    const { lineItem, lineItemData } = this.props;

    if (lineItemData.loading || (!lineItem && !lineItemData.lineItemById)) {
      return <div> Loading </div>;
    }

    const item = lineItemData.lineItemById || lineItem;

    console.log('item', item);
    return (
      <div className="search-workspace lineItem-search-workspace">
        {this.renderlineItem(item)}
        {this.renderActions(item)}
      </div>
    );
  }
}
export default LineItemView;
