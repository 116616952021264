import { SearchActionButtonSection } from '../../search/actionSection';
import MapTable from '../../search/search-table';

function actionSection(actions) {
  return [
    {
      icon: 'edit',
      label: 'Edit',
      action: actions.edit,
      routes: ['maps']
    },
    {
      icon: 'copy',
      label: 'Copy',
      action: actions.copy,
      routes: ['maps']
    },
    {
      icon: 'history',
      label: 'History',
      action: actions.history,
      routes: ['maps']
    }
  ];
}

export default [
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Map ID',
        property: 'id',
        type: 'string'
      },
      {
        label: 'Name',
        property: 'name',
        type: 'string'
      },
      {
        label: 'Source',
        property: 'source',
        type: 'string'
      },
      {
        label: 'Target',
        property: 'target',
        type: 'string'
      },
      {
        label: 'Created Date',
        property: 'createdAt',
        type: 'date'
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ],
    component: MapTable
  }
];
