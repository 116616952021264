import './index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Pagination } from 'semantic-ui-react';

import PublishSchema from '../../../graphql/mutations/publishSchemaMutation.graphql';
import { SearchActionButtonSection } from '../../search/actionSection.js';
import SearchTable from '../../search/search-table.js';

@withRouter
@graphql(PublishSchema, {
  name: 'publishSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
class DraftsTable extends Component {
  state = {
    page: 1,
    itemsPerPage: 10
  };

  setPageNum = (event, { activePage }) => {
    this.setState({ page: activePage });
  };

  getTableModel = () => {
    function actionSection(actions) {
      return [
        {
          icon: 'edit',
          label: 'Edit',
          action: actions.load
        },
        {
          icon: 'history',
          label: 'History',
          action: actions.history
        },
        {
          icon: 'certificate',
          label: 'Publish',
          action: actions.publish
        }
      ];
    }
    return [
      {
        label: 'Title',
        property: 'info.schemaName',
        type: 'string'
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ];
  };
  getActions = () => {
    const { history, publishSchema, reload } = this.props;
    return {
      load: (item) => {
        if (item._id) {
          history.push(`/schema-editor/${item._id}/edit-info`);
        }
      },
      history: (item) => {
        history.push(`/schema/history/${item._id}`);
      },
      publish: {
        show: (item) => item.status !== 'published',
        action: (item) => {
          publishSchema({ variables: { _id: item._id } }).then(() => reload());
        }
      }
    };
  };
  render() {
    const itemsPerPage = 10;
    const { page } = this.state;
    const totalPages = Math.round(this.props.items.length / itemsPerPage);
    const items = this.props.items.slice(
      (page - 1) * itemsPerPage,
      (page - 1) * itemsPerPage + itemsPerPage
    );
    return (
      <div className={'dashboard-tile-frame'}>
        <div className="schema-tile schema-table-frame">
          <div className="schema-table">
            <SearchTable
              items={items}
              actions={this.getActions()}
              model={this.getTableModel()}
              basic={'very'}
              compact={true}
            />
            <Pagination
              floated="right"
              activePage={page}
              totalPages={totalPages}
              siblingRange={1}
              onPageChange={this.setPageNum}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default DraftsTable;
