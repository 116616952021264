import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown, Icon, Input, Menu, Segment } from 'semantic-ui-react';

function componentToState(state) {
  return {
    activityBar: state.activityBar
  };
}

const SEARCH_TERM_DEBOUNCE = 300;

@withRouter
@connect(componentToState)
class FilterBar extends Component {
  state = {
    search: ''
  };

  componentDidMount() {
    const { search, searchAction } = this.props;
    this.setState({
      search: search.search,
      debouncedSearch: this.debounceAction(searchAction)
    });
  }

  debounceAction = (action) => {
    return _.debounce(
      (e, data) => {
        const { dispatch } = this.props;
        dispatch(action(data.value));
      },
      SEARCH_TERM_DEBOUNCE,
      this.debounceOptions
    );
  };

  renderSearchInput = () => {
    const { label } = this.props;
    const placeholderText = `Search ${label}...`;

    return (
      <Input
        className="prompt"
        placeholder={placeholderText}
        icon="search"
        value={this.state.search}
        onChange={(e, data) => {
          this.setState({ search: data.value });
          this.state.debouncedSearch(e, data);
        }}
      />
    );
  };

  renderViewSelector = () => {
    const { layout } = this.props.search;
    const { strategy, viewToggle } = this.props;

    const activeLayout = _.find(strategy, {
      id: layout
    });

    const menuHtml = _.map(strategy, (layout) => {
      const { id, icon, description } = layout;
      return (
        <Menu.Item
          key={`layout-strategy-${id}`}
          name={id}
          value={id}
          active={activeLayout.id === id}
          onClick={viewToggle}
          title={description}
          icon
        >
          <Icon name={icon} />
        </Menu.Item>
      );
    });

    return menuHtml;
  };

  renderSortSelector = () => {
    const { sort } = this.props.search;
    const sortAction = this.props.sort;
    if (sortAction) {
      return (
        <>
          <Menu.Item className="filter-bar-label">Sort:</Menu.Item>
          <Menu.Item className="filter-bar-facet-dropdown">
            <Dropdown
              placeholder="Sort"
              icon="sort"
              floating
              labeled
              button
              selection
              header="Sort By"
              className="icon utility-bar-facet-dropdown"
              onChange={this.debounceAction(sortAction)}
              value={sort}
              options={[
                {
                  text: 'Oldest',
                  value: 'CREATEDAT_ASC',
                  icon: 'sort amount down'
                },
                {
                  text: 'Newest',
                  value: 'CREATEDAT_DESC',
                  icon: 'sort amount up'
                },
                {
                  text: 'Alpha High-Low',
                  value: 'ALPHA_DESC',
                  icon: 'sort alphabet down'
                },
                {
                  text: 'Alpha Low-High',
                  value: 'ALPHA_ASC',
                  icon: 'sort alphabet up'
                }
              ]}
            />
          </Menu.Item>
        </>
      );
    }
  };

  renderFacetSelector() {
    const { filters } = this.props;

    if (!filters) {
      return null;
    }
    return (
      <>
        <Menu.Item className="filter-bar-label">Filters:</Menu.Item>
        <Menu.Item className="filter-bar-facet-dropdown tags-dd">{filters}</Menu.Item>
      </>
    );
  }

  render() {
    return (
      <Segment className="filter-bar">
        <Menu secondary>
          <Menu.Menu position="left">
            <Menu.Item className="filter-bar-search">{this.renderSearchInput()}</Menu.Item>
            {this.renderFacetSelector()}
            {this.renderSortSelector()}
          </Menu.Menu>
          <Menu.Menu position="right">{this.renderViewSelector()}</Menu.Menu>
        </Menu>
      </Segment>
    );
  }
}

export default FilterBar;
