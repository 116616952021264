import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Input, List, Modal, Dropdown } from 'semantic-ui-react';

import {
  addSchemaFieldGroup,
  editSchemaFieldGroupDetails,
  removeSchemaFieldGroup
} from '../../../state/actions';
import groupTypeOptions from '../../common/group-type-options';

function stateToComponent(state) {
  return { editSchemaSchema: state.editSchemaSchema };
}

@connect(stateToComponent)
class EditGroupsModal extends Component {
  state = {
    newGroupName: '',
    newGroupType: '',
    editingGroups: []
  };

  addGroupToGroups = () => {
    const { dispatch } = this.props;
    const { newGroupName, newGroupType } = this.state;

    this.setState({
      newGroupName: '',
      newGroupType: ''
    });
    dispatch(
      addSchemaFieldGroup({
        group: {
          name: newGroupName,
          fields: [],
          metadata: [],
          groupType: newGroupType
        },
        index: _.findIndex(this.state.editingGroups, { editing: true })
      })
    );
  };

  updateEditedGroup = (e, { value }) => {
    this.setState({
      newGroupName: value
    });
  };

  groupToListItemHtml = (group, index) => {
    let item = group.name ? (
      <>
        <List.Header>{group.name}</List.Header>
        <List.Description>{`Fields: ${group.fields && group.fields.length}`}</List.Description>
      </>
    ) : (
      <>
        <List.Content floated="right">{this.renderMenuItemControlPanel(group, index)}</List.Content>
        <List.Description>
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '-webkit-fill-available' }}
              autoFocus
              placeholder="Type here to create a new name for a group"
              onChange={this.updateEditedGroup}
              value={this.state.newGroupName}
            />
            <Dropdown
              style={{ width: '-webkit-fill-available' }}
              placeholder="Select GroupType"
              selection
              search
              onChange={(e, { value }) => this.setState({ newGroupType: value })}
              options={groupTypeOptions}
            />
          </div>
        </List.Description>
      </>
    );

    return <List.Item className={`group-li ${group.name && 'editing'}`}>{item}</List.Item>;
  };

  renderMenuItems = () => {
    const { groups } = this.props.editSchemaSchema;

    if (
      groups.length === 1 &&
      this.state.editingGroups.length >= 2 &&
      this.state.editingGroups.length < 4 &&
      this.state.newGroupName === ''
    ) {
      return _.map(this.state.editingGroups.reverse(), this.groupToListItemHtml);
    } else if (
      groups.length === 1 &&
      this.state.editingGroups.length !== 0 &&
      this.state.editingGroups[0].editing === true
    ) {
      return _.map(this.state.editingGroups.reverse(), this.groupToListItemHtml);
    } else {
      return _.map(this.state.editingGroups, this.groupToListItemHtml);
    }
  };

  moveInList(list, predicate, distance) {
    const index = _.findIndex(list, predicate);
    const item = list[index];

    const newList = [...list.slice(0, index), ...list.slice(index + 1, list.length)];

    newList.splice(index - distance, 0, item);

    return newList;
  }

  ascendInList = () => () => {
    let newList = this.moveInList(this.state.editingGroups, { editing: true }, 1);
    this.setState({
      editingGroups: newList
    });
  };
  descendInList = () => () => {
    let newList = this.moveInList(this.state.editingGroups, { editing: true }, -1);
    this.setState({
      editingGroups: newList
    });
  };

  modalOpens = () => {
    const { groups } = this.props.editSchemaSchema;
    const { group } = this.props;

    const groupIndex = _.findIndex(this.props.editSchemaSchema.groups, {
      name: group.name
    });

    this.setState({
      editingGroups: [
        ...[...groups].splice(0, groupIndex),
        { editing: true },
        ...[...groups].splice(groupIndex)
      ],
      currentGroupName: group.name,
      currentGroupType: group.groupType
    });
  };

  renderMenuItemControlPanel = (group, index) => {
    const { editingGroups } = this.state;
    return (
      <Button.Group className="group-interface-controlpanel">
        <Button
          icon
          circular
          basic
          size={'mini'}
          onClick={this.ascendInList(group)}
          disabled={index < 2}
          title="Add the group in a Higher Position in List"
        >
          <Icon name="arrow up" />
        </Button>
        <Button
          icon
          circular
          basic
          size={'mini'}
          onClick={this.descendInList(group)}
          disabled={index === editingGroups.length - 1}
          title="Add a group in a Lower Position in List"
        >
          <Icon name="arrow down" />
        </Button>
      </Button.Group>
    );
  };

  renderEditCurrentGroupModal = () => {
    const { currentGroupName, currentGroupType } = this.state;
    const { group, dispatch, onClose } = this.props;
    return (
      <Modal open={this.props.open} onClose={this.props.onClose} onMount={this.modalOpens}>
        <Modal.Header>Edit Current Group</Modal.Header>

        <Modal.Content>
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '-webkit-fill-available' }}
              autoFocus
              placeholder="Type here to edit group name"
              onChange={(e, { value }) => this.setState({ currentGroupName: value })}
              value={currentGroupName}
            />
            <Dropdown
              style={{ width: '-webkit-fill-available' }}
              placeholder="Edit GroupType"
              selection
              search
              value={currentGroupType}
              onChange={(e, { value }) => this.setState({ currentGroupType: value })}
              options={groupTypeOptions}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            floated="left"
            color="red"
            content="Delete this group"
            onClick={() => {
              dispatch(
                removeSchemaFieldGroup({
                  group
                })
              );
              onClose();
            }}
            title="Delete this group"
          />
          <Button basic content="Nevermind" onClick={onClose} title="Do not add a new group" />
          <Button
            title="Edit Group"
            positive={currentGroupName && currentGroupType}
            icon="checkmark"
            labelPosition="right"
            content={'Edit Group'}
            disabled={!currentGroupName || !currentGroupType}
            onClick={() => {
              dispatch(
                editSchemaFieldGroupDetails({
                  group,
                  value: {
                    name: currentGroupName,
                    groupType: currentGroupType
                  },
                  prop: ['name', 'groupType']
                })
              );
              onClose();
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { newGroupName, newGroupType } = this.state;

    if (this.props.editCurrentGroup) return this.renderEditCurrentGroupModal();

    return (
      <Modal open={this.props.open} onClose={this.props.onClose} onMount={this.modalOpens}>
        <Modal.Header>Edit Groups</Modal.Header>

        <Modal.Content scrolling>
          <List divided relaxed>
            {this.renderMenuItems()}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            content="Nevermind"
            onClick={this.props.onClose}
            title="Make no changes to the groups and close this modal"
          />
          <Button
            title={
              newGroupName ? 'Add This New Group At this index.' : 'You cannot add an unnamed group'
            }
            positive={newGroupName && newGroupType}
            icon="checkmark"
            labelPosition="right"
            content={'Add New Group'}
            disabled={!newGroupName || !newGroupType}
            onClick={() => {
              this.addGroupToGroups();
              this.props.onClose();
            }}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default EditGroupsModal;
