import './index.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getElement } from '@wegolook/schema-form-renderer';

import { changeEditingField, moveSchemaField, removeSchemaField } from '../../../state/actions';
import ActiveValidationReadout from './active-validation-readout';
import ControlPanel from './control-panel';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@withRouter
@connect(componentToState)
class EditingField extends Component {
  editFieldDetails = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(
      changeEditingField({
        field: field
      })
    );
  };

  removeItem = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(removeSchemaField(field));
  };

  ascendInList = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(
      moveSchemaField({
        field,
        distance: 1
      })
    );
  };

  ascend25InList = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(
      moveSchemaField({
        field,
        distance: 25
      })
    );
  };

  descendInList = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(
      moveSchemaField({
        field,
        distance: -1
      })
    );
  };

  descend25InList = (field) => (e) => {
    const { dispatch } = this.props;
    dispatch(
      moveSchemaField({
        field,
        distance: -25
      })
    );
  };

  windowPane = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          position: 'absolute'
        }}
      >
        <div
          style={{
            backgroundColor: 'transparent',
            display: 'table',
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    );
  };

  // Order is important on the windows pane
  // you can see the element in the window
  // but you cannot touch it

  render() {
    const field = this.props.children;

    const element = getElement(field, { editing: true });
    const windowPane = this.windowPane();

    const { fields } = this.props.editSchemaSchema;

    return (
      <div className="editing-element">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ControlPanel
            onEdit={this.editFieldDetails(field)}
            ascendInList={this.ascendInList(field)}
            ascend25InList={this.ascend25InList(field)}
            descendInList={this.descendInList(field)}
            descend25InList={this.descend25InList(field)}
            onTrash={this.removeItem(field)}
            item={field}
            first={field.index === 0}
            last={field.index === fields.length - 1}
          />
          <ActiveValidationReadout field={field} />
        </div>
        <div className="mock-element">
          {element}
          {windowPane}
        </div>
      </div>
    );
  }
}

export default EditingField;
