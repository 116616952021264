import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import GetAllUniqueFieldsQuery from '../../../graphql/queries/getAllUniqueFields.graphql';
import { addSchemaField } from '../../../state/actions';
import ToolbarItem from './item';

function stateToComponent(state) {
  return {
    search: state.uniqueFieldSearch,
    editSchemaToolbar: state.editSchemaToolbar,
    editSchemaSchema: state.editSchemaSchema
  };
}

@connect(stateToComponent)
@graphql(GetAllUniqueFieldsQuery, {
  name: 'uniqueFieldsData',
  options: (props) => {
    const { search } = props.search;
    const editSchemaSchema = props.editSchemaSchema;

    const notInIds = editSchemaSchema.fields.map((x) => x.id);

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        search,
        perPage: 20,
        notInIds
      }
    };
  }
})
class ToolBarList extends Component {
  addfieldToSchema = (field) => (event) => {
    const { dispatch, editSchemaToolbar } = this.props;
    dispatch(
      addSchemaField({
        field,
        targetGroup: editSchemaToolbar.activeGroupsTabName
      })
    );
  };

  itemToHtml = (field, index) => {
    return (
      <ToolbarItem
        key={`toolbar-item-${field._id}`}
        item={field}
        onClick={this.addfieldToSchema(field)}
      />
    );
  };

  render() {
    const { uniqueFieldsData } = this.props;

    if (uniqueFieldsData.loading) {
      return null;
    }

    const menuHtml = _.map(uniqueFieldsData.uniqueFieldPagination.items, this.itemToHtml);

    return (
      <div className="toolbar-list-container">
        <Button.Group vertical labeled icon className="toolbar" basic>
          {menuHtml}
        </Button.Group>
      </div>
    );
  }
}

export default ToolBarList;
