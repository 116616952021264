import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Button, Header, Modal, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';
import {
  BaseElements,
  Elements,
  buildValidatorForField,
  getElement
} from '@wegolook/schema-form-renderer';

import CreateUniqueField from '../../../graphql/mutations/createUniqueField.graphql';
import UpdateUniqueField from '../../../graphql/mutations/updateUniqueField.graphql';
import GetUniqueFieldById from '../../../graphql/queries/getUniqueFieldById.graphql';
import {
  changeUniqueFieldComponent,
  clearUniqueField,
  loadUniqueField
} from '../../../state/actions';
import SaveActions from '../../common/save-actions.js';
import EditDetailsContent from '../../schema-editor/edit-field-details';
import iconMap from '../../schema-editor/toolbar/toolbar-icon-mapper';

function stateToComponent(state) {
  return {
    who: state.who,
    uniqueField: state.uniqueFieldEdit
  };
}

@connect(stateToComponent)
@graphql(GetUniqueFieldById, {
  name: 'uniqueFieldData',
  skip: (props) => {
    const urlId = props.match.params.id;
    const skip = (props.uniqueField && props.uniqueField._id) || urlId === 'new';
    return skip;
  },
  options: (props) => {
    const { dispatch } = props;
    const { id } = props.match.params;

    return {
      onCompleted: (data) => {
        dispatch(loadUniqueField(data.uniqueFieldById));
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
@graphql(CreateUniqueField, {
  name: 'createUniqueField',
  options: (props) => {
    return {
      errorPolicy: 'all'
    };
  }
})
@graphql(UpdateUniqueField, {
  name: 'updateUniqueField',
  options: (props) => {
    return {
      errorPolicy: 'all'
    };
  }
})
class uniqueFieldEdit extends Component {
  state = {
    showModal: false
  };

  renderComponentSelectorModal = () => {
    const { showModal } = this.state;
    const { id } = this.props.match.params;

    const elementToButton = (el) => {
      const { component, label } = el;
      const icon = iconMap[component] || 'x';
      const onClick = () => {
        this.setState({ showModal: false });
        this.changeComponent(el);
      };
      return (
        <div>
          <Button
            key={label}
            icon={`${icon}`}
            content={`${label}`}
            size="massive"
            onClick={onClick}
            basic
            labelPosition="left"
            className="chooseable-element"
          />
        </div>
      );
    };

    const elements = BaseElements.map(elementToButton);

    const actions = (
      <Modal.Actions>
        <Button
          onClick={() => {
            if (id === 'new') {
              this.props.history.push('/uniquefield/search');
            } else {
              this.setState({ showModal: false });
            }
          }}
        >
          Nevermind
        </Button>
      </Modal.Actions>
    );

    if (showModal) {
      return (
        <Modal size={'large'} open={showModal}>
          <Modal.Header>Choose Component</Modal.Header>
          <Modal.Content scrolling className={`modal-search`}>
            <div className="choose-elements-container">{elements}</div>
          </Modal.Content>
          {actions}
        </Modal>
      );
    }

    return null;
  };

  changeComponent = (el) => {
    const { dispatch } = this.props;
    dispatch(changeUniqueFieldComponent({ baseField: el }));
    this.setState({ showModal: false });
  };

  componentWillMount() {
    const { uniqueField, uniqueFieldData } = this.props;

    const showModal =
      !uniqueFieldData &&
      uniqueField &&
      !uniqueField.id &&
      !uniqueField.component &&
      !this.state.showModal;

    if (showModal) {
      this.setState({ showModal });
    }
  }

  componentDidUpdate() {
    const { uniqueField, uniqueFieldData } = this.props;

    const showModal =
      !uniqueFieldData &&
      uniqueField &&
      !uniqueField.id &&
      !uniqueField.component &&
      !this.state.showModal;
    if (showModal) {
      this.setState({ showModal });
    }
  }

  getEntityToPersist = (entity) => {
    delete entity.tooltip;
    delete entity.name;

    return entity;
  };

  renderButtons = () => {
    const { uniqueField, createUniqueField, updateUniqueField } = this.props;
    const { id } = this.props.match.params;

    const actions = [
      {
        label: 'Choose Component',
        icon: 'target',
        action: () => {
          this.setState({ showModal: true });
        }
      },
      {
        label: id === 'new' ? 'Save' : 'Update',
        icon: 'save',
        action: 'update'
      },
      {
        label: 'New',
        icon: 'edit',
        action: 'new'
      }
    ];

    return (
      <SaveActions
        actions={actions}
        entity={uniqueField}
        entityType={'uniqueField'}
        updateQuery={id === 'new' ? createUniqueField : updateUniqueField}
        loadAction={loadUniqueField}
        clearAction={clearUniqueField}
        getEntityToPersist={this.getEntityToPersist}
      />
    );
  };

  render() {
    const { uniqueField } = this.props;

    if (!uniqueField) {
      return <div> Loading </div>;
    }

    if (uniqueField.component === 'Checkboxes' && uniqueField.validations.length === 0) {
      uniqueField.validations.push({ id: 'required', enabled: false });
    }

    let value = {
      [uniqueField.name]: uniqueField.defaultValue || ''
    };

    const validation = {
      [uniqueField.name]: buildValidatorForField(uniqueField)
    };

    const element = uniqueField ? getElement(uniqueField, {}) : null;

    const preview = (
      <Segment className="uniqueField-preview">
        <Header>{`Preview this ${uniqueField.component}`}</Header>
        <ValidatedForm
          initialValue={value}
          value={value}
          validateSchema={validation}
          whitelist={Object.values(Elements)}
        >
          {element}
        </ValidatedForm>
      </Segment>
    );

    return (
      <div className="search-workspace uniqueField-search-workspace uniqueField-search-workspace-w-preview">
        {preview}
        <div className="unique-field-view">
          <EditDetailsContent field={uniqueField} name={uniqueField.name} />
        </div>
        {this.renderButtons()}
        {this.renderComponentSelectorModal()}
      </div>
    );
  }
}

export default uniqueFieldEdit;
