import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';

import EditingField from '../editing-element';

class Field extends Component {
  state = {
    hovering: false
  };

  onMouseEnter = () => {
    this.setState({ hovering: true });
  };

  onMouseLeave = () => {
    this.setState({ hovering: false });
  };

  render() {
    const { className } = this.props;
    const dragging = !!className ? 'dragging' : '';
    const hovering = this.state.hovering ? 'hovering' : '';
    const action = dragging || hovering;
    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        {...this.props}
        className={`sortable-list-item ${action}`}
      >
        <EditingField {...this.props} />
      </div>
    );
  }
}

class FieldList extends Component {
  fieldDataToFieldListItem = (item, i) => {
    return <Field key={item.id}>{item}</Field>;
  };

  render() {
    var listItems = _.map(this.props.data, this.fieldDataToFieldListItem);
    return <div className="list field-list">{listItems}</div>;
  }
}

export default FieldList;
