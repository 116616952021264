import _ from 'lodash';

import createReducer from './create-reducer';
import { moveInList, removeFromList, replaceInList } from './list-manipulation';

const initial = {
  id: '',
  name: '',
  source: '',
  target: '',
  tags: [],
  properties: []
};

const reducers = {
  CLEAR_MAP: (state, value) => {
    if (!value) {
      return initial;
    }
    const { baseField } = value;
    return Object.assign({}, baseField);
  },
  LOAD_MAP: (state, value) => {
    return _.cloneDeep(value);
  },
  EDIT_MAP: (state, value) => {
    const { property, newValue } = value;
    return _.set({ ...state }, property.name, newValue);
  },
  EDIT_MAP_PROPERTIES: (state, value) => {
    const { prop, newValue, option } = value;
    let newOption = {};

    newOption[prop] = newValue;

    const properties = replaceInList(
      state.properties,
      { ...option, ...newOption },
      {
        source: option.source
      }
    );

    return {
      ...state,
      ...{
        properties
      }
    };
  },
  ADD_MAP_PROPERTIES: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.properties, {
      source: option.source
    });

    let next = state.properties.length;
    while (
      state.properties.find(
        (x) => x.source === `Text-${next + 1}` || x.target === `value-${next + 1}`
      )
    ) {
      next++;
    }

    let newOption = {
      source: `Text-${next + 1}`,
      target: `value-${next + 1}`,
      text: ''
    };

    const properties = [
      ...state.properties.slice(0, optionIndex + 1),
      { ...option, ...newOption },
      ...state.properties.slice(optionIndex + 1, state.properties.length)
    ];

    return {
      ...state,
      ...{
        properties
      }
    };
  },
  REMOVE_MAP_PROPERTIES: (state, value) => {
    const { option } = value;

    return {
      ...state,
      ...{
        properties: removeFromList(state.properties, { source: option.source })
      }
    };
  },
  MOVE_MAP_PROPERTIES: (state, value) => {
    const { option, distance } = value;

    return {
      ...state,
      ...{
        properties: moveInList(state.properties, { source: option.source }, distance)
      }
    };
  }
};

export default createReducer(JSON.parse(JSON.stringify(initial)), reducers);
export { initial };
