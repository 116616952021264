import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SearchWorkspace from '../routes/workspace';
import CompanySearch from './search';
import CompanyView from './view';

function componentToState(state) {
  return {
    companyView: state.companyView
  };
}

@withRouter
@connect(componentToState)
class CompanyWorkspace extends Component {
  rootUrl = '/company';

  workSpaces = [
    {
      id: 'search',
      component: CompanySearch
    },
    {
      id: 'view',
      param: ':id?',
      component: CompanyView
    }
  ];
  render() {
    return <SearchWorkspace rootUrl={this.rootUrl} workSpaces={this.workSpaces} />;
  }
}
export default CompanyWorkspace;
