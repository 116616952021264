import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Icon, List, Message, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import departments from '../../../api/data/departments';
import UpdateUserProfileInfoById from '../../../graphql/mutations/updateUserProfileInfoById.graphql';
import GetTruncateduserByIdQuery from '../../../graphql/queries/getTruncatedUserById.graphql';
import { updateWho } from '../../../state/actions';
import validateProfileSchema from '../../settings/profile-schema';

const departmentOptions = _.map(departments, (d) => {
  return { key: d.id, text: d.label, value: d.id };
});

const DEFAULT_AVATAR_URL = 'https://randomuser.me/api/portraits/lego/0.jpg';

function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
@graphql(UpdateUserProfileInfoById, {
  name: 'updateUser',
  options: () => ({
    errorPolicy: 'all'
  })
})
@graphql(GetTruncateduserByIdQuery, {
  name: 'userData',
  options: (props) => {
    const { id } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class ProfileSettings extends Component {
  state = {
    userProfileImage: {
      preview: DEFAULT_AVATAR_URL
    },
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      bio: '',
      department: ''
    },
    formErrors: { error: null },
    submitting: false,
    hasSubmitted: false,
    readOnly: true
  };

  confirmationDialog = () => {};

  updateProfile = () => {
    this.setState({
      submitting: true,
      hasSubmitted: true
    });
  };

  renderDropzoneContents = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    const { userData } = this.props;

    let overlay = null;
    if (isDragActive) {
      overlay = <Icon name="upload" color="green" size="huge" />;
    }
    if (isDragReject) {
      overlay = <Icon name="cancel" color="red" size="huge" />;
    }
    console.log(userData);
    let avatarUrl = userData.userById.avatarUrl ? 'http://' + userData.userById.avatarUrl : ``;

    let style = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${avatarUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      maxHeight: 200
    };

    return <div style={style}>{overlay}</div>;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      this.setState({ userProfileImage: acceptedFiles[0] });
    }
  };

  renderHeader = () => {
    return (
      <Header as="h3">
        <Header.Content>User Information</Header.Content>
      </Header>
    );
  };

  renderSubmitErrors = () => {
    const { formErrors, hasSubmitted } = this.state;

    return hasSubmitted && formErrors.error ? (
      <Message negative>
        <Message.Header>Cannot Submit</Message.Header>
        <List>
          {formErrors.error.details.map((detail, i) => {
            return <List.Item key={`error-on-submit-${i}`}>{detail.message}</List.Item>;
          })}
        </List>
      </Message>
    ) : null;
  };

  render() {
    const { who, userData } = this.props;
    const { submitting, readOnly } = this.state;
    let formData = userData.loading ? this.state.formData : userData.userById;

    if (userData.loading) {
      return <div />;
    }

    const errorMessages = this.renderSubmitErrors();

    const submitContent = readOnly ? (
      ''
    ) : (
      <Grid.Row>
        <Grid.Column width={5}>
          <Button.Group widths={2}>
            <Button primary onClick={this.updateProfile}>
              Update Profile
            </Button>
          </Button.Group>
        </Grid.Column>
        <Grid.Column width={7}>{errorMessages}</Grid.Column>
      </Grid.Row>
    );

    const dropzoneHtml = (
      <div>
        <Dropzone
          ref={(ref) => {
            this._dropzone = ref;
          }}
          accept="image/jpeg, image/png"
          style={{
            position: 'relative',
            width: 200,
            height: 200,
            borderRadius: 5,
            borderWidth: 2,
            borderStyle: 'dotted',
            borderColor: '#eeeeee'
          }}
          acceptStyle={{
            borderStyle: 'solid',
            borderColor: '#21ba45'
          }}
          rejectStyle={{
            borderStyle: 'solid',
            borderColor: '#db2828'
          }}
          multiple={false}
          onDrop={this.onDrop}
        >
          {this.renderDropzoneContents}
        </Dropzone>
        {readOnly ? (
          ''
        ) : (
          <Button
            style={{ width: 200, marginTop: '.8em' }}
            onClick={() => {
              this._dropzone.open();
            }}
          >
            Upload a Photo
          </Button>
        )}
      </div>
    );

    const FormHTML = (
      <Grid stackable reversed="tablet computer" width={16} style={{ margin: 0 }}>
        <Grid.Row>
          <Grid.Column width={12}>
            <div>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  onChange={(event, data) => {}}
                />
                <Form.Input fluid label="Last Name" placeholder="Last Name" name="lastName" />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input fluid label="Email" name="email" placeholder="Email" />
                <Form.Select
                  fluid
                  label="Department"
                  options={departmentOptions}
                  placeholder="Department"
                  name="department"
                />
              </Form.Group>

              <Form.TextArea
                label="About"
                rows={4}
                placeholder="Tell us more about you..."
                name="bio"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <br />
              {dropzoneHtml}
              <br />
              <div style={{ dispay: 'flex' }}>
                <Header as="h4" textAlign="left">
                  <Icon name="lock" />
                  <Header.Content>
                    Roles
                    <Header.Subheader>{formData.roles}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {submitContent}
        <Grid.Row>
          <Grid.Column>{this.confirmationDialog()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <div className="edit-view">
        <Grid.Row>
          <Grid.Column width={16}>{this.renderHeader()}</Grid.Column>
        </Grid.Row>
        <Segment className="settings-profile">
          <ValidatedForm
            intialValue={formData}
            validateSchema={validateProfileSchema}
            onValidate={(data) => {}}
            value={formData}
            onChange={(event, { field, form, submitting }, err) => {
              this.setState({
                formData: form,
                formErrors: err,
                submitting: submitting
              });
            }}
            onSubmit={(event, { form }, err) => {
              const { updateUser, dispatch } = this.props;
              const userProfileImage = this.state.userProfileImage;
              let variables = form;

              if (userProfileImage.size) {
                variables = { ...variables, avatarUpload: userProfileImage };
              }

              updateUser({ variables }).then((record) => {
                dispatch(updateWho(record));
                this.setState({
                  submitting: false
                });
              });
            }}
            readOnly={readOnly}
            submitting={submitting}
            submitMessage={`Updating your profile,  ${who.firstName}`}
          >
            {FormHTML}
          </ValidatedForm>
        </Segment>
      </div>
    );
  }
}

export default ProfileSettings;
