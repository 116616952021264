import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Dropdown } from 'semantic-ui-react';
import currencies from './available-currencies';
import { updateSchemaCompanies } from '../../state/actions';

import './index.css';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
class CurrencyInput extends Component {
  state = {
    value: {
      amount: 0,
      currency: 'USD'
    }
  };

  componentDidMount() {
    if (this.props.value) {
      const { amount, currency } = this.props.value;

      if (amount && currency) {
        this.setState({ value: { amount, currency } });
      }
    }
  }

  onChange = (e, value) => {
    const { onChange, lineItem, name, dispatch, company, editSchemaInfo } = this.props;
    if (onChange && typeof onChange === 'function') {
      onChange(e, { value });
    }

    if (lineItem) {
      const updatedLineItem = { ...lineItem };
      const priceName = name.split('.')[2];
      if (priceName === 'price') {
        updatedLineItem.price = { ...updatedLineItem.price, ...value };
      } else if (priceName === 'lookerFee') {
        updatedLineItem.lookerFee = { ...updatedLineItem.lookerFee, ...value };
      }
      const companyIndex = editSchemaInfo.companies.findIndex(
        (a) => a.companyId === company.companyId
      );
      const updatedCompany = { ...company };
      const lineItemIndex = updatedCompany.lineItems.findIndex(
        (a) => a.lineItemId === lineItem.lineItemId
      );
      updatedCompany.lineItems[lineItemIndex][priceName] = updatedLineItem[[priceName]];
      const updatedCompaies = [...editSchemaInfo.companies];
      updatedCompaies[companyIndex] = updatedCompany;
      dispatch(updateSchemaCompanies(updatedCompaies));
    }

    this.setState({
      value
    });
  };

  onChangeCurrency = (e, { value }) => {
    const currentValue = this.props.value || this.state.value;

    this.onChange(e, {
      ...currentValue,
      ...{ currency: value }
    });
  };

  onChangeInput = (e, { value }) => {
    const currentValue = this.props.value || this.state.value;
    this.onChange(e, {
      ...currentValue,
      ...{ amount: value }
    });
  };

  render() {
    const currentValue = this.props.value || this.state.value;
    const { amount, currency } = currentValue;

    return (
      <Form.Input
        {...this.props}
        action={
          <Dropdown
            button
            basic
            floating
            options={currencies.map((c) => c)}
            value={currency}
            onChange={this.onChangeCurrency}
            name={this.props.name ? this.props.name + '.currency' : ''}
          />
        }
        value={amount}
        onChange={this.onChangeInput}
        onBlur={(e) => {
          const value =
            parseFloat((this.props.value && this.props.value.amount) || this.state.value.amount) ||
            0;
          this.onChangeInput(e, { value });
        }}
        name={this.props.name ? this.props.name + '.amount' : ''}
      />
    );
  }
}

export default CurrencyInput;
