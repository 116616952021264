import createReducer from './create-reducer';

const activityBarSizes = ['compressed', 'expanded'];

const initial = {
  size: activityBarSizes[0]
};

const reducers = {
  UPDATE_ACTIVITYBAR_SIZE: (state, value) => {
    if (value) {
      return { ...state, ...{ size: value } };
    }

    const sizeIndex = activityBarSizes.indexOf(state.size);

    const newSize = activityBarSizes[sizeIndex + 1]
      ? activityBarSizes[sizeIndex + 1]
      : activityBarSizes[0];

    return { ...state, ...{ size: newSize } };
  }
};

export default createReducer(initial, reducers);
