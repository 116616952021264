import _ from 'lodash';

import createReducer from './create-reducer';

const initial = {
  lineItems: [],
  fields: []
};

const reducers = {
  LOAD_DEFAULTS: (state, value) => {
    if (!value) {
      return initial;
    }
    //TODO: remove HACK to unfreeze
    const copy = JSON.parse(JSON.stringify(value));

    return {
      ...state,
      ...copy,
      ...{ lineItems: copy.lineItems || [] },
      ...{ fields: copy.fields || [] }
    };
  },
  ADD_DEFAULT_LINE_ITEM: (state, value) => {
    const lineItems = [...state.lineItems, _.cloneDeep(value)];

    return {
      ...state,
      ...{ lineItems: _.uniqBy(lineItems, '_id') }
    };
  },
  REMOVE_DEFAULT_LINE_ITEM: (state, value) => {
    const lineItems = state.lineItems.filter((c) => c._id !== value._id);

    return {
      ...state,
      ...{ lineItems }
    };
  },
  ADD_DEFAULT_FIELD: (state, value) => {
    const fields = [...state.fields, _.cloneDeep(value)];

    return {
      ...state,
      ...{ fields: _.uniqBy(fields, '_id') }
    };
  },
  REMOVE_DEFAULT_FIELD: (state, value) => {
    const fields = state.fields.filter((c) => c._id !== value._id);

    return {
      ...state,
      ...{ fields }
    };
  }
};

export default createReducer(initial, reducers);

export { initial };
