const groupTypeOptions = [
  { key: 'cont', value: 'contact', text: 'Contact' },
  { key: 'shipInfo', value: 'shipping-information', text: 'Shipping Information' },
  { key: 'generic', value: 'generic', text: 'Generic' },
  { key: 'photo desc', value: 'photo-with-description', text: 'Photo With Description' },
  { key: 'media', value: 'media', text: 'Media' },
  { key: 'address', value: 'address', text: 'Address' },
  { key: 'lookAddress', value: 'look-address', text: 'Look Address' }
];

export default groupTypeOptions;
