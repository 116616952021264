import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Icon, Image, Menu } from 'semantic-ui-react';

import { LOGO } from './../../assets';
import auth from '../../auth';
import { updateActivitybarSize } from '../../state/actions';
import client from '../../apollo';

function stateToComponent(state) {
  return {
    activityBar: state.activityBar
  };
}

@connect(stateToComponent)
class EditorMenu extends Component {
  logout = () => {
    auth.logout();
    client.resetStore();
    window.location = window.location;
  };

  render() {
    const { dispatch } = this.props;
    const loginMessage = 'Logout';

    const imgLogo = (
      <Image
        src={LOGO}
        style={{
          width: 48,
          padding: 0
        }}
      />
    );

    return (
      <Menu attached="top" borderless>
        <Menu.Item
          vertical={'true'}
          onClick={() => {
            dispatch(updateActivitybarSize());
          }}
        >
          {imgLogo}
        </Menu.Item>
        <Menu.Item>
          <Header as="h3">
            We Go Look
            <Header.Subheader>Schema Author</Header.Subheader>
          </Header>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item onClick={this.logout}>
            <Icon name="log out" />
            <span className="text">{loginMessage}</span>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

export default EditorMenu;
