import _ from 'lodash';
import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import settingsWorkspaces from './settings-workspaces';

const SETTINGS_ROOT_URL = `/settings`;

export default class SettingsRoutes extends Component {
  renderWorkspaces = () => {
    return _.map(settingsWorkspaces, (workspace) => {
      return (
        <Route
          key={`route-${workspace.id}`}
          path={`${SETTINGS_ROOT_URL}/${workspace.id}`}
          exact={true}
          component={workspace.component}
        />
      );
    });
  };

  render() {
    const workSpaces = this.renderWorkspaces();
    return (
      <div className="editor-canvas-active">
        <Route
          path={`${SETTINGS_ROOT_URL}`}
          exact={true}
          render={() => <Redirect to={`${SETTINGS_ROOT_URL}/${settingsWorkspaces[0].id}`} />}
        />
        {workSpaces}
      </div>
    );
  }
}
