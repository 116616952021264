import './index.css';

import Promise from 'bluebird';
import Joi from 'joi-browser';
import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Message,
  Modal,
  Progress
} from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import CreateSchemaMutation from '../../graphql/mutations/createSchema.graphql';
import GetSchemaByIdQuery from '../../graphql/queries/getSchemaById.graphql';
import GetSchemaFacets from '../../graphql/queries/getSchemaFacets.graphql';
import {
  addCopyMachineCopy,
  clearCopyMachineCopies,
  removeCopyMachineCopy,
  setCopyMachineTargetId,
  updateCopyMachineCopies
} from '../../state/actions';
import SchemaCard from '../schema/search/schema-card';

const MAX_COPIES = 10;

function stateToComponent(state) {
  return {
    schemaCopyMachine: state.schemaCopyMachine,
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(CreateSchemaMutation, {
  name: 'createSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
@graphql(GetSchemaFacets, {
  name: 'schemaFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@graphql(GetSchemaByIdQuery, {
  skip: (props) => !props.schemaCopyMachine.targetId,
  name: 'getSourceDocument',
  options: (props) => {
    const variables = {
      _id: props.schemaCopyMachine.targetId
    };

    return {
      variables: variables,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    };
  }
})
class SchemaCopyMachine extends Component {
  state = {
    errors: null,
    focusOn: 0,
    mode: 'edit', //'summary', 'submitting'
    savedRecords: [],
    percentComplete: 0,
    groups: []
  };

  makeCopies = () => {
    const { schemaCopyMachine } = this.props;
    const copies = schemaCopyMachine.copies;

    this.setState({
      mode: 'submitting',
      savedRecords: []
    });

    Promise.mapSeries(copies, this.makeCopy)
      .then(() => {
        this.setState({
          mode: 'summary'
        });
      })
      .catch((err) => {
        this.setState({
          errors: err
        });
      });
  };

  makeCopy = (schema, i, l) => {
    const { createSchema } = this.props;

    const variables = {
      companies: schema.companies,
      lineItems: schema.lineItems,
      info: schema.info,
      fields: schema.fields,
      groups: schema.groups,
      professions: schema.professions,
      status: 'draft'
    };

    variables.info.schemaName = variables.info.subSchema + ' : ' + variables.info.schemaGroup;

    return createSchema({ variables })
      .then((record) => {
        if (record.errors) {
          return this.setState({
            submitting: false,
            errors: record.errors
          });
        }
        const schemaCreate = _.get(record, 'data.schemaCreate').record;

        this.setState({
          percentComplete: Math.round((i / l) * 100),
          savedRecords: [...this.state.savedRecords, schemaCreate]
        });
      })
      .catch((err) => {
        this.setState({
          submitting: false,
          errors: err
        });
      });
  };

  nevermind = () => {
    this.clear({
      refresh: false
    });
  };

  done = () => {
    this.clear({
      refresh: true
    });
  };

  clear = ({ refresh }) => {
    const { dispatch, refetch } = this.props;
    dispatch(setCopyMachineTargetId(null));
    dispatch(clearCopyMachineCopies());
    this.setState({
      mode: 'edit',
      errors: null
    });
    if (refresh) {
      refetch();
    }
  };

  addCopy = (copy, index) => () => {
    const { dispatch, schemaCopyMachine, getSourceDocument } = this.props;
    const { copies } = schemaCopyMachine;
    const schema = getSourceDocument.schemaById;

    dispatch(
      addCopyMachineCopy({
        sourceDocument: schema,
        copy: {
          info: {
            subSchema: `${schema.info.subSchema} - COPY ${copies.length + 1}`,
            schemaGroup: `${schema.info.schemaGroup}`
          }
        },
        index: index
      })
    );

    this.setState({
      focusOn: index + 1
    });
  };

  deleteCopy = (copy, index) => () => {
    const { dispatch } = this.props;
    dispatch(removeCopyMachineCopy(copy));
    this.setState({
      focusOn: 0
    });
  };

  updateCopyMachineValue = (event, model, err) => {
    const { form } = model;
    const { dispatch } = this.props;
    dispatch(updateCopyMachineCopies(form.copies));
    this.setState({ errors: err.error });
  };

  copyMachineSchema = {
    copies: Joi.array()
      .unique((a, b) => {
        return a.info.subSchema === b.info.subSchema && a.info.schemaGroup === b.info.schemaGroup;
      })
      .items({
        info: {
          subSchema: Joi.string()
            .required()
            .label('Schema Name')
        }
      })
      .label('Copies')
  };

  renderCopiesHtml = () => {
    const { schemaCopyMachine, getSourceDocument } = this.props;
    const schema = getSourceDocument.schemaById;
    const copies = schemaCopyMachine.copies;
    const focusOn = this.state.focusOn;
    const full = copies.length >= MAX_COPIES;
    const empty = copies.length < 2;

    let groups = this.props.schemaFacets.schemaFacets.info_schemaGroup.map((x) => ({
      text: x,
      value: x
    }));

    const titleAdd = full
      ? `You've hit the maximum copies`
      : `You can add ${MAX_COPIES - copies.length} more`;

    const titleDelete = empty
      ? `If you don't want to make a copy, jsut click nevermind`
      : `Remove this copy from the list.`;

    return (
      <ValidatedForm
        validateSchema={this.copyMachineSchema}
        intialValue={{ copies }}
        value={{ copies }}
        submitting={this.state.mode === 'submitting'}
        onChange={this.updateCopyMachineValue}
      >
        <Grid>
          {_.map(copies, (copy, index) => {
            return (
              <Grid.Row key={`input-${copy.tempId}`}>
                <Grid.Column width={2}>
                  <Label size="tiny" ribbon className="copy-machine-item-index-label">
                    {index + 1}
                  </Label>
                  <Icon name="file outline" className="copy-machine-item-index-icon" />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Input
                    fluid
                    name={`copies[${index}].info.subSchema`}
                    autoFocus={index === focusOn}
                    placeholder={`Copy of ${schema.info.subSchema}`}
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <Form.Select
                    options={groups.concat(this.state.groups)}
                    placeholder="Scene Inspection"
                    search
                    selection
                    fluid
                    allowAdditions
                    onChange={(e, { value }) => {
                      if (!this.state.groups.find((x) => x.text === value)) {
                        const newOptions = _.uniqBy(
                          [{ text: value, value }, ...this.state.groups],
                          'value'
                        );
                        this.setState({ groups: newOptions });
                      }
                    }}
                    name={`copies[${index}].info.schemaGroup`}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Button.Group basic fluid>
                    <Button
                      icon="add"
                      onClick={this.addCopy(copy, index)}
                      disabled={full}
                      title={titleAdd}
                    />
                    <Button
                      disabled={empty}
                      icon="delete"
                      title={titleDelete}
                      onClick={this.deleteCopy(copy, index)}
                    />
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </ValidatedForm>
    );
  };

  renderEditActionPanel = () => {
    const { schemaCopyMachine } = this.props;

    const copies = schemaCopyMachine.copies;
    const plural = copies.length > 1 ? 'Copies' : 'Copy';
    const copyCta = `Make ${copies.length} ${plural}`;
    return (
      <Modal.Actions>
        <Button basic onClick={this.nevermind}>
          Nevermind
        </Button>
        <Button
          positive
          icon="copy"
          labelPosition="right"
          content={copyCta}
          onClick={this.makeCopies}
          disabled={!!this.state.errors || copies.length === 0}
        />
      </Modal.Actions>
    );
  };

  renderSummaryPanel = () => {
    return (
      <Modal.Actions>
        <Button basic onClick={this.done}>
          I'm Done
        </Button>
      </Modal.Actions>
    );
  };

  renderProgressPanel = () => {
    return (
      <Modal.Actions>
        <span>{this.state.percentComplete}</span>
      </Modal.Actions>
    );
  };

  actionPanel = (mode) => {
    const panels = {
      edit: this.renderEditActionPanel,
      submitting: this.renderProgressPanel,
      summary: this.renderSummaryPanel
    };

    return panels[mode]();
  };

  renderGetStartedCTAContent = () => {
    const { getSourceDocument } = this.props;
    const schema = getSourceDocument.schemaById;

    return (
      <Modal.Description className="copy-machine-get-started">
        <Header as="h2" className="copy-machine-get-started-header">
          <Icon name="copy" size="huge" />
          {`Make Copies of "${schema.info.schemaName}"`}
          <Header.Subheader>
            {`You can make up to ${MAX_COPIES} copies, click below to add your first copy.`}
          </Header.Subheader>
        </Header>
        <Button className="copy-machine-get-started-cta" onClick={this.addCopy(null, 0)}>
          Add Your First Copy
        </Button>
      </Modal.Description>
    );
  };

  renderCopyMachineContent = () => {
    const { schemaCopyMachine, getSourceDocument } = this.props;
    const copies = schemaCopyMachine.copies;
    const schema = getSourceDocument.schemaById;
    const plural = copies.length > 1 ? 'Copies' : 'Copy';
    return (
      <Modal.Description>
        <Header as="h2">
          {`${plural} of  "${schema.info.schemaName}"`}
          <Header.Subheader>{`You can make up to ${MAX_COPIES} copies`}</Header.Subheader>
        </Header>
        <br />
        {this.renderCopiesHtml()}
      </Modal.Description>
    );
  };

  renderSummary = () => {
    const { savedRecords, errors } = this.state;

    let _content = (
      <List>
        {_.map(savedRecords, (record) => {
          return (
            <List.Item
              icon="file outline big"
              description={record.info.schemaName}
              content={`Fields: ${record.fields.length} | Conpanies: ${record.companies.length}`}
            />
          );
        })}
      </List>
    );

    let _errors = null;
    if (errors) {
      _errors = <Message error header="Something went wrong" content={errors.toString()} />;
    }

    return (
      <Modal.Description>
        <Header as="h2">
          Copies Complete
          <Header.Subheader>These Were created:</Header.Subheader>
        </Header>
        {_content}
        {_errors}
      </Modal.Description>
    );
  };

  renderSubmitting = () => {
    return (
      <Modal.Description>
        <Progress percent={this.state.percentComplete} progress />
        <List>
          {_.map(this.state.savedRecords, (record, index) => {
            return (
              <List.Item
                key={`completed-copy-readout-${index}`}
                icon="file outline big"
                content={record.info.schemaName}
                description={`Fields: ${record.fields.length} | Conpanies: ${record.companies.length}`}
              />
            );
          })}
        </List>
      </Modal.Description>
    );
  };

  content = (mode) => {
    const { schemaCopyMachine } = this.props;
    const copies = schemaCopyMachine.copies;

    const hasCopies = copies.length > 0;
    const _content = {
      edit: hasCopies ? this.renderCopyMachineContent : this.renderGetStartedCTAContent,
      summary: this.renderSummary,
      submitting: this.renderSubmitting
    };

    return _content[mode]();
  };

  render() {
    const { getSourceDocument } = this.props;

    if (!getSourceDocument || getSourceDocument.loading) {
      return null;
    }

    const schema = getSourceDocument.schemaById;
    const _content = this.content(this.state.mode);

    return (
      <Modal open={!!schema} className="copy-machine">
        <Modal.Content>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={6} className="copy-machine-card-container">
                <SchemaCard result={schema} />
              </Grid.Column>
              <Grid.Column width={1}>
                <Divider vertical>
                  <Icon name="arrow right" />
                </Divider>
              </Grid.Column>
              <Grid.Column width={9} className="copy-machine-content-frame">
                {_content}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {this.actionPanel(this.state.mode)}
      </Modal>
    );
  }
}
export default SchemaCopyMachine;
