import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

import iconMap from './toolbar-icon-mapper';

export default class ToolbarItem extends Component {
  render() {
    const { onClick, item } = this.props;
    const { component, label } = item;
    const icon = iconMap[component] || 'x';

    return <Button icon={`${icon}`} content={`${label}`} onClick={onClick} />;
  }
}
