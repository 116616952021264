const roles = [
  {
    label: 'Normal User',
    value: 'user',
    abilities: ['Can Search and View Forms', 'Can Author Forms']
  },
  {
    label: 'Moderator',
    value: 'mod',
    abilities: ['Can Search and View Users', 'Can Promote Forms from Draft']
  },
  {
    label: 'System Adminstrator',
    value: 'admin',
    abilities: ['Can Seed Data', 'Can Change User Permissions']
  }
];

export default roles;
