import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Workspace from '../routes/workspace';
import Search from './search';
import View from './view';

@withRouter
class LineItemWorkspace extends Component {
  rootUrl = '/lineItem';

  workSpaces = [
    {
      id: 'search',
      param: ':id?',
      component: Search
    },
    {
      id: 'view',
      param: ':id?',
      component: View
    }
  ];
  render() {
    return <Workspace rootUrl={this.rootUrl} workSpaces={this.workSpaces} />;
  }
}
export default LineItemWorkspace;
