import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Checkbox, Form, Grid, Header, Icon, Message, Segment } from 'semantic-ui-react';

import { editSchemaFieldReports, editUniqueFieldReports } from '../../../../state/actions';

@withRouter
@connect()
class ReportEditor extends Component {
  state = {
    selected: 'public'
  };

  name = (env) => {
    const name = {
      public: 'Public Reports',
      private: 'Private Reports',
      invoice: 'Invoice Reports'
    };

    return name[env] || '';
  };

  toIcon = (env) => {
    const icon = {
      public: 'unlock',
      private: 'lock',
      invoice: 'lock'
    };

    return icon[env] || '';
  };

  //TODO: Move to global reducer
  onChange = (report) => (e, value) => {
    const { dispatch, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? editSchemaFieldReports : editUniqueFieldReports;

    dispatch(
      action({
        value: value.checked,
        field: this.props.field,
        report: report
      })
    );
  };

  renderReportInfo = ({ environment, enabled }) => {
    return (
      <Message>
        <Header as="h2" icon>
          <Icon name={this.toIcon(environment)} />
          {this.name(environment)}
        </Header>
        <Header.Subheader>{this.makeLabel({ environment, enabled })}</Header.Subheader>
      </Message>
    );
  };

  makeLabel = ({ environment, enabled }) => {
    const name = this.name(environment);

    const label = enabled
      ? `This will be included in ${name}`
      : `This will not be included in ${name}`;

    return label;
  };

  makeReportOption = ({ report }) => {
    const { enabled, environment } = report;

    return (
      <Segment className="report-">
        {this.renderReportInfo(report)}
        <Form>
          <br />
          <Form.Field>
            <Checkbox
              toggle
              label={'Enable'}
              value={environment}
              name={`${environment}-report`}
              checked={enabled}
              onChange={this.onChange(report)}
            />
          </Form.Field>
        </Form>
      </Segment>
    );
  };

  publicReports = (report) => {
    return this.makeReportOption({
      report: report
    });
  };

  privateReports = (report) => {
    return this.makeReportOption({
      report: report
    });
  };

  invoiceReports = (report) => {
    return this.makeReportOption({
      report: report
    });
  };

  inputByEnv = (env) => {
    var inputs = {
      public: this.publicReports,
      private: this.privateReports,
      invoice: this.invoiceReports
    };

    return inputs[env] || null;
  };

  reportToContent = (report) => {
    const { field } = this.props;
    const reportInput = this.inputByEnv(report.environment);
    if (!reportInput) {
      console.warn(`Looks like you've indicated a report environment we didn't expect`);
      return null;
    }
    const key = `${field.id}-report-${report.environment}`;
    return <Grid.Column key={key}>{reportInput(report)}</Grid.Column>;
  };

  render() {
    const { field } = this.props;
    const { reports } = field;
    const content = _.map(reports, this.reportToContent);

    return (
      <Grid columns={reports.length}>
        <Grid.Row>{content}</Grid.Row>
      </Grid>
    );
  }
}
export default ReportEditor;
