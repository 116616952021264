import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Form, Header, List, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import GetUniqueFieldById from '../../../graphql/queries/getUniqueFieldById.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(GetUniqueFieldById, {
  name: 'uniqueFieldData',
  skip: (props) => props.uniqueField && props.uniqueField._id,
  options: (props) => {
    const { id } = props.match.params;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class uniqueFieldView extends Component {
  renderFormList(data) {
    const formData = data.schemaMany;
    const formList = formData
      ? formData.map((form) => {
          const description = `
          fields: ${form.fields.length}
          `;

          return (
            <List.Item key={`associated-form-${form._id}`}>
              <List.Icon name="file alternate outline" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a">{form.info.schemaName}</List.Header>
                <List.Description as="a">{description}</List.Description>
              </List.Content>
            </List.Item>
          );
        })
      : null;

    return formList ? (
      <Segment>
        <Header>Associated Forms:</Header>
        <List divided relaxed>
          {formList}
        </List>
      </Segment>
    ) : null;
  }

  renderUniqueField(data) {
    const uniqueField = data.uniqueFieldById || data;

    if (data.loading || !uniqueField) {
      return <div> Loading </div>;
    }
    const formData = uniqueField;

    return (
      <div className="unique-field-view">
        <ValidatedForm intialValue={formData} value={formData} readOnly={true}>
          <Form.Field>{uniqueField._id}</Form.Field>
          <Form.Field>{uniqueField.id}</Form.Field>
          <Form.Field>{uniqueField.name}</Form.Field>
        </ValidatedForm>

        {this.renderFormList(data)}
      </div>
    );
  }
  render() {
    let { uniqueField, uniqueFieldData } = this.props;

    return (
      <div className="search-workspace uniqueField-search-workspace">
        {this.renderUniqueField(uniqueField || uniqueFieldData)}
      </div>
    );
  }
}

export default uniqueFieldView;
