import createReducer from './create-reducer';

const initial = {
  page: 1,
  perPageCard: 10,
  perPageTable: 20,
  layout: 'table-layout', //todo make this come form layout strategy ?
  search: '',
  tagsFacets: [],
  componentFacets: [],
  pagerInfo: {},
  filterTags: [],
  sort: null
};

const reducers = {
  UPDATE_MAP_PAGE: (state, value) => {
    return { ...state, ...{ page: value.page, pagerInfo: value.pagerInfo } };
  },
  UPDATE_MAP_PERPAGE: (state, value) => {
    return { ...state, ...{ perPage: value } };
  },
  UPDATE_MAP_SEARCH_TERM: (state, value) => {
    return { ...state, ...{ search: value, page: 1 } };
  },
  UPDATE_MAP_SORT: (state, value) => {
    return { ...state, ...{ sort: value, page: 1 } };
  },
  UPDATE_MAP_TAGS_FACETS: (state, value) => {
    return { ...state, ...{ tagsFacets: value } };
  },
  UPDATE_MAP_FILTER_TAGS: (state, value) => {
    return { ...state, ...{ filterTags: value, page: 1 } };
  }
};

export default createReducer(initial, reducers);
