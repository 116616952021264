import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ValidatedForm from 'semantic-ui-react-validated-form';

import { editSchemaField, editUniqueField } from '../../../../state/actions';
import EditablePropertyField from '../mappers/to-editable-property';
import InfoEditorSchema from './schema';

@connect()
@withRouter
class InfoEditor extends Component {
  generateSchemaFieldsJsonFromField = (field) => {
    const { match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    let formfields = [];
    let _keys = _.keys(field);

    if (_.includes(_keys, 'id')) {
      formfields.push({
        name: 'id',
        label: 'Field Ids',
        title: `
          This is the unique name of the field
          that will be used to identify the field
          such as "firstName", "address1" or "age"
        `,
        readOnly: isSchemaEditor
      });
    }

    if (_.includes(_keys, 'label')) {
      formfields.push({
        name: 'label',
        label: 'Label',
        title: `
          This is the label of the field
          shown in the schema editor.
        `,
        readOnly: isSchemaEditor
      });
    }

    if (_.includes(_keys, 'highlightedField')) {
      formfields.push({
        name: 'highlightedField',
        label: 'This field is a highlighted field.',
        title: `
          A highlighted field indicates this field or
          element will appear in form previews, in certain
          situations to endusers.
        `
      });
    }

    if (_.includes(_keys, 'defaultValue')) {
      if (_.isObject(field.defaultValue)) {
        let defaultValueKeys = _.keys(field.defaultValue);

        _.each(defaultValueKeys, (defaultValueKey) => {
          let readableName = _.startCase(defaultValueKey);

          formfields.push({
            name: `defaultValue.${defaultValueKey}`,
            label: `Default ${readableName}`,
            title: `
              The default value of ${readableName},when
              a user loads a form and has  not yet entered data
            `
          });
        });
      } else {
        formfields.push({
          name: 'defaultValue',
          label: 'Default Value',
          title: `
            The default value of the field, such as when
            a user loads a form and has  not yet entered data
          `
        });
      }
    }

    return formfields;
  };

  render() {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    if (!field) {
      return null;
    }

    let formfields = this.generateSchemaFieldsJsonFromField(field);

    const fields = _.map(formfields, (property, index) => {
      let args = {
        keyPrefix: `edit-detail-info`,
        dispatchAction: isSchemaEditor ? editSchemaField : editUniqueField,
        dispatch,
        field,
        property,
        index
      };

      return <EditablePropertyField key={`edit-detail-info-${index}`} {...args} />;
    });

    return (
      <ValidatedForm
        intialValue={field}
        value={field}
        validateSchema={InfoEditorSchema}
        readOnly={false}
        onChange={() => {}}
      >
        {fields}
      </ValidatedForm>
    );
  }
}
export default InfoEditor;
