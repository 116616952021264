import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Label, Transition } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { editSchemaFieldValidation, editUniqueFieldValidation } from '../../../../state/actions';
import { validators } from '../../../../validation/validator-list';

@connect()
@withRouter
class ValidationsEditor extends Component {
  editValidationValue = (validation) => (event, data) => {
    const { dispatch, field, match } = this.props;

    let newValue = data.value;
    let isNumber = !!_.toNumber(newValue);
    if (isNumber) {
      newValue = _.toNumber(newValue);
    }

    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? editSchemaFieldValidation : editUniqueFieldValidation;

    dispatch(
      action({
        field: field,
        prop: 'value',
        newValue: newValue,
        validation: validation
      })
    );
  };

  toggleValidation = (validation) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const newValue = data.checked;

    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? editSchemaFieldValidation : editUniqueFieldValidation;

    dispatch(
      action({
        field: field,
        prop: 'enabled',
        newValue: newValue,
        validation: validation
      })
    );
  };

  validationToEditHtml = (validation, index) => {
    const { field } = this.props;
    let inputType = 'text';
    const { id } = validation;
    const decoratedValidator = {
      ...validation,
      ..._.find(validators, { id })
    };

    const { icon, label, enabled, value } = decoratedValidator;
    const key = `${field.id}-options-${validation.id}`;
    const _keys = _.keys(validation);
    const hasAValue = _.includes(_keys, 'value') && validation.value !== null;

    let _value = value;
    let isNumber = _.isNumber(value);
    if (isNumber) {
      inputType = 'number';
    }

    const _valueField = hasAValue ? (
      <Form.Input
        type={inputType}
        value={_value}
        width={9}
        disabled={!enabled}
        onChange={(event, data) => {
          this.editValidationValue(validation)(event, data);
        }}
      />
    ) : null;

    const _icon = (
      <Form.Field width={1}>
        <Transition visible={enabled} animation="scale" duration={1000}>
          <Label
            circular
            icon={icon}
            size="big"
            color="orange"
            style={{ maxWidth: '2em', textAlign: 'center' }}
          />
        </Transition>
      </Form.Field>
    );

    return (
      <Form.Group key={key} style={{ minHeight: 38 }}>
        <Form.Checkbox
          label={label}
          checked={enabled}
          width={hasAValue ? 6 : 15}
          toggle
          style={{ paddingTop: 9 }}
          onChange={(event, data) => {
            this.toggleValidation(validation)(event, data);
          }}
        />
        {_valueField}
        {_icon}
      </Form.Group>
    );
  };

  render() {
    const { field } = this.props;
    const validations = _.map(field.validations, this.validationToEditHtml.bind(this));
    return <Form>{validations}</Form>;
  }
}
export default ValidationsEditor;
