import SearchTable from '../../search/search-table';
import companyCards from './company-cards';
import { SearchActionButtonSection } from '../../search/actionSection';

function actionSection(actions) {
  return [
    {
      icon: 'edit',
      label: 'Edit',
      action: actions.select,
      routes: ['company']
    },
    {
      icon: 'hand point up outline',
      label: 'Select',
      action: actions.select,
      routes: ['schema-editor']
    }
  ];
}

export default [
  {
    id: 'card-layout',
    icon: 'block layout',
    label: 'Card',
    description: 'Convert layout to a cards. More detail; less per page',
    component: companyCards,
    model: {
      actionSection: actionSection
    }
  },
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Title',
        property: 'name',
        type: 'string'
      },
      {
        label: 'Accounting Id',
        property: 'accountingId',
        type: 'string'
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ],
    component: SearchTable
  }
];
