import _ from 'lodash';
import React, { Component } from 'react';
import { Card, Image } from 'semantic-ui-react';
import { SearchActionMenuSection } from '../../search/actionSection';

export class TooltipCard extends Component {
  render() {
    const { result, model, actions } = this.props;
    const { _id, label, text, tags, imageSignedUrl } = result;

    let preview = text;

    if (preview.length > 144) {
      preview = preview.substr(0, 141);
      preview += '...';
    }

    const cardDropDown = () => {
      if (!model) {
        return null;
      }
      return <SearchActionMenuSection item={result} model={model.actionSection(actions)} />;
    };

    return (
      <Card key={`card-${_id}`}>
        <Card.Content>
          <Card.Header>
            {`${label}`}
            {cardDropDown()}
          </Card.Header>
          <div style={{ height: 290 }}>
            <Image src={imageSignedUrl} style={{ maxHeight: 290 }} />
          </div>
          <Card.Meta>{tags.join(' ')}</Card.Meta>
          <Card.Description>{preview}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default class TooltipCards extends Component {
  render() {
    const { items, model, actions } = this.props;

    const resultItemsHtml = _.map(items, (item, index) => {
      return (
        <TooltipCard
          key={`card-${item._id}`}
          result={item}
          index={index}
          model={model}
          actions={actions}
        />
      );
    });

    return <div className="search-results">{resultItemsHtml}</div>;
  }
}
