import React, { Component } from 'react';
import { Dimmer, Loader, Modal, Header, Button, Icon } from 'semantic-ui-react';

class SavedModal extends Component {
  state = {
    loading: false
  };

  publishSchema = async () => {
    this.setState({ loading: true });
    await this.props.publishAction();
    this.setState({ loading: false, published: true });
  };

  render = () => {
    const {
      savedRecord,
      saving,
      editAction,
      cardComponent,
      newAction,
      publishAction,
      error
    } = this.props;
    const { loading, published } = this.state;

    const color = error ? 'red' : 'green';
    const iconName = error ? 'close' : 'circle check';
    const subHeader = error ? (
      <>
        <p>There was an error when trying to save</p>
        <p>{error}</p>
      </>
    ) : (
      <>
        {' '}
        <p>You've successfully saved.</p>{' '}
        <p>You can go make a new one, or continue editing this one.</p>
      </>
    );

    const CardComponent = cardComponent;

    if (!savedRecord && !saving) {
      return null;
    }

    let cardContent = null;
    if (CardComponent) {
      cardContent = <CardComponent result={savedRecord} />;
    }

    const savedRecordInfo = (
      <div className="form-success-message">
        <div className="form-success-message-info">
          <Header as="h2" icon>
            <Icon color={color} name={iconName} />
            {error ? 'Error' : 'Success'}
            <Header.Subheader>{subHeader}</Header.Subheader>
          </Header>
        </div>
        <div>{cardContent}</div>
      </div>
    );

    const loader = (
      <Dimmer active>
        <Loader>Loading</Loader>
      </Dimmer>
    );

    const newButton = !newAction ? null : (
      <Button loading={loading} color={color} inverted onClick={newAction}>
        <Icon name={error ? 'arrow left' : 'checkmark'} /> {error ? 'Go Back' : 'New'}
      </Button>
    );

    const publishButton = publishAction ? (
      <Button
        disabled={published ? true : false}
        floated="left"
        loading={loading}
        color="green"
        inverted
        onClick={this.publishSchema}
      >
        <Icon name="certificate" /> {published ? 'Published' : 'Publish'}
      </Button>
    ) : null;

    return (
      <Modal size="small" open={!!savedRecord || saving}>
        {!saving || error ? savedRecordInfo : loader}
        <Modal.Actions>
          {publishButton}
          <Button loading={loading} color="green" inverted onClick={editAction} disabled={error}>
            <Icon name="checkmark" /> Keep Editing
          </Button>
          {newButton}
        </Modal.Actions>
      </Modal>
    );
  };
}

export default SavedModal;
