import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

import client from '../../apollo';
import GetSchemaByIdQuery from '../../graphql/queries/getSchemaById.graphql';
import { loadSchema, updateSchemaEditorTab } from '../../state/actions';
import SchemaEditorActions from './schema-editor-actions';
import SchemaEditorRoutes from './schema-editor-routes';
import SchemaEditorWorkspaces from './schema-editor-workspaces';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@withRouter
@connect(componentToState)
class SchemaEditor extends Component {
  schemaShouldBeLoaded = (currentSchemaId) => {
    const { editSchemaSchema } = this.props;
    if (currentSchemaId.length !== 24) {
      return false;
    }

    if (currentSchemaId.length === 24 && (!editSchemaSchema._id || editSchemaSchema._id !== 24)) {
      return true;
    }

    return false;
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    const chunks = location.pathname.split('/');
    const currentSchemaId = chunks[chunks.length - 2];
    if (this.schemaShouldBeLoaded(currentSchemaId)) {
      const variables = {
        _id: currentSchemaId
      };
      client
        .query({
          query: GetSchemaByIdQuery,
          variables: variables,
          errorPolicy: 'all'
        })
        .then((res) => {
          console.log('GOT FROM DB', res.data.schemaById);
          // Check if the schema had preschedule before. If not, GraphQL will set all validation fields to null so we need to fix.
          if (res.data.schemaById.info.preschedule.validations.id === null) {
            res.data.schemaById.info.preschedule = {
              validations: {
                id: 'render',
                status: 'disabled',
                value: ['disabled', 'required', 'allowed']
              }
            };
          }
          dispatch(loadSchema(res.data.schemaById));
        });
    }
  }

  workspaceToHtml = (view) => {
    const { id, label, icon } = view;
    const { history, location, dispatch, editSchemaSchema } = this.props;

    const chunks = location.pathname.split('/');
    const currentSubroute = chunks[chunks.length - 1];
    const currentSchema = chunks[chunks.length - 2];
    const _isActive = currentSubroute.includes(id);
    const schemaId = currentSchema || 'new';

    if (id === 'versions' && !editSchemaSchema._id) return;

    return (
      <Menu.Item
        active={_isActive}
        key={`editorview-item-${id}`}
        className="editor-canvas-view"
        onClick={(event) => {
          if (!_isActive) {
            dispatch(updateSchemaEditorTab(id));
            history.push(`/schema-editor/${schemaId}/${id}`);
          }
        }}
      >
        <Icon name={`${icon}`} />
        {`${label}`}
      </Menu.Item>
    );
  };

  render() {
    const menuHtml = _.map(SchemaEditorWorkspaces, this.workspaceToHtml).filter(Boolean);

    return (
      <div className="editor-workspace">
        <div className="editor-canvas-views">
          <Menu pointing secondary widths={SchemaEditorWorkspaces.length}>
            {menuHtml}
          </Menu>
        </div>
        <SchemaEditorRoutes />
        <SchemaEditorActions />
      </div>
    );
  }
}

export default SchemaEditor;
