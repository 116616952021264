import '../index.css';

import _ from 'lodash';
import momentTZ from 'moment-timezone';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Modal,
  Segment,
  SegmentGroup,
  Table
} from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import AddCompanyToSchema from '../../../graphql/mutations/addCompanyToSchema.graphql';
import RemoveCompanyFromSchema from '../../../graphql/mutations/removeCompanyFromSchema.graphql';
import CreatePermissions from '../../../graphql/mutations/createPermissions.graphql';
import UpdatePermissions from '../../../graphql/mutations/updatePermission.graphql';
import GetCompanybyIdQuery from '../../../graphql/queries/getCompanyById.graphql';
import GetAllAttachedPermissions from '../../../graphql/queries/getAllAttachedPermissions.graphql';
import GetSchemaPublishedByIds from '../../../graphql/queries/getSchemaPublishedByIds.graphql';
import {
  addCompanyBillingCenter,
  loadCompany,
  removeCompanyBillingCenter,
  resetSchemaPermissions,
  updateCompany,
  updateSchemaPermissions
} from '../../../state/actions';
import Placeholder from '../../placeholder';
import SchemaSearch from '../../schema/search';
import SchemaAddPermissionsSearch from '../../schema/search-add-schema-permission';
import CompanyViewActions from './company-view-actions';
import companyViewSchema from './companySchema';
import { removeFromList } from '../../../state/reducers/list-manipulation';

const timeZonesList = momentTZ.tz.names();

const timeZoneOptions = _.map(timeZonesList, (tz) => {
  return {
    text: tz,
    value: tz
  };
});

function stateToComponent(state) {
  return {
    who: state.who,
    company: state.companyView,
    schemaPermissions: state.schemaPermissions
  };
}

@connect(stateToComponent)
@graphql(GetCompanybyIdQuery, {
  name: 'companyData',
  options: (props) => {
    const { id } = props.match.params;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        id
      }
    };
  }
})
@graphql(AddCompanyToSchema, {
  name: 'addCompanyToSchema',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
@graphql(RemoveCompanyFromSchema, {
  name: 'removeCompanyFromSchema',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
@graphql(GetAllAttachedPermissions, {
  name: 'attachedPermissionsToCompany',
  options: (props) => {
    if (props.company) {
      return {
        variables: {
          companyId: props.company._id
        }
      };
    }
  }
})
@graphql(GetSchemaPublishedByIds, {
  name: 'publishedSchemasByIds',
  options: (props) => {
    const schemaPermissions = props.schemaPermissions;
    if (schemaPermissions) {
      const { schemas } = schemaPermissions;
      if (schemas && schemas.length > 0) {
        const documentIds = _.map(schemas, (schema) => schema.id);
        return {
          errorPolicy: 'all',
          variables: {
            _ids: documentIds
          }
        };
      }
    }
    return {
      variables: { _ids: [] }
    };
  }
})
@graphql(CreatePermissions, {
  name: 'createPermission',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
@graphql(UpdatePermissions, {
  name: 'updatePermission',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
class CompanyView extends Component {
  state = {
    showAddSchemaModal: false,
    valid: false,
    showAddSchemaPermissionModal: false
  };

  addCompanyBillingCenter = () => {
    const { dispatch } = this.props;
    dispatch(addCompanyBillingCenter());
  };

  removeCompanyBillingCenter = (center) => () => {
    const { dispatch } = this.props;
    dispatch(removeCompanyBillingCenter(center));
  };

  renderDropzoneContents = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    const { company } = this.props;

    let style = {
      maxHeight: 200,
      maxWidth: '100%'
    };

    return <div>{company.logoUrl ? <img style={style} src={company.logoUrl} /> : ''}</div>;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { dispatch } = this.props;

    if (acceptedFiles && acceptedFiles.length) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        dispatch(
          updateCompany({
            logoUrl: reader.result,
            logoUpload: acceptedFiles[0]
          })
        );
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  addCompanyBillingCenter = () => {
    const { dispatch } = this.props;
    dispatch(addCompanyBillingCenter());
  };

  removeCompanyBillingCenter = (center) => () => {
    const { dispatch } = this.props;
    dispatch(removeCompanyBillingCenter(center));
  };
  componentWillUnmount() {
    this.props.dispatch(resetSchemaPermissions());
    console.log('unmouting edit company');
  }
  renderDropzoneContents = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    const { company } = this.props;

    let style = {
      maxHeight: 200,
      maxWidth: '100%'
    };

    return <div>{company.logoUrl ? <img style={style} src={company.logoUrl} /> : ''}</div>;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { dispatch } = this.props;

    if (acceptedFiles && acceptedFiles.length) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        dispatch(
          updateCompany({
            logoUrl: reader.result,
            logoUpload: acceptedFiles[0]
          })
        );
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  renderHeader = () => {
    const { company } = this.props;

    return (
      <Header as="h2">
        <Icon name="building" />
        <Header.Content>{company.name}</Header.Content>
      </Header>
    );
  };

  showAddSchemaModal = () => {
    this.setState({
      showAddSchemaModal: true
    });
  };

  closeAddSchemaModal = () => {
    this.setState({
      showAddSchemaModal: false
    });
  };

  showAddSchemaPermissionModal = () => {
    this.setState({
      showAddSchemaPermissionModal: true
    });
  };

  closeAddSchemaPermissionModal = () => {
    this.setState(
      {
        showAddSchemaPermissionModal: false
      },
      () => {
        this.props.dispatch(resetSchemaPermissions());
        const { id } = this.props.match.params;
        this.props.companyData.refetch({ variables: { id } });
      }
    );
  };

  addSchemaToCompany = (item) => {
    const { addCompanyToSchema, companyData } = this.props;
    const company = companyData.companyById;
    const schemas = companyData.schemaMany;

    const existing = schemas.find((s) => s._id === item._id);
    if (existing && existing.companies.some((c) => c.companyId === company._id)) {
      this.closeAddSchemaModal();
      return;
    }

    const variables = {
      schemaId: item._id,
      companyId: company._id
    };
    addCompanyToSchema({
      variables
    }).then((results) => {
      companyData.refetch();
    });

    this.closeAddSchemaModal();
  };

  storeUpdatedData = (data) => {
    this.props.dispatch(
      updateSchemaPermissions({
        companyId: data.companyId,
        schemas: data.schemas
      })
    );
  };

  removeSchema = (item) => {
    const { removeCompanyFromSchema, companyData } = this.props;
    const company = companyData.companyById;
    const variables = {
      schemaId: item._id,
      companyId: company._id
    };
    removeCompanyFromSchema({
      variables
    }).then((results) => {
      companyData.refetch();
    });
  };

  renderAddSchemaModal = () => {
    const { companyData } = this.props;
    const company = companyData.companyById;

    if (companyData.loading || !company) {
      return null;
    }

    return (
      <Modal size={'large'} open={this.state.showAddSchemaModal} onClose={this.closeAddSchemaModal}>
        <Modal.Header>{`Assign a Schema to ${company.name}`}</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <SchemaSearch
            ninIds={companyData.schemaMany.map((x) => x._id)}
            loadHandler={this.addSchemaToCompany}
            area="company-view"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeAddSchemaModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  onSavePermissionSchemas = () => {
    const {
      createPermission,
      attachedPermissionsToCompany,
      company,
      updatePermission,
      schemaPermissions
    } = this.props;

    if (attachedPermissionsToCompany && attachedPermissionsToCompany.permissionsOne) {
      const permissions = attachedPermissionsToCompany.permissionsOne;
      const modifiedSchemas = schemaPermissions.schemas.map((schema) => {
        return {
          id: schema._id || schema.id,
          publishedVersion: schema.publishedVersion,
          collectionId: schema.collectionId
        };
      });

      const record = {
        _id: permissions._id,
        companyId: company._id,
        schemas: modifiedSchemas
      };

      updatePermission({ variables: { record } }).then((results) => {
        const { record } = results.data.permissionsUpdateById;
        attachedPermissionsToCompany.refetch();
        this.storeUpdatedData(record);
      });
      return this.closeAddSchemaPermissionModal();
    }
    const record = {
      companyId: company._id,
      schemas: [
        ...schemaPermissions.schemas.map(({ _id, publishedVersion, collectionId }) => {
          return {
            id: _id,
            publishedVersion,
            collectionId
          };
        })
      ]
    };
    createPermission({
      variables: { record }
    }).then((results) => {
      const { record } = results.data.permissionsCreate;
      attachedPermissionsToCompany.refetch();
      this.storeUpdatedData(record);
    });
    this.closeAddSchemaPermissionModal();
  };

  renderAddSchemaPermissionModal = () => {
    const { companyData } = this.props;
    const company = companyData.companyById;

    if (companyData.loading || !company) {
      return null;
    }

    return (
      <Modal
        size={'large'}
        open={this.state.showAddSchemaPermissionModal}
        onClose={this.closeAddSchemaPermissionModal}
      >
        <Modal.Header>{`Assign a Schema Permissions to ${company.name}`}</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <SchemaAddPermissionsSearch area="company-view" companyId={company._id} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.onSavePermissionSchemas}>
            Save
          </Button>
          <Button basic onClick={this.closeAddSchemaPermissionModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  addBillingCentersCta = () => {
    return (
      <Segment attached="bottom">
        <Button
          icon
          labelPosition="left"
          primary
          size="small"
          onClick={this.addCompanyBillingCenter}
        >
          <Icon name="add" /> Add Billing Center
        </Button>
      </Segment>
    );
  };

  renderBillingCenters = () => {
    const { company } = this.props;

    const billingCenterToHtml = (center, i) => {
      const path = `billingCenters[${i}]`;
      return (
        <Table.Row key={`billing-center-${i}`}>
          <Table.Cell width={5}>
            <Form.Input fluid placeholder="Name" readOnly={true} name={`${path}.name`} />
          </Table.Cell>
          <Table.Cell width={4}>
            <Form.Input
              fluid
              readOnly={true}
              placeholder="Accounting Id"
              name={`${path}.accountingId`}
            />
          </Table.Cell>
          <Table.Cell width={6}>
            <Form.Input
              fluid
              readOnly={true}
              placeholder="Address"
              name={`${path}.address.line1`}
            />
          </Table.Cell>
        </Table.Row>
      );
    };

    const renderBillingCenterList = (
      <Table className="subform" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Accounting ID</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{_.map(company.billingCenters, billingCenterToHtml)}</Table.Body>
      </Table>
    );

    return (
      <>
        <Header>Billing Centers</Header>
        <Segment.Group>
          <Segment>{renderBillingCenterList}</Segment>
        </Segment.Group>
      </>
    );
  };

  renderBasicInformation = () => {
    return (
      <>
        <Header>Company Information</Header>
        <Form.Input readOnly={true} fluid label="Company Name" name="name" />
        <Form.Input readOnly={true} fluid label="Accounting Id" name="accountingId" />
      </>
    );
  };

  renderPhotoUpload = () => {
    return (
      <>
        <Dropzone
          ref={(ref) => {
            this._dropzone = ref;
          }}
          accept="image/jpeg, image/png"
          style={{
            position: 'relative',
            height: 200,
            borderRadius: 5,
            borderWidth: 2,
            borderStyle: 'dotted',
            borderColor: '#eeeeee'
          }}
          acceptStyle={{
            borderStyle: 'solid',
            borderColor: '#21ba45'
          }}
          rejectStyle={{
            borderStyle: 'solid',
            borderColor: '#db2828'
          }}
          multiple={false}
          onDrop={this.onDrop}
          className="center-all"
        >
          {this.renderDropzoneContents}
        </Dropzone>
        <Button
          fluid
          style={{ marginTop: '.8em' }}
          onClick={() => {
            this._dropzone.open();
          }}
        >
          Upload a Photo
        </Button>
      </>
    );
  };

  renderSchemaCompanyLineItemOverrides = ({ company }) => (schema, i) => {
    const { history } = this.props;

    const onDelete = () => {
      this.removeSchema(schema);
    };

    const onSelect = () => {
      history.push(`/schema-editor/${schema._id}/edit-info`);
    };

    const companyOnTheSchema = schema.companies.find((x) => x.companyId === company._id);

    if (!companyOnTheSchema) {
      return null;
    }

    const { name } = companyOnTheSchema;

    return (
      <Grid.Row columns="equal" key={schema.info.schemaName}>
        <Grid.Column className="schema-name" onClick={onSelect}>
          {schema.info.schemaName}
        </Grid.Column>
        <Grid.Column>{name}</Grid.Column>
        <Grid.Column width={2}>
          <Button basic onClick={onDelete}>
            <Icon name="trash" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  };

  onDeleteAttachedPermission = (documentId) => {
    const { schemaPermissions, attachedPermissionsToCompany, updatePermission } = this.props;
    schemaPermissions.schemas = removeFromList(
      schemaPermissions.schemas,
      schemaPermissions.schemas.find((schema) => schema.id === documentId)
    );
    if (attachedPermissionsToCompany && attachedPermissionsToCompany.permissionsOne) {
      const record = {
        companyId: schemaPermissions.companyId,
        schemas: schemaPermissions.schemas,
        _id: attachedPermissionsToCompany.permissionsOne._id
      };

      updatePermission({ variables: { record } }).then((results) => {
        const { record } = results.data.permissionsUpdateById;
        attachedPermissionsToCompany.refetch();
        this.storeUpdatedData(record);
      });
    }
  };

  getSchemaName = (documentId) => {
    const { publishedSchemasByIds } = this.props;
    const schemasByIds = publishedSchemasByIds.schemaPublishedByIds;

    const filteredSchemas = schemasByIds.filter((schema) => schema._id === documentId);
    if (filteredSchemas.length) return filteredSchemas[0].info.schemaName;
    return '';
  };

  renderTableBody = (schema) => {
    return (
      <Table.Row key={`schema-table-row-${schema.id}`}>
        <Table.Cell width={7}>{this.getSchemaName(schema.id)}</Table.Cell>
        <Table.Cell width={6}>
          <div className="published-schemas-table-cell">
            {schema.publishedVersions.map((version, index) => (
              <p>{version.version + (index !== schema.publishedVersions.length - 1 ? ', ' : '')}</p>
            ))}
          </div>
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    );
  };

  renderSchemaPermissionsList = () => {
    const { schemaPermissions } = this.props;

    const reduced = schemaPermissions.schemas
      .sort((a, b) =>
        parseInt(a.publishedVersion.split('.')[0]) > parseInt(b.publishedVersion.split('.')[0])
          ? -1
          : 1
      )
      .reduce((previous, current, index) => {
        const foundIndex = previous.findIndex(
          (schema) => schema.collectionId === current.collectionId
        );
        const currentId = current.id ? current.id : current._id;
        if (foundIndex >= 0) {
          previous[foundIndex].publishedVersions.push({
            id: currentId,
            version: current.publishedVersion
          });
          return previous;
        } else {
          return [
            ...previous,
            {
              collectionId: current.collectionId,
              id: currentId,
              publishedVersions: [{ id: currentId, version: current.publishedVersion }]
            }
          ];
        }
      }, []);

    return (
      <Table className="subform" basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Schema Name</Table.HeaderCell>
            <Table.HeaderCell>Published Version</Table.HeaderCell>
            {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>{reduced.map(this.renderTableBody)}</Table.Body>
      </Table>
    );
  };

  renderCompanyDeliveryInformation = () => {
    return (
      <>
        <Header size="medium">Delivery Options</Header>
        <Form.Group>
          <Form.Input
            fluid
            label="Cutoff Hour"
            type="number"
            placeholder="12"
            name="deliveryInfo.cutoff.hour"
            width={6}
          />
          <Form.Input
            fluid
            type="number"
            label="Minute"
            placeholder="30"
            name="deliveryInfo.cutoff.minute"
            width={6}
          />
          <Form.Input
            fluid
            type="number"
            label="Second"
            placeholder="15"
            name="deliveryInfo.cutoff.second"
            width={6}
          />
        </Form.Group>
        <Form.Select
          fluid
          label="Timezone"
          search
          placeholder="CST"
          name="deliveryInfo.cutoff.timezone"
          options={timeZoneOptions}
        />
        <Form.Group>
          <Form.Checkbox
            label="Delivery on Weekends?"
            name="deliveryInfo.weekendDelivery"
            toggle
            style={{ marginTop: 8 }}
          />
          <Form.Checkbox
            label="Delivery on Holidays?"
            name="deliveryInfo.holidayDelivery"
            toggle
            style={{ marginTop: 8 }}
          />
        </Form.Group>
      </>
    );
  };

  companyFormUpdated = (event, { field, form, submitting }, err) => {
    const { dispatch } = this.props;
    this.setState({ valid: !err.error });

    const updatedForm = Object.assign({}, form, {
      deliveryInfo: {
        ...form.deliveryInfo,
        cutoff: {
          hour: parseFloat(form.deliveryInfo.cutoff.hour),
          minute: parseFloat(form.deliveryInfo.cutoff.minute),
          second: parseFloat(form.deliveryInfo.cutoff.second),
          timezone: form.deliveryInfo.cutoff.timezone
        }
      }
    });

    dispatch(updateCompany(updatedForm));
  };

  emptySchemasPermissionsCta = () => {
    /**
     * Todo check the data here...
     * change according to the permissions....
     */
    const { company, companyData } = this.props;

    if (companyData.loading) {
      return null;
    }

    return (
      <Placeholder
        icon="file text outline"
        header={`No Schemas Permissions are in use by ${company.name} (Yet)`}
        subheader="This company needs at least one billing center."
      />
    );
  };

  loader = (
    <div className="search-content">
      <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
        <Dimmer inverted active>
          <Loader size="huge">Loading Company</Loader>
        </Dimmer>
      </div>
    </div>
  );

  renderCompany() {
    const { companyData, company, schemaPermissions } = this.props;
    const schemas = companyData.schemaMany;

    if (companyData.loading || !company) {
      return this.loader;
    }

    const content = (
      <div width={16} style={{ margin: 0 }}>
        <ValidatedForm
          initialValue={company}
          value={company}
          validateSchema={companyViewSchema}
          readOnly={false}
          onChange={this.companyFormUpdated}
        >
          {this.renderHeader()}
          <Segment>
            <Grid relaxed divided>
              <Grid.Column width={3}>{this.renderPhotoUpload()}</Grid.Column>
              <Grid.Column width={7}>{this.renderBasicInformation()}</Grid.Column>
              <Grid.Column width={6}>{this.renderCompanyDeliveryInformation()}</Grid.Column>
            </Grid>
          </Segment>
          {this.renderBillingCenters()}
        </ValidatedForm>

        <Header size="medium">Schemas Permissions </Header>
        <SegmentGroup>
          <Segment>
            {schemaPermissions.companyId === companyData.companyById._id &&
            schemaPermissions.schemas.length
              ? this.renderSchemaPermissionsList()
              : this.emptySchemasPermissionsCta()}
          </Segment>
          <Segment attached="bottom">
            <Button
              icon
              labelPosition="left"
              primary
              size="small"
              onClick={this.showAddSchemaPermissionModal}
            >
              <Icon name="plus" />{' '}
              {schemas.length
                ? 'Add another Schema Permissions to this Company'
                : 'Assign a Schema Permissions to this Company'}
            </Button>
          </Segment>
        </SegmentGroup>
      </div>
    );

    return <Segment basic>{content}</Segment>;
  }

  renderActions = () => {
    const { companyData, company } = this.props;

    if (companyData.loading || !company) {
      return null;
    }
    return <CompanyViewActions valid={true} />;
  };

  componentDidUpdate() {
    const { company, companyData, dispatch } = this.props;

    if (!company && !companyData.loading) {
      dispatch(loadCompany(companyData.companyById));
    }
  }

  render() {
    const {
      company,
      companyData,
      dispatch,
      schemaPermissions,
      attachedPermissionsToCompany
    } = this.props;

    if ((!company && !companyData.loading) || !companyData.schemaMany) {
      companyData.refetch();
      return null;
    }

    if (
      !attachedPermissionsToCompany.loading &&
      attachedPermissionsToCompany.permissionsOne &&
      schemaPermissions.companyId !== companyData.companyById._id
    ) {
      const permissions = attachedPermissionsToCompany.permissionsOne;
      dispatch(
        updateSchemaPermissions({
          companyId: permissions.companyId,
          schemas: permissions.schemas
        })
      );
    }

    return (
      <div className="search-workspace company-search-workspace">
        {this.renderCompany()}
        {this.renderAddSchemaModal()}
        {this.renderAddSchemaPermissionModal()}
        {this.renderActions()}
      </div>
    );
  }
}

export default CompanyView;
