import Mapper from 'data-import-mapper';

import { ADDRESS_PATTERN, EMAIL_PATTERN, PHONE_PATTERN } from '../../validation/validator-list.js';
import config from '../../config';

const { REACT_APP_S3_BUCKET_FOLDER } = config;

const types = [
  'proper-name',
  'email',
  'phone',
  'street',
  'city',
  'state',
  'zip',
  'photo',
  'file',
  'video',
  'vin',
  'geojson-point',
  'address-box'
];

// const componentBlacklist = ['Label'];

function mapComponentToType(field) {
  switch (field.component) {
    case 'Dropdown':
    case 'Select':
      return 'choice';
    case 'Checkboxes':
      return 'boolean';
    case 'TextInput':
      return 'single-line';
    case 'DatePicker':
      return 'single-line';
    case 'ColorSelector':
      return 'single-line';
    case 'BarcodeInput':
      return 'single-line';
    case 'TextArea':
      return 'multi-line';
    case 'Camera':
      return 'photo';
    case 'Video':
      return 'video';
    case 'FileUpload':
      return 'file';
    case 'NumberInput':
      return 'number';
    case 'Telephone':
      return 'phone';
    default:
      return field.component;
  }
}

function mapType(field) {
  let type = mapComponentToType(field);

  if (field.validations.find((x) => x.id === 'is-email' && x.enabled)) {
    return 'email';
  }

  if (field.validations.find((x) => x.id === 'is-phone' && x.enabled)) {
    return 'phone';
  }
  if (field.validations.find((x) => x.id === 'is-address' && x.enabled)) {
    return 'address-box';
  }

  const legacyType = field.metadata.find((x) => x.key === 'legacy_type');

  if (legacyType) {
    return legacyType.value;
  }

  return type;
}

function mapTypeToDisplay(field) {
  const type = mapType(field);

  if (type === 'address-box' || type === 'reference-number' || type === 'proper-name') {
    return 'text';
  }

  if (types.includes(type)) {
    return type + '-display';
  }

  const legacyType = field.metadata.find((x) => x.key === 'legacy_type');

  if (legacyType) {
    return legacyType.value;
  }

  if (type === 'single-line' || type === 'multi-line' || type === 'choice') {
    return 'text';
  }

  return null;
}

const workingValidators = ['required', 'matches-pattern', 'is-email', 'is-phone', 'is-address'];

function mapValidationsToOutput(field, type) {
  if (field.doNotValidate) {
    return undefined;
  }
  const output = field.validations
    .map((x) => {
      if (!x.enabled || !workingValidators.includes(x.id)) {
        return undefined;
      }
      if (x.id === 'matches-pattern') {
        return {
          regex: {
            pattern: new RegExp(x.value).source,
            options: 'i'
          }
        };
      }

      if (x.id === 'is-email') {
        return {
          regex: {
            pattern: EMAIL_PATTERN.source,
            options: 'i'
          }
        };
      }
      if (x.id === 'is-phone') {
        return {
          regex: {
            pattern: PHONE_PATTERN.source,
            options: 'i'
          }
        };
      }
      if (x.id === 'is-address') {
        return {
          regex: {
            pattern: ADDRESS_PATTERN.source,
            options: 'i'
          }
        };
      }

      return {
        [x.id]: x.enabled
      };
    })
    .filter((x) => x)
    .reduce((obj, cur) => ({ ...obj, ...cur }), {});

  if (type === 'file') {
    output.fileTypes = getFileTypes(field.properties);
  }

  if (type === 'video') {
    output.fileTypes = ['video/mp4'];
    const maxLength = field.validations.find((f) => f.id === 'length-maximum');
    const minLength = field.validations.find((f) => f.id === 'length-minumum');
    output.length = {
      max: maxLength ? maxLength.value : undefined,
      min: minLength ? minLength.value : undefined
    };
  }

  return output;
}

function getFileTypes(properties) {
  let allowedTypes = [];
  const allowPdf = properties.find((x) => x.name === 'allowPdf');
  const allowPng = properties.find((x) => x.name === 'allowPng');
  const allowJpeg = properties.find((x) => x.name === 'allowJpeg');

  if (allowPdf && allowPdf.value === true) {
    allowedTypes.push('application/pdf');
  }
  if (allowPng && allowPng.value === true) {
    allowedTypes.push('image/png');
  }
  if (allowJpeg && allowJpeg.value === true) {
    allowedTypes.push('image/jpeg');
  }
  // to support legacy fields or user unticks all the file types
  if (allowedTypes.length === 0) {
    allowedTypes = ['application/pdf', 'image/jpeg'];
  }

  return allowedTypes;
}

function mapEnvironment(env) {
  switch (env) {
    case 'client-ordering':
      return 'client';
    case 'looker-app':
      return 'looker';
  }
}

function reduceContexts(contexts, field) {
  mapValidationsToOutput(field);

  return (
    contexts &&
    contexts.reduce((forms, context) => {
      let conditions = context.conditions && context.conditions.find((x) => x.as === 'input');

      let current;

      if ((context && context.render === 'input') || conditions) {
        current = {
          type: mapType(field),
          validations: mapValidationsToOutput(field, mapComponentToType(field))
        };

        if (field.component === 'Label') {
          current.content = field.properties.find((x) => x.name === 'content').value;
        }

        if (field.options && field.options.length) {
          current.options = field.options && field.options.map((opt) => opt.text);
        }

        if (field.tooltip) {
          const urlPath = field.tooltip.path.replace(`${REACT_APP_S3_BUCKET_FOLDER}/`, '');
          current.helpURL = '/' + urlPath;
        }

        if (field.defaultValue) {
          current.placeholder = field.defaultValue || '';
        }

        if (
          conditions &&
          conditions.any &&
          conditions.any.length &&
          conditions.any[0].all &&
          conditions.any[0].all.length
        ) {
          current.matchCriteria = conditions.any[0].all;
        }
      }

      if (context && context.render === 'read-only') {
        let displayType = mapTypeToDisplay(field);
        if (displayType) {
          current = {
            type: displayType
          };
        }
      }

      if (current && mapEnvironment(context.environment)) {
        if (mapEnvironment(context.environment) === 'client') {
          if (field.metadata.length) {
            const intacctMappings = field.metadata.find((x) => x.key === 'intacct_field_name');
            if (intacctMappings) {
              current['intacctMappings'] = {
                customFields: [intacctMappings.value]
              };
            }
          }
        }
        forms[mapEnvironment(context.environment)] = current;
      }

      return forms;
    }, {})
  );
}

export default async function mapFields(field) {
  const fieldMapConfig = {
    id: 'id',
    name: {
      from: 'label',
      map: (label) => {
        const propLabel = field.properties.find((x) => x.name === 'label');
        return (propLabel && propLabel.value) || label;
      }
    },
    forms: {
      from: 'contexts',
      reduce: (contexts) => {
        const d = reduceContexts(contexts, field);
        return d;
      }
    },
    reports: (reports) =>
      reports &&
      reports
        .filter((x) => x && x.enabled)
        .map((x) => (x.environment === 'private' ? 'client' : x.environment))
  };

  const mapper = new Mapper(fieldMapConfig);

  const mapped = await mapper.map(field);

  const keywordField = field.properties.find((x) => x.name === 'keywordField');

  if (keywordField) {
    mapped.index = keywordField.value ? true : false;
  }

  if (field.highlightedField) mapped.highlight = ['looker'];

  // Set relationship to confirmation if it is input on both client and looker app.
  if (mapped.forms.client && mapped.forms.looker) {
    if (mapped.forms.client.type === mapped.forms.looker.type) {
      mapped.relationship = mapped.relationship || 'confirmation';
    } else {
      mapped.relationship = mapped.relationship || 'information';
    }
  }

  if (field.component === 'Location') {
    return mapLocationField(field);
  }

  if (field.component === 'Contact') {
    return mapContactField(field);
  }

  if (field.component === 'BarcodeInput') {
    mapped.forms.looker.type = 'vin';
    mapped.forms.looker.inputs = ['barcode'];
  }

  if (field.component === 'Address') {
    return mapAddressField(field);
  }

  if (field.component === 'Camera') {
    const includeDescription = field.properties.find((x) => x.name === 'includeDescription');

    if (includeDescription && includeDescription.value != 'false') {
      const multiplePhotos = field.properties.find((x) => x.name === 'multiple');
      const descriptionField = await mapFields({
        ...field,
        properties: [],
        label: `Description`,
        component: 'multi-line',
        id: `${field.id}_description`,
        doNotValidate: true
      });
      const photoField = { ...mapped };
      if (includeDescription.value === 'required' || includeDescription.value === 'Required') {
        descriptionField.forms.looker.validations = {
          required: true
        };
      }
      photoField.name = 'Photo';
      // mapped.name += ' Photo';
      // mapped.id += '_photo';
      mapped.type = 'group';
      mapped.groupType = 'photo-with-description';
      mapped.fields = [];
      mapped.id += '_group';
      if (multiplePhotos && multiplePhotos.value) {
        const requiredValue = field.validations.find((x) => x.id === 'required');
        mapped.multiple = {
          count: requiredValue.value
        };
      } else {
        mapped.multiple = {
          count: 1
        };
      }
      mapped.fields.push(photoField);
      delete mapped.forms;
      mapped.fields.push(descriptionField);
    }
  }

  return mapped;
}

const mapAddressField = async (field) => {
  const propLabel = field.properties.find((x) => x.name === 'label');
  const label = (propLabel && propLabel.value) || field.label;

  const street = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Street`,
    component: 'street',
    id: `${field.id}_street`
  });

  const city = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} City`,
    component: 'city',
    id: `${field.id}_city`
  });
  const state = await mapFields({
    ...field,
    label: `${label} State`,
    properties: field.properties.filter((x) => x.name !== 'label'),
    component: 'state',
    options: [
      {
        id: 'AK',
        value: 'Alaska'
      },
      {
        id: 'AL',
        value: 'Alabama'
      },
      {
        id: 'AR',
        value: 'Arkansas'
      },
      {
        id: 'AZ',
        value: 'Arizona'
      },
      {
        id: 'CA',
        value: 'California'
      },
      {
        id: 'CO',
        value: 'Colorado'
      },
      {
        id: 'CT',
        value: 'Connecticut'
      },
      {
        id: 'DC',
        value: 'District of Columbia'
      },
      {
        id: 'DE',
        value: 'Delaware'
      },
      {
        id: 'FL',
        value: 'Florida'
      },
      {
        id: 'GA',
        value: 'Georgia'
      },
      {
        id: 'HI',
        value: 'Hawaii'
      },
      {
        id: 'IA',
        value: 'Iowa'
      },
      {
        id: 'ID',
        value: 'Idaho'
      },
      {
        id: 'IL',
        value: 'Illinois'
      },
      {
        id: 'IN',
        value: 'Indiana'
      },
      {
        id: 'KS',
        value: 'Kansas'
      },
      {
        id: 'KY',
        value: 'Kentucky'
      },
      {
        id: 'LA',
        value: 'Louisiana'
      },
      {
        id: 'MA',
        value: 'Massachusetts'
      },
      {
        id: 'MD',
        value: 'Maryland'
      },
      {
        id: 'ME',
        value: 'Maine'
      },
      {
        id: 'MI',
        value: 'Michigan'
      },
      {
        id: 'MN',
        value: 'Minnesota'
      },
      {
        id: 'MO',
        value: 'Missouri'
      },
      {
        id: 'MS',
        value: 'Mississippi'
      },
      {
        id: 'MT',
        value: 'Montana'
      },
      {
        id: 'NE',
        value: 'Nebraska'
      },
      {
        id: 'NV',
        value: 'Nevada'
      },
      {
        id: 'NH',
        value: 'New Hampshire'
      },
      {
        id: 'NJ',
        value: 'New Jersey'
      },
      {
        id: 'NM',
        value: 'New Mexico'
      },
      {
        id: 'NY',
        value: 'New York'
      },
      {
        id: 'NC',
        value: 'North Carolina'
      },
      {
        id: 'ND',
        value: 'North Dakota'
      },
      {
        id: 'OH',
        value: 'Ohio'
      },
      {
        id: 'OK',
        value: 'Oklahoma'
      },
      {
        id: 'OR',
        value: 'Oregon'
      },
      {
        id: 'PA',
        value: 'Pennsylvania'
      },
      {
        id: 'RI',
        value: 'Rhode Island'
      },
      {
        id: 'SC',
        value: 'South Carolina'
      },
      {
        id: 'SD',
        value: 'South Dakota'
      },
      {
        id: 'TN',
        value: 'Tennessee'
      },
      {
        id: 'TX',
        value: 'Texas'
      },
      {
        id: 'UT',
        value: 'Utah'
      },
      {
        id: 'VA',
        value: 'Virginia'
      },
      {
        id: 'VI',
        value: 'Virgin Islands'
      },
      {
        id: 'VT',
        value: 'Vermont'
      },
      {
        id: 'WA',
        value: 'Washington'
      },
      {
        id: 'WI',
        value: 'Wisconsin'
      },
      {
        id: 'WV',
        value: 'West Virginia'
      },
      {
        id: 'WY',
        value: 'Wyoming'
      }
    ],
    id: `${field.id}_state`
  });
  const zip = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Zipcode`,
    component: 'zip',
    id: `${field.id}_zip_code`
  });

  return [street, city, state, zip];
};

const mapContactField = async (field) => {
  const propLabel = field.properties.find((x) => x.name === 'label');
  const label = (propLabel && propLabel.value) || field.label;

  const name = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Name`,
    component: 'proper-name',
    id: `${field.id}_name`
  });
  const phone = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    component: 'single-line',
    label: `${label} Phone`,
    id: `${field.id}_phone`
  });

  const email = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Email`,
    component: 'single-line',
    id: `${field.id}_email`
  });
  const altPhone = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Alternate Phone`,
    component: 'single-line',
    id: `${field.id}_alternate_phone`
  });

  return [name, phone, email, altPhone];
};

const mapLocationField = async (field) => {
  const propLabel = field.properties.find((x) => x.name === 'label');
  const addCoordinates = field.metadata.find((x) => x.key === 'add-coordinates');
  const label = (propLabel && propLabel.value) || field.label;

  const addressField = await mapFields({
    ...field,
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label}`,
    component: 'address-box',
    id: `${field.id}`
  });

  const coordsField = await mapFields({
    ...field,
    ...{ validations: [] },
    properties: field.properties.filter((x) => x.name !== 'label'),
    label: `${label} Coordinates`,
    component: 'geojson-point',
    id: `${field.id}_coordinates`
  });
  if (addCoordinates && addCoordinates.value === 'true') {
    return [addressField, coordsField];
  }
  return [addressField];
};
