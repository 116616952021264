import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect, Route } from 'react-router-dom';

@withRouter
class WorkspaceRoutes extends Component {
  renderWorkspaces = () => {
    const { rootUrl, workSpaces } = this.props;

    return _.map(workSpaces, (workspace) => {
      let path = workspace.param
        ? `${rootUrl}/${workspace.id}/${workspace.param}`
        : `${rootUrl}/${workspace.id}`;

      return (
        <Route
          key={`workspace-${workspace.id}`}
          path={path}
          exact={false}
          component={workspace.component}
        />
      );
    });
  };

  render() {
    const { rootUrl, match, workSpaces } = this.props;

    return (
      <div className="search-workspace">
        <Route
          path={rootUrl}
          exact={true}
          render={() => {
            return <Redirect to={`${match.url}/${workSpaces[0].id}`} />;
          }}
        />
        {this.renderWorkspaces()}
      </div>
    );
  }
}

@withRouter
class Workspace extends Component {
  render() {
    const { rootUrl, workSpaces } = this.props;
    return <WorkspaceRoutes rootUrl={rootUrl} workSpaces={workSpaces} />;
  }
}
export default Workspace;
