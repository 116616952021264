import createReducer from './create-reducer';

const initial = {
  activeEditorTabName: 'edit-info'
};
const reducers = {
  UPDATE_SCHEMA_EDITOR_TAB: (state, value) => {
    return { ...state, ...{ activeEditorTabName: value } };
  }
};

export default createReducer(initial, reducers);
