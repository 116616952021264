import './index.css';

import React, { Component } from 'react';
// import { graphql } from 'react-apollo';
import CalendarHeatmap from 'react-calendar-heatmap';
import { Button, Header, Menu, Popup, Segment } from 'semantic-ui-react';

import ActivityTable from './activity-table';
// import getDashboardSchemaHistory from '../../../graphql/queries/getDashboardSchemaHistory.graphql';

// @graphql(getDashboardSchemaHistory, {
//   name: 'schemaHistory',
//   options: (props) => {
//     return {
//       fetchPolicy: 'cache-and-network',
//       errorPolicy: 'all',
//       context: {},
//       variables: {
//         skip: props.skip
//       }
//     };
//   }
// })
class ContributionGraph extends Component {
  state = {
    x: 0,
    y: 0,
    open: false,
    toolTipContent: null
  };

  toDiffs = (diffs, history) => {
    history.diffs.map((x) => {
      x._id = history.item ? history.item._id : null;
      x.item = history.item ? history.item : null;
      return x;
    });
    return diffs.concat(history.diffs);
  };

  toValues = (values, value) => {
    const date = new Date(value.timestamp).toLocaleDateString();
    let counter = values.find((v) => v.date === date);
    if (!counter) {
      counter = { date, count: 0 };
      values.push(counter);
    }

    counter.count++;
    return values;
  };

  handleref = (node) => {
    this.setState({ node });
  };

  render() {
    const { skip, updateSkip, schemaHistory } = this.props;
    const { node, x, y, open, toolTipContent } = this.state;

    console.log(this.props);

    // const diffs = schemaHistory.schemaHistoryMany
    //   ? schemaHistory.schemaHistoryMany.reduce(this.toDiffs, [])
    //   : [];
    const diffs = [];
    const values = diffs.reduce(this.toValues, []) || [];
    const start = Date.now() - 1000 * 60 * 60 * 24 * 365;
    const end = Date.now();

    const popupcontent = toolTipContent ? `${toolTipContent.date} : ${toolTipContent.count}` : '';

    return (
      <div className={'dashboard-tile-frame'}>
        <Header as="h3" content="Activity" subheader="Overview of all your latest activity" />
        <Segment.Group raised>
          <Segment padded="very" className="">
            <Popup content={popupcontent} context={node} open={open} />
            <div ref={this.handleref} style={{ position: 'fixed', top: y, left: x + 30 }} />
            <CalendarHeatmap
              className={'react-calendar-heatmap'}
              startDate={start}
              endDate={end}
              values={values}
              onMouseOver={(e, val) => {
                const bounding = e.target.getBoundingClientRect();
                this.setState({
                  x: bounding.x,
                  y: bounding.y,
                  open: val ? true : false,
                  toolTipContent: val
                });
              }}
              onMouseLeave={(e) => this.setState({ open: false, toolTipContent: null })}
              classForValue={(value) => {
                if (!value) {
                  return 'color-empty';
                }
                return `color-scale-${value.count}`;
              }}
            />
          </Segment>
          <Segment secondary>
            <Menu secondary>
              <Menu.Item secondary>
                <div className="ui aligned category search">
                  <div className="ui transparent icon input">
                    <i className="search link icon" />
                    <input className="prompt" type="text" placeholder="Search users..." />
                  </div>
                </div>
              </Menu.Item>
              <Menu.Menu position="right">
                <Button icon="filter" />
                <Button icon="calendar" />
                <Button icon="tag" />
              </Menu.Menu>
            </Menu>
          </Segment>
          <Segment padded="very">
            <ActivityTable
              items={diffs}
              skip={skip}
              updateSkip={updateSkip}
              // loading={schemaHistory.loading}
            />
          </Segment>
        </Segment.Group>
      </div>
    );
  }
}

export default ContributionGraph;
