import './index.css';
import './line-items.css';

import Joi from 'joi-browser';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import GetSchemaFacets from '../../../graphql/queries/getSchemaFacets.graphql';
import {
  updateEquipmentOptions,
  updateSchemaGroupOptions,
  updateSchemaInfo,
  updateSubSchemaOptions,
  updateTagOptions,
  editSchemaInfo
} from '../../../state/actions';
import currencies from '../../currency-input/available-currencies.js';
import CompanySelector from './company-selector';
import BaseDeliveryOptionSelector from './delivery-option-selector';
import LineItemSelector from './lineitem-selector';
import metaInfoSchema from './metainfo-schema';
import MetadataSelector from './metadata-selector';
import ProfessionsSelector from './professions-selector';
import prescheduleOption from '../../preschedule-input/preschedule';

function stateToComponent(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
@graphql(GetSchemaFacets, {
  name: 'schemaFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
class MetaInfoEditor extends Component {
  state = {
    populatedFacets: false,
    infoValue: {},
    pipeline: {
      skipQA: false,
      skipPrep: false,
      skipVerification: false,
      autoSolicit: false
    }
  };

  componentDidMount() {
    const { info } = this.props.editSchemaSchema;
    if (info && info.pipeline) {
      if (info.pipeline) {
        this.setState({
          pipeline: info.pipeline,
          infoValue: info
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { info } = this.props.editSchemaSchema;
    if (info && info != prevProps.editSchemaSchema.info) {
      if (info.pipeline) {
        this.setState({
          pipeline: info.pipeline,
          infoValue: info
        });
      }
    }
  }

  populateFacetOptions = () => {
    const { schemaFacets, dispatch } = this.props;

    const {
      info_requiredEquipment,
      info_subSchema,
      info_tags,
      info_schemaGroup
    } = schemaFacets.schemaFacets;

    this.setState({ populatedFacets: true });

    dispatch(
      updateSchemaGroupOptions(
        _.map(info_schemaGroup, (value) => {
          return {
            text: value,
            value
          };
        })
      )
    );

    dispatch(
      updateSubSchemaOptions(
        _.map(info_subSchema, (value) => {
          return {
            text: value,
            value
          };
        })
      )
    );
    dispatch(
      updateTagOptions(
        _.map(info_tags, (value) => {
          return {
            text: value,
            value
          };
        })
      )
    );
    dispatch(
      updateEquipmentOptions(
        _.map(info_requiredEquipment, (value) => {
          return {
            text: value,
            value
          };
        })
      )
    );
  };

  generatedName = () => {
    const { info } = this.props.editSchemaInfo;
    let name = info.subSchema;
    if (info.schemaGroup && info.subSchema) {
      name = `${info.schemaGroup} : ${info.subSchema}`;
    }
    return name;
  };

  generatedNameHtml = () => {
    const name = this.generatedName();
    let placeholder = 'Schema Name';
    let color = name ? 'rgba(0, 0, 0, 0.87)' : 'rgba(191, 191, 191, 0.87)';
    return <div style={{ lineHeight: '2.5rem', color: color }}>{name || placeholder}</div>;
  };

  setPipelineData = (e, { name, checked }) => {
    const { dispatch } = this.props;
    const { pipeline } = this.state;
    let newPipeline = { ...pipeline };
    newPipeline[name] = checked;
    const updatedInfo = {
      ...this.state.infoValue,
      ...{ pipeline: newPipeline }
    };
    this.setState({
      infoValue: updatedInfo,
      pipeline: newPipeline
    });
    dispatch(
      updateSchemaInfo({
        ...updatedInfo
      })
    );
  };

  render() {
    const { dispatch } = this.props;
    const {
      info,
      equipmentOptions,
      tagOptions,
      subSchemaOptions,
      schemaGroupOptions
    } = this.props.editSchemaInfo;

    const { skipQA, skipPrep, skipVerification, autoSolicit } = this.state.pipeline;

    if (!this.state.populatedFacets && !this.props.schemaFacets.loading) {
      this.populateFacetOptions();
      return null;
    }

    const countryOptions = [
      { key: 'us', value: 'us', flag: 'us', text: 'United States' },
      { key: 'ca', value: 'ca', flag: 'ca', text: 'Canada' },
      { key: 'gb', value: 'gb', flag: 'gb', text: 'United Kingdom' },
      { key: 'au', value: 'au', flag: 'au', text: 'Australia' }
    ];

    const pipelineOptions = [
      {
        name: 'skipQA',
        label: 'Skip QA',
        key: 'skipqa',
        value: skipQA
      },
      {
        name: 'skipPrep',
        label: 'Skip Prep',
        key: 'skipprep',
        value: skipPrep
      },
      {
        name: 'skipVerification',
        label: 'Skip Verification',
        key: 'skipverification',
        value: skipVerification
      },
      {
        name: 'autoSolicit',
        label: 'Auto Solicit',
        key: 'autosolicit',
        value: autoSolicit
      }
    ];

    const validationSchema = {
      ...metaInfoSchema,
      ...{
        subSchema: Joi.string()
          .invalid(_.map(subSchemaOptions, (sso) => sso.value))
          .label('Sub Schema (must be unique)')
          .required()
      }
    };

    return (
      <div>
        <Header size="medium">Schema Info</Header>
        <Segment>
          <ValidatedForm
            intialValue={info}
            value={this.state.infoValue.subSchema ? this.state.infoValue : info}
            validateSchema={validationSchema}
            onChange={(event, { field, form, submitting }, err) => {
              let newForm = { ...form };
              newForm.pipeline = { ...this.state.infoValue.pipeline };
              this.setState({ infoValue: newForm });
              dispatch(
                updateSchemaInfo({
                  ...newForm,
                  ...{
                    schemaName: this.generatedName(),
                    clientWarning: newForm.clientWarning.trim(),
                    subSchema: newForm.subSchema.trim(),
                    schemaDescription: newForm.schemaDescription.trim()
                  }
                })
              );
              dispatch(
                editSchemaInfo({
                  ...newForm,
                  ...{
                    schemaName: this.generatedName(),
                    clientWarning: newForm.clientWarning.trim(),
                    subSchema: newForm.subSchema.trim(),
                    schemaDescription: newForm.schemaDescription.trim()
                  }
                })
              );
            }}
          >
            <Header size="medium">
              {this.generatedNameHtml()}
              <Header.Subheader>Update Schema Meta Information</Header.Subheader>
            </Header>
            <Grid relaxed divided>
              <Grid.Column width={10}>
                <Form.Input
                  label={`Subschema Name`}
                  placeholder="Enter the schema name."
                  fluid
                  name={`subSchema`}
                />
                <Form.Select
                  options={schemaGroupOptions}
                  label={`Schema Group Name`}
                  placeholder="Enter the schema group name."
                  search
                  selection
                  fluid
                  allowAdditions
                  onChange={(e, { value }) => {
                    if (!schemaGroupOptions.find((x) => x.text === value)) {
                      const newOptions = _.uniqBy(
                        [{ text: value, value }, ...schemaGroupOptions],
                        'value'
                      );
                      dispatch(updateSchemaGroupOptions(newOptions));
                    }
                  }}
                  name={`schemaGroup`}
                />
                <Form.Group>
                  <Form.Input
                    fluid
                    label="Estimated Time Min"
                    placeholder="Min"
                    name="estimatedTime.min"
                  />
                  <Form.Input
                    fluid
                    label="Estimated Time Max"
                    placeholder="Max"
                    name="estimatedTime.max"
                  />
                  <Form.Select
                    options={equipmentOptions}
                    className="fieldItem-fit"
                    label={`Required Equipment`}
                    placeholder="Ruler, Tire Gauge"
                    search
                    selection
                    multiple
                    allowAdditions={true}
                    onAddItem={(e, { value }) => {
                      const newOptions = _.uniqBy(
                        [{ text: value, value }, ...equipmentOptions],
                        'value'
                      );

                      dispatch(updateEquipmentOptions(newOptions));
                    }}
                    name={`requiredEquipment`}
                  />
                </Form.Group>
                <Form.TextArea
                  fluid
                  rows={2}
                  label="Client Warning"
                  placeholder="Enter a short description describing this schema's purpose"
                  name="clientWarning"
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.TextArea
                  rows={2}
                  label="Schema Description"
                  placeholder="Enter a short description describing this schema's purpose"
                  name="schemaDescription"
                />
                <Form.Select
                  options={tagOptions}
                  label={`Tags`}
                  placeholder="Inspection, Intersection, Scene, Road, Traffic Accidents"
                  search
                  selection
                  multiple
                  allowAdditions={true}
                  onAddItem={(e, { value }) => {
                    const newOptions = _.uniqBy([{ text: value, value }, ...tagOptions], 'value');

                    dispatch(updateTagOptions(newOptions));
                  }}
                  name={`tags`}
                />

                <Form.Group>
                  <Form.Select
                    options={countryOptions}
                    label={`Country`}
                    placeholder="Select a Country"
                    className="fieldItem-fit"
                    search
                    selection
                    name={`country`}
                  />
                  <Form.Select
                    options={currencies}
                    label={`Currency`}
                    placeholder="Select a Currency"
                    className="fieldItem-fit"
                    search
                    selection
                    name={`currency`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Checkbox
                    rows={2}
                    className="consumer-schema-editor"
                    toggle
                    name={`isConsumer`}
                    label="Consumer Schema"
                  />
                  <Form.Select
                    options={prescheduleOption}
                    label={`Preschedule`}
                    className="fieldItem-fit"
                    search
                    selection
                    placeholder="Select a Preschedule"
                    name={`preschedule.validations.status`}
                  />
                </Form.Group>
              </Grid.Column>
            </Grid>
          </ValidatedForm>
          <div className="pipeline-options-editor">
            <Form>
              <label style={{ fontSize: '0.92857143em', fontWeight: 'bold' }}>
                Pipeline Options
              </label>
              <Form.Group>
                {pipelineOptions.map((i) => (
                  <Form.Checkbox
                    key={i.key}
                    rows={2}
                    toggle
                    name={i.name}
                    label={i.label}
                    checked={i.value}
                    onChange={this.setPipelineData}
                  />
                ))}
              </Form.Group>
            </Form>
          </div>
        </Segment>
        <br />
        <BaseDeliveryOptionSelector />
        <br />
        <LineItemSelector />
        <br />
        <ProfessionsSelector />
        <br />
        <CompanySelector />
        <br />
        <MetadataSelector />
      </div>
    );
  }
}
export default MetaInfoEditor;
