import 'babel-polyfill';
import './index.css';
import './themes/semantic.wgl-flat.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root';
import App from './components/app';

const TARGET_ROOT = 'root';

let application = (
  <Root>
    <App />
  </Root>
);

ReactDOM.render(application, document.getElementById(TARGET_ROOT));
