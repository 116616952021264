import '../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Dimmer, Dropdown, Icon, Loader, Pagination } from 'semantic-ui-react';

import GetAlluniqueFieldsQuery from '../../../graphql/queries/getAllUniqueFields.graphql';
import GetUniqueFieldFacets from '../../../graphql/queries/getUniqueFieldFacets.graphql';
import {
  clearUniqueField,
  loadUniqueField,
  updateUniqueFieldsComponentFacets,
  updateUniqueFieldsLayout,
  updateUniqueFieldsPage,
  updateUniqueFieldsSort,
  updateUniqueFieldsTagsFacets,
  updateUniqueFieldsTerm
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import uniqueFieldLayoutStrategy from './uniqueField-layout-strategy';

const FACETS_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.uniqueFieldSearch
  };
}

@connect(stateToComponent)
@graphql(GetAlluniqueFieldsQuery, {
  name: 'uniqueFieldsData',
  options: (props) => {
    const {
      sort,
      page,
      perPageCard,
      perPageTable,
      search,
      tagsFacets,
      componentFacets,
      layout,
      pagerInfo
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    console.log('fetching', props.excludeIds);

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        tagsFacets,
        componentFacets,
        page: _page,
        perPage: _perPage,
        notInIds: props.excludeIds || []
      }
    };
  }
})
@graphql(GetUniqueFieldFacets, {
  name: 'uniqueFieldFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
@withRouter
class UniqueFieldSearch extends Component {
  renderFacetSelector = () => {
    const { loading, uniqueFieldFacets } = this.props.uniqueFieldFacets;

    let tags = loading ? [] : uniqueFieldFacets.tags.map((x) => ({ key: x, text: x, value: x }));

    let components = loading
      ? []
      : uniqueFieldFacets.component.map((x) => ({ key: x, text: x, value: x }));

    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
      // icon: 'check'
    });

    return (
      <>
        <Dropdown
          text="Tags"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Tags"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateUniqueFieldsTagsFacets}
          renderLabel={renderLabel}
          value={this.props.search.tagsFacets}
          options={tags}
          scrolling={true}
        />
        <Dropdown
          text="Component"
          icon="tags"
          floating
          labeled
          button
          multiple
          selection
          search
          header="Component"
          className="icon utility-bar-facet-dropdown"
          onChange={this.updateUniqueFieldsComponentFacets}
          renderLabel={renderLabel}
          value={this.props.search.componentFacets}
          options={components}
          scrolling={true}
        />
      </>
    );
  };

  changeView = (event, data) => {
    const { dispatch, uniqueFieldsData } = this.props;
    const pagerInfo = _.get(uniqueFieldsData, 'uniqueFieldsData.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout =
      _.find(uniqueFieldLayoutStrategy, { id: data.value }) || uniqueFieldLayoutStrategy[0];

    dispatch(
      updateUniqueFieldsLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateUniqueFieldsTagsFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateUniqueFieldsTagsFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  updateUniqueFieldsComponentFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateUniqueFieldsComponentFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  loadUniqueField = (uniqueField) => {
    const { dispatch, history } = this.props;
    dispatch(loadUniqueField(uniqueField));
    history.push(`/uniquefield/view/${uniqueField._id}`);
  };

  copyUniqueField = (uniqueField) => {
    const { dispatch, history } = this.props;
    delete uniqueField._id;
    dispatch(
      loadUniqueField({
        ...uniqueField,
        ...{
          id: `${uniqueField.id} - Copy`,
          label: `${uniqueField.label} - Copy`
        }
      })
    );
    history.push(`/uniquefield/edit/new`);
  };

  editUniqueField = (uniqueField) => {
    const { dispatch, history } = this.props;
    dispatch(loadUniqueField(uniqueField));
    history.push(`/uniquefield/edit/${uniqueField._id}`);
  };

  loadUniqueFieldHistory = (uniqueField) => {
    const { history } = this.props;
    history.push(`/uniquefield/history/${uniqueField._id}`);
  };

  renderLayoutContent = (items) => {
    const { who, search, loadHandler, editHandler } = this.props;

    const activeLayout =
      _.find(uniqueFieldLayoutStrategy, { id: search.layout }) || uniqueFieldLayoutStrategy[0];

    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{
          select: loadHandler || this.loadUniqueField,
          edit: editHandler || this.editUniqueField,
          history: this.loadUniqueFieldHistory,
          copy: this.copyUniqueField
        }}
      />
    );
  };

  createNew = () => {
    const { history, dispatch } = this.props;
    dispatch(clearUniqueField());
    history.push('/uniquefield/edit/new');
  };

  renderButtons = () => {
    const { history, dispatch } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 16
        }}
      >
        <Button.Group>
          <Button
            size="big"
            primary
            onClick={() => {
              dispatch(clearUniqueField());
              history.push(`/uniqueField/edit/new`);
            }}
          >
            {<Icon name={`edit`} />}
            {`New`}
          </Button>
        </Button.Group>
      </div>
    );
  };

  render() {
    const { uniqueFieldsData, dispatch } = this.props;

    const { loading } = uniqueFieldsData;

    const items = _.get(uniqueFieldsData, 'uniqueFieldPagination.items', []);
    const pageInfo = _.get(uniqueFieldsData, 'uniqueFieldPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    //TODO extract to function.
    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Unique Fields</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateUniqueFieldsPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    console.log('what', this.props.search);
    return (
      <div className="search-workspace tooltips-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            sort={updateUniqueFieldsSort}
            label="Unique Fields"
            search={this.props.search}
            strategy={uniqueFieldLayoutStrategy}
            searchAction={updateUniqueFieldsTerm}
            filters={this.renderFacetSelector()}
            viewToggle={this.changeView}
          />
          {content}
        </div>
        {pager}
        <div>{this.renderButtons()}</div>
      </div>
    );
  }
}
export default UniqueFieldSearch;
