import createReducer from './create-reducer';

const initial = {
  page: 1
};
const reducers = {
  CHANGE_VERSIONS_PAGINATION_PAGE: (state, value) => {
    return { ...state, page: value };
  }
};

export default createReducer(initial, reducers);
