import UserCards from './user-cards';
import SearchTable from '../../search/search-table';
import { SearchActionButtonSection } from '../../search/actionSection';

function actionSection(actions) {
  return [
    {
      icon: 'edit',
      label: 'View',
      action: actions.load
    }
  ];
}

function infoSection(actions) {
  return [
    // {
    //   icon: 'user',
    //   content: item =>
    //     `author: ${item.author.firstName} ${item.author.lastName}`,
    //   routes: ['search', 'company-view']
    // }
  ];
}

export default [
  {
    id: 'card-layout',
    icon: 'block layout',
    label: 'Card',
    description: 'Convert layout to a cards. More detail; less per page',
    model: {
      actionSection,
      infoSection
    },
    component: UserCards
  },
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Avatar',
        type: 'image',
        property: 'avatarUrl',
        useDefault: true
      },
      {
        label: 'First',
        property: 'firstName'
      },
      {
        label: 'Last',
        property: 'lastName'
      },
      {
        label: 'Email',
        property: 'email'
      },
      {
        label: 'Roles',
        property: 'roles'
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ],
    component: SearchTable
  }
];
