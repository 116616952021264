import createReducer from './create-reducer';

const initial = null;

const reducers = {
  UPDATE_JSON_SCHEMA_EDITOR_PREVIEW_OUTPUT_MODEL: (state, value) => {
    return value;
  }
};

export default createReducer(initial, reducers);
