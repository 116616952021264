import '../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Dimmer, Dropdown, Loader, Pagination } from 'semantic-ui-react';

import GetAllLineItemsQuery from '../../../graphql/queries/getAllLineItems.graphql';
import GetLineItemFacets from '../../../graphql/queries/getLineItemFacets.graphql';
import {
  loadLineItem,
  updateLineItemsFacets,
  updateLineItemsLayout,
  updateLineItemsPage,
  updateLineItemsTerm,
  updateLineItemsSort
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import lineItemLayoutStrategy from './layout-strategy';

const FACETS_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.lineItemSearch
  };
}

@connect(stateToComponent)
@graphql(GetAllLineItemsQuery, {
  name: 'lineItemData',
  options: (props) => {
    const { exclude } = props;
    const {
      sort,
      page,
      perPageCard,
      perPageTable,
      search,
      facets,
      layout,
      pagerInfo
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        categoryFacets: facets,
        page: _page,
        perPage: _perPage,
        notInIds: exclude || []
      }
    };
  }
})
@graphql(GetLineItemFacets, {
  name: 'lineItemFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
class LineItemsSearch extends Component {
  renderFacetSelector = () => {
    const { facets } = this.props.search;
    const { loading, lineItemFacets } = this.props.lineItemFacets;

    let items = loading ? [] : lineItemFacets.category.map((x) => ({ key: x, text: x, value: x }));

    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
    });

    return (
      <Dropdown
        text="Category"
        icon="folder open"
        floating
        labeled
        button
        multiple
        selection
        search
        header="Type"
        className="icon utility-bar-facet-dropdown"
        onChange={this.updateLineItemsFacets}
        renderLabel={renderLabel}
        value={facets}
        options={items}
        scrolling={true}
      />
    );
  };

  changeView = (event, data) => {
    const { dispatch, lineItemData } = this.props;
    const pagerInfo = _.get(lineItemData, 'lineItemData.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout =
      _.find(lineItemLayoutStrategy, { id: data.value }) || lineItemLayoutStrategy[0];

    dispatch(
      updateLineItemsLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateLineItemsFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateLineItemsFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  loadLineItem = (lineItem) => {
    const { dispatch, history } = this.props;
    dispatch(loadLineItem(lineItem));
    history.push(`/lineItem/view/${lineItem._id}`);
  };

  renderLayoutContent = (items) => {
    const { who, search, loadHandler } = this.props;
    const activeLayout =
      _.find(lineItemLayoutStrategy, { id: search.layout }) || lineItemLayoutStrategy[0];

    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{
          select: loadHandler || this.loadLineItem
        }}
      />
    );
  };

  render() {
    const { lineItemData, dispatch } = this.props;

    const { loading } = lineItemData;

    const items = _.get(lineItemData, 'lineItemPagination.items', []);
    const pageInfo = _.get(lineItemData, 'lineItemPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    //TODO extract to function.
    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Line Items</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateLineItemsPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace line-item-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            label="LineItems"
            sort={updateLineItemsSort}
            search={this.props.search}
            strategy={lineItemLayoutStrategy}
            searchAction={updateLineItemsTerm}
            filters={this.renderFacetSelector()}
            viewToggle={this.changeView}
          />
          {content}
        </div>
        {pager}
      </div>
    );
  }
}
export default LineItemsSearch;
