import Joi from 'joi-browser';

export default {
  name: Joi.string().required(),
  price: {
    amount: Joi.number().example(0),
    currency: Joi.string().required()
  },
  lookerFee: {
    amount: Joi.number().example(0),
    currency: Joi.string().required()
  },
  category: Joi.string().required(),
  taxCode: Joi.string().required(),
  itemCode: Joi.string().required(),
  glAccountNumber: Joi.string().required()
};
