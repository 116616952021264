import _ from 'lodash';
import React, { Component } from 'react';
import { Card } from 'semantic-ui-react';

import { SearchActionMenuSection } from '../../search/actionSection';

export class UniqueFieldCard extends Component {
  render() {
    const { result, actions, model } = this.props;
    const { _id, id, name } = result;

    const renderExtra = () => {
      return <SearchActionMenuSection item={result} model={model.actionSection(actions)} />;
    };

    return (
      <Card key={`card-${_id}`}>
        <Card.Content>
          <Card.Header>
            {name}
            {renderExtra()}
          </Card.Header>
          <Card.Meta>{`${id}`}</Card.Meta>
          <Card.Description>{'Description'}</Card.Description>
        </Card.Content>
      </Card>
    );
  }
}

export default class UniqueFieldCards extends Component {
  render() {
    const { items, actions, model } = this.props;

    const resultItemsHtml = _.map(items, (item, index) => {
      return (
        <UniqueFieldCard
          key={`card-${item._id}`}
          result={item}
          index={index}
          model={model}
          actions={actions}
        />
      );
    });

    return <div className="search-results">{resultItemsHtml}</div>;
  }
}
