import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label, Popup } from 'semantic-ui-react';

import { validators } from '../../../validation/validator-list';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@connect(componentToState)
class ActiveValidationReadout extends Component {
  validationToIcon = ({ icon, label, value, id }) => {
    let message = value ? `${label} ${value}` : label;

    return (
      <Popup
        key={`validation-readout-${id}`}
        trigger={
          <Label
            circular
            icon={icon}
            size="big"
            color="orange"
            style={{ maxWidth: '2em', textAlign: 'center' }}
          />
        }
        content={message}
      />
    );
  };

  render() {
    const { field } = this.props;
    const validationGlyphs = _.chain(field.validations)
      .filter((validation) => {
        return validation.enabled;
      })
      .map((validation) => {
        return { ...validation, ..._.find(validators, { id: validation.id }) };
      })
      .map(this.validationToIcon.bind(this))
      .value();

    return (
      <div
        className="active-validation-readout"
        style={{ marginTop: 8, marginLeft: 8, marginRight: 8 }}
      >
        {validationGlyphs}
      </div>
    );
  }
}
export default ActiveValidationReadout;
