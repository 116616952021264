import './index.css';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';
import ConditionEditor from './conditionEditor';

import {
  addSchemaInfoLineItem,
  removeSchemaInfoLineItem,
  updateSchemaLineItems,
  updateSchemaInfoLineItem
} from '../../../../state/actions';

import { removeFromList, replaceInList } from '../../../../state/reducers/list-manipulation';
import Placeholder from '../../../placeholder';
import CurrencyInput from '../../../currency-input';
import LineItemSearch from '../../../lineItems/search';
import metaInfoSchema from './../metainfo-schema';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
class LineItemSelector extends Component {
  state = {
    showConditionModal: false,
    showLineItemModal: false,
    editingLineItem: null
  };

  openLineItemModal = () => {
    this.setState({ showLineItemModal: true });
  };

  closeLineItemModal = () => {
    this.setState({ showLineItemModal: false });
  };

  removeLineItem = (lineItem, indexToRemove) => () => {
    const { dispatch, editSchemaInfo } = this.props;
    const lineItemsCopy = [...editSchemaInfo.lineItems];
    lineItemsCopy.splice(indexToRemove, 1);
    dispatch(removeSchemaInfoLineItem(lineItemsCopy));
  };

  addLineItem = (lineItem) => {
    const { dispatch } = this.props;
    dispatch(addSchemaInfoLineItem(lineItem));
    this.closeLineItemModal();
  };

  emptyCta = () => {
    return (
      <Placeholder
        icon="list ol outline"
        header="No Line Items for this Schema (Yet)"
        subheader="This schema will probably need LineItems, please add them here."
      />
    );
  };

  renderSelectLineItemModal = () => {
    return (
      <Modal size={'large'} open={this.state.showLineItemModal} onClose={this.closeLineItemModal}>
        <Modal.Header>Select a line item</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <LineItemSearch loadHandler={this.addLineItem} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeLineItemModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  removeConditionAll = (lineItem, index, allIndex) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any[index].all = removeFromList(
      lineItem.conditions.any[index].all,
      allIndex
    );
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  updateConditionAll = (lineItem, index, all, property) => (e, { value }) => {
    const { dispatch } = this.props;
    const newItem = { ...all, [property]: value };
    lineItem.conditions.any[index].all = replaceInList(
      lineItem.conditions.any[index].all,
      newItem,
      all
    );
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  addConditionalAll = (lineItem, index) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any[index].all.push({
      field: '',
      operator: '',
      value: ''
    });
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  removeConditionAny = (lineItem, index) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any = removeFromList(lineItem.conditions.any, index);
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  addConditionalAny = (lineItem) => () => {
    const { dispatch } = this.props;

    lineItem.conditions.any.push({ all: [] });
    dispatch(updateSchemaInfoLineItem(lineItem));
  };

  renderLineItemConditionModal = () => {
    return (
      <Modal
        size={'large'}
        open={this.state.showConditionModal}
        onClose={() => this.setState({ showConditionModal: false, editingLineItem: null })}
      >
        <Modal.Header>Select a line item</Modal.Header>
        <ConditionEditor
          lineItem={this.state.editingLineItem}
          removeConditionAll={this.removeConditionAll}
          updateConditionAll={this.updateConditionAll}
          addConditionalAll={this.addConditionalAll}
          removeConditionAny={this.removeConditionAny}
          addConditionalAny={this.addConditionalAny}
        />
        <Modal.Actions>
          <Button
            basic
            onClick={() =>
              this.setState({
                showConditionModal: false,
                editingLineItem: null
              })
            }
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  addLineItemsCta = () => {
    return (
      <Segment attached="bottom">
        <Button icon labelPosition="left" primary size="small" onClick={this.openLineItemModal}>
          <Icon name="add" /> Add LineItem
        </Button>
      </Segment>
    );
  };

  renderLineItemLabels = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={7}>Name</Table.HeaderCell>
          <Table.HeaderCell width={2}>Category</Table.HeaderCell>
          <Table.HeaderCell width={3}>Price</Table.HeaderCell>
          <Table.HeaderCell width={3}>Looker Fee</Table.HeaderCell>
          <Table.HeaderCell width={1}>Required</Table.HeaderCell>
          <Table.HeaderCell width={1}>Base</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );
  };

  renderLineItem = (lineItem, i) => {
    const { lineItems } = this.props.editSchemaInfo;
    const isSubform = false;
    const onRemove = this.removeLineItem;

    let subform = isSubform ? 'subform' : null;

    return (
      <Table.Row key={`schema-lineItem-${lineItem.lineItemId}-${i}`} className="lineItem-item">
        <Table.Cell className={`line-item ${subform}`}>
          {lineItem.lineItem ? lineItem.lineItem.name : lineItem.name}
        </Table.Cell>
        <Table.Cell collapsing>
          {(lineItem.lineItem && lineItem.lineItem.category) || lineItem.category}
        </Table.Cell>
        <Table.Cell>
          <CurrencyInput width={12} name={`lineItems[${i}].price`} />
        </Table.Cell>
        <Table.Cell>
          <CurrencyInput width={12} name={`lineItems[${i}].lookerFee`} />
        </Table.Cell>
        <Table.Cell collapsing>
          <Form.Checkbox name={`lineItems[${i}].required`} />
        </Table.Cell>
        <Table.Cell collapsing>
          <Form.Checkbox
            name={`lineItems[${i}].base`}
            onChange={() => {
              lineItems.forEach((x) => (x.base = false));
            }}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Button
            icon="check circle outline"
            basic
            onClick={() => {
              this.setState({
                editingLineItem: lineItem,
                showConditionModal: true
              });
            }}
            title="Edit line item conditions"
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <Button
            icon="trash"
            basic
            onClick={onRemove(lineItem, i)}
            title="Remove This Line Item"
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  renderLineItemList = () => {
    const { lineItems } = this.props.editSchemaInfo;
    const isSubform = false;

    const subform = isSubform ? 'subform' : '';
    return (
      <Table basic="very" className={`line-items ${subform}`}>
        {this.renderLineItemLabels()}

        <Table.Body>{_.map(lineItems, this.renderLineItem.bind(this))}</Table.Body>
      </Table>
    );
  };

  renderLineItemSegment = () => {
    const { lineItems } = this.props.editSchemaInfo;

    const isSubform = false;
    const subform = isSubform ? 'subform' : '';
    const styleprops = isSubform
      ? { basic: true, className: `${subform} line-item-list` }
      : { className: `${subform} line-item-list` };

    return (
      <Segment.Group>
        <Segment {...styleprops}>
          {lineItems.length ? this.renderLineItemList() : this.emptyCta()}
        </Segment>
        {this.addLineItemsCta()}
      </Segment.Group>
    );
  };

  render() {
    const { dispatch } = this.props;
    const { lineItems } = this.props.editSchemaInfo;

    return (
      <div>
        <Header size="medium">LineItems</Header>
        <ValidatedForm
          whitelist={[CurrencyInput]}
          ignoreChildrenList={[CurrencyInput]}
          intialValue={{ lineItems }}
          value={{ lineItems }}
          validateSchema={{ lineItems: metaInfoSchema.lineItems }}
          onChange={(event, { field, form, submitting }, err) => {
            dispatch(updateSchemaLineItems(form.lineItems));
          }}
        >
          {this.renderLineItemSegment()}
        </ValidatedForm>
        {this.renderSelectLineItemModal()}
        {this.renderLineItemConditionModal()}
      </div>
    );
  }
}

export default LineItemSelector;
