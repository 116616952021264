import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Icon, Segment, Form, Header, List, Button } from 'semantic-ui-react';

import LineItemSearch from '../../lineItems/search';
import { addDefaultLineItem, removeDefaultLineItem } from '../../../state/actions';

function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
class LineItemList extends Component {
  state = { showLineItemModal: false };

  openLineItemModal = () => {
    this.setState({ showLineItemModal: true });
  };

  closeLineItemModal = () => {
    this.setState({ showLineItemModal: false });
  };

  addLineItem = (lineItem) => {
    const { dispatch } = this.props;
    dispatch(addDefaultLineItem(lineItem));
    this.closeLineItemModal();
  };

  removeLineItem = (lineItem) => () => {
    const { dispatch } = this.props;
    dispatch(removeDefaultLineItem(lineItem));
  };

  addAnotherCta = () => {
    return (
      <Button basic onClick={this.openLineItemModal}>
        <Icon name="plus" />
        Add another LineItem
      </Button>
    );
  };

  emptyCta = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Header as="h2" icon textAlign="center" className="empty-message" style={{ padding: 10 }}>
          <Icon name="add to cart" />
          No Default Line Items (Yet)
        </Header>
        <br />
        <div>
          <Button basic onClick={this.openLineItemModal}>
            <Icon name="plus" /> Add an Line Item
          </Button>
        </div>
      </div>
    );
  };

  renderLineItem = (lineItem, i) => {
    const onRemove = this.removeLineItem;

    return (
      <List.Item key={`schema-lineItem-${lineItem._id}`} className="lineItem-item">
        <Form.Group className={`line-item`}>
          <Form.Field width={4}>
            {<label>Name</label>}
            <Header>{lineItem.lineItem ? lineItem.lineItem.name : lineItem.name}</Header>
          </Form.Field>

          <Form.Field width={5}>
            {<label>Category</label>}
            <Header>
              {(lineItem.lineItem && lineItem.lineItem.category) || lineItem.category}
            </Header>
          </Form.Field>

          <Form.Field width={1}>
            <Button.Group basic fluid style={{ marginTop: 18 }}>
              <Button
                icon="trash"
                basic
                onClick={onRemove(lineItem)}
                title="Remove This Line Item"
              />
            </Button.Group>
          </Form.Field>
        </Form.Group>
      </List.Item>
    );
  };

  renderLineItemList = () => {
    const { lineItems } = this.props;
    return <List className={`line-items`}>{lineItems.map(this.renderLineItem)}</List>;
  };

  renderSelectLineItemModal = () => {
    const excludeIds = this.props.lineItems.map((x) => x._id);
    return (
      <Modal size={'large'} open={this.state.showLineItemModal} onClose={this.closeLineItemModal}>
        <Modal.Header>Select a line item</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <LineItemSearch loadHandler={this.addLineItem} exclude={excludeIds} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeLineItemModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { lineItems } = this.props;

    return (
      <div style={{ padding: 8 }}>
        <Header size="medium">LineItems</Header>

        <Form className="settings-data-seeder">
          <Segment>
            {lineItems.length ? this.renderLineItemList() : this.emptyCta()}
            {lineItems.length ? this.addAnotherCta() : null}
          </Segment>
        </Form>
        {this.renderSelectLineItemModal()}
      </div>
    );
  }
}

export default LineItemList;
