import createReducer from './create-reducer';

const baseTooltip = {
  tooltipId: '',
  body: '',
  text: '',
  label: '',
  path: '',
  tags: []
};

const initial = null;

const reducers = {
  CLEAR_TOOLIP: (state, value) => {
    return { ...baseTooltip };
  },
  LOAD_TOOLTIP: (state, value) => {
    return value;
  },
  UPDATE_TOOLTIP: (state, value) => {
    return { ...state, ...value };
  }
};

export default createReducer(initial, reducers);
