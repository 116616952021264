import _ from 'lodash';
import randomColor from 'randomcolor';
import React, { Component } from 'react';
import * as moment from 'moment';
import { Card, Icon, Image, Label, Popup } from 'semantic-ui-react';
import { SearchActionMenuSection } from '../../search/actionSection';
import departments from '../../../api/data/departments';

export class UserCard extends Component {
  render() {
    const { who, result, model, actions } = this.props;
    const userRoleTrigger = <Icon name="lock" className="user-role-popover" />;

    const {
      email,
      firstName,
      lastName,
      _id,
      role,
      avatarUrl,
      department,
      phone,
      last_login,
      schemaCount,
      publishedSchemaCount
    } = result;

    const cardDropDown = () => {
      if (!model) {
        return null;
      }
      return <SearchActionMenuSection item={result} model={model.actionSection(actions)} />;
    };

    const emailLink = () => {
      const emailTo = `mailto:${email}`;
      return (
        <a href={emailTo} target="_blank" rel="noopener noreferrer">
          <Icon name="mail square" />
          &nbsp;
          {email}
        </a>
      );
    };

    const phoneLink = () => {
      const tel = `tel:${phone}`;
      return (
        <a href={tel} target="_blank" rel="noopener noreferrer">
          <Icon name="phone square" />
          {phone}
        </a>
      );
    };

    const showRole =
      who.role === 'admin' ? (
        <Popup trigger={userRoleTrigger} flowing hoverable>
          {this.changePermissionContents(role, result)}
        </Popup>
      ) : null;

    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    const initials = `${firstInitial}${lastInitial}`;
    const cardImgClass = avatarUrl ? 'card-img' : 'card-img no-avatar';
    const cardImgStyle = {
      backgroundColor: randomColor({ luminosity: 'light', hue: 'blue' }),
      height: 100,
      width: 100,
      content: `${firstInitial}${lastInitial}`
    };

    const cardImg = avatarUrl ? <Image src={avatarUrl} /> : '';

    const _department = _.find(departments, { id: department });
    const departmentLabel = _department ? _department.label : '';

    const authoredSchemas = (
      <Label color="olive" size="mini" content={`${publishedSchemaCount} Published`} />
    );
    const editedSchemas = <Label color="purple" size="mini" content={`${schemaCount} Modified`} />;

    return (
      <Card key={`user-${_id}`} className="identity-card">
        <div className="user-card-left">
          <div className={cardImgClass} style={cardImgStyle} data-initials={initials}>
            {cardImg}
          </div>
          <span className="user-last-login">{`Last Login: ${moment(last_login).fromNow()}`}</span>
        </div>
        <Card.Content>
          <Card.Header>
            {`${firstName} ${lastName}`}
            {cardDropDown()}
          </Card.Header>
          <Card.Meta>{departmentLabel}</Card.Meta>
          <Card.Meta>{emailLink()}</Card.Meta>
          <Card.Meta>{phoneLink()}</Card.Meta>

          <Card.Description>
            <div className="user-card-associations">
              <div className="user-card-schemas">
                <label>SCHEMAS:</label>
                {authoredSchemas}
                {editedSchemas}
              </div>
            </div>
          </Card.Description>
          {showRole}
        </Card.Content>
      </Card>
    );
  }
}

export class UserCards extends Component {
  render() {
    const { items, who, actions, model } = this.props;

    console.log('model', model);

    const resultItemsHtml = _.map(items, (item, index) => {
      return (
        <UserCard
          key={`user-card-${item._id}`}
          result={item}
          who={who}
          index={index}
          model={model}
          actions={actions}
        />
      );
    });

    return <div className="search-results identity-cards">{resultItemsHtml}</div>;
  }
}

export default UserCards;
