import React, { Component } from 'react';

import SchemaPermissionCard from './schema-card';

class SchemaCards extends Component {
  constructor(props) {
    super(props);
  }
  schemaToCardHTML = (schema, index) => {
    const { actions, model, publishedVersions } = this.props;

    return (
      <SchemaPermissionCard
        key={`card-${schema._id}`}
        actions={actions}
        schema={schema}
        index={index}
        model={model}
        publishedVersions={publishedVersions}
        schemaPermissions={this.props.schemaPermissions}
      />
    );
  };

  render() {
    const { items } = this.props;
    const content = items.map(this.schemaToCardHTML);

    return <div className="search-results">{content}</div>;
  }
}

export default SchemaCards;
