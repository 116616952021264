import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Form, Header, Segment, Button, Icon } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';
import Dropzone from 'react-dropzone';

import { loadTooltip, updateTooltip, clearTooltip } from '../../../state/actions';

import GetToolTipByIdQuery from '../../../graphql/queries/getToolTipById.graphql';
import TooltipViewActions from './tooltip-view-actions';
import TooltipPreview from './tooltip-preview';
import TooltipSchema from './tooltipSchema';
import config from '../../../config';

const BUCKET = config.REACT_APP_S3_BUCKET_FOLDER;

function stateToComponent(state) {
  return {
    who: state.who,
    tooltip: state.tooltipView
  };
}

@connect(stateToComponent)
@graphql(GetToolTipByIdQuery, {
  name: 'tooltipData',
  skip: (props) => props.match.params.id === 'new',
  options: (props) => {
    const id = props.tooltipId || props.match.params.id;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
class TooltipView extends Component {
  tooltipFormUpdated = _.debounce((event, { field, form, submitting }, err) => {
    const { dispatch } = this.props;
    dispatch(updateTooltip(form));
  }, 1000);

  renderDropzoneContents = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    const { tooltip } = this.props;

    let style = {
      maxHeight: 200,
      maxWidth: 200
    };

    return (
      <div>
        {tooltip && tooltip.imageUrl ? (
          <img style={style} src={tooltip.imageSignedUrl} />
        ) : (
          <Icon name="building" />
        )}
      </div>
    );
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { dispatch, tooltip } = this.props;

    if (acceptedFiles && acceptedFiles.length) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        tooltip.imageSignedUrl = reader.result;
        dispatch(
          updateTooltip({
            imageUrl: reader.result,
            imageUpload: acceptedFiles[0]
          })
        );
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  renderSchemaList() {
    const { tooltipData } = this.props;
    let schemaData;
    if (!tooltipData) {
      schemaData = [];
    } else {
      schemaData = tooltipData.schemaMany;
    }
    const schemaList = schemaData
      ? schemaData.map((x) => {
          return <div>{x.info.schemaName}</div>;
        })
      : null;

    return schemaList ? (
      <Segment>
        <Header>Associated Schemas:</Header>
        {schemaList}
      </Segment>
    ) : null;
  }

  renderTooltipForm(tooltip) {
    return (
      <Segment basic>
        <TooltipPreview tooltip={tooltip} />
        <ValidatedForm
          intialValue={tooltip}
          value={tooltip}
          readOnly={false}
          validateSchema={TooltipSchema}
          onChange={this.tooltipFormUpdated}
        >
          <Segment>
            <Dropzone
              ref={(ref) => {
                this._dropzone = ref;
              }}
              accept="image/jpeg, image/png"
              style={{
                position: 'relative',
                width: 200,
                height: 200,
                borderRadius: 5,
                borderWidth: 2,
                borderStyle: 'dotted',
                borderColor: '#eeeeee'
              }}
              acceptStyle={{
                borderStyle: 'solid',
                borderColor: '#21ba45'
              }}
              rejectStyle={{
                borderStyle: 'solid',
                borderColor: '#db2828'
              }}
              multiple={false}
              onDrop={this.onDrop}
            >
              {this.renderDropzoneContents}
            </Dropzone>
            <Button
              style={{ width: 200, marginTop: '.8em' }}
              onClick={() => {
                this._dropzone.open();
              }}
            >
              Upload a Photo
            </Button>

            <Form.Input fluid label="Id" disabled name="tooltipId" />
            <Form.Input fluid label="Label" name="label" />
            <Form.TextArea label="Body" name="text" />
            {this.renderTags(tooltip)}
          </Segment>
        </ValidatedForm>
        {this.renderSchemaList()}
      </Segment>
    );
  }
  renderActions = () => {
    const { tooltip, tooltipData } = this.props;

    if ((tooltipData && tooltipData.loading) || !tooltip) {
      return null;
    }
    return <TooltipViewActions tooltip={tooltip} />;
  };

  state = { pageId: '' };

  componentDidUpdate() {
    const { tooltip, tooltipData, dispatch } = this.props;
    if (!tooltip && tooltipData && !tooltipData.loading) {
      this.props.dispatch(loadTooltip(tooltipData.tooltipById));
    } else if (this.state.pageId === 'new' && tooltip.path.length == 0) {
      const currentTime = Date.now();
      tooltip.path = `${BUCKET}/new/tooltip${currentTime}.html`;
      tooltip.tooltipId = `${BUCKET.replace('-', '_')}_new_tooltip${currentTime}`;
      dispatch(updateTooltip(tooltip));
    }
  }

  componentDidMount() {
    let { dispatch } = this.props;
    const { id } = this.props.match.params;
    this.setState({ pageId: id });

    if (id === 'new') {
      dispatch(clearTooltip());
    }
  }

  renderTags = (tooltip) => {
    if (tooltip) {
      const { tags } = tooltip;

      let tagOptions =
        tags && tags.length > 0
          ? tags.map((i) => {
              return { key: i, value: i, text: i };
            })
          : [];

      return (
        <Form.Select
          options={tagOptions}
          label={`Tags`}
          placeholder="Additional, exterior, damage, staging"
          search
          selection
          multiple
          allowAdditions={true}
          name="tags"
        />
      );
    } else {
      return null;
    }
  };

  render() {
    let { tooltip, tooltipData } = this.props;
    const { id } = this.props.match.params;
    if ((tooltipData && tooltipData.loading) || (!tooltip && id !== 'new')) {
      return <div> Loading </div>;
    }

    if (tooltip.imageUrl && tooltipData && tooltipData.tooltipById.imageSignedUrl) {
      tooltip.imageSignedUrl =
        tooltip.imageUrl === tooltip.imageSignedUrl
          ? tooltip.imageSignedUrl
          : tooltipData.tooltipById.imageSignedUrl;
    }

    return (
      <div className="search-workspace">
        {this.renderTooltipForm(tooltip)}
        {this.renderActions()}
      </div>
    );
  }
}
export default TooltipView;
