import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Header, Icon, List } from 'semantic-ui-react';

import SeedType from '../../graphql/mutations/seed.graphql';

function stateToComponent(state) {
  return { who: state.who };
}

@graphql(
  gql`
    {
      uniqueFieldCount
      defaultsCount
      tooltipCount
      companyCount
      lineItemCount
      schemaCount
    }
  `,
  {
    name: 'entityCounts',
    options: (props) => ({
      fetchPolicy: 'cache-and-network'
    })
  }
)
@graphql(SeedType, {
  name: 'SeedMutation',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
@connect(stateToComponent)
class DataSeederSettings extends Component {
  state = { uploaded: 0 };

  renderHeader = () => {
    return (
      <Header as="h2">
        <Icon name="leaf" />
        <Header.Content>
          Data Seeder
          <Header.Subheader>
            Warning: this section is only for admins, and will seed data in the database
          </Header.Subheader>
        </Header.Content>
      </Header>
    );
  };

  seedTargets = () => {
    const { entityCounts, SeedMutation } = this.props;
    return [
      {
        id: 'company',
        icon: 'address card',
        label: 'Companies',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'Company' } });
        }
      },
      {
        id: 'tooltip',
        icon: 'idea',
        label: 'Tooltips',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'Tooltip' } });
        }
      },
      {
        id: 'lineItem',
        icon: 'address card',
        label: 'LineItems',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'LineItem' } });
        }
      },
      {
        id: 'uniqueField',
        icon: 'table',
        label: 'Unique Fields',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'UniqueField' } });
        }
      },
      {
        id: 'schema',
        icon: 'table',
        label: 'Schemas',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'Schema' } });
        }
      },
      {
        id: 'defaults',
        icon: 'table',
        label: 'Defaults',
        action: () => {
          entityCounts.startPolling(1000);
          return SeedMutation({ variables: { type: 'Defaults' } });
        }
      }
    ];
  };

  seedTargetToHtml = (seedTarget) => {
    const { entityCounts } = this.props;
    const { id, label, icon, action } = seedTarget;

    console.log('entityCounts', entityCounts);

    const onClick = () => {
      action();
    };

    return (
      <List.Item key={id}>
        <List.List>
          <List.Item onClick={onClick}>
            <List.Icon name={`${icon}`} size="huge" verticalAlign="middle" />
            <List.Content>
              <div style={{ minWidth: 400 }}>
                <List.Header>{`${label}`}</List.Header>
                <List.Description>
                  {`Current in DB: ${entityCounts[id + 'Count']}`}
                </List.Description>
              </div>
            </List.Content>
          </List.Item>
        </List.List>
      </List.Item>
    );
  };

  render() {
    const { SeedMutation, entityCounts } = this.props;
    if (!SeedMutation.loading) {
      entityCounts.stopPolling();
    }
    if (entityCounts.loading) {
      return null;
    }

    console.log('entityCounts', entityCounts);
    const seedTargetHtml = _.map(this.seedTargets(), this.seedTargetToHtml);
    const content = (
      <div>
        <List divided relaxed selection>
          {seedTargetHtml}
        </List>
      </div>
    );
    return (
      <div className="settings-data-seeder">
        <div style={{ padding: 15 }}>{this.renderHeader()}</div>
        <div style={{ padding: 15 }}>{content}</div>
      </div>
    );
  }
}

export default DataSeederSettings;
