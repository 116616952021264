import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';

import CreateMap from '../../../graphql/mutations/createMap.graphql';
import UpdateMap from '../../../graphql/mutations/updateMap.graphql';
import PublishMap from '../../../graphql/mutations/publishMap.graphql';

import GetMapById from '../../../graphql/queries/getMapById.graphql';
import { clearMap, loadMap } from '../../../state/actions';
import SaveActions from '../../common/save-actions.js';
import EditDetailsContent from '../properties';

function stateToComponent(state) {
  return {
    who: state.who,
    map: state.mapEdit
  };
}

@connect(stateToComponent)
@graphql(GetMapById, {
  name: 'mapData',
  skip: (props) => {
    const urlId = props.match.params.id;
    const skip = (props.map && props.map._id) || urlId === 'new';
    return skip;
  },
  options: (props) => {
    const { dispatch } = props;
    const { id } = props.match.params;

    return {
      onCompleted: (data) => {
        dispatch(loadMap(data.mapById));
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id
      }
    };
  }
})
@graphql(CreateMap, {
  name: 'createMap',
  options: (props) => {
    return {
      errorPolicy: 'all'
    };
  }
})
@graphql(UpdateMap, {
  name: 'updateMap',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {
        _id: props.map._id,
        record: props.map
      }
    };
  }
})
@graphql(PublishMap, {
  name: 'publishMap',
  options: () => ({
    errorPolicy: 'all'
  })
})
class MapEdit extends Component {
  getEntityToPersist = (entity) => {
    return entity;
  };

  publishMap = async () => {
    const { publishMap } = this.props;
    const { id } = this.props.match.params;
    const data = await publishMap({ variables: { _id: id } });
    return data;
  };

  renderButtons = () => {
    const { map, createMap, updateMap } = this.props;
    const { id } = this.props.match.params;

    const actions = [
      {
        label: id === 'new' ? 'Save' : 'Update',
        icon: 'save',
        action: 'update'
      },
      {
        label: 'Clear',
        icon: 'certificate',
        action: 'new'
      }
    ];
    map.status = 'draft';

    return (
      <SaveActions
        actions={actions}
        entity={map}
        entityType={'map'}
        updateQuery={id === 'new' ? createMap : updateMap}
        loadAction={loadMap}
        clearAction={clearMap}
        getEntityToPersist={this.getEntityToPersist}
        publishAction={this.publishMap}
      />
    );
  };

  render() {
    const { map } = this.props;

    if (!map) {
      return <div> Loading </div>;
    }

    return (
      <div className="search-workspace uniqueField-search-workspace uniqueField-search-workspace-w-preview">
        <div className="unique-field-view">
          <EditDetailsContent field={map} />
        </div>
        {this.renderButtons()}
      </div>
    );
  }
}

export default MapEdit;
