import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/eclipse.css';

import './index.css';

import React, { Component } from 'react';
import CodeMirrorLib from 'codemirror';
import CodeMirror from 'react-codemirror';
import { connect } from 'react-redux';
import { omitDeep } from '../../../apollo/apollo-typename-cleaner';

function stateToComponent(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    editSchemaPreview: state.editSchemaPreview,
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
class JSONView extends Component {
  render() {
    const { editSchemaPreview, editSchemaSchema, editSchemaInfo } = this.props;

    const currentSchemaState = {
      ...editSchemaSchema,
      ...{ info: editSchemaInfo.info },
      ...{ companies: editSchemaInfo.companies }
    };

    const schemaJson = JSON.stringify(omitDeep(currentSchemaState, '__typename'), null, 2);
    const outputJson = JSON.stringify(omitDeep(editSchemaPreview, '__typename'), null, 2);

    window.CodeMirror = CodeMirrorLib;

    let foldFunc = CodeMirrorLib.newFoldFunction(CodeMirrorLib.braceRangeFinder);

    const options = {
      lineNumbers: true,
      readOnly: true,
      mode: { name: 'javascript', json: true },
      foldGutter: true,
      lineWrapping: true,
      theme: 'eclipse',
      extraKeys: {
        'Ctrl-Q': function(cm) {
          foldFunc(cm, cm.getCursor().line);
        }
      },
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter']
    };

    return (
      <div className="editor-json-view">
        <div className="form-schema-json json-view-frame">
          <CodeMirror value={schemaJson} options={options} />
        </div>
        <div className="editor-json-view-divider" />
        <div className="form-output-json json-view-frame">
          <CodeMirror value={outputJson} options={options} />
        </div>
      </div>
    );
  }
}
export default JSONView;
