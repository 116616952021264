import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import LineItemList from './defaults/lineItemList.js';
import FieldList from './defaults/fieldList.js';
import { Icon, Button } from 'semantic-ui-react';

import {
  loadDefaults,
  setSchemaDefaultLineItems,
  setSchemaDefaultFields
} from '../../state/actions';
import GetDefaultsQuery from '../../graphql/queries/getDefaults.graphql';
import UpdateDefaults from '../../graphql/mutations/updateDefaults.graphql';

function stateToComponent(state) {
  return {
    who: state.who,
    defaults: state.defaults
  };
}

@connect(stateToComponent)
@graphql(GetDefaultsQuery, {
  name: 'getDefaults',
  skip: (props) => {
    return props.defaults && props.defaults._id;
  },
  options: (props) => {
    const { dispatch } = props;

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      onCompleted: (data) => {
        dispatch(loadDefaults(data.defaultsOne));
      }
    };
  }
})
@graphql(UpdateDefaults, {
  name: 'updateDefaults',
  options: (props) => {
    return {
      errorPolicy: 'all'
    };
  }
})
class SiteSettings extends Component {
  update = () => {
    const { dispatch, defaults, updateDefaults } = this.props;

    const variables = {
      record: {
        _id: defaults._id,
        lineItemIds: defaults.lineItems.map((x) => x._id),
        fieldIds: defaults.fields.map((x) => x._id)
      }
    };
    updateDefaults({ variables }).then((response) => {
      dispatch(loadDefaults(response.data.defaultsUpdateById.record));
      dispatch(setSchemaDefaultLineItems(defaults.lineItems));
      dispatch(setSchemaDefaultFields(defaults.fields));
    });
  };

  render() {
    const { defaults } = this.props;

    if (!defaults._id) {
      return null;
    }

    return (
      <div className="settings-profile">
        <LineItemList lineItems={defaults.lineItems} />
        <FieldList fields={defaults.fields} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
            position: 'fixed',
            bottom: 8,
            right: 16
          }}
        >
          <Button.Group>
            <Button size="big" primary onClick={this.update}>
              <Icon name={`save`} />
              {'Update'}
            </Button>
          </Button.Group>
        </div>
      </div>
    );
  }
}

export default SiteSettings;
