import _ from 'lodash';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

const LENGTHY_VALUE_THRESHOLD = 100;

export default class EditablePropertyField extends Component {
  state = {
    search: ''
  };

  toEditableProperty = ({
    keyPrefix,
    arrayPrefix,
    dispatchAction,
    dispatch,
    field,
    property,
    index
  }) => {
    let inputType = 'text';
    const isOnlyLabel = !property.value && !property.name;

    if (isOnlyLabel) {
      return (
        <Form.Field>
          <p className="edit-field-details-label">{property.label}</p>
        </Form.Field>
      );
    }

    let _value = _.has(property, 'value') ? property.value : field[property.name];

    let _title = property.title || '';

    const isNumber = _.isNumber(_value);
    const isBoolean = _.isBoolean(_value);

    const isList = property.options && _.isArray(property.options);
    const isObject = _.isObject(_value) && !isList;

    if (isObject) {
      console.log('THIS IS AN OBJECT HERE', _value);
    }

    const isLengthy = _value && _value.toString().length > LENGTHY_VALUE_THRESHOLD;
    const transitioningType = _value && _value.toString().length == LENGTHY_VALUE_THRESHOLD - 1;

    if (isNumber) {
      inputType = 'number';
    }

    let label = property.label || property.name;

    const name = arrayPrefix ? `${arrayPrefix}[${index}].value` : property.name;

    if (!isBoolean && !isList && isLengthy) {
      return (
        <Form.TextArea
          key={`${keyPrefix}_${name}`}
          id={`${keyPrefix}_${name}`}
          autoFocus={!!transitioningType}
          fluid
          label={label}
          name={name}
          readOnly={property.readOnly}
          title={_title}
          type={inputType}
          value={this.state.search.length > 0 ? this.state.search : _value}
          onChange={(event, data) => {
            const newValue = isNumber ? _.toNumber(data.value) : data.value;
            this.setState({ search: newValue });
            dispatch(
              dispatchAction({
                property: property,
                newValue: newValue.trim(),
                field: field
              })
            );
          }}
        />
      );
    }

    if (!isBoolean && !isList) {
      return (
        <Form.Input
          id={`${keyPrefix}_${name}`}
          key={`${keyPrefix}_${name}`}
          autoFocus={!!transitioningType}
          fluid
          label={label}
          name={name}
          readOnly={property.readOnly}
          title={_title}
          type={inputType}
          value={this.state.search.length > 0 ? this.state.search : _value}
          onChange={(event, data) => {
            const newValue = isNumber ? _.toNumber(data.value) : data.value;
            this.setState({ search: newValue });
            dispatch(
              dispatchAction({
                property: property,
                newValue: newValue.trim(),
                field: field
              })
            );
          }}
        />
      );
    }

    if (isBoolean) {
      return (
        <Form.Checkbox
          id={`${keyPrefix}_${name}`}
          key={`${keyPrefix}_${name}`}
          checked={_value}
          label={label}
          name={name}
          readOnly={property.readOnly}
          title={_title}
          onChange={(event, data) => {
            const newValue = !!data.checked;
            dispatch(
              dispatchAction({
                property: property,
                newValue: newValue,
                field: field
              })
            );
          }}
        />
      );
    }

    if (isList) {
      return (
        <Form.Select
          id={`${keyPrefix}_${name}`}
          key={`${keyPrefix}_${name}`}
          fluid
          label={label}
          name={name}
          options={property.options}
          readOnly={property.readOnly}
          title={_title}
          value={_value}
          onChange={(event, data) => {
            const newValue = data.value;
            dispatch(
              dispatchAction({
                property: property,
                newValue: newValue,
                field: field
              })
            );
          }}
        />
      );
    }
  };

  render() {
    const { keyPrefix, arrayPrefix, dispatchAction, dispatch, field, property, index } = this.props;

    return this.toEditableProperty({
      keyPrefix,
      arrayPrefix,
      dispatchAction,
      dispatch,
      field,
      property,
      index
    });
  }
}
