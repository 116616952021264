import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import ContextEditor from './context-editor';
import GroupsEditor from './groups-editor/index.js';
import InfoEditor from './info-editor';
import MetadataEditor from './metadata-editor/index.js';
import OptionsEditor from './options-editor';
import PropertiesEditor from './properties-editor';
import ReportEditor from './report-editor';
import TooltipsEditor from './tooltips-editor';
import ValidationsEditor from './validations-editor';

import { BaseElements as baseFields } from '@wegolook/schema-form-renderer';

@withRouter
class EditFieldDetails extends Component {
  layoutPorportions = { tabWidth: 16, paneWidth: 16 };
  menuConfiguration = {
    secondary: true,
    pointing: true,
    vertical: false,
    className: 'eight item'
  };

  toPane = (component, styles) => {
    return (
      <Tab.Pane className={'edit-field-details-content-pane'} style={styles || {}}>
        {component}
      </Tab.Pane>
    );
  };

  panels = () => {
    const { field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    let _keys = _.keys(baseFields.find((f) => f.component === field.component));

    let panes = [
      {
        menuItem: { key: 'info', icon: 'info circle', content: 'Info' },
        render: () => this.toPane(<InfoEditor field={field} />)
      }
    ];

    if (isSchemaEditor) {
      panes.push({
        menuItem: {
          key: 'groups',
          icon: 'object group',
          content: 'Groups'
        },
        render: () => this.toPane(<GroupsEditor field={field} />)
      });
    }

    if (_.includes(_keys, 'properties')) {
      panes.push({
        menuItem: {
          key: 'properties',
          icon: 'settings',
          content: 'Properties'
        },
        render: () => this.toPane(<PropertiesEditor field={field} />)
      });
    }

    if (_.includes(_keys, 'validations') && field.validations.length > 0) {
      panes.push({
        menuItem: {
          key: 'validations',
          icon: 'check circle outline',
          content: 'Validations'
        },
        render: () => this.toPane(<ValidationsEditor field={field} />)
      });
    }

    if (_.includes(_keys, 'options')) {
      panes.push({
        menuItem: { key: 'options', icon: 'list', content: 'Options' },
        render: () => this.toPane(<OptionsEditor field={field} />)
      });
    }

    if (_.includes(_keys, 'metadata')) {
      panes.push({
        menuItem: { key: 'metadata', icon: 'list', content: 'Metadata' },
        render: () => this.toPane(<MetadataEditor field={field} />)
      });
    }

    panes.push({
      menuItem: { key: 'tooltips', icon: 'lightbulb', content: 'Tooltips' },
      render: () =>
        this.toPane(<TooltipsEditor field={field} />, {
          padding: 0
        })
    });

    panes.push({
      menuItem: { key: 'contexts', icon: 'world', content: 'Contexts' },
      render: () => this.toPane(<ContextEditor field={field} />)
    });

    panes.push({
      menuItem: { key: 'reports', icon: 'bar chart', content: 'Reports' },
      render: () => this.toPane(<ReportEditor field={field} />)
    });

    return (
      <Tab
        className={'edit-field-details-content'}
        menu={this.menuConfiguration}
        panes={panes}
        grid={this.layoutPorportions}
      />
    );
  };

  render() {
    return this.panels();
  }
}

export default EditFieldDetails;
