import createReducer from './create-reducer';

const initial = null;

const reducers = {
  LOAD_LINEITEM: (state, value) => {
    return value;
  },
  UPDATE_LINEITEM: (state, value) => {
    return { ...state, ...value };
  }
};

export default createReducer(initial, reducers);
