import createReducer from './create-reducer';

const initial = {
  page: 1,
  perPageCard: 10,
  perPageTable: 20,
  layout: 'card-layout', //todo make this come form layout strategy ?
  search: '',
  clientFacets: [],
  tagsFacets: [],
  schemaGroupFacets: [],
  subSchemaFacets: [],
  statusFacets: [],
  pagerInfo: {},
  sort: null
};

const reducers = {
  UPDATE_SCHEMAS_LAYOUT: (state, value) => {
    return {
      ...state,
      ...{
        pagerInfo: value.pagerInfo,
        layout: value.layout
      }
    };
  },
  UPDATE_SCHEMAS_PAGE: (state, value) => {
    return { ...state, ...{ page: value.page, pagerInfo: value.pagerInfo } };
  },
  UPDATE_SCHEMAS_PERPAGE: (state, value) => {
    return { ...state, ...{ perPage: value } };
  },
  UPDATE_SCHEMAS_SEARCH_TERM: (state, value) => {
    return { ...state, ...{ search: value, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_SCHEMA_GROUP_FACETS: (state, value) => {
    return { ...state, ...{ schemaGroupFacets: value, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_STATUS_FACETS: (state, value) => {
    const newValue = value ? [value] : [];
    return { ...state, ...{ statusFacets: newValue, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_TAGS_FACETS: (state, value) => {
    return { ...state, ...{ tagsFacets: value, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_SUB_SCHEMA_FACETS: (state, value) => {
    return { ...state, ...{ subSchemaFacets: value, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_CLIENT_FACETS: (state, value) => {
    return { ...state, ...{ clientFacets: value, page: 1 } };
  },
  UPDATE_SCHEMAS_SEARCH_SORT: (state, value) => {
    return { ...state, ...{ sort: value, page: 1 } };
  }
};

export default createReducer(initial, reducers);
