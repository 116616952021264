import _ from 'lodash';
import React, { Component } from 'react';
import { Image, Table } from 'semantic-ui-react';

export class SearchRow extends Component {
  render() {
    const { result, columns, index, actions } = this.props;

    const cells = _.map(columns, (column, columnIndex) => {
      let content = _.get(result, column.property, '');
      let collapsing = false;
      let cellClass = '';
      if (_.isFunction(column.property)) {
        content = column.property(result);
      }

      if (column.type === 'image') {
        let value = content;
        let src = value || `https://randomuser.me/api/portraits/lego/${index}.jpg`;

        if (!value && !column.useDefault) {
          content = <div />;
        } else {
          content = <Image size="mini" src={src} />;
        }
      }

      if (column.type === 'date') {
        content = new Date(content).toLocaleDateString('en-us');
      }

      if (column.type === 'component') {
        const model = typeof column.model === 'function' ? column.model(actions) : column.model;
        collapsing = true;
        cellClass = 'actions';
        content = <column.component model={model} item={result} />;
      }

      const _key = `table-cell-${result._id}-${column.label || column.property}-${Math.random() *
        5000}`;

      return (
        <Table.Cell key={_key} collapsing={collapsing} className={cellClass}>
          {content}
        </Table.Cell>
      );
    });

    return <Table.Row>{cells}</Table.Row>;
  }
}

class SearchTable extends Component {
  render() {
    const { items, model, actions } = this.props;
    const cols = model || [];

    const content = _.chain(items)
      .filter((x) => x)
      .map((item, index) => {
        return (
          <SearchRow
            key={`table-row-${item._id}-${Math.random() * 5000}`}
            result={item}
            columns={cols}
            index={index}
            actions={actions}
          />
        );
      })
      .value();

    const headers = _.map(cols, (col) => {
      const _key = `table-header-${col.property || col.label}`;
      return _.toLower(col.label) != 'actions' ? (
        <Table.HeaderCell key={_key}>{`${col.label}`}</Table.HeaderCell>
      ) : (
        <Table.HeaderCell key={_key} />
      );
    });

    return (
      <div className="search-results">
        <Table padded selectable>
          <Table.Header>
            <Table.Row>{headers}</Table.Row>
          </Table.Header>
          <Table.Body>{content}</Table.Body>
        </Table>
      </div>
    );
  }
}
export default SearchTable;
