import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SearchWorkspace from '../routes/workspace';
import UserSearch from './search';
import UserView from './view';

@withRouter
class UserWorkspace extends Component {
  rootUrl = '/user';

  workSpaces = [
    {
      id: 'search',
      component: UserSearch
    },
    {
      id: 'view',
      param: ':id?',
      component: UserView
    }
  ];
  render() {
    return <SearchWorkspace rootUrl={this.rootUrl} workSpaces={this.workSpaces} />;
  }
}
export default UserWorkspace;
