import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Workspace from '../routes/workspace';
import SchemaHistory from './history';
import SchemaSearch from './search';

@withRouter
class SchemaWorkspace extends Component {
  workSpaces = [
    {
      id: 'search',
      component: SchemaSearch
    },
    {
      id: 'history',
      param: ':id?',
      component: SchemaHistory
    }
  ];
  render() {
    const { match } = this.props;
    return <Workspace rootUrl={match.url} workSpaces={this.workSpaces} />;
  }
}
export default SchemaWorkspace;
