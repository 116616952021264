import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Form, Label } from 'semantic-ui-react';

import {
  addSchemaFieldOption,
  addUniqueFieldOption,
  editSchemaFieldOption,
  editUniqueFieldOption,
  moveSchemaFieldOption,
  moveUniqueFieldOption,
  removeSchemaFieldOption,
  removeUniqueFieldOption
} from '../../../../state/actions';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@withRouter
@connect(componentToState)
class OptionsEditor extends Component {
  noOptionsHtml = () => {
    return (
      <Form.Group>
        <Form.Field>
          <label>Add An Option</label>
          <Button.Group basic>
            <Button icon="add" onClick={this.addOptionAtIndex({})} />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  addOptionAtIndex = (option) => (event) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? addSchemaFieldOption : addUniqueFieldOption;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  removeOptionAtIndex = (option) => (event) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? removeSchemaFieldOption : removeUniqueFieldOption;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  increaseIndex = (option) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? moveSchemaFieldOption : moveUniqueFieldOption;

    dispatch(
      action({
        field: field,
        option: option,
        distance: 1
      })
    );
  };

  decreaseIndex = (option) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? moveSchemaFieldOption : moveUniqueFieldOption;
    dispatch(
      action({
        field: field,
        option: option,
        distance: -1
      })
    );
  };

  editOptionText = (option) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? editSchemaFieldOption : editUniqueFieldOption;
    const newValue = data.value;
    dispatch(
      action({
        field: field,
        prop: 'text',
        newValue: newValue,
        option: option
      })
    );
  };

  editOptionValue = (option) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');
    const action = isSchemaEditor ? editSchemaFieldOption : editUniqueFieldOption;
    const newValue = data.value;
    dispatch(
      action({
        field: field,
        prop: 'value',
        newValue: newValue,
        option: option
      })
    );
  };

  optionToEditHtml = (option, index) => {
    const { field } = this.props;
    const { text, value } = option;
    const key = `${field.id}-options-${option.id}`;

    console.log('field', field);

    const valueNotUnique =
      _.filter(field.options, (opt) => {
        return opt.value === option.value;
      }).length > 1;

    const textNotUnique =
      _.filter(field.options, (opt) => {
        return opt.text === option.text;
      }).length > 1;

    console.log('option', index, option);

    return (
      <Form.Group key={key}>
        <Form.Field width={1}>
          <label>&nbsp;</label>
          <Label circular size="big">
            {index}
          </Label>
        </Form.Field>
        <Form.Input
          label="Text"
          width={5}
          value={text}
          error={textNotUnique}
          onChange={(event, data) => {
            this.editOptionText(option)(event, data);
          }}
        />
        <Form.Input
          label="Value"
          width={5}
          value={value}
          error={valueNotUnique}
          onChange={(event, data) => {
            this.editOptionValue(option)(event, data);
          }}
        />
        <Form.Field width={2}>
          <label>Add/Remove</label>
          <Button.Group basic fluid>
            <Button
              icon="add"
              basic
              onClick={(event, data) => {
                this.addOptionAtIndex(option)(event, data);
              }}
              title="Add another option at this index"
            />

            <Button
              icon="trash"
              basic
              disabled={field.options.length < 2}
              onClick={(event, data) => {
                this.removeOptionAtIndex(option)(event, data);
              }}
              title="Remove this option"
            />
          </Button.Group>
        </Form.Field>
        <Form.Field width={2}>
          <label>Move</label>
          <Button.Group basic fluid>
            <Button
              icon="arrow up"
              onClick={(event, data) => {
                this.increaseIndex(option)(event, data);
              }}
              disabled={index === 0}
              title="Move this option to a higher position in the list"
            />
            <Button
              icon="arrow down"
              onClick={(event, data) => {
                this.decreaseIndex(option)(event, data);
              }}
              disabled={field.options.length === index + 1}
              title="Move this option to a lower position in the list"
            />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  render() {
    const { field } = this.props;

    console.log(field, field.options);

    const options =
      field.options.length > 0 ? _.map(field.options, this.optionToEditHtml) : this.noOptionsHtml();

    return (
      <div>
        <Form>{options}</Form>
      </div>
    );
  }
}
export default OptionsEditor;
