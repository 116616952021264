import Joi from 'joi-browser';

export default {
  firstName: Joi.string()
    .alphanum()
    .min(1)
    .max(50)
    .required()
    .label('First Name'),
  lastName: Joi.string()
    .alphanum()
    .min(1)
    .max(50)
    .required()
    .label('Last Name'),
  department: Joi.string()
    .required()
    .label('Department'),
  email: Joi.string()
    .email()
    .required()
    .label('Email'),
  bio: Joi.string()
    .max(2000)
    .allow('')
    .label('User Bio')
};
