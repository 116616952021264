import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';

class ControlPanel extends Component {
  renderMakeUnique = (field) => {
    const { makeUnique } = this.props;
    if (field.uniqueFieldId) {
      return null;
    }
    return (
      <Button icon onClick={makeUnique} title="Make this a unique field.">
        <Icon name="bullseye" />
      </Button>
    );
  };
  render() {
    const {
      onEdit,
      ascendInList,
      ascend25InList,
      descendInList,
      descend25InList,
      onTrash,
      first,
      last
    } = this.props;

    return (
      <div className="control-panel">
        <Button.Group basic className="control-panel-buttongroup" width={4}>
          <Button icon onClick={onEdit} title="Edit This Element">
            <Icon name="edit" />
          </Button>
          <Button icon onClick={onTrash} title="Remove Item From List">
            <Icon name="trash" />
          </Button>

          <Button
            icon
            disabled={first}
            onClick={ascendInList}
            title="Raise To Higher Position in List"
          >
            <Icon name="arrow up" />
          </Button>
          <Button icon disabled={last} onClick={descendInList} title="Lower Position in List">
            <Icon name="arrow down" />
          </Button>
          <Button icon disabled={first} onClick={ascend25InList} title="Raise To First">
            <Icon name="arrow up" />
          </Button>
          <Button icon disabled={last} onClick={descend25InList} title="Lower To Last">
            <Icon name="arrow down" />
          </Button>
        </Button.Group>
      </div>
    );
  }
}
export default ControlPanel;
