import Mapper from 'data-import-mapper';

export default function buildMapper(company, info) {
  const schemaMapConfig = {
    id: '_id',
    // TODO: figure out where these should come from.
    priority: () => 0,
    minimumClientVersion: () => 1,
    useChrome: () => false,
    attachPhotos: () => info.tags.includes('attach-photos'),
    skipQA:
      company && company.pipeline && company.pipeline.skipQA
        ? () => company.pipeline.skipQA
        : {
            map: (item) => item || false,
            from: 'info.pipeline.skipQA'
          },
    autoSolicit:
      company && company.pipeline && company.pipeline.autoSolicit
        ? () => company.pipeline.autoSolicit
        : {
            map: (item) => item || false,
            from: 'info.pipeline.autoSolicit'
          },
    skipVerification:
      company && company.pipeline && company.pipeline.skipVerification
        ? () => company.pipeline.skipVerification
        : {
            map: (item) => item || false,
            from: 'info.pipeline.skipVerification'
          },
    skipOrderPrep:
      company && company.pipeline && company.pipeline.skipPrep
        ? () => company.pipeline.skipPrep
        : {
            map: (item) => item || false,
            from: 'info.pipeline.skipPrep'
          },
    estimatedTime: 'info.estimatedTime',
    photoGuideURL: () => '',
    schemaGroup: 'info.schemaGroup',
    tooltip: {
      map: (item) => item || '',
      from: 'info.schemaDescription'
    },
    clientWarning: {
      map: (item) => item || '',
      from: 'info.clientWarning'
    },
    isConsumer: {
      map: (item) => item || false,
      from: 'info.isConsumer'
    },
    requiredEquipment: {
      map: (item) => ({ name: item }),
      from: 'info.requiredEquipment'
    },
    defaultKeywords: 'info.tags',

    // Actual maps
    name:
      company && company.name.length > 0
        ? () => company.name
        : company.company.name.length > 0
        ? () => company.company.name
        : {
            map: (item) => `${item.schemaGroup}-${item.subSchema}`,
            from: 'info'
          },
    type: {
      map: (v) => {
        const replaceSpacesAndSpacesWithDashesRegex = /\s[^\a-zA-z-]?|\s?\-\s?/g;
        return (
          v.schemaGroup.toLowerCase().replace(replaceSpacesAndSpacesWithDashesRegex, '-') +
          '-' +
          v.subSchema.toLowerCase().replace(replaceSpacesAndSpacesWithDashesRegex, '-')
        );
      },
      from: 'info'
    },
    schemaBuilderId: 'collectionId'
  };

  return new Mapper(schemaMapConfig);
}
