import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import CodeMirror from 'react-codemirror';
import { connect } from 'react-redux';
import getSchemaByIdAndVersion from '../../../graphql/queries/getSchemaByIdAndVersion.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}
@connect(stateToComponent)
@graphql(getSchemaByIdAndVersion, {
  name: 'formData',
  options: (props) => {
    const { id, version } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id,
        version
      }
    };
  }
})
class FormView extends Component {
  renderform(data) {
    const form = data.schemaByIdAndVersion || data;

    if (data.loading || !form) {
      return <div> Loading </div>;
    }
    const options = {
      lineNumbers: true,
      readOnly: true,
      mode: { name: 'javascript', json: true },
      lineWrapping: true,
      theme: 'eclipse'
    };

    return <CodeMirror value={JSON.stringify(form, null, 2)} options={options} />;
  }
  render() {
    let { form, formData } = this.props;

    return (
      <div className="search-workspace form-search-workspace">
        {this.renderform(form || formData)}
      </div>
    );
  }
}
export default FormView;
