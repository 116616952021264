import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Menu } from 'semantic-ui-react';

import SettingsActions from './settings-actions';
import SettingsRoutes from './settings-routes';
import settingsWorkspaces from './settings-workspaces';

function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
class Settings extends Component {
  state = {};
  workspaceToHtml = (view) => {
    const { id, label, icon } = view;
    const { history, location } = this.props;

    const chunks = location.pathname.split('/').filter((x) => {
      return x;
    });

    const fragment = chunks[chunks.length - 1];
    const _isActive = fragment.includes(id);
    return (
      <Menu.Item
        active={_isActive}
        key={`settingsview-item-${id}`}
        className="settings-canvas-view"
        onClick={(event) => {
          if (!_isActive) {
            history.push(`/settings/${id}`);
          }
        }}
      >
        <Icon name={`${icon}`} />
        {`${label}`}
      </Menu.Item>
    );
  };
  componentDidMount() {}

  render() {
    const menuItems = settingsWorkspaces.filter(() => {
      return true; //permisison filtering here.
    });
    const menuHtml = _.map(menuItems, this.workspaceToHtml);

    return (
      <div className="settings-workspace">
        <div className="settings-canvas-views">
          <Menu pointing secondary widths={menuItems.length}>
            {menuHtml}
          </Menu>
        </div>
        <SettingsRoutes />
        <SettingsActions />
      </div>
    );
  }
}
export default Settings;
