export const groupOnEverySchema = (group, schemas) => {
  return schemas.every((s) => {
    return s.fields.find((f) => (f.id && f.id === group.id) || f.name === group.name);
  });
};

export const fieldInGroupOnSchema = (field, group, schema) => {
  const findGroup = (f) => (f.id && f.id === group.id) || f.name === group.name;
  const schemaHasGroup = schema.fields.find(findGroup);

  if (!schemaHasGroup) {
    return false;
  }

  return schemaHasGroup.fields.find(
    (f) => (f && f.id && f.id === field.id) || f.name === field.name
  );
};

export const fieldIsOnOtherSchemasInSameGroup = (field, group, schemas) => {
  return schemas.every((s) => {
    return fieldInGroupOnSchema(field, group, s);
  });
};
