import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import createReducer from './create-reducer';
import {
  defaultDeep,
  moveInList,
  removeById,
  removeFromList,
  replaceInList,
  setPropInList
} from './list-manipulation';

const initial = {
  id: '',
  label: '',
  contexts: [],
  metadata: [],
  options: [],
  properties: [],
  reports: [],
  validations: []
};

const reducers = {
  CLEAR_UNIQUE_FIELD: (state, value) => {
    if (!value) {
      return initial;
    }
    const { baseField } = value;
    return Object.assign({}, baseField);
  },
  LOAD_UNIQUE_FIELD: (state, value) => {
    return _.cloneDeep(value);
  },
  CHANGE_UNIQUE_FIELD_COMPONENT: (state, value) => {
    const { baseField } = value;
    return defaultDeep(
      state,
      baseField,
      ['component', 'validations', 'defaultValue'],
      ['_id'],
      ['groups', 'uniqueFieldId']
    );
  },

  EDIT_UNIQUE_FIELD: (state, value) => {
    const { property, newValue } = value;
    return _.set({ ...state }, property.name, newValue);
  },
  EDIT_UNIQUE_FIELD_PROPERTY: (state, value) => {
    const { property, newValue } = value;

    const properties = replaceInList(
      state.properties,
      { ...property, ...{ value: newValue } },
      {
        name: property.name
      }
    );

    return {
      ...state,
      ...{
        properties
      }
    };
  },
  EDIT_UNIQUE_FIELD_OPTION: (state, value) => {
    const { prop, newValue, option } = value;
    let newOption = {};

    newOption[prop] = newValue;

    const options = replaceInList(
      state.options,
      { ...option, ...newOption },
      {
        id: option.id
      }
    );

    return {
      ...state,
      ...{
        options
      }
    };
  },
  ADD_UNIQUE_FIELD_OPTION: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.options, {
      id: option.id
    });

    let next = state.options.length;
    while (
      state.options.find((x) => x.text === `Text-${next + 1}` || x.value === `value-${next + 1}`)
    ) {
      next++;
    }

    let newOption = {
      id: uuid(),
      text: `Text-${next + 1}`,
      value: `value-${next + 1}`
    };

    const options = [
      ...state.options.slice(0, optionIndex + 1),
      { ...option, ...newOption },
      ...state.options.slice(optionIndex + 1, state.options.length)
    ];

    return {
      ...state,
      ...{
        options
      }
    };
  },
  REMOVE_UNIQUE_FIELD_OPTION: (state, value) => {
    const { option } = value;

    return {
      ...state,
      ...{
        options: removeById(state.options, option)
      }
    };
  },
  MOVE_UNIQUE_FIELD_OPTION: (state, value) => {
    const { option, distance } = value;
    const resequencedOptions = moveInList(state.options, { id: option.id }, distance);
    return {
      ...state,
      ...{
        options: resequencedOptions
      }
    };
  },
  EDIT_UNIQUE_FIELD_VALIDATION: (state, value) => {
    const { prop, newValue, validation } = value;

    let _newVal = newValue;
    if (_.isNumber(newValue)) {
      _newVal = _.toNumber(newValue);
    }

    return {
      ...state,
      ...{
        validations: setPropInList(state.validations, validation, prop, _newVal)
      }
    };
  },
  EDIT_UNIQUE_FIELD_TOOLTIP: (state, value) => {
    const { tooltip } = value;

    return {
      ...state,
      ...{
        tooltipId: tooltip._id
      }
    };
  },
  REMOVE_UNIQUE_FIELD_TOOLTIP: (state, value) => {
    return {
      ...state,
      ...{
        tooltipId: null
      }
    };
  },
  EDIT_UNIQUE_FIELD_CONTEXTS: (state, val) => {
    const { value, context } = val;

    const newContext = {
      ...context,
      render: value
    };
    const contexts = replaceInList(state.contexts, newContext, {
      environment: context.environment
    });

    const newState = {
      ...state,
      contexts
    };

    return newState;
  },
  ADD_UNIQUE_FIELD_CONDITION_ANY: (state, val) => {
    const { context, as } = val;
    const condition = context.conditions.find((c) => c.as === as);
    const newCondition = {
      as: (condition && condition.as) || as,
      any: (condition && condition.any) || []
    };
    newCondition.any.push({ all: [{ field: '', operator: '', value: '' }] });

    const newState = {
      ...state,
      contexts: replaceInList(
        state.contexts,
        {
          ...context,
          conditions: replaceInList(context.conditions, newCondition, { as }, true)
        },
        {
          environment: context.environment
        }
      )
    };

    return newState;
  },
  REMOVE_UNIQUE_FIELD_CONDITION_ANY: (state, val) => {
    const { context, as, index } = val;
    const condition = context.conditions.find((c) => c.as === as);
    const newCondition = {
      ...condition,
      any: removeFromList(condition.any, index)
    };

    const newState = {
      ...state,
      contexts: replaceInList(
        state.contexts,
        {
          ...context,
          conditions: replaceInList(context.conditions, newCondition, { as })
        },
        {
          environment: context.environment
        }
      )
    };

    return newState;
  },
  ADD_UNIQUE_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index } = val;
    const condition = context.conditions.find((c) => c.as === as);
    const newCondition = {
      as: (condition && condition.as) || as,
      any: (condition && condition.any) || [{ any: [{ all: [] }] }]
    };
    newCondition.any[index].all.push({ field: '', operator: '', value: '' });

    const newState = {
      ...state,
      contexts: replaceInList(
        state.contexts,
        {
          ...context,
          conditions: replaceInList(context.conditions, newCondition, { as })
        },
        {
          environment: context.environment
        }
      )
    };

    return newState;
  },
  REMOVE_UNIQUE_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index, item } = val;
    const condition = context.conditions.find((c) => c.as === as);

    const newCondition = {
      ...condition,
      any: replaceInList(
        condition.any,
        { all: removeFromList(condition.any[index].all, item) },
        index
      )
    };

    const newState = {
      ...state,
      contexts: replaceInList(
        state.contexts,
        {
          ...context,
          conditions: replaceInList(context.conditions, newCondition, { as })
        },
        {
          environment: context.environment
        }
      )
    };

    return newState;
  },
  UPDATE_UNIQUE_FIELD_CONDITION_ALL: (state, val) => {
    const { context, as, index, item, value, prop } = val;
    const condition = context.conditions.find((c) => c.as === as);

    const newItem = {
      ...item,
      ...{ [prop]: value }
    };

    const newCondition = {
      ...condition,
      any: replaceInList(
        condition.any,
        { all: replaceInList(condition.any[index].all, newItem, item) },
        index
      )
    };

    const newState = {
      ...state,
      contexts: replaceInList(
        state.contexts,
        {
          ...context,
          conditions: replaceInList(context.conditions, newCondition, { as })
        },
        {
          environment: context.environment
        }
      )
    };

    return newState;
  },

  EDIT_UNIQUE_FIELD_REPORTS: (state, val) => {
    const { value, report } = val;

    const reports = [
      ...replaceInList(
        state.reports,
        { ...report, enabled: value },
        { environment: report.environment }
      )
    ];

    return {
      ...state,
      reports
    };
  },
  EDIT_UNIQUE_FIELD_METADATA: (state, value) => {
    const { prop, newValue, option } = value;
    let newOption = {};

    newOption[prop] = newValue;

    const metadata = replaceInList(
      state.metadata,
      { ...option, ...newOption },
      {
        key: option.key
      }
    );

    return {
      ...state,
      ...{
        metadata
      }
    };
  },
  ADD_UNIQUE_FIELD_METADATA: (state, value) => {
    const { option } = value;

    const optionIndex = _.findIndex(state.metadata, {
      key: option.key
    });

    let next = state.metadata.length;
    while (
      state.metadata.find((x) => x.key === `Text-${next + 1}` || x.value === `value-${next + 1}`)
    ) {
      next++;
    }

    let newOption = {
      key: `Text-${next + 1}`,
      value: `value-${next + 1}`
    };

    const metadata = [
      ...state.metadata.slice(0, optionIndex + 1),
      { ...option, ...newOption },
      ...state.metadata.slice(optionIndex + 1, state.metadata.length)
    ];

    return {
      ...state,
      ...{
        metadata
      }
    };
  },
  REMOVE_UNIQUE_FIELD_METADATA: (state, value) => {
    const { option } = value;

    return {
      ...state,
      ...{
        metadata: removeFromList(state.metadata, { key: option.key })
      }
    };
  },
  MOVE_UNIQUE_FIELD_METADATA: (state, value) => {
    const { option, distance } = value;

    return {
      ...state,
      ...{
        metadata: moveInList(state.metadata, { key: option.key }, distance)
      }
    };
  }
};

export default createReducer(JSON.parse(JSON.stringify(initial)), reducers);
export { initial };
