import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import {
  addSchemaFieldMetadata,
  addUniqueFieldMetadata,
  editSchemaFieldMetadata,
  editUniqueFieldMetadata,
  moveSchemaFieldMetadata,
  moveUniqueFieldMetadata,
  removeSchemaFieldMetadata,
  removeUniqueFieldMetadata
} from '../../../../state/actions';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema
  };
}

@withRouter
@connect(componentToState)
class MetadataEditor extends Component {
  noOptionsHtml = () => {
    return (
      <Form.Group>
        <Form.Field>
          <label>Add Metadata Item</label>
          <Button.Group basic>
            <Button icon="add" onClick={this.addAtIndex({})} />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  addAtIndex = (option) => (event) => {
    const { dispatch, field, match } = this.props;

    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? addSchemaFieldMetadata : addUniqueFieldMetadata;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  removeAtIndex = (option) => (event) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? removeSchemaFieldMetadata : removeUniqueFieldMetadata;
    dispatch(
      action({
        field: field,
        option: option
      })
    );
  };

  changeIndex = (option, dir) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? moveSchemaFieldMetadata : moveUniqueFieldMetadata;

    dispatch(
      action({
        field: field,
        option: option,
        distance: dir
      })
    );
  };

  editOptionKey = (option) => (event, data) => {
    const { dispatch, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? editSchemaFieldMetadata : editUniqueFieldMetadata;
    const newValue = data.value;
    dispatch(
      action({
        prop: 'key',
        newValue: newValue,
        option: option
      })
    );
  };

  editOptionValue = (option) => (event, data) => {
    const { dispatch, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? editSchemaFieldMetadata : editUniqueFieldMetadata;
    const newValue = data.value;
    dispatch(
      action({
        prop: 'value',
        newValue: newValue,
        option: option
      })
    );
  };

  metadataToEditHtml = (option, index) => {
    const { field } = this.props;

    const { key, value } = option;
    const listKey = `${field.id}-options-${index}`;

    const valueNotUnique =
      _.filter(field.metadata, (opt) => {
        return opt.value === option.value;
      }).length > 1;

    const keyNotUnique =
      _.filter(field.metadata, (opt) => {
        return opt.key === option.key;
      }).length > 1;

    return (
      <Form.Group key={listKey}>
        <Form.Field width={1}>
          <label>&nbsp;</label>
          <Label circular size="big">
            {index}
          </Label>
        </Form.Field>
        <Form.Input
          label="Key"
          width={5}
          value={key}
          error={keyNotUnique}
          onChange={(event, data) => {
            this.editOptionKey(option)(event, data);
          }}
        />
        <Form.Input
          label="Value"
          width={5}
          value={value}
          error={valueNotUnique}
          onChange={(event, data) => {
            this.editOptionValue(option)(event, data);
          }}
        />
        <Form.Field width={2}>
          <label>Add/Remove</label>
          <Button.Group basic fluid>
            <Button
              icon="add"
              basic
              onClick={this.addAtIndex(option)}
              title="Add another option at this index"
            />

            <Button
              icon="trash"
              basic
              onClick={this.removeAtIndex(option)}
              title="Remove this option"
            />
          </Button.Group>
        </Form.Field>
        <Form.Field width={2}>
          <label>Move</label>
          <Button.Group basic fluid>
            <Button
              icon="arrow up"
              onClick={this.changeIndex(option, 1)}
              disabled={index === 0}
              title="Move this option to a higher position in the list"
            />
            <Button
              icon="arrow down"
              onClick={this.changeIndex(option, -1)}
              disabled={field.metadata.length === index + 1}
              title="Move this option to a lower position in the list"
            />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  render() {
    const { field } = this.props;
    const options =
      field.metadata.length > 0
        ? _.map(field.metadata, this.metadataToEditHtml.bind(this))
        : this.noOptionsHtml();
    return (
      <div>
        <Form>{options}</Form>
      </div>
    );
  }
}
export default MetadataEditor;
