import '../index.css';

import * as jsondiffpatch from 'jsondiffpatch';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';

import QueryGetUniqueFieldDiff from '../../../graphql/queries/getUniqueFieldDiff.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(QueryGetUniqueFieldDiff, {
  name: 'uniqueFieldDiff',
  options: (props) => {
    const { id, version } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id,
        version
      }
    };
  }
})
class UniqueFieldView extends Component {
  render() {
    const { uniqueFieldDiff } = this.props;
    if (uniqueFieldDiff.loading) {
      return <div />;
    }
    const diff = uniqueFieldDiff.uniqueFieldDiff.diff;

    const html = jsondiffpatch.formatters.html.format(diff);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

export default UniqueFieldView;
