export default [
  {
    id: 'One Day Delivery',
    days: 0,
    itemCode: '400006',
    lookerDays: 0,
    name: 'One Day Delivery',
    standard: false,
    price: {
      amount: 4500,
      currency: 'USD'
    }
  },
  {
    id: 'Two Day Delivery',
    days: 1,
    itemCode: '302456',
    lookerDays: 0,
    name: 'Two Day Delivery',
    standard: false,
    price: {
      amount: 2500,
      currency: 'USD'
    }
  },
  {
    id: 'Three Day Delivery',
    days: 2,
    itemCode: '302456',
    lookerDays: 1,
    name: 'Three Day Delivery',
    standard: true,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Four Day Delivery',
    days: 3,
    itemCode: '302456',
    lookerDays: 2,
    name: 'Four Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Five Day Delivery',
    days: 4,
    itemCode: '302456',
    lookerDays: 3,
    name: 'Five Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Six Day Delivery',
    days: 5,
    itemCode: '302456',
    lookerDays: 4,
    name: 'Six Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Seven Day Delivery',
    days: 6,
    itemCode: '302456',
    lookerDays: 5,
    name: 'Seven Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Eight Day Delivery',
    days: 7,
    itemCode: '302456',
    lookerDays: 6,
    name: 'Eight Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Nine Day Delivery',
    days: 8,
    itemCode: '302456',
    lookerDays: 7,
    name: 'Nine Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Ten Day Delivery',
    days: 9,
    itemCode: '302456',
    lookerDays: 8,
    name: 'Ten Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  },
  {
    id: 'Thirty Day Delivery',
    days: 29,
    itemCode: '302456',
    lookerDays: 28,
    name: 'Thirty Day Delivery',
    standard: false,
    price: {
      amount: 0,
      currency: 'USD'
    }
  }
];
