import { ApolloLink, Observable } from 'apollo-link';
import {
  createSignalIfSupported,
  fallbackHttpConfig,
  parseAndCheckHttpResponse,
  selectHttpOptionsAndBody,
  selectURI,
  serializeFetchParameter
} from 'apollo-link-http-common';

/**
 * Checks a node is an enumerable object.
 * @param {*} node - A node to check.
 * @returns {Boolean} Is the node an enumerable object.
 */
const isObject = (node) => typeof node === 'object' && node !== null;

/**
 * A file extraction.
 * @typedef {Object} ExtractedFile
 * @property {String} path - Original location in the object tree.
 * @property {String} file - The actual file.
 */

/**
 * Reversibly extracts files from an object tree.
 * @param {object} tree - An object tree to extract files from.
 * @param {string} [treePath=''] - Optional tree path to prefix file paths.
 * @returns {ExtractedFile[]} Extracted files.
 */
function extractFiles(tree, treePath = '') {
  const files = [];
  const recurse = (node, nodePath) => {
    // Iterate enumerable properties of the node.
    Object.keys(node).forEach((key) => {
      // Skip non-object.
      if (!isObject(node[key])) return;

      const path = `${nodePath}${key}`;

      if (
        // Node is a File.
        (typeof File !== 'undefined' && node[key] instanceof File) ||
        // Node is a Blob.
        (typeof Blob !== 'undefined' && node[key] instanceof Blob) ||
        // Node is a ReactNativeFile.
        node[key] instanceof ReactNativeFile
      ) {
        // Extract the file and it's object tree path.
        files.push({ path, file: node[key] });

        // Delete the file. Array items must be deleted without reindexing to
        // allow repopulation in a reverse operation.
        node[key] = null;

        // No further checks or recursion.
        return;
      }

      if (typeof FileList !== 'undefined' && node[key] instanceof FileList)
        // Convert read-only FileList to an array for manipulation.
        node[key] = Array.prototype.slice.call(node[key]);

      // Recurse into child node.
      recurse(node[key], `${path}.`);
    });
  };

  if (isObject(tree))
    // Recurse object tree.
    recurse(
      tree,
      // If a tree path was provided, append a dot.
      treePath === '' ? treePath : `${treePath}.`
    );

  return files;
}

/**
 * A React Native FormData file object.
 * @see {@link https://github.com/facebook/react-native/blob/v0.45.1/Libraries/Network/FormData.js#L34}
 * @typedef {Object} ReactNativeFileObject
 * @property {String} uri - File system path.
 * @property {String} [type] - File content type.
 * @property {String} [name] - File name.
 */

/**
 * A React Native file.
 */
class ReactNativeFile {
  /**
   * Constructs a new file.
   * @param {ReactNativeFileObject} file
   * @example
   * const file = new ReactNativeFile({
   *  uri: uriFromCameraRoll,
   *  type: 'image/jpeg',
   *  name: 'photo.jpg'
   * })
   */
  constructor({ uri, type, name }) {
    this.uri = uri;
    this.type = type;
    this.name = name;
  }

  /**
   * Creates an array of file instances.
   * @param {ReactNativeFileObject[]} files
   * @example
   * const files = ReactNativeFile.list([{
   *   uri: uriFromCameraRoll1,
   *   type: 'image/jpeg',
   *   name: 'photo-1.jpg'
   * }, {
   *   uri: uriFromCameraRoll2,
   *   type: 'image/jpeg',
   *   name: 'photo-2.jpg'
   * }])
   */
  static list = (files) => files.map((file) => new ReactNativeFile(file));
}

export { ReactNativeFile };

export const createUploadLink = ({
  uri: fetchUri = '/graphql',
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions
} = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers
  };

  return new ApolloLink((operation) => {
    const uri = selectURI(operation, fetchUri);
    const context = operation.getContext();
    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers
    };

    const { options, body } = selectHttpOptionsAndBody(
      operation,
      fallbackHttpConfig,
      linkConfig,
      contextConfig
    );

    const files = extractFiles(body);
    const payload = serializeFetchParameter(body, 'Payload');

    if (files.length) {
      // Automatically set by fetch when the body is a FormData instance.
      delete options.headers['content-type'];

      // GraphQL multipart request spec:
      // https://github.com/jaydenseric/graphql-multipart-request-spec
      options.body = new FormData();
      options.body.append('operations', payload);
      options.body.append(
        'map',
        JSON.stringify(
          files.reduce((map, { path }, index) => {
            map[`${index}`] = [path];
            return map;
          }, {})
        )
      );
      files.forEach(({ file }, index) => options.body.append(index, file, file.name));
    } else options.body = payload;

    return new Observable((observer) => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      linkFetch(uri, options)
        .then((response) => {
          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then((result) => {
          observer.next(result);
          observer.complete();
        })
        .catch((error) => {
          if (error.name === 'AbortError')
            // Fetch was aborted.
            return;

          if (error.result && error.result.errors && error.result.data)
            // There is a GraphQL result to forward.
            observer.next(error.result);

          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
};
