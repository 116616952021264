import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import schemaEditorWorkspaces from './schema-editor-workspaces';

const EDITOR_ROOT_URL = `/schema-editor`;

function stateToComponent(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    activeEditorTab: state.schemaEditorTabSelected.activeEditorTabName
  };
}

@withRouter
@connect(stateToComponent)
class SchemaEditorRoutes extends Component {
  renderWorkspaces = () => {
    return _.map(schemaEditorWorkspaces, (workspace) => {
      return (
        <Route
          key={`route-${workspace.id}`}
          path={`${EDITOR_ROOT_URL}/:_id/${workspace.id}`}
          exact={true}
          component={workspace.component}
        />
      );
    });
  };

  render() {
    const { editSchemaSchema, activeEditorTab } = this.props;
    const workSpaces = this.renderWorkspaces();
    const schemaId = editSchemaSchema._id || 'new';
    return (
      <div className="editor-canvas-active">
        <Route
          path={`${EDITOR_ROOT_URL}`}
          exact={true}
          render={() => {
            return <Redirect to={`${EDITOR_ROOT_URL}/${schemaId}/${activeEditorTab}`} />;
          }}
        />
        {workSpaces}
      </div>
    );
  }
}
export default SchemaEditorRoutes;
