import './index.css';

import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import InfoEditor from './info-editor';
import PropertiesEditor from './properties-editor/index.js';

@withRouter
class EditMapDetails extends Component {
  layoutPorportions = { tabWidth: 16, paneWidth: 16 };
  menuConfiguration = {
    secondary: true,
    pointing: true,
    vertical: false,
    className: 'six item'
  };

  toPane = (component, styles) => {
    return (
      <Tab.Pane className={'edit-field-details-content-pane'} style={styles || {}}>
        {component}
      </Tab.Pane>
    );
  };

  panels = () => {
    const { field } = this.props;

    let panes = [
      {
        menuItem: { key: 'info', icon: 'info circle', content: 'Info' },
        render: () => this.toPane(<InfoEditor field={field} />)
      },
      {
        menuItem: { key: 'metadata', icon: 'list', content: 'Map Properties' },
        render: () => this.toPane(<PropertiesEditor field={field} />)
      }
    ];

    return (
      <Tab
        className={'edit-field-details-content'}
        menu={this.menuConfiguration}
        panes={panes}
        grid={this.layoutPorportions}
      />
    );
  };

  render() {
    return this.panels();
  }
}

export default EditMapDetails;
