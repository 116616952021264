import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, Form, Icon, Input, Segment, List, Button, Dropdown } from 'semantic-ui-react';

@connect((state) => {
  return {
    editSchemaInfo: state.editSchemaInfo,
    editSchemaSchema: state.editSchemaSchema
  };
})
@withRouter
class ConditionEditor extends Component {
  getFieldInput = (value, action) => {
    const { match, editSchemaSchema } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    if (isSchemaEditor) {
      const fieldOptions = editSchemaSchema.fields.map((x) => ({
        key: x.id,
        text: x.label,
        value: x.id
      }));

      console.log('building options', editSchemaSchema.fields, fieldOptions);
      return (
        <Form.Field>
          <Dropdown
            label="Field"
            value={value}
            onChange={action}
            selection
            options={fieldOptions}
          />
        </Form.Field>
      );
    }
    return (
      <Form.Field>
        <Input label="Field" value={value} onChange={action} />
      </Form.Field>
    );
  };
  getValueInput = (target, value, action) => {
    const { match, editSchemaSchema } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    if (isSchemaEditor) {
      const targetField = editSchemaSchema.fields.find((x) => x.id === target);

      if (targetField && targetField.options && targetField.options.length > 0) {
        return (
          <Form.Field>
            <Dropdown
              label="Field"
              value={value}
              onChange={action}
              selection
              multiple
              options={targetField.options}
            />
          </Form.Field>
        );
      }
    }

    return (
      <Form.Field>
        <Input label="Value" value={value} onChange={action} />
      </Form.Field>
    );
  };

  renderConditionals = () => {
    const {
      removeConditionAll,
      updateConditionAll,
      addConditionalAll,
      removeConditionAny,
      addConditionalAny,
      lineItem
    } = this.props;
    const { conditions } = lineItem;
    console.log('WHAT', lineItem);
    const cons = conditions;
    console.log(conditions, 'conditions');
    const content = cons.any.map((any, i) => {
      const ands = any.all.map((all, j) => {
        return (
          <List.Item key={`${cons.as}-any-${i}-all-${j}`}>
            <Segment>
              <Button negative onClick={removeConditionAll(lineItem, i, j)}>
                <Icon name="minus" />
                Remove AND condition.
              </Button>
              {this.getFieldInput(all.field, updateConditionAll(lineItem, i, all, 'field'))}
              <Form.Field>
                <Dropdown
                  label="Operator"
                  value={all.operator || 'eq'}
                  selection
                  options={[{ key: 'eq', value: 'eq', text: 'equals' }]}
                  onChange={updateConditionAll(lineItem, i, all, 'operator')}
                />
              </Form.Field>
              {this.getValueInput(
                all.field,
                all.value,
                updateConditionAll(lineItem, i, all, 'value')
              )}
              {Array.isArray(all.value) && all.value.length
                ? 'Multiple selections treated as ORs'
                : !Array.isArray(all.value) && all.value.length
                ? 'Values with || will be split into ORs'
                : null}
            </Segment>
            {j === any.all.length - 1 ? null : <Divider horizontal>AND</Divider>}
          </List.Item>
        );
      });

      return (
        <List.Item key={`${cons.as}-any-${i}`}>
          <Segment>
            <Button negative onClick={removeConditionAny(lineItem, i)}>
              <Icon name="minus" />
              Remove OR condition.
            </Button>

            {ands}
            <Button onClick={addConditionalAll(lineItem, i)}>
              <Icon name="plus" />
              Add AND Condition
            </Button>
          </Segment>
          {i === cons.any.length - 1 ? null : <Divider horizontal>OR</Divider>}
        </List.Item>
      );
    });

    return (
      <List>
        {content}
        <Button onClick={addConditionalAny(lineItem)}>
          <Icon name="plus" />
          Add Or Condition
        </Button>
      </List>
    );
  };

  render() {
    return this.renderConditionals();
  }
}

export default ConditionEditor;
