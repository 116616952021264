'../index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Dimmer, Dropdown, Loader, Pagination, Button, Icon } from 'semantic-ui-react';

import GetAllTooltipsQuery from '../../../graphql/queries/getAllToolTips.graphql';
import GetToolTipFacets from '../../../graphql/queries/getToolTipFacets.graphql';
import {
  loadTooltip,
  updateTooltipsFacets,
  updateTooltipsLayout,
  updateTooltipsPage,
  updateTooltipsTerm,
  updateTooltipsSort,
  clearTooltip
} from '../../../state/actions';
import FilterBar from '../../filter-bar';
import tooltipLayoutStrategy from './tooltip-layout-strategy';

const FACETS_DEBOUNCE = 200;

function stateToComponent(state) {
  return {
    who: state.who,
    search: state.tooltipSearch
  };
}

@connect(stateToComponent)
@graphql(GetAllTooltipsQuery, {
  name: 'tooltipsData',
  options: (props) => {
    const {
      page,
      perPageCard,
      perPageTable,
      search,
      facets,
      layout,
      pagerInfo,
      sort
    } = props.search;

    const _perPage = layout === 'card-layout' ? perPageCard : perPageTable;
    let _page = page;
    if (pagerInfo.perPage !== _perPage) {
      let newPageCount = Math.round(pagerInfo.itemCount / _perPage);
      _page = newPageCount < _page ? newPageCount : _page;
    }

    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        sort,
        search,
        facets,
        page: _page,
        perPage: _perPage
      }
    };
  }
})
@graphql(GetToolTipFacets, {
  name: 'tooltipFacets',
  options: (props) => {
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {}
    };
  }
})
class TooltipSearch extends Component {
  renderFacetSelector = () => {
    const { loading, tooltipFacets } = this.props.tooltipFacets;
    let items = loading ? [] : tooltipFacets.tags.map((x) => ({ key: x, text: x, value: x }));

    const renderLabel = (label) => ({
      color: 'blue',
      content: `${label.text}`
      // icon: 'check'
    });

    return (
      <Dropdown
        text="Tags"
        icon="tags"
        floating
        labeled
        button
        multiple
        selection
        search
        header="Tags"
        className="icon utility-bar-facet-dropdown"
        onChange={this.updateTooltipsFacets}
        renderLabel={renderLabel}
        value={this.props.search.facets}
        options={items}
        scrolling={true}
      />
      // activeFacets={this.props.search.facets}
    );
  };

  changeView = (event, data) => {
    const { dispatch, tooltipsData } = this.props;
    const pagerInfo = _.get(tooltipsData, 'tooltipsData.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const activeLayout =
      _.find(tooltipLayoutStrategy, { id: data.value }) || tooltipLayoutStrategy[0];

    dispatch(
      updateTooltipsLayout({
        pagerInfo: pagerInfo,
        layout: activeLayout.id
      })
    );
  };

  debounceOptions = {
    leading: false,
    trailing: true
  };

  updateTooltipsFacets = _.debounce(
    (e, data) => {
      const { dispatch } = this.props;
      dispatch(updateTooltipsFacets(data.value));
    },
    FACETS_DEBOUNCE,
    this.debounceOptions
  );

  loadTooltip = (tooltip) => {
    const { dispatch, history } = this.props;
    dispatch(loadTooltip(tooltip));
    history.push(`/tooltip/view/${tooltip._id}`);
  };

  renderButtons = () => {
    const { history, dispatch } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 16
        }}
      >
        <Button.Group>
          <Button
            size="big"
            primary
            onClick={() => {
              dispatch(clearTooltip());
              history.push(`/tooltip/view/new`);
            }}
          >
            {<Icon name={`edit`} />}
            {`New`}
          </Button>
        </Button.Group>
      </div>
    );
  };

  renderLayoutContent = (items) => {
    const { who, search, loadHandler } = this.props;
    const activeLayout =
      _.find(tooltipLayoutStrategy, { id: search.layout }) || tooltipLayoutStrategy[0];

    return (
      <activeLayout.component
        items={items}
        who={who}
        model={activeLayout.model}
        actions={{ select: loadHandler || this.loadTooltip }}
      />
    );
  };

  render() {
    const { tooltipsData, dispatch, editor } = this.props;

    const { loading } = tooltipsData;

    //const count = _.get(tooltipsData, 'tooltipPagination.count', 0);
    const items = _.get(tooltipsData, 'tooltipPagination.items', []);
    const pageInfo = _.get(tooltipsData, 'tooltipPagination.pageInfo', {
      currentPage: 0,
      pageCount: 0,
      itemCount: 0,
      perPage: 0,
      hasNextPage: false,
      hasPreviousPage: false
    });

    const layoutContent = this.renderLayoutContent(items);
    const { currentPage, pageCount } = pageInfo;

    //TODO extract to function.
    const results = (
      <div className="search-content">
        <div className="search-results-frame">{layoutContent}</div>
      </div>
    );

    const loader = (
      <div className="search-content">
        <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
          <Dimmer inverted active>
            <Loader size="huge">Loading Tooltips</Loader>
          </Dimmer>
        </div>
      </div>
    );

    const content = loading ? loader : results;

    const pager = (
      <div className="pager-frame">
        <Pagination
          activePage={currentPage}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
          totalPages={pageCount}
          onPageChange={(eve, data) => {
            dispatch(
              updateTooltipsPage({
                pagerInfo: pageInfo,
                page: data.activePage
              })
            );
          }}
        />
      </div>
    );

    return (
      <div className="search-workspace tooltips-search-workspace">
        <div className="search-results-frame-wrap">
          <FilterBar
            label="Tooltips"
            sort={updateTooltipsSort}
            search={this.props.search}
            strategy={tooltipLayoutStrategy}
            searchAction={updateTooltipsTerm}
            filters={this.renderFacetSelector()}
            viewToggle={this.changeView}
          />
          {content}
        </div>
        {pager}
        {editor ? '' : <div>{this.renderButtons()}</div>}
      </div>
    );
  }
}
export default TooltipSearch;
