import './app.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { updateWho } from '../state/actions';
import ActivityBar from './activity-bar';
import DefaultsProvider from './defaults-provider';
import LoginForm from './login-form';
import EditorMenu from './menu';
import Routes from './routes';
import auth from '../auth';
import UnauthorizedPage from './error_pages/unauthorized';

function componentToState(state) {
  return {
    activityBar: state.activityBar,
    who: state.who
  };
}

@withRouter
@connect(componentToState)
class App extends Component {
  takeToLogin = () => {
    return <LoginForm />;
  };

  blockAccess = () => {
    return <UnauthorizedPage></UnauthorizedPage>;
  };

  loader = () => {
    return (
      <div className="loading-who">
        <Loader active size="large" inline="centered" />
      </div>
    );
  };

  componentDidMount() {
    auth.renewSession();
  }

  render() {
    const { who, dispatch } = this.props;

    if (!auth.isLoggedIn()) {
      return this.takeToLogin();
    } else if (!auth.checkAuthExpiration() || !auth.isAuthenticated()) {
      return this.blockAccess();
    }

    if (!who._id) {
      dispatch(updateWho(auth.who));
    }

    let content = who._id ? <Routes /> : this.loader();

    return (
      <DefaultsProvider>
        <div className="app">
          <div className="menu-frame">
            <EditorMenu />
          </div>
          <div className="body-frame">
            <ActivityBar />
            {content}
          </div>
        </div>
      </DefaultsProvider>
    );
  }
}

export default App;
