import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import moment from 'moment';
import { Dimmer, Loader, Pagination, Segment, Table, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import { updateVersionsPaginationPage } from '../../../state/actions';
import GetVersions from '../../../graphql/queries/getPublishedVersions.graphql';
import UpdatePublishedStatus from '../../../graphql/mutations/updateIsProductionById.graphql';
import './index.css';

function componentToState(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    editSchemaVersions: state.editSchemaVersions
  };
}
@connect(componentToState)
@graphql(GetVersions, {
  name: 'publishedVersions',
  options: (props) => {
    const { _id } = props.editSchemaSchema;
    const { page } = props.editSchemaVersions;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      variables: {
        page,
        filter: {
          collectionId: _id
        }
      }
    };
  }
})
@graphql(UpdatePublishedStatus, {
  name: 'updateIsProduction',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
class VersionsView extends React.Component {
  state = {
    page: 1
  };
  renderVersionTableBody = () => {
    const { versions, loading } = this.props.publishedVersions;

    const { updateIsProduction, publishedVersions } = this.props;

    if (!loading) {
      const { items } = versions;
      return _.map(items, generateTableBody);

      function generateTableBody({ _id, publishedVersion, collectionId, createdAt, isProduction }) {
        return (
          <Table.Row key={`${_id}-publishedTable`}>
            <Table.Cell>{publishedVersion}</Table.Cell>
            <Table.Cell>{moment(createdAt).format('MM-DD-YYYY')}</Table.Cell>
            <Table.Cell>{`${isProduction}`}</Table.Cell>
            <Table.Cell>
              <Radio
                slider
                checked={Boolean(isProduction)}
                onChange={(e, data) => {
                  console.log(data.checked);
                  const record = {
                    _id,
                    isProduction: data.checked
                  };
                  F;
                  updateIsProduction({ variables: { record } }).then(() => {
                    publishedVersions.refetch();
                  });
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      }
    }
  };
  loader = (
    <div className="search-content">
      <div style={{ position: 'relative', display: 'flex', flexGrow: 1 }}>
        <Dimmer inverted active>
          <Loader size="huge">Loading Company</Loader>
        </Dimmer>
      </div>
    </div>
  );

  onPageChange = (event, data) => {
    this.props.dispatch(updateVersionsPaginationPage(data.activePage));
  };

  renderVersionsTable = () => {
    const { publishedVersions, editSchemaVersions } = this.props;
    const { loading, versions } = publishedVersions;
    if (loading) return this.loader;
    const { pageInfo } = versions;
    return (
      <>
        <Segment style={{ margin: 24 }}>
          <Table className="subform" basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Published Version</Table.HeaderCell>
                <Table.HeaderCell>Created At</Table.HeaderCell>
                <Table.HeaderCell>Is Production</Table.HeaderCell>
                <Table.HeaderCell>Upgrade To Production</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>{this.renderVersionTableBody()}</Table.Body>
          </Table>
        </Segment>
        {this.renderPagination(editSchemaVersions.page, pageInfo.pageCount)}
      </>
    );
  };

  renderPagination = (currentPage, totalPages) => {
    return (
      <div className="versions_pagination_container">
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={this.onPageChange}
          firstItem={null}
          lastItem={null}
          pointing
          secondary
        />
      </div>
    );
  };

  render() {
    return this.renderVersionsTable();
  }
}
export default VersionsView;
