import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SavedModal from './saved-modal.js';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@withRouter
class SaveActions extends Component {
  state = {
    savedRecord: null,
    saving: false,
    error: false
  };

  updateEntity = () => {
    const { entity, entityType, updateQuery, getEntityToPersist } = this.props;
    let url = '';
    const entityData = { ...entity };

    if (this.state.saving) {
      return;
    }
    this.setState({ saving: true });

    if (entityType === 'tooltip') {
      url = entityData.imageSignedUrl;
      delete entityData.imageSignedUrl;
    }

    const record = getEntityToPersist ? getEntityToPersist(entityData) : entityData;
    delete record._id;

    const variables = { _id: entity._id, record };

    updateQuery({ variables })
      .then((response) => {
        const savedRecord = response.data[`${entityType}`].record;
        if (entityType === 'tooltip') {
          savedRecord.imageSignedUrl = url;
        }
        this.setState({ savedRecord, saving: false });
      })
      .catch((error) => {
        this.setState({ error: error.message, saving: false, savedRecord: true });
      });
  };

  renderButtons = () => {
    const { valid, actions } = this.props;

    const actionMap = {
      update: this.updateEntity,
      create: this.updateEntity,
      new: this.clearEntity
    };

    const buttons = actions.map((action) => {
      const actionFunc =
        typeof action.action === 'function' ? action.action : actionMap[action.action];

      const disabled =
        (action.action === 'create' || action.action === 'update') && valid === false;

      return (
        <Button
          disabled={disabled}
          key={`action_button_${action.label}`}
          size="big"
          primary
          onClick={actionFunc}
        >
          {action.icon ? <Icon name={`${action.icon}`} /> : null}
          {action.label}
        </Button>
      );
    });

    return <Button.Group>{buttons}</Button.Group>;
  };

  loadEntity = (entity) => () => {
    const { loadAction, history, match } = this.props;
    this.setState({ savedRecord: null, saving: false });
    this.props.dispatch(loadAction(entity));

    history.push(match.url.replace(match.params.id, entity._id));
  };

  clearEntity = () => {
    const { clearAction, history, match } = this.props;
    const { error } = this.state;
    this.setState({ savedRecord: null, saving: false });
    this.props.dispatch(clearAction());
    console.log('match is', match, history);
    if (error) {
      history.goBack();
    } else {
      history.push(match.url.replace(match.params.id, 'new'));
    }
  };

  renderModal = () => {
    const { savedRecord, saving, error } = this.state;
    const { cardComponent, clearAction, publishAction } = this.props;
    console.log('publis actin is', publishAction);
    return (
      <SavedModal
        savedRecord={savedRecord}
        saving={saving}
        error={error}
        cardComponent={cardComponent}
        editAction={this.loadEntity(savedRecord)}
        newAction={clearAction ? this.clearEntity : null}
        publishAction={publishAction ? publishAction : null}
      />
    );
  };

  render() {
    const { savedRecord, saving } = this.state;

    const content = savedRecord || saving ? this.renderModal() : this.renderButtons();

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 16
        }}
      >
        {content}
      </div>
    );
  }
}

export default SaveActions;
