import { SearchActionButtonSection } from '../../search/actionSection';
import UniqueFieldTable from '../../search/search-table';
import UniqueFieldCards from './uniqueField-cards';

function actionSection(actions) {
  return [
    {
      icon: 'hand point up outline',
      label: 'Select',
      action: actions.select,
      routes: ['schema-editor', 'settings/site']
    },
    {
      icon: 'edit',
      label: 'Edit',
      action: actions.edit,
      routes: ['uniquefield']
    },
    {
      icon: 'copy',
      label: 'Copy',
      action: actions.copy,
      routes: ['uniquefield']
    },
    {
      icon: 'history',
      label: 'History',
      action: actions.history,
      routes: ['uniquefield']
    }
  ];
}

export default [
  {
    id: 'card-layout',
    icon: 'block layout',
    label: 'Card',
    description: 'Convert layout to a cards. More detail; less per page',
    component: UniqueFieldCards,
    model: {
      actionSection: actionSection
    }
  },
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Field ID',
        property: 'id',
        type: 'string'
      },
      {
        label: 'Label',
        property: 'label',
        type: 'string'
      },
      {
        label: 'Component',
        property: 'component',
        type: 'string'
      },
      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ],
    component: UniqueFieldTable
  }
];
