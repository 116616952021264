import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';

import { loadCompany } from '../../../state/actions';
import updateCompany from '../../../graphql/mutations/updateCompany.graphql';
import { CompanyCard } from '../search/company-cards.js';
import SaveActions from '../../common/save-actions.js';

function stateToComponent(state) {
  return {
    who: state.who,
    company: state.companyView
  };
}

@connect(stateToComponent)
@graphql(updateCompany, {
  name: 'updateCompany',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {
        id: props.company._id,
        record: props.company
      }
    };
  }
})
class CompanyViewActions extends Component {
  render() {
    const { company, updateCompany, valid } = this.props;

    const actions = [
      {
        label: 'Save',
        icon: 'save',
        action: 'update'
      }
    ];

    return (
      <SaveActions
        actions={actions}
        entity={company}
        entityType={'company'}
        cardComponent={CompanyCard}
        updateQuery={updateCompany}
        loadAction={loadCompany}
        valid={valid}
        getEntityToPersist={(e) => e}
      />
    );
  }
}

export default CompanyViewActions;
