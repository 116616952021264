import createReducer from './create-reducer';
import { removeFromList } from './list-manipulation';

const initial = {
  companyId: '',
  schemas: [] //containes Object of id, publishedVersion {id: '1234', publishedVersion: 0}
};

const reducers = {
  ADD_SCHEMA_PERMISSIONS: (state, value) => {
    return {
      ...state,
      companyId: value.companyId,
      schemas: [...state.schemas, ...value.schemas]
    };
  },

  UPDATE_SCHEMA_PERMISSIONS: (state, value) => {
    return {
      ...state,
      companyId: value.companyId,
      schemas: value.schemas
    };
  },

  UPDATE_COMPANY_ID: (state, value) => {
    return {
      ...state,
      companyId: value.companyId
    };
  },

  REMOVE_SCHEMA_PERMISSION: (state, value) => {
    const newSchemas = removeFromList(
      state.schemas,
      state.schemas.find((schema) => (schema._id || schema.id) === value)
    );

    return {
      ...state,
      schemas: newSchemas
    };
  },

  RESET_SCHEMA_PERMISSION: (state, value) => {
    return {
      ...state,
      companyId: '',
      schemas: []
    };
  }
};

export default createReducer(initial, reducers);
