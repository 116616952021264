import './group-editor.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { Button, Card, Form, Icon, List, Message, Header } from 'semantic-ui-react';

import {
  editSchemaField,
  editSchemaFieldGroup,
  editSchemaFieldGroupDetails,
  removeSchemaFieldGroup,
  removeInvalidFieldGroup
} from '../../../../state/actions';
import { fieldToIcon } from '../../toolbar/toolbar-icon-mapper';
import groupTypeOptions from '../../../common/group-type-options';

class GroupEditor extends Component {
  state = {
    showConfirmRemoval: false,
    showEditGroup: false
  };

  fieldIcon = (thisField) => {
    return fieldToIcon(thisField);
  };

  groupEditorcontent = () => {
    const { group } = this.props;
    group.groupType = group.groupType ? group.groupType : 'generic';

    const { text } = groupTypeOptions.find((item) => item.value === group.groupType);

    return (
      <Card.Content style={{ maxHeight: '8rem' }}>
        <Card.Header>{group.name}</Card.Header>
        <Header as="h4">Group Type : {text}</Header>
      </Card.Content>
    );
  };

  getFieldHeaderMessage = (field) => {
    let headerMessage = field.label;

    let labelProp = _.find(field.properties, { name: 'label' });
    let messageProp = _.find(field.properties, { name: 'message' });
    let contentProp = _.find(field.properties, { name: 'content' });

    if (contentProp) {
      headerMessage = contentProp.value || headerMessage;
    }

    if (messageProp) {
      headerMessage = messageProp.value || headerMessage;
    }

    if (labelProp) {
      headerMessage = labelProp.value;
    }

    return headerMessage;
  };

  fieldListContent = () => {
    const { groups, group, dispatch, field, editSchemaSchema } = this.props;
    return (
      <Card.Content basic>
        <List divided relaxed>
          {_.map(group.fields, (id, index) => {
            const thisField = _.find(editSchemaSchema.fields, { id: id });
            if (!thisField) {
              dispatch(removeInvalidFieldGroup({ fieldId: id, group }));
              return <></>;
            }
            const isThisfield = field.id === id;
            const hasMoreThanOneGroup = groups.length > 1;
            const isNotDefaultGroup = group !== groups[0];

            let listItemClass = 'group-field-li';

            if (isThisfield) {
              listItemClass = `${listItemClass} active`;
            }

            const actions =
              isThisfield && hasMoreThanOneGroup && isNotDefaultGroup ? (
                <List.Content floated="right">
                  <Button
                    icon
                    basic
                    size="tiny"
                    compact
                    title={`Remove field from the group`}
                    onClick={() => {
                      dispatch(
                        editSchemaFieldGroup({
                          field: field,
                          group: groups[0].name
                        })
                      );
                      dispatch(
                        editSchemaField({
                          property: { name: 'group' },
                          newValue: groups[0].name
                        })
                      );
                    }}
                  >
                    <Icon name="x" />
                  </Button>
                </List.Content>
              ) : null;

            const headerMessage = this.getFieldHeaderMessage(thisField);
            const subHeaderMessage = thisField.name || thisField.component;

            return (
              <List.Item key={`${group.name}-${id}`} className={listItemClass}>
                {actions}
                <List.Icon
                  name={this.fieldIcon(thisField) || 'square'}
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <List.Header
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '10rem',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {headerMessage}
                  </List.Header>
                  <List.Description
                    size="tiny"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '10rem',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {subHeaderMessage}
                  </List.Description>
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </Card.Content>
    );
  };

  ctaContent = () => {
    const { group } = this.props;
    let isDefault = group.name === 'Basic Information';

    let removeGroup = !isDefault ? (
      <Button
        basic
        compact
        title="Delete this group from the Schema"
        onClick={() => {
          this.setState({
            showConfirmRemoval: true,
            showEditGroup: false
          });
        }}
      >
        <Icon name="trash" />
      </Button>
    ) : null;

    let editGroup = !isDefault ? (
      <Button
        basic
        compact
        title="Edit this group"
        onClick={() => {
          this.setState({
            showConfirmRemoval: false,
            showEditGroup: true
          });
        }}
      >
        <Icon name="edit" />
      </Button>
    ) : null;

    return (
      <Card.Content extra className="group-cards-actions">
        {removeGroup}
        {editGroup}
      </Card.Content>
    );
  };

  editGroupContent = () => {
    const { field, group, dispatch } = this.props;
    let newGroup = { ...group };
    return (
      <Card.Content>
        <Message>
          <Form style={{ overflowX: 'visible' }}>
            <Form.Input
              fluid
              label="Group Name"
              name="name"
              defaultValue={newGroup.name}
              onChange={(e, value) => {
                newGroup.name = value.value;
              }}
            />
            <Form.Select
              options={groupTypeOptions}
              defaultValue={newGroup.groupType}
              label="Group Type"
              fluid
              search
              name="name"
              onChange={(e, value) => {
                newGroup.groupType = value.value;
              }}
            />
          </Form>
        </Message>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            basic
            size="small"
            title="Nevermind, leave this group alone."
            onClick={() => {
              this.setState({
                showConfirmRemoval: false,
                showEditGroup: false
              });
            }}
          >
            Nevermind
          </Button>
          <Button
            size="small"
            title="Yes, Confirm the edits to this group."
            onClick={() => {
              dispatch(
                editSchemaFieldGroupDetails({
                  group,
                  value: { name: newGroup.name, groupType: newGroup.groupType },
                  prop: ['name', 'groupType']
                })
              );
              if (field.group === group.name) {
                dispatch(
                  editSchemaField({
                    property: { name: 'group' },
                    newValue: newGroup.name
                  })
                );
              }

              this.setState({
                showConfirmRemoval: false,
                showEditGroup: false
              });
            }}
          >
            {`Save Edits`}
          </Button>
        </div>
      </Card.Content>
    );
  };

  confirmDeletionContent = () => {
    const { groups, group, dispatch } = this.props;
    return (
      <Card.Content>
        <Message warning>
          <Message.Header>Are you certain?</Message.Header>
          <p>Every field within the group and the group itself will be deleted.</p>
        </Message>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            basic
            size="small"
            title="Nevermind, leave this group alone."
            onClick={() => {
              this.setState({
                showConfirmRemoval: false,
                showEditGroup: false
              });
            }}
          >
            Nevermind
          </Button>
          <Button
            negative
            size="small"
            title="Yes, Delete this group completely."
            onClick={() => {
              dispatch(
                removeSchemaFieldGroup({
                  group
                })
              );
              dispatch(
                editSchemaField({
                  property: { name: 'group' },
                  newValue: groups[0].name
                })
              );

              this.setState({
                showConfirmRemoval: false,
                showEditGroup: false
              });
            }}
          >
            {`Delete it`}
          </Button>
        </div>
      </Card.Content>
    );
  };

  render() {
    if (this.state.showConfirmRemoval) {
      return <Card>{this.confirmDeletionContent()}</Card>;
    }

    if (this.state.showEditGroup) {
      return <Card>{this.editGroupContent()}</Card>;
    }

    return (
      <Card>
        {this.groupEditorcontent()}
        {this.fieldListContent()}
        {this.ctaContent()}
      </Card>
    );
  }
}
export default GroupEditor;
