import createReducer from './create-reducer';

const initial = {
  progress: 0,
  target: null
};

const reducers = {
  SET_DELETE_MACHINE_TARGET: (state, value) => {
    return {
      ...state,
      ...{
        target: value
      }
    };
  }
};

export default createReducer(initial, reducers);
