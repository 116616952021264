import './index.css';

import React, { Component } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

@withRouter
class SearchInfoElement extends Component {
  render() {
    const { item, infoElement } = this.props;
    const { icon, routes, content } = infoElement;

    const routeRoot = this.props.match ? this.props.match.path : null;

    if (
      routeRoot &&
      routes &&
      !routes.find((x) => routeRoot.startsWith(x) || routeRoot.startsWith(`/${x}`))
    ) {
      return null;
    }

    const openTrigger = (
      <a href="#">
        <Icon name={icon} />
      </a>
    );

    return (
      <Popup position="top center" size="mini" inverted trigger={openTrigger}>
        <p>{content(item)}</p>
      </Popup>
    );
  }
}

export default class SearchInfoSection extends Component {
  render() {
    const { item, model } = this.props;

    if (!model) {
      return null;
    }

    const elements = model.map((infoElement) => (
      <SearchInfoElement
        key={`info-element-${item.id}-{${infoElement.label}}`}
        item={item}
        infoElement={infoElement}
      />
    ));

    if (elements.lenth === 0) {
      return null;
    }

    return <div className="search-info-section">{elements}</div>;
  }
}
