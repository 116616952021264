import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Workspace from '../routes/workspace';
import MapHistory from './history';
import MapSearch from './search';
import MapEdit from './edit';

function componentToState(state) {
  return {
    companyView: state.companyView
  };
}

@withRouter
@connect(componentToState)
class MapWorkspace extends Component {
  rootUrl = '/maps';

  workSpaces = [
    {
      id: 'search',
      param: ':id?',
      component: MapSearch
    },
    {
      id: 'edit',
      param: ':id?',
      component: MapEdit
    },
    {
      id: 'history',
      param: ':id?',
      component: MapHistory
    }
  ];
  render() {
    return <Workspace rootUrl={this.rootUrl} workSpaces={this.workSpaces} />;
  }
}

export default MapWorkspace;
