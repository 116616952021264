import React from 'react';
import { Table } from 'semantic-ui-react';
import SchemaTableRow from './schema-table-row';

const SchemaTable = (props) => {
  const { items, model, publishedVersions, actions } = props;

  return (
    <Table padded selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Title</Table.HeaderCell>
          <Table.HeaderCell>Last Edited</Table.HeaderCell>
          <Table.HeaderCell>Versions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map((schema, index) => (
          <SchemaTableRow
            key={`card-${schema._id}`}
            actions={actions}
            schema={schema}
            index={index}
            model={model}
            publishedVersions={publishedVersions}
            schemaPermissions={props.schemaPermissions}
          ></SchemaTableRow>
        ))}
      </Table.Body>
    </Table>
  );
};

export default SchemaTable;
