import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Card, Icon, Image, Popup } from 'semantic-ui-react';

import { SearchActionMenuSection } from '../../search/actionSection';

@withRouter
class SchemaCard extends Component {
  state = {
    hovering: false
  };

  previewSchema = (schema) => {
    return (
      <div>
        {_.map(schema.fields, (f, index) => {
          return (
            <div key={`field-${index}`}>
              <p
                style={{
                  padding: 1,
                  width: '100%',
                  margin: 1
                }}
              >
                {f.component}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  hoverIn = () => {
    this.setState({
      hovering: true
    });
  };

  hoverOut = () => {
    this.setState({
      hovering: false
    });
  };

  render() {
    const { result, model, actions } = this.props;

    const { info, updatedAt, _id, author } = result;
    const { schemaName } = info;

    const onClick = () => {
      if (actions && actions.load) {
        actions.load(result);
      }
    };

    const cardDropDown = () => {
      if (!model) {
        return null;
      }
      return <SearchActionMenuSection item={result} model={model.actionSection(actions)} />;
    };

    const renderExtraAuthor = () => {
      const onClick = () => {
        const { history, result } = this.props;
        history.push(`/user/view/${result.authorId}`);
      };

      const authorName = author ? `${author.firstName} ${author.lastName}` : ``;
      const avatarUrl = author ? author.avatarUrl : ''; //mock image here

      return (
        <>
          <div>
            <Image src={avatarUrl} avatar onClick={onClick} />
          </div>
          <div>
            <span>{`${authorName}`}</span>
            <br />
            <span className="card-last-edited">
              <Icon name="history" fitted />
              {` Last Edited: ${moment(updatedAt).fromNow()}`}
            </span>
          </div>
        </>
      );
    };

    const renderExtraCompanies = () => {
      const { companies } = this.props.result;

      const onClick = (company) => () => {
        const { history } = this.props;
        history.push(`/company/view/${company.companyId}`);
      };

      const popups = companies.slice(0, 3).map((company) => {
        const companyName = company.company ? company.company.name : '';
        return (
          <Popup
            size="tiny"
            position="top center"
            trigger={<Image onClick={onClick(company)} avatar />}
            content={companyName}
          />
        );
      });

      return <div>{popups}</div>;
    };

    const renderDescription = () => {
      if (info.schemaDescription != '') {
        return (
          <div className="card-description">
            <div>{info.schemaDescription}</div>
          </div>
        );
      }
    };

    return (
      <Card
        key={`schema-preview-${_id}`}
        onMouseEnter={this.hoverIn}
        onMouseLeave={this.hoverOut}
        className={`${this.state.hovering ? 'hovering' : ''}`}
      >
        <Card.Content className="card-shrink">
          <Card.Header className="card-header center-all">
            {schemaName}
            {cardDropDown()}
          </Card.Header>
        </Card.Content>
        <Card.Content className="card-main">
          <div onClick={onClick} style={{ height: 290, overflow: 'hidden' }}>
            <div className="card-preview">{this.previewSchema(result)}</div>
            {renderDescription()}
          </div>
        </Card.Content>
        <Card.Content className="card-footer">
          <div className="card-footer-left">{renderExtraAuthor()}</div>
          <div className="card-footer-right">{renderExtraCompanies()}</div>
        </Card.Content>
      </Card>
    );
  }
}

export default SchemaCard;
