import auth0 from 'auth0-js';
import config from './config';

const CALLBACK_URI = config.REACT_APP_AUTH0_CALLBACK_URI;
const AUTH0_CLIENT_ID = config.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = config.REACT_APP_AUTH0_DOMAIN;
const AUTH0_AUDIENCE = config.REACT_APP_AUTH0_AUDIENCE;

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: AUTH0_DOMAIN,
      clientID: AUTH0_CLIENT_ID,
      redirectUri: CALLBACK_URI,
      audience: AUTH0_AUDIENCE,
      responseType: 'token id_token'
    });

    this.setFromLocalStorage();
  }

  setFromLocalStorage() {
    this.accessToken = localStorage.getItem('accessToken') || null;
    this.idToken = localStorage.getItem('idToken') || null;
    this.expiresAt = localStorage.getItem('expiresAt') || 0;
    const who = localStorage.getItem('who');
    this.who = who ? JSON.parse(who) : null;
  }

  login() {
    return this.auth0.authorize();
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(this.setSession(authResult));
        } else if (err) {
          console.log(err);
          alert(`Error: ${err.error}. Check the console for further details.`);
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
        if (err) {
          reject(err);
        }
        const who = {
          _id: (user && user.sub && user.sub.split('|')[1]) || 'FAKE_ID',
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
          department: '',
          roles: user['https://wegolook.com/user'] ? user['https://wegolook.com/user'].roles : [],
          bio: '',
          avatarUrl: user.picture
        };
        let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem('idToken', authResult.idToken);
        localStorage.setItem('who', JSON.stringify(who));
        localStorage.setItem('expiresAt', expiresAt);

        this.setFromLocalStorage();
        resolve(true);
      });
    });
  }

  renewSession() {
    if (localStorage.getItem('accessToken') !== 'true') {
      return;
    }

    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        console.log(err);
        alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('who');
    localStorage.removeItem('expiresAt');

    this.setFromLocalStorage();
  }

  /**
   * Check weather there is a user logged in or not
   *
   * @returns {boolean} True is a user is logged in, false otherwise
   */
  isLoggedIn() {
    return this.who && this.who.email ? true : false;
  }

  /**
   * Check weather the users authentication using Auth0 has expired.
   *
   * @returns {boolean} True if the auth has not epired, false if the auth is expired.
   */
  checkAuthExpiration() {
    // Check whether the current time is past the access token's expiry time
    return new Date().getTime() < this.expiresAt;
  }

  /**
   * Check weather there is a signed in user and if so, check if that user has permissions for using Schema Builder
   *
   * @returns {boolean} True if there is a user signed in and the user has permission to use schema builder, false otherwise.
   */
  isAuthenticated() {
    if (!this.who || !this.who.email) return false;

    const hasSchemaBuilderRole = this.who.roles.includes('schema builder');
    return hasSchemaBuilderRole;
  }
}

export default new Auth();
