import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import SchemaCreateMutation from '../../graphql/mutations/createSchema.graphql';
import SchemaUpdateMutation from '../../graphql/mutations/updateSchemaById.graphql';
import PublishSchema from '../../graphql//mutations/publishSchemaMutation.graphql';
import { loadSchema, clearSchema } from '../../state/actions';
import SchemaCard from '../schema/search/schema-card';
import SavedModal from '../common/saved-modal.js';

function stateToComponent(state) {
  return {
    editSchemaSchema: state.editSchemaSchema,
    who: state.who,
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
@graphql(SchemaCreateMutation, {
  name: 'createSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
@graphql(SchemaUpdateMutation, {
  name: 'updateSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
@graphql(PublishSchema, {
  name: 'publishSchema',
  options: () => ({
    errorPolicy: 'all'
  })
})
@withRouter
class SchemaEditorActions extends Component {
  state = {
    saving: false,
    showSuccessModal: false,
    savedRecord: null
  };

  updateEditingSchema = () => {
    const { saving } = this.state;

    const { editSchemaInfo, updateSchema } = this.props;

    if (saving) {
      return;
    }

    this.setState({
      saving: true
    });

    const variables = {
      ...this.getSchemaToPersist(),
      ...{ _id: editSchemaInfo._id }
    };

    console.log(variables, 'updateSchemaVariables');

    updateSchema({ variables })
      .then((record) => {
        if (record.errors) {
          return this.setState({
            saving: false,
            error: record.errors
          });
        }

        this.setState({
          saving: false,
          savedRecord: record.data.schemaUpdateById.record
        });
      })
      .catch((err) => {
        this.setState({
          saving: false,
          error: err
        });
        console.log(err);
      });
  };

  getSchemaToPersist = () => {
    const { editSchemaSchema, editSchemaInfo } = this.props;
    const { fields, groups } = editSchemaSchema;
    const { info, companies, lineItems, professions } = editSchemaInfo;

    const _companies = _.map(companies, (c) => {
      return {
        companyId: c.companyId,
        name: c.name,
        price: c.price,
        lineItems: _.map(c.lineItems, (a) => {
          return {
            lineItemId: a.lineItemId,
            lookerFee: a.lookerFee,
            price: a.price,
            required: a.required,
            base: a.base,
            conditions: a.conditions
          };
        }),
        pipeline: c.pipeline
      };
    });

    const _lineItems = _.map(lineItems, (a) => {
      return {
        lineItemId: a.lineItemId,
        lookerFee: a.lookerFee,
        price: a.price,
        required: a.required,
        base: a.base,
        conditions: a.conditions
      };
    });

    const _fields = _.map(fields, (f) => {
      const {
        id,
        index,
        label,
        defaultValue,
        component,
        highlightedField,
        relationship,
        properties,
        reports,
        contexts,
        options,
        validations,
        tooltipId,
        uniqueFieldId,
        metadata,
        group
      } = f;

      return {
        id,
        index,
        label,
        defaultValue,
        component,
        highlightedField: highlightedField === 'true' || highlightedField === true,
        relationship,
        properties,
        reports,
        contexts,
        options,
        validations,
        tooltipId,
        group,
        metadata,
        uniqueFieldId
      };
    });

    const _groups = _.map(groups, (g) => {
      const { name, groupType, fields } = g;
      return {
        name,
        groupType,
        fields
      };
    });

    const _deliveryOptions = _.map(info.deliveryOptions, (d) => {
      return {
        ...d,
        lookerDays: parseFloat(d.lookerDays)
      };
    });

    const _info = {
      ...info,
      estimatedTime: {
        min: parseFloat(info.estimatedTime.min),
        max: parseFloat(info.estimatedTime.max)
      },
      deliveryOptions: _deliveryOptions
    };

    const variables = {
      lineItems: _lineItems,
      professions,
      companies: _companies,
      info: _info,
      fields: _fields,
      groups: _groups,
      status: 'draft'
    };

    return variables;
  };

  saveEditingSchema = () => {
    const { saving } = this.state;

    const { dispatch, history, createSchema } = this.props;

    if (saving) {
      return;
    }

    this.setState({
      saving: true
    });

    const variables = this.getSchemaToPersist();

    createSchema({ variables })
      .then((record) => {
        if (record.errors) {
          return this.setState({
            saving: false,
            error: record.errors
          });
        }

        this.setState({
          saving: false,
          savedRecord: record.data.schemaCreate.record
        });

        const _record = record.data.schemaCreate.record;
        dispatch(loadSchema(_record));
        history.push(`/schema-editor/${_record._id}/edit-info`);
      })
      .catch((err) => {
        this.setState({
          saving: false,
          error: err
        });
        console.log(err);
      });
  };

  clearSchema = () => {
    const { dispatch, history } = this.props;
    dispatch(clearSchema());
    history.push('/schema-editor/new/edit-info');
  };

  clearError = () => {
    this.setState({
      error: null
    });
  };

  errorMessage = () => {
    let errors = this.state.error.errors || this.state.error;

    if (!_.isArray(errors)) {
      errors = [errors];
    }

    const listofErrors = errors.map((er) => {
      return (
        <div>
          <Header icon="x" content={`${er.code}`} />
          <Modal.Content>{`${er.message}`}</Modal.Content>
          <Modal.Content>
            <pre>{`${er.stack}`}</pre>
          </Modal.Content>
        </div>
      );
    });

    return (
      <Modal basic size="small" open={errors.length}>
        {listofErrors}
        <Modal.Actions>
          <Button color="green" inverted onClick={this.clearError}>
            <Icon name="checkmark" /> Understood
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  clearSavedRecordAndEdit = () => {
    const { history } = this.props;
    const { savedRecord } = this.state;

    this.setState({
      savedRecord: null
    });

    const _id = savedRecord._id ? savedRecord._id : 'new';

    history.push(`/schema-editor/${_id}/edit-info`);
  };

  clearSavedRecord = () => {
    const { history, dispatch } = this.props;

    this.setState({
      savedRecord: null
    });

    //RESET FORM
    dispatch(clearSchema());
    history.push(`/schema-editor/new/edit-info`);
  };

  publishSchema = async () => {
    const { editSchemaInfo, publishSchema } = this.props;

    const data = await publishSchema({
      variables: { _id: editSchemaInfo._id }
    });
    return data;
  };

  successMessage = () => {
    const { savedRecord, saving } = this.state;

    return (
      <SavedModal
        savedRecord={savedRecord}
        saving={saving}
        cardComponent={SchemaCard}
        editAction={this.clearSavedRecordAndEdit}
        newAction={this.clearSavedRecord}
        publishAction={this.publishSchema}
      />
    );
  };

  preventsaving = () => {
    const { saving, error } = this.state;

    //TODO: handle error state,
    if (error) {
      console.log(error);
    }

    if (saving) {
      return true;
    }

    return false;
  };

  render() {
    const { editSchemaSchema } = this.props;
    const { saving, error, savedRecord } = this.state;

    const saveOrUpdate = editSchemaSchema._id ? (
      <Button size="big" primary onClick={this.updateEditingSchema} loading={saving}>
        <Icon name="save" />
        Update
      </Button>
    ) : (
      <Button
        size="big"
        primary
        onClick={this.saveEditingSchema}
        disabled={this.preventsaving()}
        loading={saving}
      >
        <Icon name="save" />
        Save
      </Button>
    );

    const icon = editSchemaSchema._id ? 'trash' : 'edit';
    const ctaText = editSchemaSchema._id ? 'Clear' : 'New Schema';
    let content = (
      <Button.Group>
        {saveOrUpdate}
        <Button size="big" primary onClick={this.clearSchema} loading={saving}>
          <Icon name={icon} />
          {ctaText}
        </Button>
      </Button.Group>
    );

    if (error) {
      content = this.errorMessage();
    }

    if (savedRecord) {
      content = this.successMessage();
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          position: 'fixed',
          bottom: 8,
          right: 16
        }}
      >
        {content}
      </div>
    );
  }
}
export default SchemaEditorActions;
