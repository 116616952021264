import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';

import activityBarButtons from '../routes/activitybar-workspaces';

function componentToState(state) {
  return {
    activityBar: state.activityBar
  };
}

@withRouter
@connect(componentToState)
class ActivityBar extends Component {
  activityBarButtons = () => {
    return activityBarButtons;
  };

  activityButtonToHtml = (activityBtn) => {
    const { id, icon, menuLocation } = activityBtn;
    const { activityBar, location, history } = this.props;
    const _isActive = location.pathname.includes(activityBtn.id + '/');

    let className = 'activitybar-list-item';
    if (menuLocation === 'footer') {
      className = `${className} activity-bar-footer`;
    }

    const _label = activityBtn.label || ' ';

    function navTo(event) {
      history.push(`/${activityBtn.id}`);
    }

    return (
      <Button
        active={_isActive}
        title={activityBtn.label}
        key={`activity-button-${id}`}
        className={className}
        onClick={navTo}
      >
        <Icon name={`${icon}`} />
        <span className={`activitybar-list-item-label ${activityBar.size}`}>{_label}</span>
      </Button>
    );
  };

  footerButtonGroup = (activityBtn) => {
    return activityBtn.activityBarGroup === 'footer';
  };

  mainButtonGroup = (activityBtn) => {
    return activityBtn.activityBarGroup !== 'footer';
  };

  render() {
    const { activityBar } = this.props;

    const activityBarContent = _.chain(this.activityBarButtons())
      .filter(this.mainButtonGroup)
      .map(this.activityButtonToHtml)
      .value();

    const activityBarFooterContent = _.chain(this.activityBarButtons())
      .filter(this.footerButtonGroup)
      .map(this.activityButtonToHtml)
      .value();

    return (
      <div className={`activity-bar ${activityBar.size}`}>
        <div className="activitybar-group">
          <Button.Group className="activitybar-list" vertical primary>
            {activityBarContent}
          </Button.Group>
        </div>
        <div className="activitybar-group">
          <Button.Group className="activitybar-list" vertical primary>
            {activityBarFooterContent}
          </Button.Group>
        </div>
      </div>
    );
  }
}

export default ActivityBar;
