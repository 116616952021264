import './index.css';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import MetaInfoEditor from '../metainfo-editor';

function stateToComponent(state) {
  return { editSchemaSchema: state.editSchemaSchema };
}

@connect(stateToComponent)
class EditInfoView extends Component {
  render() {
    const { activeWorkspace } = this.props;
    const { info } = this.props.editSchemaSchema;

    return (
      <div className="edit-view">
        <div className="edit-view-metainfo">
          <MetaInfoEditor activeWorkspace={activeWorkspace} data={info} />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
export default EditInfoView;
