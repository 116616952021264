import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ValidatedForm from 'semantic-ui-react-validated-form';

import { editSchemaFieldProperty, editUniqueFieldProperty } from '../../../../state/actions';
import EditablePropertyField from '../mappers/to-editable-property';
import ValidationSchema from './schema';

@withRouter
@connect()
class PropertiesEditor extends Component {
  render() {
    const { dispatch, field } = this.props;
    const isSchemaEditor = this.props.match.path.startsWith('/schema-editor');

    if (!field) {
      return null;
    }

    const fields = _.map(field.properties, (property, index) => {
      let args = {
        keyPrefix: `edit-detail-property`,
        arrayPrefix: 'properties',
        dispatchAction: isSchemaEditor ? editSchemaFieldProperty : editUniqueFieldProperty,
        dispatch,
        field,
        property,
        index
      };

      return <EditablePropertyField {...args} />;
    });

    return (
      <ValidatedForm
        intialValue={field}
        value={field}
        validateSchema={ValidationSchema}
        readOnly={false}
        onChange={() => {}}
      >
        {fields}
      </ValidatedForm>
    );
  }
}
export default PropertiesEditor;
