import { SearchActionButtonSection } from '../../search/actionSection';
import SearchTable from '../../search/search-table';

function actionSection(actions) {
  return [
    {
      icon: 'hand point up outline',
      label: 'Select',
      action: actions.select,
      routes: ['schema-editor', 'settings']
    }
  ];
}

export default [
  {
    id: 'table-layout',
    icon: 'list layout',
    label: 'Table',
    description: 'Convert layout to a table. Less detail; more per page',
    model: [
      {
        label: 'Name',
        property: 'name',
        type: 'string'
      },
      {
        label: 'Category',
        property: 'category',
        type: 'string'
      },
      {
        label: 'Price',
        property: (item) => {
          return `${item.price.amount} ${item.price.currency}`;
        },
        type: 'string'
      },
      {
        label: 'Looker Fee',
        property: (item) => {
          return `${item.lookerFee.amount} ${item.lookerFee.currency}`;
        },
        type: 'string'
      },
      {
        label: 'Item Code',
        property: 'itemCode',
        type: 'string'
      },
      {
        label: 'Tax Code',
        property: 'taxCode',
        type: 'string'
      },

      {
        label: 'Actions',
        type: 'component',
        component: SearchActionButtonSection,
        model: actionSection
      }
    ],
    component: SearchTable
  }
];
