import _ from 'lodash';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Button, Form, Grid, Header, Icon, List, Message, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import departments from '../../api/data/departments';
import UpdateUserProfileInfoById from '../../graphql/mutations/updateUserProfileInfoById.graphql';
import { updateWho } from '../../state/actions';
import validateProfileSchema from './profile-schema';

const departmentOptions = _.map(departments, (d) => {
  return { key: d.id, text: d.label, value: d.id };
});

const DEFAULT_AVATAR_URL = 'https://randomuser.me/api/portraits/lego/0.jpg';

function stateToComponent(state) {
  return { who: state.who };
}

@connect(stateToComponent)
@graphql(UpdateUserProfileInfoById, {
  name: 'updateUser',
  options: () => ({
    errorPolicy: 'all'
  })
})
class ProfileSettings extends Component {
  state = {
    userProfileImage: this.props.who.avatarUrl ? `${this.props.who.avatarUrl}` : DEFAULT_AVATAR_URL,
    uploadedAvatarImage: null,
    formData: {
      ...{
        firstName: '',
        lastName: '',
        email: '',
        bio: '',
        department: ''
      },
      ...this.props.who
    },
    formErrors: { error: null },
    submitting: false,
    hasSubmitted: false,
    readOnly: false
  };

  componentDidMount() {}

  confirmationDialog = () => {};

  toggleReadOnly = () => {
    this.setState({
      readOnly: !this.state.readOnly
    });
  };

  updateProfile = () => {
    this.setState({
      submitting: true,
      hasSubmitted: true
    });
  };

  renderDropzoneContents = ({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
    let overlay = null;
    if (isDragActive) {
      overlay = <Icon name="upload" color="green" size="huge" />;
    }
    if (isDragReject) {
      overlay = <Icon name="cancel" color="red" size="huge" />;
    }

    let avatarUrl = this.state.userProfileImage;

    let style = {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${avatarUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      maxHeight: 200
    };

    return <div style={style}>{overlay}</div>;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          userProfileImage: reader.result,
          uploadedAvatarImage: acceptedFiles[0]
        });
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  renderHeader = () => {
    return (
      <Header as="h3">
        <Header.Content>
          Update Profile
          <Header.Subheader>Update information about yourself</Header.Subheader>
        </Header.Content>
      </Header>
    );
  };

  renderSubmitErrors = () => {
    const {
      formErrors,

      hasSubmitted
    } = this.state;

    return hasSubmitted && formErrors.error ? (
      <Message negative>
        <Message.Header>Cannot Submit</Message.Header>
        <List>
          {formErrors.error.details.map((detail, i) => {
            return <List.Item key={`error-on-submit-${i}`}>{detail.message}</List.Item>;
          })}
        </List>
      </Message>
    ) : null;
  };

  render() {
    const { who } = this.props;
    const { formData, submitting, readOnly } = this.state;

    const errorMessages = this.renderSubmitErrors();

    const FormHTML = (
      <Grid stackable reversed="tablet computer" width={16} style={{ margin: 0 }}>
        <Grid.Row>
          <Grid.Column width={12}>
            <div>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  onChange={(event, data) => {}}
                />
                <Form.Input fluid label="Last Name" placeholder="Last Name" name="lastName" />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input fluid label="Email" name="email" placeholder="Email" />
                <Form.Select
                  fluid
                  label="Department"
                  options={departmentOptions}
                  placeholder="Department"
                  name="department"
                />
              </Form.Group>

              <Form.TextArea
                label="About"
                rows={4}
                placeholder="Tell us more about you..."
                name="bio"
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <br />
              <Dropzone
                ref={(ref) => {
                  this._dropzone = ref;
                }}
                accept="image/jpeg, image/png"
                style={{
                  position: 'relative',
                  width: 200,
                  height: 200,
                  borderRadius: 5,
                  borderWidth: 2,
                  borderStyle: 'dotted',
                  borderColor: '#eeeeee'
                }}
                acceptStyle={{
                  borderStyle: 'solid',
                  borderColor: '#21ba45'
                }}
                rejectStyle={{
                  borderStyle: 'solid',
                  borderColor: '#db2828'
                }}
                multiple={false}
                onDrop={this.onDrop}
              >
                {this.renderDropzoneContents}
              </Dropzone>
              <Button
                style={{ width: 200, marginTop: '.8em' }}
                onClick={() => {
                  this._dropzone.open();
                }}
              >
                Upload a Photo
              </Button>
              <br />
              <div style={{ dispay: 'flex' }}>
                <Header as="h4" textAlign="left">
                  <Icon name="lock" />
                  <Header.Content>
                    Role
                    <Header.Subheader>{formData.roles}</Header.Subheader>
                  </Header.Content>
                </Header>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5}>
            <Button.Group widths={2}>
              <Button onClick={this.toggleReadOnly}>
                {readOnly ? `Edit Profile` : 'View Profile'}
              </Button>
              <Button primary onClick={this.updateProfile}>
                Update Profile
              </Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column width={7}>{errorMessages}</Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{this.confirmationDialog()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <div className="edit-view">
        <Grid.Row>
          <Grid.Column width={16}>{this.renderHeader()}</Grid.Column>
        </Grid.Row>
        <Segment className="settings-profile">
          <ValidatedForm
            intialValue={formData}
            validateSchema={validateProfileSchema}
            onValidate={(data) => {}}
            value={formData}
            onChange={(event, { field, form, submitting }, err) => {
              this.setState({
                formData: form,
                formErrors: err,
                submitting: submitting
              });
            }}
            onSubmit={(event, { form }, err) => {
              const { updateUser, dispatch } = this.props;
              const uploadedAvatarImage = this.state.uploadedAvatarImage;
              let variables = form;
              if (uploadedAvatarImage) {
                variables = { ...variables, avatarUpload: uploadedAvatarImage };
              }
              updateUser({ variables }).then(({ data }) => {
                dispatch(updateWho(data.userUpdateById.record));
                this.setState({
                  submitting: false
                });
              });
            }}
            readOnly={readOnly}
            submitting={submitting}
            submitMessage={`Updating your profile,  ${who.firstName}`}
          >
            {FormHTML}
          </ValidatedForm>
        </Segment>
      </div>
    );
  }
}

export default ProfileSettings;
