import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Form, Header, List, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';
import CodeMirror from 'react-codemirror';

import getUniqueFieldByIdAndVersion from '../../../graphql/queries/getUniqueFieldByIdAndVersion.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(getUniqueFieldByIdAndVersion, {
  name: 'formData',
  options: (props) => {
    const { id, version } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id,
        version
      }
    };
  }
})
// class UniqueFieldView extends Component {
//   renderFormList(data) {
//     const formData = data.schemaByIdAndVersion || data;
//     const formList = formData
//       ? formData.map((form) => {
//           const description = `
//           fields: ${form.fields.length}
//           `;

//           return (
//             <List.Item key={`associated-form-${form._id}`}>
//               <List.Icon name="file alternate outline" size="large" verticalAlign="middle" />
//               <List.Content>
//                 <List.Header as="a">{form.info.schemaName}</List.Header>
//                 <List.Description as="a">{description}</List.Description>
//               </List.Content>
//             </List.Item>
//           );
//         })
//       : null;

//     return formList ? (
//       <Segment>
//         <Header>Associated Forms:</Header>
//         <List divided relaxed>
//           {formList}
//         </List>
//       </Segment>
//     ) : null;
//   }

//   renderuniqueField(data) {
//     const uniqueField = data.uniqueFieldAtVersion || data;

//     if (data.loading || !uniqueField) {
//       return <div> Loading </div>;
//     }
//     const formData = uniqueField;

//     return (
//       <div className="unique-field-view">
//         <ValidatedForm intialValue={formData} value={formData} readOnly={true}>
//           <Form.Field>{uniqueField._id}</Form.Field>
//           <Form.Field>{uniqueField.id}</Form.Field>
//           <Form.Field>{uniqueField.name}</Form.Field>
//         </ValidatedForm>

//         {this.renderFormList(data)}
//       </div>
//     );
//   }
//   render() {
//     let { uniqueField, uniqueFieldData } = this.props;

//     return (
//       <div className="search-workspace uniqueField-search-workspace">
//         {this.renderuniqueField(uniqueField || uniqueFieldData)}
//       </div>
//     );
//   }
// }
class UniqueFieldView extends Component {
  renderform(data) {
    const form = data.getUniqueFieldByIdAndVersion || data;

    if (data.loading || !form) {
      return <div> Loading </div>;
    }
    const options = {
      lineNumbers: true,
      readOnly: true,
      mode: { name: 'javascript', json: true },
      lineWrapping: true,
      theme: 'eclipse'
    };

    return <CodeMirror value={JSON.stringify(form, null, 2)} options={options} />;
  }
  render() {
    let { form, formData } = this.props;

    return (
      <div className="search-workspace form-search-workspace">
        {this.renderform(form || formData)}
      </div>
    );
  }
}
export default UniqueFieldView;
