import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

import { createTypeNameCleanerLink } from './apollo-typename-cleaner';
import { createUploadLink } from './apollo-upload-client';
import { createStandardVariablesLink } from './standard-variables';

import auth from '../auth.js';
import config from '../config';

const BASE_URL = config.REACT_APP_BASE_URL;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const uploadLink = createUploadLink({
  uri: `${BASE_URL}/graphql`
});

const authLink = setContext((request, previousContext) => {
  return {
    headers: {
      Authorization: `Bearer ${auth.accessToken}`
    }
  };
});

const standardVariablesLink = createStandardVariablesLink();
const typenameCleanerLink = createTypeNameCleanerLink();

const link = ApolloLink.from([
  authLink,
  errorLink,
  standardVariablesLink,
  typenameCleanerLink,
  uploadLink
]);

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    if (object.__typename && object._id) {
      return object.__typename + '-' + object._id;
    }

    return object._id || null;
  }
});

const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache
});

export default client;
