function action(type, args) {
  let _action = { type };
  if (args && args.length) {
    _action.value = args[0];
  }
  return _action;
}

function actionCreator(type) {
  return function() {
    return action(type, arguments);
  };
}

/*WHO?*/
export const updateWho = actionCreator('UPDATE_WHO');

/* LOGIN SCHEMA */
export const updateLoginFirstName = actionCreator('UPDATE_LOGIN_FIRST_NAME');
export const updateLoginLastName = actionCreator('UPDATE_LOGIN_LAST_NAME');
export const updateLoginEmail = actionCreator('UPDATE_LOGIN_EMAIL');
export const updateLoginPassword = actionCreator('UPDATE_LOGIN_PASSWORD');
export const updateLoginTOC = actionCreator('UPDATE_LOGIN_TOC');

/* EDIT-VIEW / EDITOR */

export const loadSchema = actionCreator('LOAD_SCHEMA');
export const clearSchema = actionCreator('CLEAR_SCHEMA');
export const editSchemaInfo = actionCreator('EDIT_SCHEMA_INFO');
export const editSchemaInfoTags = actionCreator('EDIT_SCHEMA_INFO_TAGS');
export const updateSchemaEditorTab = actionCreator('UPDATE_SCHEMA_EDITOR_TAB');

/* EDIT SCHEMA DEFAULTS */
export const setSchemaDefaultLineItems = actionCreator('SET_DEFAULT_LINE_ITEMS');

export const setSchemaDefaultFields = actionCreator('SET_DEFAULT_FIELDS');
//field

export const changeEditingField = actionCreator('CHANGE_EDITING_FIELD');

export const addSchemaField = actionCreator('ADD_SCHEMA_FIELD');
export const updateSchemaField = actionCreator('UPDATE_SCHEMA_FIELD');
export const removeSchemaField = actionCreator('REMOVE_SCHEMA_FIELD');
export const editSchemaField = actionCreator('EDIT_SCHEMA_FIELD');
export const moveSchemaField = actionCreator('MOVE_SCHEMA_FIELD');
export const moveSchemaGroup = actionCreator('MOVE_SCHEMA_GROUP');
// export const cloneSchemaField = actionCreator('CLONE_SCHEMA_FIELD');
//props
export const editSchemaFieldProperty = actionCreator('EDIT_SCHEMA_FIELD_PROPERTY');
//option
export const editSchemaFieldOption = actionCreator('EDIT_SCHEMA_FIELD_OPTION');
export const addSchemaFieldOption = actionCreator('ADD_SCHEMA_FIELD_OPTION');
export const removeSchemaFieldOption = actionCreator('REMOVE_SCHEMA_FIELD_OPTION');
export const moveSchemaFieldOption = actionCreator('MOVE_SCHEMA_FIELD_OPTION');
export const editSchemaFieldTooltip = actionCreator('EDIT_SCHEMA_FIELD_TOOLTIP');
export const removeSchemaFieldTooltip = actionCreator('REMOVE_SCHEMA_FIELD_TOOLTIP');
export const editSchemaFieldUniqueField = actionCreator('EDIT_SCHEMA_FIELD_UNIQUEFIELD');
export const removeSchemaFieldUniqueField = actionCreator('REMOVE_SCHEMA_FIELD_UNIQUEFIELD');

//metadata
export const editSchemaFieldMetadata = actionCreator('EDIT_SCHEMA_FIELD_METADATA');
export const addSchemaFieldMetadata = actionCreator('ADD_SCHEMA_FIELD_METADATA');
export const removeSchemaFieldMetadata = actionCreator('REMOVE_SCHEMA_FIELD_METADATA');
export const moveSchemaFieldMetadata = actionCreator('MOVE_SCHEMA_FIELD_METADATA');

//groups
export const editSchemaFieldGroupsOptions = actionCreator('EDIT_SCHEMA_FIELD_GROUPS_OPTIONS');
export const editSchemaFieldGroup = actionCreator('EDIT_SCHEMA_FIELD_GROUP');
export const editSchemaFieldGroupDetails = actionCreator('EDIT_SCHEMA_FIELD_GROUP_DETAILS');
export const removeSchemaFieldGroup = actionCreator('REMOVE_SCHEMA_FIELD_GROUP');
export const removeInvalidFieldGroup = actionCreator('REMOVE_INVALID_FIELD_GROUP');

export const addSchemaFieldGroup = actionCreator('ADD_SCHEMA_FIELD_GROUP');

export const editSchemaGroup = actionCreator('EDIT_SCHEMA_GROUP');
export const changeVisibleGroupName = actionCreator('CHANGE_VISIBLE_GROUP_NAME');

//validations
export const editSchemaFieldValidation = actionCreator('EDIT_SCHEMA_FIELD_VALIDATION');
//contexts
export const editSchemaFieldContexts = actionCreator('EDIT_SCHEMA_FIELD_CONTEXTS');

export const addSchemaFieldConditionAny = actionCreator('ADD_SCHEMA_FIELD_CONDITION_ANY');
export const removeSchemaFieldConditionAny = actionCreator('REMOVE_SCHEMA_FIELD_CONDITION_ANY');

export const addSchemaFieldConditionAll = actionCreator('ADD_SCHEMA_FIELD_CONDITION_ALL');
export const removeSchemaFieldConditionAll = actionCreator('REMOVE_SCHEMA_FIELD_CONDITION_ALL');
export const updateSchemaFieldConditionAll = actionCreator('UPDATE_SCHEMA_FIELD_CONDITION_ALL');
//reports
export const editSchemaFieldReports = actionCreator('EDIT_SCHEMA_FIELD_REPORTS');

/* EDIT-VIEW / INFO */
export const updateSchemaInfo = actionCreator('UPDATE_SCHEMA_INFO');
export const updateSchemaCompanies = actionCreator('UPDATE_SCHEMA_COMPANIES');
export const updateSchemaLineItems = actionCreator('UPDATE_SCHEMA_LINE_ITEMS');
export const addSchemaInfoCompany = actionCreator('ADD_SCHEMA_INFO_COMPANY');
export const addSchemaInfoLineItem = actionCreator('ADD_SCHEMA_INFO_LINE_ITEM');
export const updateSchemaInfoLineItem = actionCreator('UPDATE_SCHEMA_INFO_LINE_ITEM');
export const removeSchemaInfoCompany = actionCreator('REMOVE_SCHEMA_INFO_COMPANY');
export const removeSchemaInfoLineItem = actionCreator('REMOVE_SCHEMA_INFO_LINE_ITEM');
export const updateTagOptions = actionCreator('UPDATE_TAG_OPTIONS');
export const updateEquipmentOptions = actionCreator('UPDATE_EQUIPMENT_OPTIONS');
export const updateSubSchemaOptions = actionCreator('UPDATE_SUB_SCHEMA_OPTIONS');
export const updateSchemaGroupOptions = actionCreator('UPDATE_SCHEMA_GROUP_OPTIONS');
export const updateTaxCodeOptions = actionCreator('UPDATE_TAXCODE_OPTIONS');
export const updateItemCodeOptions = actionCreator('UPDATE_ITEMCODE_OPTIONS');
export const updateGLAccountOptions = actionCreator('UPDATE_GLACCOUNT_OPTIONS');
export const updateCompanySchemaName = actionCreator('UPDATE_COMPANY_SCHEMA_NAME');

export const updateSchemaBaseDeliveryOptions = actionCreator('UPDATE_BASE_DELIVERY_OPTIONS');

export const removeSchemaBaseDeliveryOption = actionCreator('REMOVE_SCHEMA_BASE_DELIVERY_OPTION');
export const addSchemaInfoMetaData = actionCreator('ADD_SCHEMA_INFO_METADATA');
export const updateSchemaInfoMetadata = actionCreator('UPDATE_SCHEMA_INFO_METADATA');

export const updateProfessions = actionCreator('UPDATE_PROFESSIONS');
export const addProfessions = actionCreator('ADD_PROFESSIONS');
export const updateSchemaInfoProfessions = actionCreator('UPDATE_SCHEMA_INFO_PROFESSIONS');
export const deleteProfession = actionCreator('DELETE_PROFESSION_ROW');

/*USER-SEARCH*/
export const updateUsersLayout = actionCreator('UPDATE_USERS_LAYOUT');
export const updateUsersPage = actionCreator('UPDATE_USERS_PAGE');
export const updateUsersPerPage = actionCreator('UPDATE_USERS_PERPAGE');
export const updateUsersSearchTerm = actionCreator('UPDATE_USERS_SEARCH_TERM');
export const updateUsersSort = actionCreator('UPDATE_USERS_SORT');
export const updateUsersSearchFacetsRoles = actionCreator('UPDATE_USERS_SEARCH_FACETS_ROLES');
export const updateUsersSearchFacetsDepartments = actionCreator(
  'UPDATE_USERS_SEARCH_FACETS_DEPARTMENTS'
);

export const loadUser = actionCreator('LOAD_USER');

/*TOOLTIP-SEARCH*/
export const updateTooltipsLayout = actionCreator('UPDATE_TOOLTIPS_LAYOUT');
export const updateTooltipsPage = actionCreator('UPDATE_TOOLTIPS_PAGE');
export const updateTooltipsPerPage = actionCreator('UPDATE_TOOLTIPS_PERPAGE');
export const updateTooltipsTerm = actionCreator('UPDATE_TOOLTIPS_SEARCH_TERM');
export const updateTooltipsFacets = actionCreator('UPDATE_TOOLTIPS_SEARCH_FACETS');
export const clearTooltipSearch = actionCreator('CLEAR_TOOLTIP_SEARCH');
export const updateTooltipsSort = actionCreator('UPDATE_TOOLTIPS_SORT');

export const clearTooltip = actionCreator('CLEAR_TOOLIP');
export const loadTooltip = actionCreator('LOAD_TOOLTIP');
export const updateTooltip = actionCreator('UPDATE_TOOLTIP');

/*MAP-SEARCH*/
export const updateMapSort = actionCreator('UPDATE_MAP_SORT');
export const updateMapSearchTerm = actionCreator('UPDATE_MAP_SEARCH_TERM');
export const updateMapPage = actionCreator('UPDATE_MAP_PAGE');
export const updateMapPerPage = actionCreator('UPDATE_MAP_PERPAGE');
export const updateMapTagsFacets = actionCreator('UPDATE_MAP_TAGS_FACETS');
export const updateMapFilterTags = actionCreator('UPDATE_MAP_FILTER_TAGS');

/*UNIQUE-FIELD-SEARCH*/
export const updateUniqueFieldsLayout = actionCreator('UPDATE_UNIQUEFIELDS_LAYOUT');
export const updateUniqueFieldsPage = actionCreator('UPDATE_UNIQUEFIELDS_PAGE');
export const updateUniqueFieldsPerPage = actionCreator('UPDATE_UNIQUEFIELDS_PERPAGE');
export const updateUniqueFieldsTerm = actionCreator('UPDATE_UNIQUEFIELDS_SEARCH_TERM');
export const updateUniqueFieldsTagsFacets = actionCreator('UPDATE_UNIQUEFIELDS_SEARCH_TAGS_FACETS');
export const updateUniqueFieldsComponentFacets = actionCreator(
  'UPDATE_UNIQUEFIELDS_SEARCH_COMPONENT_FACETS'
);
export const updateUniqueFieldsSort = actionCreator('UPDATE_UNIQUEFIELDS_SORT');

/*SEARCHABLE-ENTITY-SEARCH*/
export const updateSearchableEntitiesLayout = actionCreator('UPDATE_SEARCHABLEENTITIES_LAYOUT');
export const updateSearchableEntitiesPage = actionCreator('UPDATE_SEARCHABLEENTITIES_PAGE');
export const updateSearchableEntitiesPerPage = actionCreator('UPDATE_SEARCHABLEENTITIES_PERPAGE');
export const updateSearchableEntitiesTerm = actionCreator('UPDATE_SEARCHABLEENTITIES_SEARCH_TERM');
export const updateSearchableEntitiesFacets = actionCreator(
  'UPDATE_SEARCHABLEENTITIES_SEARCH_FACETS'
);

/*LINE-ITEM-VIEW*/
export const loadLineItem = actionCreator('LOAD_LINEITEM');
export const updateLineItem = actionCreator('UPDATE_LINEITEM');

/*LINE-ITEM-SEARCH*/
export const updateLineItemsLayout = actionCreator('UPDATE_LINE_ITEMS_LAYOUT');
export const updateLineItemsPage = actionCreator('UPDATE_LINE_ITEMS_PAGE');
export const updateLineItemsPerPage = actionCreator('UPDATE_LINE_ITEMS_PERPAGE');
export const updateLineItemsTerm = actionCreator('UPDATE_LINE_ITEMS_SEARCH_TERM');
export const updateLineItemsFacets = actionCreator('UPDATE_LINE_ITEMS_SEARCH_FACETS');
export const updateLineItemsSort = actionCreator('UPDATE_LINE_ITEMS_SORT');

/*SCHEMA-SEARCH*/
export const updateSchemasLayout = actionCreator('UPDATE_SCHEMAS_LAYOUT');
export const updateSchemasPage = actionCreator('UPDATE_SCHEMAS_PAGE');
export const updateSchemasPerPage = actionCreator('UPDATE_SCHEMAS_PERPAGE');
export const updateSchemasTerm = actionCreator('UPDATE_SCHEMAS_SEARCH_TERM');
export const updateSchemasSchemaGroupFacets = actionCreator(
  'UPDATE_SCHEMAS_SEARCH_SCHEMA_GROUP_FACETS'
);
export const updateSchemasStatusFacets = actionCreator('UPDATE_SCHEMAS_SEARCH_STATUS_FACETS');
export const updateSchemasTagsFacets = actionCreator('UPDATE_SCHEMAS_SEARCH_TAGS_FACETS');
export const updateSchemasClientFacets = actionCreator('UPDATE_SCHEMAS_SEARCH_CLIENT_FACETS');
export const updateSchemasSort = actionCreator('UPDATE_SCHEMAS_SEARCH_SORT');

/*COMPANY-SEARCH*/
export const updateCompaniesLayout = actionCreator('UPDATE_COMPANIES_LAYOUT');
export const updateCompaniesPage = actionCreator('UPDATE_COMPANIES_PAGE');
export const updateCompaniesSort = actionCreator('UPDATE_COMPANIES_SORT');
export const updateCompaniesPerPage = actionCreator('UPDATE_COMPANIES_PERPAGE');
export const updateCompaniesTerm = actionCreator('UPDATE_COMPANIES_SEARCH_TERM');
export const updateCompaniesFacets = actionCreator('UPDATE_COMPANIES_SEARCH_FACETS');

export const loadCompany = actionCreator('LOAD_COMPANY');
export const updateCompany = actionCreator('UPDATE_COMPANY');
export const clearCompany = actionCreator('CLEAR_COMPANY');
export const addCompanyBillingCenter = actionCreator('ADD_COMPANY_BILLING_CENTER');
export const removeCompanyBillingCenter = actionCreator('REMOVE_COMPANY_BILLING_CENTER');
/*ACTIVITY BAR*/
export const updateActivitybarSize = actionCreator('UPDATE_ACTIVITYBAR_SIZE');

/*SCHEMA-COPY-MACHINE*/
export const setCopyMachineTargetId = actionCreator('SET_COPY_MACHINE_TARGET_ID');
export const addCopyMachineCopy = actionCreator('ADD_COPY_MACHINE_COPY');
export const removeCopyMachineCopy = actionCreator('REMOVE_COPY_MACHINE_COPY');
export const updateCopyMachineCopies = actionCreator('UPDATE_COPY_MACHINE_COPIES');
export const clearCopyMachineCopies = actionCreator('CLEAR_COPY_MACHINE_COPIES');

/*SCHEMA-DELETE-MACHINE*/
export const setDeleteMachineTarget = actionCreator('SET_DELETE_MACHINE_TARGET');

/* DEFAULTS */
export const loadDefaults = actionCreator('LOAD_DEFAULTS');
export const addDefaultLineItem = actionCreator('ADD_DEFAULT_LINE_ITEM');
export const removeDefaultLineItem = actionCreator('REMOVE_DEFAULT_LINE_ITEM');
export const addDefaultField = actionCreator('ADD_DEFAULT_FIELD');
export const removeDefaultField = actionCreator('REMOVE_DEFAULT_FIELD');

/* MAP_EDIT */
export const editMap = actionCreator('EDIT_MAP');
export const addMapProperties = actionCreator('ADD_MAP_PROPERTIES');
export const removeMapProperties = actionCreator('REMOVE_MAP_PROPERTIES');
export const moveMapProperties = actionCreator('MOVE_MAP_PROPERTIES');
export const editMapProperties = actionCreator('EDIT_MAP_PROPERTIES');
export const loadMap = actionCreator('LOAD_MAP');
export const clearMap = actionCreator('CLEAR_MAP');

/* UNIQUE_FIELD_EDIT */
export const clearUniqueField = actionCreator('CLEAR_UNIQUE_FIELD');
export const loadUniqueField = actionCreator('LOAD_UNIQUE_FIELD');
export const changeUniqueFieldComponent = actionCreator('CHANGE_UNIQUE_FIELD_COMPONENT');
export const editUniqueField = actionCreator('EDIT_UNIQUE_FIELD');
export const editUniqueFieldProperty = actionCreator('EDIT_UNIQUE_FIELD_PROPERTY');
export const editUniqueFieldOption = actionCreator('EDIT_UNIQUE_FIELD_OPTION');
export const addUniqueFieldOption = actionCreator('ADD_UNIQUE_FIELD_OPTION');
export const removeUniqueFieldOption = actionCreator('REMOVE_UNIQUE_FIELD_OPTION');
export const moveUniqueFieldOption = actionCreator('MOVE_UNIQUE_FIELD_OPTION');
export const editUniqueFieldValidation = actionCreator('EDIT_UNIQUE_FIELD_VALIDATION');
export const editUniqueFieldTooltip = actionCreator('EDIT_UNIQUE_FIELD_TOOLTIP');
export const removeUniqueFieldTooltip = actionCreator('REMOVE_UNIQUE_FIELD_TOOLTIP');
export const editUniqueFieldContexts = actionCreator('EDIT_UNIQUE_FIELD_CONTEXTS');
export const addUniqueFieldConditionAll = actionCreator('ADD_UNIQUE_FIELD_CONDITION_ALL');
export const removeUniqueFieldConditionAll = actionCreator('REMOVE_UNIQUE_FIELD_CONDITION_ALL');
export const updateUniqueFieldConditionAll = actionCreator('UPDATE_UNIQUE_FIELD_CONDITION_ALL');
export const addUniqueFieldConditionAny = actionCreator('ADD_UNIQUE_FIELD_CONDITION_ANY');
export const removeUniqueFieldConditionAny = actionCreator('REMOVE_UNIQUE_FIELD_CONDITION_ANY');
export const editUniqueFieldReports = actionCreator('EDIT_UNIQUE_FIELD_REPORTS');
export const editUniqueFieldMetadata = actionCreator('EDIT_UNIQUE_FIELD_METADATA');
export const addUniqueFieldMetadata = actionCreator('ADD_UNIQUE_FIELD_METADATA');
export const removeUniqueFieldMetadata = actionCreator('REMOVE_UNIQUE_FIELD_METADATA');
export const moveUniqueFieldMetadata = actionCreator('MOVE_UNIQUE_FIELD_METADATA');

/** Schema Permissions */
export const addSchemaPermissions = actionCreator('ADD_SCHEMA_PERMISSIONS');
export const updateSchemaPermissions = actionCreator('UPDATE_SCHEMA_PERMISSIONS');
export const updateCompanyId = actionCreator('UPDATE_COMPANY_ID');
export const removeSchemaPermission = actionCreator('REMOVE_SCHEMA_PERMISSION');
export const resetSchemaPermissions = actionCreator('RESET_SCHEMA_PERMISSION');
/** Schema Editor Versions */
export const updateVersionsPaginationPage = actionCreator('CHANGE_VERSIONS_PAGINATION_PAGE');

export const updateJsonSchemaEditorPreviewOutputModel = actionCreator(
  'UPDATE_JSON_SCHEMA_EDITOR_PREVIEW_OUTPUT_MODEL'
);
