import _ from 'lodash';

import baseDeliveryOptions from '../../components/schema-editor/base-delivery-options';
import createReducer from './create-reducer';
import { replaceInList } from './list-manipulation';

const companies = [];
const lineItems = [];

const tagOptions = [];
const equipmentOptions = [];
const schemaGroupOptions = [];
const subSchemaOptions = [];
const taxCodeOptions = [{ value: 'TC-1', text: 'TC-1' }];
const itemCodeOptions = [{ value: 'IC-1', text: 'IC-1' }];
const glAccountOptions = [{ value: 'GL-1', text: 'GL-1' }];

const info = {
  tags: [],
  schemaName: '',
  schemaDescription: '',
  schemaGroup: '',
  isConsumer: false,
  subSchema: '',
  pipeline: {
    skipQA: false,
    skipPrep: false,
    skipVerification: false,
    autoSolicit: false
  },
  clientWarning: '',
  preschedule: {
    validations: {
      id: 'render',
      status: 'disabled',
      value: ['disabled', 'required', 'allowed']
    }
  },
  country: '',
  currency: '',
  estimatedTime: {
    min: 0,
    max: 0
  },
  deliveryOptions: baseDeliveryOptions.slice(0, 3),
  requiredEquipment: [],
  metadata: []
};

const initial = {
  info: Object.assign({}, info),
  companies: companies,
  lineItems: lineItems,
  subSchemaOptions: subSchemaOptions,
  schemaGroupOptions: schemaGroupOptions,
  tagOptions: tagOptions,
  equipmentOptions: equipmentOptions,
  glAccountOptions: glAccountOptions,
  taxCodeOptions: taxCodeOptions,
  itemCodeOptions: itemCodeOptions,
  professions: [
    {
      preferred: '',
      allowed: [],
      promote: [],
      conditions: {
        any: []
      }
    }
  ]
};

const reducers = {
  SET_DEFAULT_LINE_ITEMS: (state, value) => {
    //TODO: remove HACK to unfreeze
    initial.lineItems = JSON.parse(JSON.stringify(value));

    return state;
  },

  CLEAR_SCHEMA: (state, value) => {
    initial.info = Object.assign({}, info);
    return initial;
  },
  LOAD_SCHEMA: (state, value) => {
    return {
      ...state,
      ...value,
      ...{ companies: value.companies || [] },
      ...{ lineItems: value.lineItems || [] }
    };
  },
  UPDATE_BASE_DELIVERY_OPTIONS: (state, value) => {
    const updateInfo = {
      ...state.info,
      ...{ deliveryOptions: _.uniqBy(value, 'id') }
    };

    return {
      ...state,
      ...{ info: updateInfo }
    };
  },
  REMOVE_SCHEMA_BASE_DELIVERY_OPTION: (state, value) => {
    const newDeliveryOptions = state.info.deliveryOptions.filter((c) => c.id !== value.id);

    const updateInfo = {
      ...state.info,
      ...{ deliveryOptions: newDeliveryOptions }
    };
    return {
      ...state,
      ...{ info: updateInfo }
    };
  },

  UPDATE_SCHEMA_INFO: (state, value) => {
    const updateInfo = {
      ...value,
      ...{ deliveryOptions: state.info.deliveryOptions }
    };

    return {
      ...state,
      ...{ info: updateInfo }
    };
  },
  UPDATE_SCHEMA_COMPANIES: (state, value) => {
    return {
      ...state,
      ...{ companies: value }
    };
  },
  UPDATE_SCHEMA_LINE_ITEMS: (state, value) => {
    return {
      ...state,
      ...{ lineItems: value }
    };
  },
  UPDATE_SCHEMA_INFO_LINE_ITEM: (state, value) => {
    const { lineItem } = value;

    const lineItems = replaceInList(state.lineItems, lineItem, {
      lineItemId: lineItem.lineItemId
    });

    return {
      ...state,
      ...{ lineItems }
    };
  },
  ADD_SCHEMA_INFO_LINE_ITEM: (state, value) => {
    const newLineItemList = [
      ...state.lineItems,
      {
        lineItemId: value._id,
        lineItem: value,
        name: value.name,
        category: value.category,
        price: {
          ...value.price,
          currency: state.info.currency || value.price.currency
        },
        lookerFee: {
          ...value.lookerFee,
          currency: state.info.currency || value.lookerFee.currency
        },
        conditions: {
          any: []
        },
        required: value.required
      }
    ];

    return {
      ...state,
      ...{ lineItems: newLineItemList }
    };
  },
  ADD_SCHEMA_INFO_COMPANY: (state, value) => {
    const newCompanyList = [
      ...state.companies,
      {
        companyId: value._id,
        company: value,
        name: '',
        lineItems: []
      }
    ];

    return {
      ...state,
      ...{ companies: _.uniqBy(newCompanyList, 'companyId') }
    };
  },
  REMOVE_SCHEMA_INFO_LINE_ITEM: (state, value) => {
    return {
      ...state,
      ...{ lineItems: value }
    };
  },

  REMOVE_SCHEMA_INFO_COMPANY: (state, value) => {
    const newCompanyList = state.companies.filter((c) => c.companyId !== value.companyId);

    return {
      ...state,
      ...{ companies: newCompanyList }
    };
  },
  UPDATE_TAG_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.tagOptions, ...value], 'value');
    return {
      ...state,
      ...{ tagOptions: newValues }
    };
  },
  UPDATE_EQUIPMENT_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.equipmentOptions, ...value], 'value');
    return {
      ...state,
      ...{ equipmentOptions: newValues }
    };
  },

  UPDATE_SCHEMA_GROUP_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.schemaGroupOptions, ...value], 'value');
    return {
      ...state,
      ...{ schemaGroupOptions: newValues }
    };
  },
  UPDATE_SUB_SCHEMA_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.subSchemaOptions, ...value], 'value');
    return {
      ...state,
      ...{ subSchemaOptions: newValues }
    };
  },
  UPDATE_TAXCODE_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.taxCodeOptions, ...value], 'value');
    return {
      ...state,
      ...{ taxCodeOptions: newValues }
    };
  },
  UPDATE_ITEMCODE_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.itemCodeOptions, ...value], 'value');
    return {
      ...state,
      ...{ itemCodeOptions: newValues }
    };
  },
  UPDATE_GLACCOUNT_OPTIONS: (state, value) => {
    const newValues = _.uniqBy([...state.glAccountOptions, ...value], 'value');
    return {
      ...state,
      ...{ glAccountOptions: newValues }
    };
  },

  CLEAR_TAG_OPTIONS: (state, value) => {
    return {
      ...state,
      ...{ tagOptions: [] }
    };
  },

  UPDATE_COMPANY_SCHEMA_NAME: (state, value) => {
    return {
      ...state,
      ...{ companies: replaceInList(state.companies, value, { companyId: value.companyId }) }
    };
  },
  ADD_SCHEMA_INFO_METADATA: (state, value) => {
    return {
      ...state,
      info: { ...state.info, metadata: [...state.info.metadata, value] }
    };
  },

  UPDATE_SCHEMA_INFO_METADATA: (state, value) => {
    return {
      ...state,
      info: { ...state.info, metadata: value.metadata }
    };
  },

  ADD_PROFESSIONS: (state, value) => {
    return {
      ...state,
      professions: [
        ...state.professions,
        {
          preferred: '',
          allowed: [],
          promote: [],
          conditions: {
            any: []
          }
        }
      ]
    };
  },
  UPDATE_PROFESSIONS: (state, value) => {
    return {
      ...state,
      professions: [...value.professions]
    };
  },

  DELETE_PROFESSION: (state, value) => {
    const professionsCopy = [...state.professions];
    professionsCopy.splice(value.index, 1);
    console.log(professionsCopy, 'deleted profession');

    return {
      ...state,
      professions: [...professionsCopy]
    };
  },

  UPDATE_PROFESSIONS_CONDITIONS: (state, value) => {
    return {
      ...state,
      professions: {
        ...state.professions,
        conditions: value.conditions
      }
    };
  },
  UPDATE_SCHEMA_INFO_PROFESSIONS: (state, value) => {
    const { profession, index } = value;
    const professions = [...state.professions];
    professions.splice(index, 1, profession);

    return {
      ...state,
      professions: professions
    };
  },
  DELETE_PROFESSION_ROW: (state, value) => {
    const professionsCopy = [...state.professions];
    professionsCopy.splice(value.index, 1);
    return {
      ...state,
      professions: [...professionsCopy]
    };
  }
};

export default createReducer(initial, reducers);

export { initial };
