export default [
  {
    id: 'engineering',
    label: 'Engineering'
  },
  {
    id: 'accounting',
    label: 'Accounting'
  },
  {
    id: 'sales',
    label: 'Sales'
  },
  {
    id: 'human_resources',
    label: 'Human Resources'
  },
  {
    id: 'operations',
    label: 'Operations'
  }
];
