import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';

import { loadTooltip } from '../../../state/actions';
import createTooltip from '../../../graphql/mutations/createToolTip.graphql';
import updateTooltip from '../../../graphql/mutations/updateTooltip.graphql';
import { TooltipCard } from '../search/tooltip-cards.js';
import SaveActions from '../../common/save-actions.js';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(updateTooltip, {
  name: 'updateTooltip',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {
        _id: props.tooltip._id,
        record: props.tooltip
      }
    };
  }
})
@graphql(createTooltip, {
  name: 'createTooltip',
  options: (props) => {
    return {
      errorPolicy: 'all',
      variables: {}
    };
  }
})
class TooltipViewActions extends Component {
  getEntityToPersist = (tooltip) => {
    tooltip.img = tooltip.img || '';
    tooltip.imageUrl = tooltip.imageUrl || '';

    console.log('getting tooltip to save');

    return tooltip;
  };
  render() {
    const { tooltip, updateTooltip, createTooltip } = this.props;
    const { _id } = this.props.tooltip;
    const id = _id ? _id : 'new';
    const actions = [
      {
        label: id === 'new' ? 'Save' : 'Update',
        icon: 'save',
        action: 'update'
      }
    ];

    return (
      <SaveActions
        actions={actions}
        entity={tooltip}
        entityType={'tooltip'}
        cardComponent={TooltipCard}
        updateQuery={id === 'new' ? createTooltip : updateTooltip}
        loadAction={loadTooltip}
        //clearAction={clearTooltip}
        getEntityToPersist={this.getEntityToPersist}
      />
    );
  }
}

export default TooltipViewActions;
