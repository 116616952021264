import './index.css';

import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Header, Icon, Modal, Segment, Table, Grid } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import {
  addSchemaInfoCompany,
  removeSchemaInfoCompany,
  updateCompanySchemaName,
  updateSchemaCompanies,
  updateSchemaInfoLineItem
} from '../../../../state/actions';
import Placeholder from '../../../placeholder';
import CompanySearch from '../../../company/search';
import CurrencyInput from '../../../currency-input';
import lineItemSearchLayout from '../../../lineItems/search/layout-strategy';
import SearchTable from '../../../search/search-table';
import metaInfoSchema from './../metainfo-schema';

import ConditionEditor from '../lineitem-selector/conditionEditor';

import { removeFromList, replaceInList } from '../../../../state/reducers/list-manipulation';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo,
    editSchemaSchema: state.editSchemaSchema
  };
}

@connect(stateToComponent)
class CompanySelector extends Component {
  state = {
    showCompanyModal: false,
    showLineItemOverrideModal: false,
    companies: [],
    lineItemRequired: null,
    pipeline: {}
  };

  componentDidMount() {
    const { companies } = this.props.editSchemaInfo;
    this.setState({ companies });
  }

  renderSelectCompanyModal = () => {
    const { companies } = this.props.editSchemaInfo;
    const ids = companies.map((x) => x.companyId);
    return (
      <Modal size={'large'} open={this.state.showCompanyModal} onClose={this.closeAddCompanyModal}>
        <Modal.Header>Select a company</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          <CompanySearch loadHandler={this.addCompany} exclude={ids} />
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={this.closeAddCompanyModal}>
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  addCompany = (company) => {
    const { dispatch } = this.props;
    dispatch(addSchemaInfoCompany(company));
    this.closeAddCompanyModal();
  };

  removeCompany = (company) => {
    const { dispatch } = this.props;
    dispatch(removeSchemaInfoCompany(company));
  };

  companyAddMessage = () => {
    return ` You can add some companies, and override how this schema works for
    that company.`;
  };

  removeCompanyLineItemOverride = (company, lineItem) => () => {
    const { dispatch } = this.props;
    const { companies } = this.props.editSchemaInfo;
    const i = company.lineItems.findIndex((a) => a.name === lineItem.name);
    company.lineItems.splice(i, 1);
    dispatch(updateSchemaCompanies(companies));
  };

  renderLineItemLabels = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={7}>Line Items</Table.HeaderCell>
          <Table.HeaderCell width={2}>Category</Table.HeaderCell>
          <Table.HeaderCell width={3}>Price</Table.HeaderCell>
          <Table.HeaderCell width={3}>Looker Fee</Table.HeaderCell>
          <Table.HeaderCell width={1}>Required</Table.HeaderCell>
          <Table.HeaderCell width={1}>Base</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );
  };

  renderLineItem = (company, companyIndex) => (lineItem, i) => {
    const { lineItemRequired } = this.state;
    const isSubform = true;
    const onRemove = this.removeCompanyLineItemOverride;
    let subform = isSubform ? 'subform' : null;
    return (
      <Table.Row key={`schema-lineItem-${lineItem.lineItemId}`} className="lineItem-item">
        <Table.Cell className={`line-item ${subform}`}>
          {lineItem.lineItem ? lineItem.lineItem.name : lineItem.name}
        </Table.Cell>

        <Table.Cell collapsing>
          {lineItem.lineItem ? lineItem.lineItem.category : lineItem.category}
        </Table.Cell>

        <Table.Cell>
          <CurrencyInput
            lineItem={lineItem}
            company={company}
            width={12}
            name={`companies[${companyIndex}].lineItems[${i}].price`}
          />
        </Table.Cell>

        <Table.Cell>
          <CurrencyInput
            lineItem={lineItem}
            company={company}
            width={12}
            name={`companies[${companyIndex}].lineItems[${i}].lookerFee`}
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <Form.Checkbox
            name={`companies[${companyIndex}].lineItems[${i}].required`}
            checked={lineItemRequired === null ? lineItemRequired : lineItem.required}
            onChange={() => {
              company.lineItems.forEach((x) =>
                x.lineItemId === lineItem.lineItemId ? (x.required = !x.required) : null
              );
              this.setState((prevState) => ({ lineItemRequired: !prevState.lineItemRequired }));
            }}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Form.Checkbox
            name={`companies[${companyIndex}].lineItems[${i}].base`}
            onChange={() => {
              company.lineItems.forEach((x) =>
                x.lineItemId === lineItem.lineItemId ? (x.base = !x.base) : null
              );
            }}
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <Button
            icon="check circle outline"
            basic
            onClick={() => {
              this.setState({
                editingLineItem: lineItem,
                showConditionModal: true
              });
            }}
            title="Edit line item conditions"
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <Button
            icon="trash"
            basic
            onClick={onRemove(company, lineItem)}
            title="Remove This Line Item"
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  renderLineItemList = (company, companyIndex) => {
    const { lineItems } = company;
    return (
      <Table basic="very" className={`line-items subform`}>
        {this.renderLineItemLabels()}
        <Table.Body>
          {_.map(lineItems, this.renderLineItem(company, companyIndex).bind(this))}
        </Table.Body>
      </Table>
    );
  };

  renderCompanyLineItemList = (company, companyIndex) => {
    const { lineItems } = company;

    return (
      <Table.Row className="lineItem-subform" basic>
        <Table.Cell colspan="4">
          {lineItems.length ? this.renderLineItemList(company, companyIndex) : null}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderSelectLineItemOverrideModal = (company) => {
    const schema = this.props.editSchemaInfo;
    if (this.state.showLineItemOverrideModal === false) {
      return null;
    }

    const remainingLineItems = schema.lineItems
      .filter((a) => {
        return company.lineItems.every((b) => a.name !== b.name);
      })
      .map((x) => {
        if (x.lineItem) {
          return { ...x, ...x.lineItem };
        }

        return x;
      });

    const model = {};
    Object.keys(lineItemSearchLayout[0].model).forEach((key) => {
      model[key] = _.clone(lineItemSearchLayout[0].model[key]);
    });
    model[0].property = 'name';
    model[1].property = 'category';

    const lineItemSelectionList = (
      <div className="search-workspace">
        <SearchTable
          items={remainingLineItems}
          model={model}
          actions={{
            select: (lineItem) => {
              lineItem.price.currency = schema.info.currency;
              lineItem.lookerFee.currency = schema.info.currency;
              company.lineItems.push(lineItem);
              this.setState({
                showLineItemOverrideModal: false
              });
            }
          }}
        />
      </div>
    );

    return (
      <Modal
        size={'large'}
        open={this.state.showLineItemOverrideModal}
        onClose={this.closeLineItemModal}
      >
        <Modal.Header>Select a Line Item</Modal.Header>
        <Modal.Content scrolling className={`modal-search`}>
          {lineItemSelectionList}
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            onClick={() => {
              this.setState({
                showLineItemOverrideModal: false
              });
            }}
          >
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  removeConditionAll = (lineItem, index, allIndex) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any[index].all = removeFromList(
      lineItem.conditions.any[index].all,
      allIndex
    );
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  updateConditionAll = (lineItem, index, all, property) => (e, { value }) => {
    const { dispatch } = this.props;
    const newItem = { ...all, [property]: value };
    lineItem.conditions.any[index].all = replaceInList(
      lineItem.conditions.any[index].all,
      newItem,
      all
    );
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  addConditionalAll = (lineItem, index) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any[index].all.push({
      field: '',
      operator: '',
      value: ''
    });
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  removeConditionAny = (lineItem, index) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any = removeFromList(lineItem.conditions.any, index);
    dispatch(updateSchemaInfoLineItem(lineItem));
  };
  addConditionalAny = (lineItem) => () => {
    const { dispatch } = this.props;
    lineItem.conditions.any.push({ all: [] });
    dispatch(updateSchemaInfoLineItem(lineItem));
  };

  renderLineItemConditionModal = () => {
    return (
      <Modal
        size={'large'}
        open={this.state.showConditionModal}
        onClose={() => this.setState({ showConditionModal: false, editingLineItem: null })}
      >
        <Modal.Header>Select a line item</Modal.Header>
        <ConditionEditor
          lineItem={this.state.editingLineItem}
          removeConditionAll={this.removeConditionAll}
          updateConditionAll={this.updateConditionAll}
          addConditionalAll={this.addConditionalAll}
          removeConditionAny={this.removeConditionAny}
          addConditionalAny={this.addConditionalAny}
        />
        <Modal.Actions>
          <Button
            basic
            onClick={() =>
              this.setState({
                showConditionModal: false,
                editingLineItem: null
              })
            }
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  overrideLineItemCta = ({ company, isEmpty }) => () => {
    const { lineItems } = this.props.editSchemaInfo;

    if (!lineItems.length || company.lineItems.length == lineItems.length) {
      return 'No lineItems exist to modify';
    }

    const modifier = company.lineItems.length ? 'Another ' : 'a';

    return (
      <Button
        icon
        onClick={() => {
          this.setState({
            showLineItemOverrideModal: company.companyId
          });
        }}
        labelPosition="left"
        title="This will override the price or looker fee of an lineItem assigned to this company."
      >
        {`Override ${modifier} Line Item`}
        <Icon name="add" />
      </Button>
    );
  };

  renderPipelineOptionsOverrideButtons = (company) => {
    const { dispatch, editSchemaInfo } = this.props;
    const { pipeline } = company;
    const { skipQA, skipPrep, skipVerification, autoSolicit } = pipeline;

    const pipelineOptions = [
      {
        name: 'skipQA',
        label: 'Skip QA',
        key: 'skipqa',
        value: skipQA
      },
      {
        name: 'skipPrep',
        label: 'Skip Prep',
        key: 'skipprep',
        value: skipPrep
      },
      {
        name: 'skipVerification',
        label: 'Skip Verification',
        key: 'skipverification',
        value: skipVerification
      },
      {
        name: 'autoSolicit',
        label: 'Auto Solicit',
        key: 'autosolicit',
        value: autoSolicit
      }
    ];

    return (
      <Grid relaxed>
        <Grid.Column>
          <Segment basic>
            <h4 style={{ fontSize: '0.92857143em', fontWeight: 'bold' }}>Pipeline Override</h4>
          </Segment>
        </Grid.Column>
        <Grid.Column floated="right">
          {' '}
          <Segment basic>
            {' '}
            <Form.Group>
              {pipelineOptions.map((i) => (
                <Form.Checkbox
                  key={i.key + i.label}
                  rows={2}
                  toggle
                  name={i.name}
                  label={i.label}
                  defaultChecked={pipeline[i.name]}
                  onChange={(e, { name, checked }) => {
                    company.pipeline[name] = checked;
                    const companyIndex = editSchemaInfo.companies.findIndex(
                      (i) => i.companyId === company.companyId
                    );
                    const updatedCompanies = [...editSchemaInfo.companies];
                    updatedCompanies[companyIndex] = company;
                    dispatch(updateSchemaCompanies(updatedCompanies));
                  }}
                />
              ))}
              <Button
                icon="trash"
                basic
                onClick={() => {
                  company.pipeline = null;
                  this.setState({ pipeline: null });
                }}
                title="Remove the pipeline override"
              />
            </Form.Group>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  };

  overridePipelineOptionsCta = ({ company, isEmpty }) => () => {
    const { info } = this.props.editSchemaSchema;
    const { info: schemaInfo } = this.props.editSchemaInfo;

    const companies = schemaInfo.pipeline.skipQA === null ? info.pipeline : schemaInfo.pipeline;

    if (company.pipeline) {
      return 'No pipeline exist to modify';
    }

    return (
      <Button
        icon
        onClick={() => {
          company.pipeline = { ...companies };
          this.setState({
            showPipelineOverrideButtons: true
          });
        }}
        labelPosition="left"
        title="This will override the pipeline options assigned to this company."
      >
        {`Override Pipeline`}
        <Icon name="add" />
      </Button>
    );
  };

  renderCompanyLabels = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Schema Name</Table.HeaderCell>
          <Table.HeaderCell>LineItem Override</Table.HeaderCell>
          <Table.HeaderCell>Pipeline Override</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );
  };

  renderCompanyItem = (company, i) => {
    const { schemaGroup, subSchema } = this.props.editSchemaInfo.info;

    const { lineItems, pipeline } = company;
    if (!company.company) return <div>Invalid Company</div>;
    return (
      <>
        <Table.Row key={`schema-company-${company.companyId}`} className="company-list-item">
          <Table.Cell>{company.company.name}</Table.Cell>
          <Table.Cell>
            <Form.Input
              fluid
              name={`companies[${i}].name`}
              onChange={(e, { name, value }) => {
                const updatedCompany = { ...company };
                updatedCompany.name = value;
                this.props.dispatch(updateCompanySchemaName(updatedCompany));
              }}
              placeholder={`${company.company.name} : ${schemaGroup}-${subSchema}`}
            />
          </Table.Cell>
          <Table.Cell width={4}>
            {lineItems.length ? null : this.overrideLineItemCta({ company, isEmpty: true })()}
            {lineItems.length ? this.overrideLineItemCta({ company, isEmpty: false })() : null}
          </Table.Cell>
          <Table.Cell>
            {pipeline ? null : this.overridePipelineOptionsCta({ company, isEmpty: true })()}
            {pipeline ? this.overridePipelineOptionsCta({ company, isEmpty: false })() : null}
          </Table.Cell>
          <Table.Cell collapsing className="actions">
            <Button
              icon="trash"
              basic
              onClick={() => this.removeCompany(company)}
              title="Remove this company"
            />
          </Table.Cell>
        </Table.Row>
        {lineItems.length ? this.renderCompanyLineItemList(company, i) : null}
        {pipeline ? this.renderPipelineOptionsOverrideButtons(company, i) : null}

        {this.state.showLineItemOverrideModal === company.companyId
          ? this.renderSelectLineItemOverrideModal(company)
          : null}
      </>
    );
  };

  renderCompanyList = () => {
    const { companies } = this.props.editSchemaInfo;
    return (
      <Table basic="very" className={`line-items`}>
        {this.renderCompanyLabels()}
        <Table.Body>{_.map(companies, this.renderCompanyItem.bind(this))} </Table.Body>
      </Table>
    );
  };

  addCompanyCta = () => {
    return (
      <Segment attached="bottom">
        <Button icon labelPosition="left" primary size="small" onClick={this.addCompanyModal}>
          <Icon name="add" /> Add Company
        </Button>
      </Segment>
    );
  };

  addCompaniesCTA = () => {
    return (
      <Placeholder
        icon="building outline"
        header="No Companies Use this Schema (Yet)"
        subheader={this.companyAddMessage()}
      />
    );
  };

  addCompanyModal = () => {
    this.setState({
      showCompanyModal: true
    });
  };

  closeAddCompanyModal = () => {
    this.setState({
      showCompanyModal: false
    });
  };

  render() {
    const { dispatch } = this.props;
    const { companies } = this.props.editSchemaInfo;
    return (
      <div>
        <Header size="medium">
          Companies
          <Header.Subheader>
            {companies && companies.length ? this.companyAddMessage() : ''}
          </Header.Subheader>
        </Header>
        <ValidatedForm
          whitelist={[CurrencyInput]}
          ignoreChildrenList={[CurrencyInput]}
          intialValue={{ companies }}
          value={this.state.companies.length > 0 ? this.state.companies : { companies }}
          validateSchema={{ companies: metaInfoSchema.companies }}
          onChange={(event, { field, form, submitting }, err) => {
            this.setState({ companies: form.companies });
            form.companies.map((company) => (company.name = company.name.trim()));
            dispatch(updateSchemaCompanies(form.companies));
          }}
        >
          <Segment.Group>
            <Segment>
              {companies.length ? this.renderCompanyList() : this.addCompaniesCTA()}
            </Segment>
            {this.addCompanyCta()}
          </Segment.Group>
        </ValidatedForm>
        {this.renderSelectCompanyModal()}
        {this.renderLineItemConditionModal()}
      </div>
    );
  }
}

export default CompanySelector;
