import '../index.css';

import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Form, Header, List, Segment } from 'semantic-ui-react';
import ValidatedForm from 'semantic-ui-react-validated-form';

import QueryGetMapAtVersion from '../../../graphql/queries/getMapAtVersion.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(QueryGetMapAtVersion, {
  name: 'mapData',
  options: (props) => {
    const { id, version } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id,
        version
      }
    };
  }
})
class MapView extends Component {
  renderFormList(data) {
    const formData = data.schemaMany;
    const formList = formData
      ? formData.map((form) => {
          const description = `
          fields: ${form.fields.length}
          `;

          return (
            <List.Item key={`associated-form-${form._id}`}>
              <List.Icon name="file alternate outline" size="large" verticalAlign="middle" />
              <List.Content>
                <List.Header as="a">{form.info.schemaName}</List.Header>
                <List.Description as="a">{description}</List.Description>
              </List.Content>
            </List.Item>
          );
        })
      : null;

    return formList ? (
      <Segment>
        <Header>Associated Forms:</Header>
        <List divided relaxed>
          {formList}
        </List>
      </Segment>
    ) : null;
  }

  renderMap(data) {
    const map = data.mapAtVersion || data;

    if (data.loading || !map) {
      return <div> Loading </div>;
    }
    const formData = map;

    return (
      <div className="unique-field-view">
        <ValidatedForm intialValue={formData} value={formData} readOnly={true}>
          <Form.Field>{map._id}</Form.Field>
          <Form.Field>{map.id}</Form.Field>
          <Form.Field>{map.name}</Form.Field>
        </ValidatedForm>

        {this.renderFormList(data)}
      </div>
    );
  }
  render() {
    let { map, mapData } = this.props;

    return (
      <div className="search-workspace uniqueField-search-workspace">
        {this.renderMap(map || mapData)}
      </div>
    );
  }
}
export default MapView;
