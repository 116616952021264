import '../index.css';

import * as jsondiffpatch from 'jsondiffpatch';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';

import QueryGetMapDiff from '../../../graphql/queries/getMapDiff.graphql';

function stateToComponent(state) {
  return {
    who: state.who
  };
}

@connect(stateToComponent)
@graphql(QueryGetMapDiff, {
  name: 'mapDiff',
  options: (props) => {
    const { id, version } = props.match.params;
    return {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
      context: {},
      variables: {
        id,
        version
      }
    };
  }
})
class MapView extends Component {
  render() {
    const { mapDiff } = this.props;
    if (mapDiff.loading) {
      return <div />;
    }
    const diff = mapDiff.mapDiff.diff;

    const html = jsondiffpatch.formatters.html.format(diff);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

export default MapView;
