import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Header, Icon, Modal, Segment, Table } from 'semantic-ui-react';
import ConditionEditor from './conditionEditor';
import './index.css';

import {
  updateProfessions,
  addProfessions,
  updateSchemaInfoProfessions,
  deleteProfession
} from '../../../../state/actions';

import { removeFromList, replaceInList } from '../../../../state/reducers/list-manipulation';
import Placeholder from '../../../placeholder';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo
  };
}

const options = [
  { text: 'Looker', value: 'looker' },
  {
    text: 'Ladder Assist',
    value: 'ladder assist'
  },
  {
    text: 'Notary',
    value: 'notary'
  },
  {
    text: 'Roof Inspector',
    value: 'roof inspector'
  },
  {
    text: 'Home Inspector',
    value: 'home inspector'
  },
  {
    text: 'Home Inspector with Roof',
    value: 'home inspector with roof'
  },
  {
    text: 'Vehicle Transport',
    value: 'vehicle transport'
  },
  {
    text: 'Property Inspector',
    value: 'property inspector'
  }
];

@connect(stateToComponent)
class ProfessionsSelector extends Component {
  state = {
    showConditionModal: false,
    showLineItemModal: false,
    editingProfessions: null,
    editingProfessionsIndex: -1
  };

  openLineItemModal = () => {
    this.setState({ showLineItemModal: true });
  };

  closeLineItemModal = () => {
    this.setState({ showLineItemModal: false });
  };

  emptyCta = () => {
    return (
      <Placeholder
        icon="list ol outline"
        header="No Line Items for this Schema (Yet)"
        subheader="This schema will probably need LineItems, please add them here."
      />
    );
  };

  removeConditionAll = (profession, i, allIndex, professionIndex) => () => {
    const { dispatch } = this.props;
    profession.conditions.any[i].all = removeFromList(profession.conditions.any[i].all, allIndex);
    dispatch(updateSchemaInfoProfessions({ profession, professionIndex }));
  };
  //add values in and conidtion...
  updateConditionAll = (profession, i, all, property, index) => (e, { value }) => {
    const { dispatch } = this.props;
    const newItem = { ...all, [property]: value };
    profession.conditions.any[i].all = replaceInList(
      profession.conditions.any[i].all,
      newItem,
      all
    );
    dispatch(updateSchemaInfoProfessions({ profession, index }));
  };

  // add and condition
  addConditionalAll = (profession, i, index) => () => {
    const { dispatch } = this.props;

    profession.conditions.any[i].all.push({
      field: '',
      operator: '',
      value: ''
    });

    dispatch(updateSchemaInfoProfessions({ profession, index }));
  };

  // remove condition
  removeConditionAny = (profession, i, index) => () => {
    const { dispatch } = this.props;
    profession.conditions.any = removeFromList(profession.conditions.any, i);
    dispatch(updateSchemaInfoProfessions({ profession, index }));
  };

  //add or condition
  addConditionalAny = (profession, index) => () => {
    const { dispatch } = this.props;

    profession.conditions.any.push({ all: [] });

    dispatch(updateSchemaInfoProfessions({ profession, index }));
  };

  renderProfessionsConditionModal = () => {
    return (
      <Modal
        size={'large'}
        open={this.state.showConditionModal}
        onClose={() => this.setState({ showConditionModal: false, editingProfessions: null })}
      >
        <ConditionEditor
          profession={this.state.editingProfessions}
          removeConditionAll={this.removeConditionAll}
          updateConditionAll={this.updateConditionAll}
          addConditionalAll={this.addConditionalAll}
          removeConditionAny={this.removeConditionAny}
          addConditionalAny={this.addConditionalAny}
          index={this.state.editingProfessionsIndex}
        />
        <Modal.Actions>
          <Button
            basic
            onClick={() =>
              this.setState({
                showConditionModal: false,
                editingProfessions: null
              })
            }
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  addProfession = () => {
    return (
      <Segment attached="bottom">
        <Button
          icon
          labelPosition="left"
          primary
          size="small"
          onClick={() => this.props.dispatch(addProfessions())}
        >
          <Icon name="add" /> Add Profession
        </Button>
      </Segment>
    );
  };

  renderLineItemLabels = () => {
    return (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>Preferred</Table.HeaderCell>
          <Table.HeaderCell width={3}>Required</Table.HeaderCell>
          <Table.HeaderCell width={3}>Allowed</Table.HeaderCell>
          <Table.HeaderCell width={3}>Promote</Table.HeaderCell>
          <Table.HeaderCell width={3}>Conditions</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
    );
  };

  onChangeProfessions = (e, data, index) => {
    const professionsCopy = [...this.props.editSchemaInfo.professions];
    professionsCopy[index][data.name] = data.value;

    this.props.dispatch(
      updateProfessions({
        professions: professionsCopy
      })
    );
  };

  renderProfessionBody = (profession, idx) => {
    return (
      <Table.Row>
        <Table.Cell collapsing>
          <Dropdown
            placeholder="Select"
            options={options}
            name="preferred"
            value={profession.preferred}
            onChange={(e, data) => this.onChangeProfessions(e, data, idx)}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Dropdown
            placeholder="Select"
            options={options}
            name="required"
            multiple
            selection
            value={profession.required}
            onChange={(e, data) => this.onChangeProfessions(e, data, idx)}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Dropdown
            placeholder="Select"
            options={options}
            multiple
            name="allowed"
            value={profession.allowed}
            onChange={(e, data) => this.onChangeProfessions(e, data, idx)}
          />
        </Table.Cell>

        <Table.Cell collapsing>
          <Dropdown
            placeholder="Select"
            options={options}
            multiple
            name="promote"
            value={profession.promote}
            onChange={(e, data) => this.onChangeProfessions(e, data, idx)}
          />
        </Table.Cell>
        {/**
         * condition button
         * dont' remove this code
         */}

        <Table.Cell collapsing>
          <Button
            icon="check circle outline"
            basic
            onClick={() => {
              this.setState({
                editingProfessions: profession,
                showConditionModal: true,
                editingProfessionsIndex: idx
              });
            }}
            title="Edit professions conditions"
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Table.Cell collapsing>
            <Button
              icon="trash"
              basic
              onClick={() => this.props.dispatch(deleteProfession({ index: idx }))}
              title="Remove This Line Item"
            />
          </Table.Cell>
        </Table.Cell>
      </Table.Row>
    );
  };

  renderProfessions = () => {
    const { professions } = this.props.editSchemaInfo;
    return (
      <Table basic="very">
        {this.renderLineItemLabels()}
        <Table.Body>{_.map(professions, this.renderProfessionBody)}</Table.Body>
      </Table>
    );
  };

  renderProfessionsSegment = () => {
    return (
      <Segment.Group>
        <Segment>{this.renderProfessions()}</Segment>
        {this.addProfession()}
      </Segment.Group>
    );
  };

  render() {
    return (
      <div>
        <Header size="medium">Professions</Header>
        {this.renderProfessionsSegment()}
        {this.renderProfessionsConditionModal()}
      </div>
    );
  }
}

export default ProfessionsSelector;
