import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Form, Header, Label, Segment } from 'semantic-ui-react';
import Placeholder from '../../../placeholder';
import { addSchemaInfoMetaData, updateSchemaInfoMetadata } from '../../../../state/actions';

function stateToComponent(state) {
  return {
    editSchemaInfo: state.editSchemaInfo
  };
}

@connect(stateToComponent)
class MetadataSelector extends Component {
  noOptionsHtml = () => {
    return (
      <Form.Group>
        <Form.Field>
          <label>Add Metadata Item</label>
          <Button.Group basic>
            <Button icon="add" onClick={this.addAtIndex} />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  addAtIndex = () => {
    this.props.dispatch(
      addSchemaInfoMetaData({
        key: 'key',
        value: 'value'
      })
    );
  };

  removeAtIndex = (index) => {
    const { dispatch, editSchemaInfo } = this.props;
    const metadata = editSchemaInfo.info.metadata;
    metadata.splice(index, 1);

    dispatch(
      updateSchemaInfoMetadata({
        metadata
      })
    );
  };

  changeIndex = (option, dir) => (event, data) => {
    const { dispatch, field, match } = this.props;
    const isSchemaEditor = match.path.startsWith('/schema-editor');

    const action = isSchemaEditor ? moveSchemaFieldMetadata : moveUniqueFieldMetadata;

    dispatch(
      action({
        field: field,
        option: option,
        distance: dir
      })
    );
  };

  editOptionKey = (data, index) => {
    const { dispatch, editSchemaInfo } = this.props;
    const newValue = data.value;
    const metadata = editSchemaInfo.info.metadata;
    metadata[index] = {
      key: newValue,
      value: metadata[index].value
    };

    dispatch(
      updateSchemaInfoMetadata({
        metadata
      })
    );
  };

  editOptionValue = (data, index) => {
    const { dispatch, editSchemaInfo } = this.props;
    const newValue = data.value;
    const metadata = editSchemaInfo.info.metadata;
    metadata[index] = {
      key: metadata[index].key,
      value: newValue
    };

    dispatch(
      updateSchemaInfoMetadata({
        metadata
      })
    );
  };

  metadataToEditHtml = (option, index) => {
    const { metadata } = this.props.editSchemaInfo.info;
    const { key, value } = option;
    const listKey = `metadata-${index}`;

    const valueNotUnique =
      _.filter(metadata, (opt) => {
        return opt.value === option.value;
      }).length > 1;

    const keyNotUnique =
      _.filter(metadata, (opt) => {
        return opt.key === option.key;
      }).length > 1;

    return (
      <Form.Group key={listKey}>
        <Form.Field width={1}>
          <label>&nbsp;</label>
          <Label circular size="big">
            {index}
          </Label>
        </Form.Field>
        <Form.Input
          label="Key"
          width={5}
          value={key}
          error={keyNotUnique}
          onChange={(event, data) => {
            this.editOptionKey(data, index);
          }}
        />
        <Form.Input
          label="Value"
          width={5}
          value={value}
          error={valueNotUnique}
          onChange={(event, data) => {
            this.editOptionValue(data, index);
          }}
        />
        <Form.Field width={2}>
          <label>Add/Remove</label>
          <Button.Group basic fluid>
            <Button
              icon="add"
              basic
              onClick={() => this.addAtIndex()}
              title="Add another option at this index"
            />

            <Button
              icon="trash"
              basic
              onClick={() => this.removeAtIndex(index)}
              title="Remove this option"
            />
          </Button.Group>
        </Form.Field>
      </Form.Group>
    );
  };

  _renderEmpty = () => {
    return (
      <Placeholder
        icon="code"
        header="No Metadata for this Schema (Yet)"
        subheader="This schema will probably need Metadata, please add them here."
      />
    );
  };

  renderMetadataSegment = (metadata) => {
    if (metadata) {
      const options =
        metadata.length > 0
          ? _.map(metadata, this.metadataToEditHtml.bind(this))
          : this.noOptionsHtml();
      return (
        <Segment.Group>
          <Segment>
            <Form>{options}</Form>
          </Segment>
        </Segment.Group>
      );
    }
    return null;
  };

  render() {
    const { metadata } = this.props.editSchemaInfo.info;
    return (
      <div>
        <Header size="medium">Metadata</Header>
        {this.renderMetadataSegment(metadata)}
      </div>
    );
  }
}

export default MetadataSelector;
